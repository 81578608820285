/* eslint-disable import/no-named-default */
/* eslint-disable max-lines */
import { useState, useMemo, useEffect } from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import {
  ComposedChart,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
  ReferenceLine,
} from 'recharts'
import { useI18n, useRouter, useSize } from '@popety_io/popety-io-lib'
import chroma from 'chroma-js'
import Switch from '@mui/material/Switch'
import { default as TooltipMui } from '@mui/material/Tooltip'

import CustomFilters from '../../../components/CustomFilters'
import EnergyHeatConsumptionFilters from './components/EnergyHeatConsumptionFilters'
import EnergyHeatConsumptionDesc from './EnergyHeatConsumptionDesc'

const CustomizedLegend = ({ current }: { current?: string }) => {
  const { t } = useI18n()

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      gap={1}
    >
      {current && (
        <>
          <Box
            sx={{
              width: '10px',
              height: '10px',
              backgroundColor: '#00ade6',
            }}
          />
          <Typography variant="body2">{t(`common.currentIDC`)}</Typography>
        </>
      )}
      <Box
        sx={{
          width: '10px',
          height: '10px',
          backgroundColor: '#E63900',
        }}
      />
      <Typography variant="body2">
        {t(`common.Réno énérgé obligatoire 2022`)}
      </Typography>
      <Box
        sx={{
          width: '10px',
          height: '10px',
          backgroundColor: '#FC8723',
        }}
      />
      <Typography variant="body2">
        {t(`common.Réno énérgé obligatoire 2027`)}
      </Typography>
      <Box
        sx={{
          width: '10px',
          height: '10px',
          backgroundColor: '#FFCB60',
        }}
      />
      <Typography variant="body2">
        {t(`common.Réno énérgé obligatoire 2031`)}
      </Typography>
    </Box>
  )
}

const CustomTooltip = ({ active, payload }: any) => {
  if (!active || !payload?.length) return null

  const { t } = useI18n()

  return (
    <Box
      sx={{
        padding: '10px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
      }}
    >
      <Typography variant="body1">{`${t('common.Indice de chaleur')} : ${payload[0].payload.key}`}</Typography>
      <Typography variant="body1">{`${t('common.buildingNumber')} : ${payload[0].value}`}</Typography>
      {payload?.[0]?.payload?.currentHeatConsumption && (
        <Typography
          variant="body1"
          color="#00ade6"
        >{`${t('common.currentIDC')} : ${payload[0].payload.currentHeatConsumption}`}</Typography>
      )}
      {payload?.[0]?.payload?.renovationIndice && (
        <Typography variant="body1" color={payload?.[0]?.payload?.color}>
          {`${t('common.Réno énérgé obligatoire')} : ${payload[0].payload.renovationIndice}`}
        </Typography>
      )}
    </Box>
  )
}

const EnergyHeatConsumptionAround = ({
  data,
  heatConsumptionIndex,
  loading,
  type,
  toggleDisplay,
  defaultShow = true,
}: any) => {
  const { t } = useI18n()
  const { ref, width = 300 } = useSize()

  const currentHeatConsumptionIndex = useMemo(
    () => data?.find((entry: any) => entry?.currentHeatConsumption)?.from,
    [data, heatConsumptionIndex],
  )

  const { query } = useRouter()

  const [show, setShow] = useState(defaultShow)

  const onToggle = (checked: boolean) => {
    toggleDisplay?.('EnergyHeatConsumptionAroundSection')(checked)
    setShow(checked)
  }

  useEffect(() => {
    setShow(defaultShow)
  }, [defaultShow])

  const chromaScale = (value: number) => {
    const colorScale = chroma
      .scale([
        '#82C066',
        '#93C669',
        '#AACF6D',
        '#BDD670',
        '#DBDE75',
        '#F8E97B',
        '#F6DD77',
        '#F2D273',
        '#F1C96F',
        '#EEBF6C',
        '#EAB267',
        '#E7A363',
        '#E4945D',
        '#E2895A',
        '#DF8058',
        '#DF7955',
        '#DD6951',
        '#DB5E4E',
        '#BA403D',
        '#B32926',
      ])
      .mode('lch')
      .colors(data?.length)

    return colorScale[value]
  }

  const xAxisTicks = [0, 200, 400, 600, 800, 950]

  return (
    <Box paddingY={2}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBlock: '16px',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: '4px',
            fontSize: '1.1rem',
            textTransform: 'uppercase',
          }}
        >
          {t('common.Indice de chaleur')} (IDC)
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {type === 'estimate' && (
            <TooltipMui title={t('common.disable_button_tooltip')}>
              <Switch
                checked={show}
                onChange={(e) => onToggle(e.target.checked)}
                size="small"
              />
            </TooltipMui>
          )}
        </Box>
      </Box>

      {show && (
        <>
          {heatConsumptionIndex && (
            <EnergyHeatConsumptionDesc current={heatConsumptionIndex} />
          )}
          <CustomFilters
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
            filters={{
              energyPopetyClassification: query.energyPopetyClassification,
            }}
          >
            <EnergyHeatConsumptionFilters />
          </CustomFilters>
        </>
      )}

      {loading ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
          }}
        >
          <Skeleton
            variant="rectangular"
            sx={{ height: '250px', borderRadius: 2, width: '100%' }}
          />
        </Box>
      ) : (
        <Box ref={ref} sx={{ width: '100%', flexGrow: 1 }}>
          <ComposedChart
            width={width}
            height={300}
            data={data}
            barCategoryGap={0}
            barGap={0}
            style={{
              display: show ? 'block' : 'none',
              marginRight: '20px',
            }}
          >
            <XAxis
              dataKey="from"
              ticks={xAxisTicks}
              tickLine={false}
              interval={0}
              tickFormatter={(tick: any) => (tick === 950 ? '950-*' : tick)}
              textAnchor="end"
            />
            <YAxis
              label={{
                value: t('common.buildingNumber'),
                angle: -90,
                position: 'insideLeft',
              }}
            />
            <Tooltip content={CustomTooltip} />
            <Bar dataKey="doc_count">
              {data?.map((entry: any, index: number) => (
                <Cell key={`cell-${index}`} fill={chromaScale(index)} />
              ))}
            </Bar>
            {currentHeatConsumptionIndex && (
              <>
                <ReferenceLine
                  x={currentHeatConsumptionIndex}
                  stroke="#00ade6"
                  strokeWidth={1}
                  isFront
                />
              </>
            )}
            <ReferenceLine x={550} stroke="#FFCB60" strokeWidth={1} isFront />
            <ReferenceLine x={650} stroke="#FC8723" strokeWidth={1} isFront />
            <ReferenceLine x={800} stroke="#E63900" strokeWidth={1} isFront />
            <Legend
              content={
                <CustomizedLegend current={currentHeatConsumptionIndex} />
              }
            />
          </ComposedChart>
        </Box>
      )}
    </Box>
  )
}

export default EnergyHeatConsumptionAround
