/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import {
  Caroussel,
  formatPrice,
  FilterChips,
  useI18n,
  format,
  StatusContainer,
} from '@popety_io/popety-io-lib'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { getPlotLocation } from '../../../LandDetails/LandDetailsMap/utils'

import {
  getLandSummary,
  landLayersSelector,
  getCityGeojson,
  // cityGeoCenterSelector,
  cityGeojsonSelector,
} from '../../../LandDetails/redux'
import ListingDetailMap from '../ListingDetailMap'
import { ListingDetailsRoot } from './ListingDetails.style'

const ListingDetails = ({
  data: listing,
  showMap = true,
  showDescription = true,
  sx,
  children,
  autoSwipe = true,
  isNotGetSummary = false,
}: Record<string, any>) => {
  const { t } = useI18n()

  const [inactiveDays, setInactiveDays] = useState<number | undefined>(
    undefined,
  )

  const rent = listing?.deal_type === 'rent'

  // if (!showMap && !showDescription) {
  //   delete listing?.description
  // }

  const chips = [
    {
      label: listing?.property_type,
      value: listing?.property_type,
      key: 'property_type',
    },
    {
      label: `${t('listing.rooms', { count: listing?.rooms_nb })}`,
      value: listing?.rooms_nb,
      key: 'rooms_nb',
    },
    { label: `${listing?.area} m2`, value: listing?.area, key: 'area' },
    {
      label: listing?.YearOfConstruction,
      value: listing?.YearOfConstruction,
      key: 'YearOfConstruction',
    },
    { label: listing?.Floor, value: listing?.Floor, key: 'Floor' },
    {
      label: `${listing?.UsefulArea} m2`,
      value: listing?.UsefulArea,
      key: 'UsefulArea',
    },
  ].filter((i) => i.value)

  const features = [
    ...(listing?.features || []),
    ...(listing?.attributes || []),
  ]
    .filter(Boolean)
    .map((f) => ({ label: f }))

  const dispatch: any = useDispatch()

  useEffect(() => {
    if (isNotGetSummary) return

    if (!listing?.exact_location) {
      dispatch(getCityGeojson({ city: listing?.city_name }))
    }
  }, [dispatch, listing?.exact_location, isNotGetSummary])

  const landLayer = useSelector(landLayersSelector)
  const cityGeojson = useSelector(cityGeojsonSelector)
  const locationLayer = getPlotLocation(cityGeojson)

  useEffect(() => {
    const activeListingTimestampStr = listing?.active_listing_timestamp
    const inactiveStatusTimestampStr = listing?.inactive_status_timestamp

    if (
      !listing?.active &&
      activeListingTimestampStr &&
      inactiveStatusTimestampStr
    ) {
      const activeListingTimestamp: Date = new Date(activeListingTimestampStr)
      const inactiveStatusTimestamp: Date = new Date(inactiveStatusTimestampStr)

      const timeDifference: number =
        inactiveStatusTimestamp.getTime() - activeListingTimestamp.getTime()

      const millisecondsInOneDay: number = 24 * 60 * 60 * 1000

      const numberOfDays: number = Math.floor(
        timeDifference / millisecondsInOneDay,
      )

      if (numberOfDays > 0) {
        setInactiveDays(numberOfDays)
      } else if (numberOfDays === 0) {
        setInactiveDays(1)
      } else {
        setInactiveDays(undefined)
      }
    }
  }, [listing])

  const calculateDate = (date2: any) => {
    try {
      const newdate1: any = new Date(Date.now())
      const newdate2: any = new Date(date2)

      // Calculate the time difference in milliseconds
      const differenceInMilliseconds = Math.abs(newdate2 - newdate1)

      // Convert the time difference to days
      const differenceInDays = Math.floor(
        differenceInMilliseconds / (24 * 60 * 60 * 1000),
      )

      return differenceInDays
    } catch (e) {
      return 0
    }
  }

  const listingDuration =
    listing?.listing_duration === 0 ? '0' : listing?.listing_duration

  return (
    <ListingDetailsRoot container p={2} sx={sx}>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Grid item xs={12} container mb={1}>
          <Grid xs={12} md={showMap ? 8 : 12} item>
            <Typography fontSize="1.23rem" fontWeight={500}>
              {listing.title}
            </Typography>
            <Box
              className="addedDate"
              sx={{
                mb: 1,
              }}
            >
              {listing?.address?.toUpperCase()}
            </Box>
            {!!chips?.length && (
              <FilterChips
                items={chips}
                size="small"
                sx={{
                  flexWrap: 'wrap',
                }}
                getChipKey={(item: any) => item.key}
              />
            )}
            {!!listing.price && (
              <Typography
                fontSize="1.23rem"
                mt={1}
                fontWeight={500}
                sx={{
                  mt: 1,
                }}
              >
                {formatPrice(listing.price, 0)}
                {listing.deal_type !== 'purchase' ? ' net ' : ''}
                /m2
              </Typography>
            )}
            {!!listing.price_information?.SideCost && (
              <Box className="addedDate">
                + charges de{' '}
                {formatPrice(listing.price_information.SideCost, 0)}{' '}
                {rent ? '/ mois' : ''}
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={showMap ? 4 : 12}
            container
            rowSpacing={0.5}
            sx={{ height: 'fit-content', marginTop: showDescription ? 0 : 1 }}
          >
            <Grid item xs={3}>
              <Box
                sx={{
                  my: 0.5,
                }}
                className="addedDate"
              >
                {t('listing.status')}:{' '}
              </Box>
            </Grid>
            <Grid item xs={9} sx={{ display: 'flex', my: 0.5 }}>
              <StatusContainer>
                {listing?.ad_status ? (
                  <span className="status active">
                    {t('listing.status.active')}
                  </span>
                ) : (
                  <span className="status expired">
                    {t('listing.status.expired')}
                  </span>
                )}
              </StatusContainer>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                my: 0.5,
              }}
            >
              <Box className="addedDate">{t('listing.publishedOn')}: </Box>
            </Grid>
            <Grid
              item
              xs={showDescription ? 9 : 8}
              sx={{
                paddingLeft: showDescription ? 0 : 1,
                my: 0.5,
              }}
            >
              {format(listing?.listing_timestamp, 'PPP')}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                my: 0.5,
              }}
            >
              <Box className="addedDate">{t('listing.online')}: </Box>
            </Grid>
            <Grid
              item
              xs={showDescription ? 9 : 8}
              sx={{
                paddingLeft: showDescription ? 0 : 1,
                my: 0.5,
              }}
            >
              {listing?.ad_status && listing.listing_timestamp
                ? `${t('listing.daysAgo', {
                    days: calculateDate(listing.listing_timestamp),
                  })}`
                : !listing?.ad_status && listingDuration
                  ? `${t('listing.daysAgo', {
                      days: listingDuration,
                    })}`
                  : 'N/A'}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                my: 0.5,
              }}
            >
              <Box className="addedDate">{t('listing.vendor')}: </Box>
            </Grid>
            <Grid
              item
              xs={8}
              sx={{
                paddingLeft: showDescription ? 0 : 1,
                my: 0.5,
              }}
            >
              <StatusContainer>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 0.6,
                  }}
                >
                  {listing?.vendor_type === 'pro' && (
                    <span className="status active">
                      {t('land.listing.status.pro')}
                    </span>
                  )}
                  {listing?.vendor_type === 'private' && (
                    <span className="status active">
                      {t('land.listing.status.private')}
                    </span>
                  )}
                  {(listing?.vendor_type === 'unknown' ||
                    (listing?.vendor_type !== 'pro' &&
                      listing?.vendor_type !== 'private')) && (
                    <span className="status expired">
                      {t('land.listing.status.unknown')}
                    </span>
                  )}
                </Box>
              </StatusContainer>
              <Typography fontWeight={500}>{listing.vendor_name}</Typography>
              <Typography whiteSpace="pre-wrap">
                {(listing.vendor || '')
                  .replace(`${listing.vendor_name}, `, '')
                  .replace(/^,+/g, '')
                  .split(', ')
                  .map((i: string) => (i || '').trim())
                  .join(',\n')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: {
              xs: 'block',
              md: 'flex',
            },
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              width: {
                xs: '100vw',
                md: showMap ? '50%' : '100%',
              },
            }}
          >
            <Card className="ControlsCard">
              {!!listing.urls_image?.filter((url: string) => url)?.length && (
                <Caroussel
                  images={listing.urls_image?.filter((url: string) => url)}
                  autoPlay={autoSwipe}
                  sx={{
                    '& .react-swipeable-view-container img': {
                      width: { xs: 'calc(100vw - 24px)', sm: ' 100%' },
                      marginLeft: { xs: '12px', sm: 0 },
                    },
                  }}
                  defaultImage="/assets/no-image.jpeg"
                />
              )}
            </Card>
            {children}
          </Box>
          {showMap && (
            <Box
              sx={{
                height: '300px',
                width: {
                  xs: '100vw',
                  md: '50%',
                },
              }}
            >
              <ListingDetailMap
                geo_center={listing?.geo_center}
                data={
                  [
                    listing.exact_location && landLayer,
                    !listing.exact_location && locationLayer,
                  ]
                    .flat()
                    .filter(Boolean) as any
                }
                zoom={listing.exact_location ? 16 : 12}
              />
            </Box>
          )}
        </Box>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', marginY: 1 }}
        >
          <Box className="addedDate">{t('listing.description')}: </Box>
          {listing.description && listing.description !== 'null' && (
            <Typography
              dangerouslySetInnerHTML={{ __html: listing.description }}
              sx={{ height: undefined, marginBottom: 1 }}
            />
          )}
          {features.length ? (
            <>
              <Box className="addedDate">{t('listing.features')}: </Box>
              <FilterChips
                items={features}
                size="small"
                sx={{
                  flexWrap: 'wrap',
                }}
              />
            </>
          ) : null}
        </Grid>
      </Box>
    </ListingDetailsRoot>
  )
}

export default ListingDetails
