/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { parseGeoJSON } from '@popety_io/popety-io-lib'

import {
  iterateReasonsOverTime,
  iterateOwnershipOverTime,
} from '../stats.utils'
import transactionService from './transactionService'

const initialState = {
  transactions: [],
  transactionsAround: [],
  totalAround: 0,
  transactionRange: [2008, new Date().getFullYear()],
  aggregations: {},
  geoPoint: {},
  geoPolygon: {},
  loading: false,
}

export const getTransactionsByIds = createAsyncThunk(
  'transaction/getTransactionsByIds',
  transactionService.getTransactionsByIds,
)

export const getLandTransactions = createAsyncThunk(
  'transaction/getLandTransactions',
  transactionService.getLandTransactions,
)

export const getTransactionsAround = createAsyncThunk(
  'transaction/getTransactionsAround',
  transactionService.getTransactionsAround,
)

export const getTransactionAggs = createAsyncThunk(
  'transaction/getTransactionAggs',
  transactionService.getTransactionAggs,
)

export const getGeojsonCity = createAsyncThunk(
  'transaction/getGeojsonCity',
  transactionService.getGeojsonCity,
)

export const getGeojsonDistrict = createAsyncThunk(
  'transaction/getGeojsonDistrict',
  transactionService.getGeojsonDistrict,
)

const transaction = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    updateTransationRange: (state, action) => {
      state.transactionRange = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLandTransactions.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.transactions = data?.filter(Boolean)
      })
      .addCase(getTransactionsByIds.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.transactions = data?.filter(Boolean)
      })
      .addCase(getTransactionsAround.pending, (state) => {
        state.loading = true
      })
      .addCase(getTransactionsAround.rejected, (state) => {
        state.loading = false
      })
      .addCase(getTransactionsAround.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.transactionsAround = data?.result

        state.totalAround = data?.total

        if (data?.result?.length) {
          const minTransactionDate = Math.min(
            ...data.result.map((transaction) => new Date(transaction.date)),
          )

          state.transactionRange = [
            new Date(minTransactionDate).getFullYear(),
            new Date().getFullYear(),
          ]
        }

        state.loading = false
      })
      .addCase(getTransactionAggs.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.reasonsStats = iterateReasonsOverTime(data?.aggregations) || []

        state.ownershipStats =
          iterateOwnershipOverTime(data?.aggregations) || []

        state.aggregations = data?.aggregations || {}
      })
      .addCase(getGeojsonCity.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.geoPoint = parseGeoJSON(data?.[0]?.geojson_center)
        state.geoPolygon = parseGeoJSON(data?.[0]?.geojson_polygon)
      })
      .addCase(getGeojsonDistrict.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.geoPoint = parseGeoJSON(data?.[0]?.geojson_center)
        state.geoPolygon = parseGeoJSON(data?.[0]?.geojson_polygon)
      })
  },
})

export const { updateTransationRange } = transaction.actions

export const transactionReducer = {
  transaction: transaction.reducer,
}
