/* eslint-disable max-lines */
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Map,
  useRouter,
  HistoryMapTileModal,
  useMediaQuery,
  useAsync,
  Modal,
  useI18n,
} from '@popety_io/popety-io-lib'
import mapboxgl from 'mapbox-gl'
import faoHistoryService from '../../../FaoHistory/redux/faoHistoryService'
import FaoDetails from '../../../FaoHistory/components/FaoDetails'
import { regionsByNameSelector } from '../../../../components/LocationsInput'
import {
  getFaoTiles,
  removeFaoTiles,
  buildingsFaoTiles,
} from '../../redux/fao/fao.layers'
import { estimateSelector } from '../../../Estimate/redux'
import { geoCenterSelector, landRegionSelector } from '../../redux'
import { buildingSelector } from '../../../BuildingDetails/redux'
import { getFaoFilters } from './utils'

const fillStyle = 'layer_fill'

const FaoTiles = ({ map, loaded }: { map: Map; loaded?: boolean }) => {
  const { dispatch, data, loading } = useAsync<any[]>()
  const { t } = useI18n()
  const router = useRouter()
  const building = useSelector(buildingSelector)
  const estimate: any = useSelector(estimateSelector)
  const landGeoCenter = useSelector(geoCenterSelector)
  const geoCenter =
    building?.geo_center || landGeoCenter || estimate?.geo_center
  const [feature, setFeature] = useState<any>()
  const [popup, setPopup] = useState<mapboxgl.Popup>()
  const touchScreen = useMediaQuery('(hover: none)')
  const regionsByName = useSelector(regionsByNameSelector)
  const landRegion = useSelector(landRegionSelector)

  const defaultDate = regionsByName[landRegion]?.first_fao_date

  const isFaoTab = router?.query?.tab === 'fao'
  const { faoCenter, title } = router.query

  const filters = getFaoFilters(router?.query, geoCenter, defaultDate)
  const query = filters && encodeURIComponent(JSON.stringify(filters))
  const buildingQuery = encodeURIComponent(
    JSON.stringify({
      classification: 'projected',
      geoPoint: { lat: filters?.center?.[1], lon: filters?.center?.[0] },
      radius: filters?.radius,
    }),
  )

  const handleClick = (e: any) => {
    // Use the first found feature.

    dispatch(() =>
      faoHistoryService.getFaosTileDetails(
        e.features.map(
          (f: { properties: { _key: string } }) => f.properties._key,
        ),
        query,
      ),
    )
  }

  const handleMouseMove = (e: any) => {
    map.getCanvas().style.cursor = 'pointer'
    if (popup && (!faoCenter || !title)) {
      const coordinates = e.features[0].geometry.coordinates.slice()
      const str = t('land.history.faos')
      const description = `${e.features[0].properties._count} ${str}`

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(map)
    }
  }

  const handleMouseLeave = () => {
    map.getCanvas().style.cursor = ''

    if (popup && (!faoCenter || !title)) {
      popup.remove()
    }
  }

  const addFaoTiles = () => {
    try {
      buildingsFaoTiles({ map, query: buildingQuery })
      getFaoTiles({
        map,
        query,
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    setPopup(
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    )
  }, [])

  useEffect(() => {
    if (router?.query?.tab !== 'fao' && map && loaded) {
      removeFaoTiles({ map })
    }
  }, [router?.query?.tab])

  useEffect(() => {
    if (touchScreen) setPopup(undefined)
  }, [touchScreen])

  useEffect(() => {
    if (map && loaded && faoCenter && popup && title) {
      const [lng, lat] = faoCenter.split(',')

      map.flyTo({ center: [lng, lat], zoom: 17 })
      popup.setLngLat([lng, lat]).setHTML(title).addTo(map)
    } else if (map && loaded && !faoCenter && popup && !title) {
      popup.remove()
    }
  }, [faoCenter, map, loaded, popup, title])

  useEffect(() => {
    if (!map || !loaded || router?.query?.tab !== 'fao') return

    addFaoTiles()

    map.on('style.load', addFaoTiles)
    map.on('click', fillStyle, handleClick)
    map.on('mousemove', fillStyle, handleMouseMove)
    map.on('mouseleave', fillStyle, handleMouseLeave)

    return () => {
      map.off('style.load', addFaoTiles)
      map.off('click', fillStyle, handleClick)
      map.off('mousemove', fillStyle, handleMouseMove)
      map.off('mouseleave', fillStyle, handleMouseLeave)
    }
  }, [isFaoTab, loaded, query, faoCenter, title])

  useEffect(() => {
    if (data?.length) {
      setFeature(data[0])
    }
  }, [data])

  const handleClose = () => {
    setFeature(null)
  }

  if (!isFaoTab || !map || !loaded) return null

  return (
    <>
      {!!feature && (
        <Modal
          open={!!feature}
          loading={loading}
          onClose={handleClose}
          title={data[0]?.type_name || t('land.history.faos')}
        >
          <HistoryMapTileModal
            data={data}
            loading={loading}
            Details={FaoDetails}
            previousText={t('common.previous')}
            nextText={t('common.next')}
            notFound={t('common.Not found')}
          />
        </Modal>
      )}
    </>
  )
}

export default FaoTiles
