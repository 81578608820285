/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
import { Modal, useI18n } from '@popety_io/popety-io-lib'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TagCardAdd } from '../../pages/Account/components/TagActions'
import {
  removeLandTag,
  addTag,
  updateLandTag,
  tagsByLandIdSelector,
} from '../../pages/Dashboard/redux'
import {
  addPreviewLandTag,
  fetchByLandId,
  fetchLandsTags,
  resetPreviewLandTag,
  updatePreviewLandTag,
} from '../../pages/DeveloperMap/redux'
import { getCurrentUserId } from '../../utils/user'
import { isCompanyAdminSelector } from '../../pages/Login'
import { userPreferencesSelector } from '../../pages/Account'
import useTag from './useTag'
import TagChooser from './TagChooser'

type TagModalProps = {
  landIds: string | string[]
  anchorEl: any
  tag?: any
  view?: number
  onClose: () => void
}

const TagModal = ({ anchorEl, onClose, landIds, ...other }: TagModalProps) => {
  const { t } = useI18n()
  const [addTagModalState, setAddTagModalState] = useState(false)

  const dispatch = useDispatch<any>()
  const userId = getCurrentUserId()

  const {
    activeLandTagIds,
    activeLandTags,
    myActiveLandTagIds,
    sanitizedLandIds,
    isTagOwner,
  } = useTag({ landIds })

  const isCompanyAdmin = useSelector(isCompanyAdminSelector)
  const { companyTagsCanBeEdited } = useSelector(userPreferencesSelector)

  const tagsByLandId = useSelector(tagsByLandIdSelector)

  const canAddTags = companyTagsCanBeEdited || isCompanyAdmin

  const cannotReplace = !activeLandTags?.length
    ? false
    : !isCompanyAdmin && !myActiveLandTagIds?.length

  const handleAddTagModalState = (state: boolean) => {
    setAddTagModalState(state)
  }

  const handleOnAdd = () => {
    handleAddTagModalState(true)
  }

  const handleTagToggle = (newVal: { tag_id: string }) => () => {
    sanitizedLandIds.forEach((landId) => {
      const findActiveTag = activeLandTags.find(
        (activeLand) => Number(activeLand.land_id) === Number(landId),
      )

      if (
        activeLandTagIds?.some((id) => String(id) === String(newVal.tag_id))
      ) {
        if (
          isTagOwner(newVal) ||
          myActiveLandTagIds.some(
            (id) => String(id) === String(newVal.tag_id),
          ) ||
          isCompanyAdmin
        ) {
          dispatch(
            removeLandTag({
              tagLandId: findActiveTag.id,
              landId: findActiveTag.land_id,
            }),
          )
            .then(() => dispatch(resetPreviewLandTag()))
            .then(() => {
              dispatch(fetchLandsTags())
            })
        }
      } else {
        if (cannotReplace) return

        if (findActiveTag?.id) {
          dispatch(updateLandTag({ tag: newVal, tagLandId: findActiveTag.id }))
            .then(() => {
              dispatch(updatePreviewLandTag({ ...newVal, user_id: userId }))
            })
            .then(() => {
              dispatch(fetchLandsTags())
            })
          onClose()
          handleAddTagModalState(false)
        } else {
          dispatch(addTag({ ...newVal, user_id: userId, land_id: landId }))
            .then(() => {
              dispatch(addPreviewLandTag({ ...newVal, user_id: userId }))
            })
            .then(() => {
              dispatch(fetchLandsTags())
            })
          onClose()
          handleAddTagModalState(false)
        }
      }
    })
  }

  const canToggle = (_tag: Record<string, any>) => {
    return !(
      (activeLandTagIds.includes(_tag.tag_id) &&
        !(
          isCompanyAdmin ||
          isTagOwner(_tag) ||
          myActiveLandTagIds.includes(_tag.tag_id)
        )) ||
      cannotReplace
    )
  }

  const handleAddTag = (newVal: {
    tag_id: string
    newPrimary?: boolean
    newSecondary?: boolean
    secondary?: boolean
    primary?: boolean
  }) => {
    sanitizedLandIds.forEach((landId: any) => {
      dispatch(
        addTag({
          ...newVal,
          primary: !!newVal?.newPrimary,
          user_id: userId,
          land_id: landId,
        }),
      )
        .then(() =>
          dispatch(
            fetchByLandId(
              sanitizedLandIds.length > 1 ? sanitizedLandIds.join(',') : landId,
            ) as any,
          ),
        )
        .then(() => {
          dispatch(fetchLandsTags())
        })
    })
    handleAddTagModalState(false)
  }

  const handleRemoveTag = (newVal: any) => {
    if (sanitizedLandIds.length > 1) {
      let tagIds: any = []

      for (let i = 0; i < sanitizedLandIds.length; i += 1) {
        const tags = tagsByLandId[sanitizedLandIds[i]]

        const tag = tags.find(
          (tag: any) => Number(tag.tag_id) === Number(newVal.tag_id),
        )

        if (tag) {
          tagIds = [...tagIds, tag.id]
        }
      }

      if (tagIds.length) {
        tagIds.forEach((tagId: any, i: number) => {
          dispatch(
            removeLandTag({
              tagLandId: tagId,
              landId: sanitizedLandIds.join(','),
            }),
          ).then(() => {
            if (i === tagIds.length - 1) {
              dispatch(fetchByLandId(sanitizedLandIds.join(',')) as any)
              dispatch(fetchLandsTags())
            }
          })
        })
      }
    } else {
      sanitizedLandIds.forEach((landId) => {
        const findActiveTag = activeLandTags.find(
          (activeLand) => Number(activeLand.land_id) === Number(landId),
        )

        dispatch(
          removeLandTag({
            tagLandId: newVal?.tagLandId,
            landId: findActiveTag.land_id,
          }),
        )
          .then(() => dispatch(fetchByLandId(landId) as any))
          .then(() => {
            dispatch(fetchLandsTags())
          })
      })
      handleAddTagModalState(false)
    }
  }

  return (
    <Modal
      {...other}
      maxWidth="xs"
      disableGutters
      open={!!anchorEl}
      onClose={onClose}
      title={t('common.chooseLabel')}
      okText={canAddTags && `+ ${t('common.addLabel')}`}
      onOk={handleOnAdd}
    >
      <TagChooser
        landIds={landIds}
        handleTagToggle={handleTagToggle}
        canToggle={canToggle}
        handleAddTag={handleAddTag}
        handleRemoveTag={handleRemoveTag}
      />
      <TagCardAdd
        open={addTagModalState}
        handleAddTagModalState={handleAddTagModalState}
        handleTagToggle={handleTagToggle}
      />
    </Modal>
  )
}

export default TagModal
