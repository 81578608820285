import {
  useRouter,
  PopetyMaps,
  useI18n,
  PrintIcon,
} from '@popety_io/popety-io-lib'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'

import ControlMapStyleSwitch from '../../LandDetails/Summary/ControlMapStyleSwitch'
import { buildOptions } from '../../DeveloperMap/components/Basemap/basemap.utils'
import { transformTileRequest } from '../../../utils'
import { MAPBOX_TOKEN, STYLE_PREFIX, DEFAULT_MAPSTYLE } from '../../../config'
import { mapStyleSelector, changeMapStyle } from '../../DeveloperMap/redux'
import { buildingSelector } from '../redux'
import { getBbox } from '../../LandDetails/LandDetailsMap/utils'
import GeolocMapControls from '../../../components/MapControl/GeolocMapControls'
import { MapConfigByPage, MapControllers } from './BuildingDetailsMap.config'
import { useBuildingDetailsMapLayers } from './BuildingDetailsMap.layers'

export type BuildingDetailsMapProps = {
  fullscreen?: boolean
}

const BuildingDetailsMap = ({ fullscreen }: BuildingDetailsMapProps) => {
  const [zoom, setZoom] = useState(18)
  const dispatch = useDispatch()

  const mapStyle = useSelector(mapStyleSelector)
  const building = useSelector(buildingSelector)
  const { data } = useBuildingDetailsMapLayers()

  const { t } = useI18n()

  const { query, updateQuery } = useRouter()

  const MapConfig = useMemo(() => MapConfigByPage[query.tab], [query.tab])

  const geoCenter = [building?.geo_center?.lon, building?.geo_center?.lat]

  useEffect(() => {
    if (query.zoom) {
      setZoom(Number(query.zoom))
    }
  }, [query.zoom])

  useEffect(() => {
    updateQuery({ zoom })
  }, [])

  const minHeight = fullscreen
    ? 'calc(100vh - 160px)'
    : { xs: '300px', md: '40vh' }

  const bbox = useMemo(() => {
    if (!building || !building?.geo_center) return undefined

    return getBbox(data, query.tab, geoCenter)
  }, [query.tab, geoCenter, data])

  const mapProps: any = {
    zoom,
    bearing: 0,
    bearingSnap: 0,
    dragPan: true,
    dragRotate: true,
    maxZoom: 20,
    minZoom: 13,
    // maxPitch: 60,
    preserveDrawingBuffer: true,
    transformRequest: transformTileRequest as any,
    bbox,
  }

  const options = buildOptions(t, mapStyle)

  const mapConfigStyle = useMemo(() => {
    const style = mapStyle.startsWith(STYLE_PREFIX)
      ? mapStyle.substring(STYLE_PREFIX.length)
      : mapStyle

    return `${STYLE_PREFIX}${style}`
  }, [mapStyle])

  useEffect(() => {
    dispatch(changeMapStyle(MapConfig?.style || DEFAULT_MAPSTYLE))
  }, [MapConfig?.style, query.tab])

  return (
    <Box height="100%" minHeight={minHeight}>
      <PopetyMaps
        dimension="3D"
        token={MAPBOX_TOKEN}
        // bbox={bbox}
        data={data}
        center={building?.geo_center}
        isDragPan
        Hover={MapConfig?.Hover}
        ClickComponent={MapConfig?.Click}
        style={mapConfigStyle}
        {...mapProps}
        sx={{
          minHeight,
          maxHeight: fullscreen
            ? 'calc(100% - 106px)'
            : { xs: 'calc(100% - 106px)', lg: '100%' },
          overflow: 'hidden',
        }}
        zoom={zoom}
      >
        <GeolocMapControls center={geoCenter} zoom={zoom} />
        {MapConfig?.Content && <MapConfig.Content />}
        <MapControllers />
        <PrintIcon name={t('common.myAnalysis.fileName.details')} />
        <ControlMapStyleSwitch options={options.slice(0, 2)} />
      </PopetyMaps>
    </Box>
  )
}

export default BuildingDetailsMap
