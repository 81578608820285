import { toCamelCase, toSnakeCase } from '@popety_io/popety-io-lib'

import { ApiClient } from '../../../services'

const client = new ApiClient()

type UpdatePasswordInput = { newPassword: string }

const getCurrentUserDetails = async () => {
  return client.get(`/account/detail`)
}

const updatePassword = async ({ newPassword }: UpdatePasswordInput) => {
  const response = await client.put(`/account/password`, {
    newPassword,
  })

  if (response.data) {
    localStorage.clear()
  }

  return response
}

const updateFinancialDefault = async (input: Record<string, any>) => {
  const data = { config: toSnakeCase(input) }

  return client.post(`/land/default/analysis/config`, data)
}

const getFinancialDefault = async () => {
  const response = await client.get('/land/default/analysis/config')
  const payload = response.data

  if (payload) {
    response.data = toCamelCase(response.data)
  }

  return response
}

const updateLanguage = async (lang: string) => {
  return client.put(`/account/lang`, { lang })
}

const terminatePlan = async () => {
  return client.delete(`/account/subscriptions`)
}

const getUserPreferences = async () => {
  return client.get(`/account/user-preference`)
}

const updateUserPhoto = async (photo: File | Blob) => {
  return client.put(`/account/update-photo`, { photo }, { formData: true })
}

const updateUser = async (data: Record<string, any>) => {
  return client.put(`/account/update`, data)
}

const updateCompany = async (values: any) => {
  return client.put(`/account/company/update`, values)
}

const updateCompanyLogo = async (logo: File | Blob) => {
  return client.put(
    `/account/company/update-logo`,
    { logo },
    { formData: true },
  )
}

const updateUserOffice = async ({
  userId,
  officeId,
}: {
  userId: string
  officeId: string
}) => {
  return client.put(`/company/offices/updateOffice/${userId}/${officeId}`)
}

const updateTagsPermissions = async (data: Record<string, any>) => {
  return client.put(`/company/update-tags-permissions`, data)
}

export const getSecurityStatus = async () => {
  return client.get(`/auth/security/status`)
}

export const updateSecurityStatus = async (data: {
  type: 'sms' | 'authenticator'
}) => {
  return client.put(`/auth/security/status`, data)
}

export const verifySecurityStatus = async (data: {
  type: 'sms' | 'authenticator'
  code: string
}) => {
  return client.put(`/auth/security/verify`, data)
}

export const updateTutorials = async (data: Record<string, any>) => {
  return client.put(`/account/tutorials`, data)
}

const accountService = {
  getCurrentUserDetails,
  updatePassword,
  updateFinancialDefault,
  getFinancialDefault,
  terminatePlan,
  updateLanguage,
  updateUserPhoto,
  updateUser,
  updateCompany,
  updateCompanyLogo,
  updateUserOffice,
  getUserPreferences,
  updateTagsPermissions,
  updateSecurityStatus,
  getSecurityStatus,
  verifySecurityStatus,
  updateTutorials,
}

export default accountService
