import FaoIcon from '@mui/icons-material/Construction'
import { useSelector } from 'react-redux'
import { landFaoCountSelector } from '../redux'
import CountChip from '../../../components/Tag/CountChip'

interface IProps {
  show?: boolean
}
const FaoCountChip = ({ show }: IProps) => {
  const count = useSelector(landFaoCountSelector)

  return <CountChip count={count} icon={<FaoIcon />} show={show} />
}

export default FaoCountChip
