import { FC, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import {
  Button,
  ProjectDesignItemCard,
  useI18n,
} from '@popety_io/popety-io-lib'
import { generatedProjectsSelector, setSelectedProject } from '../redux'
import {
  AdditionalButton,
  DesignActionButton,
  DesignStepFourRoot,
} from './Design.style'

interface IProps {
  handleContinue: () => void
  handleBack: () => void
}
const DesignStepFour: FC<IProps> = ({ handleContinue, handleBack }) => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const generatedProjects = useSelector(generatedProjectsSelector)

  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const onSelect = (id: number, project: any) => {
    setSelectedIds([id])
    dispatch(setSelectedProject(project))
  }

  return (
    <DesignStepFourRoot>
      <Box className="project-list">
        {generatedProjects.map((project: any, k: number) => (
          <ProjectDesignItemCard
            key={`design_building_${k}`}
            isSelected={selectedIds.includes(k)}
            onSelect={() => onSelect(k, project)}
            details={<h1>{t('lang.designDetail')}</h1>}
            name={project.name}
            analysis={[
              {
                name: 'Analysis 1',
                value: '85%',
              },
              {
                name: 'Analysis 2',
                value: 'Very good',
              },
              {
                name: 'Analysis 1',
                value: '76%',
              },
              {
                name: 'Land Use Coefficient',
                value: `${project?.cus}` || '0',
              },
              {
                name: 'Land Occupation Coefficient',
                value: `${project?.cos}` || '0',
              },
            ]}
            actions={[
              {
                id: '3',
                label: 'Ground area',
                defaultValue: project?.groundArea || 'N/A',
                endAdornment: 'm2',
              },
              {
                id: '4',
                label: 'Floor area',
                defaultValue: project?.floorArea || 'N/A',
                endAdornment: 'm2',
              },
              {
                id: '5',
                label: 'Floor(s)',
                defaultValue: project?.floors || 'N/A',
              },
              {
                id: '1',
                label: 'Height',
                defaultValue: project.height || 'N/A',
                endAdornment: 'm',
              },
              {
                id: '2',
                label: 'Dwelling(s)',
                defaultValue: project?.dwellings || 'N/A',
              },
            ]}
            valuesTooltip={[]}
          />
        ))}
      </Box>
      <AdditionalButton sx={{ paddingY: 2 }}>
        + {t('land.design.generateMoreProject')}
      </AdditionalButton>

      <DesignActionButton>
        <Button onClick={handleContinue} variant="contained">
          {t('common.save')}
        </Button>
        <Button onClick={handleBack}>{t('land.design.back')}</Button>
      </DesignActionButton>
    </DesignStepFourRoot>
  )
}

export default DesignStepFour
