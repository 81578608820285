// import { mapBy } from '@popety_io/popety-io-lib'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { iterateClassificationsOverTime } from '../../../LandDetails/redux/stats.utils'

import listingService from '../../../LandDetails/redux/listing/listingService'
import listingInsightsService from '../../../ListingHistory/redux/listingInsights/listingInsightsService'
import estimateMarketService from './estimateMarketService'

const initialState = {
  loadingAggs: false,
  loadInsights: false,
  aggregations: [],
  stats: {},
  buyData: undefined,
  loadBuyData: false,
  loadAnnouncements: false,
  announcementData: undefined,
}

export const getEstimateListingBuyInsights = createAsyncThunk(
  'estimateMarket/getListingBuyInsights',
  listingInsightsService.getListingInsights,
)

export const getListingsAggsPriceAnnoncements = createAsyncThunk(
  'estimateMarket/getListingsAggsPriceAnnoncements',
  estimateMarketService.getListingsAggsPriceAnnoncements,
)

export const getEstimateListingAggs = createAsyncThunk(
  'estimateMarket/getEstimateListingAggs',
  listingService.getListingAggs,
)

const estimateMarket = createSlice({
  name: 'estimateMarket',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEstimateListingAggs.pending, (state) => {
      state.loadingAggs = true
    })
    builder.addCase(getEstimateListingAggs.rejected, (state) => {
      state.loadingAggs = false
    })
    builder.addCase(getEstimateListingAggs.fulfilled, (state, action) => {
      const { data } = action.payload || {}

      state.loadingAggs = false
      state.stats = iterateClassificationsOverTime(data?.aggregations)
      state.aggregations = data?.aggregations || {}
    })
    builder.addCase(getEstimateListingBuyInsights.rejected, (state) => {
      state.loadInsights = false
      state.loadBuyData = false
    })
    builder.addCase(getEstimateListingBuyInsights.pending, (state) => {
      state.loadBuyData = true
      state.loadInsights = true
    })
    builder.addCase(
      getEstimateListingBuyInsights.fulfilled,
      (state, action) => {
        state.loadInsights = false
        const { data = {} } = action.payload

        state.loadBuyData = false
        state.buyData = data
      },
    )
    builder.addCase(getListingsAggsPriceAnnoncements.rejected, (state) => {
      state.loadAnnouncements = false
    })
    builder.addCase(getListingsAggsPriceAnnoncements.pending, (state) => {
      state.loadAnnouncements = true
    })
    builder.addCase(
      getListingsAggsPriceAnnoncements.fulfilled,
      (state, action) => {
        state.loadAnnouncements = false
        const { data = {} } = action.payload

        state.announcementData =
          data?.aggregations?.price_over_time_purchase?.price_range.buckets
      },
    )
  },
})

export const estimateMarketReducer = {
  estimateMarket: estimateMarket.reducer,
}
