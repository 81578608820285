/* eslint-disable max-lines */
/* eslint-disable import/no-absolute-path */
import { getYearColor, Map } from '@popety_io/popety-io-lib'
import * as turf from '@turf/turf'
import { API_BASE_URL } from '../../../../services'
import shopping from './icons/shopping.png'
import grocery from './icons/grocery.png'
import culture from './icons/culture.png'
import education from './icons/education.png'
import healthcare from './icons/healthcare.png'
import dining from './icons/dining.png'
import amenities from './icons/amenities.png'
import transport from './icons/transport.png'
import wellness from './icons/wellness.png'
import frontOfBus from './icons/front-of-bus.png'

// const renderMethod = 'aggs'

const source = 'es_mvt_poi'
const fillStyle = 'layer_fill_poi'

const loadedImages = new Set()

// Function to load an image if it hasn't been loaded before
const loadImageIfNotLoaded = (map: Map, path: string, id: string) => {
  if (!map.hasImage(id)) {
    map.loadImage(path, (error, image: any) => {
      if (error) throw error

      map.addImage(id, image, { sdf: true })

      loadedImages.add(path) // Add the path to the set of loaded images
    })
  }
}

const removePoisTiles = ({ map }: { map: Map }) => {
  if (map.getSource(source)) {
    map.removeLayer(fillStyle)
    map.removeLayer('clusters')
    map.removeLayer('cluster-count')
    map.removeLayer('unclustered-point')
    map.removeSource(source)
  }
}

const displayPois = (
  map: Map,
  pois: { geo_center_lat: number; geo_center_lon: number }[],
) => {
  if (map.getSource(source)) {
    map.removeLayer('clusters')
    map.removeLayer('cluster-count')
    map.removeLayer('unclustered-point')
    map.removeLayer(fillStyle)
    map.removeSource(source)
  }
  const items = pois?.map((element: any) => {
    const geoCenter = [element?.geo_center_lon, element?.geo_center_lat]

    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: geoCenter,
      },
      properties: {
        ...element,
      },
    }
  })

  const collection = turf.featureCollection(items as any) as any

  const imsPaths = [
    { id: 'shopping-icon', path: shopping },
    { id: 'grocery-icon', path: grocery },
    { id: 'culture-icon', path: culture },
    { id: 'education-icon', path: education },
    { id: 'healthcare-icon', path: healthcare },
    { id: 'dining-icon', path: dining },
    { id: 'amenities-icon', path: amenities },
    { id: 'transport-icon', path: transport },
    { id: 'wellness-icon', path: wellness },
    { id: 'front-of-bus-icon', path: frontOfBus },
  ]

  imsPaths.forEach(({ path, id }) => {
    loadImageIfNotLoaded(map, path, id)
  })

  map.addSource(source, {
    type: 'geojson',
    data: collection,
    cluster: true,
    clusterMaxZoom: 14, // Max zoom to cluster points on
    clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
  })
  map.addLayer({
    id: 'clusters',
    type: 'circle',
    source,
    filter: ['has', 'point_count'],
    paint: {
      // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
      // with three steps to implement three types of circles:
      //   * Blue, 20px circles when point count is less than 100
      //   * Yellow, 30px circles when point count is between 100 and 750
      //   * Pink, 40px circles when point count is greater than or equal to 750
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#00abe6',
        100,
        '#00abe6',
        750,
        '#00abe6',
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      // 'text-color': '#fff',
    },
  })
  map.addLayer({
    id: 'cluster-count',
    type: 'symbol',
    source,
    filter: ['has', 'point_count'],
    layout: {
      'text-field': ['get', 'point_count_abbreviated'],
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 14,
    },
    paint: {
      'text-color': '#fff', // Change the text color to red
    },
  })
  map.addLayer({
    id: 'unclustered-point',
    type: 'circle',
    source,
    // 'source-layer': 'hits',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': '#00ade6',
      'circle-radius': 0,
      'circle-stroke-width': 0,
      'circle-stroke-color': '#fff',
    },
  })
  // map.addLayer({
  //   id: fillStyle,
  //   type: 'symbol',
  //   source,
  //   minzoom: 13,
  //   layout: {
  //     'icon-image': [
  //       'match',
  //       ['get', 'category'], // Use the result 'category' property
  //       'Shopping',
  //       'shopping-icon',
  //       'Grocery',
  //       'grocery-icon',
  //       'Culture and Entertainment',
  //       'culture-icon',
  //       'Wellness',
  //       'wellness-icon',
  //       'Transport',
  //       'front-of-bus-icon',
  //       'Dining and Drinking',
  //       'dining-icon',
  //       'Healthcare',
  //       'healthcare-icon',
  //       'Basic Amenities',
  //       'amenities-icon',
  //       'Education',
  //       'education-icon',
  //       '',
  //     ],
  //     'icon-size': 1.1,
  //     'icon-allow-overlap': true,
  //   },
  //   paint: {
  //     'icon-halo-color': [
  //       'match',
  //       ['get', 'category'], // Use the result 'category' property
  //       'Shopping',
  //       '#ffff3b',
  //       'Grocery',
  //       '#81b1d3',
  //       'Culture and Entertainment',
  //       '#d9d9d9',
  //       'Wellness',
  //       '#fb8072',
  //       'Transport',
  //       '#bebada',
  //       'Dining and Drinking',
  //       '#fdb462',
  //       'Healthcare',
  //       '#b3de69',
  //       'Education',
  //       '#fccde5',
  //       'Basic Amenities',
  //       '#8dd3c7',
  //       '#ffff3b', // any other category
  //     ],
  //     'icon-halo-width': 12,
  //     'icon-opacity': 1,
  //     'icon-color': '#fff',
  //   },
  // })
}

const getPoisTiles = ({
  map,
  geoCenter,
}: {
  map: Map
  geoCenter: { lon: number; lat: number }
}) => {
  try {
    if (map.getSource(source)) {
      map.removeLayer('clusters')
      map.removeLayer('cluster-count')
      // map.removeLayer(fillStyle)
      map.removeSource(source)
    }

    const imsPaths = [
      { id: 'shopping-icon', path: shopping },
      { id: 'grocery-icon', path: grocery },
      { id: 'culture-icon', path: culture },
      { id: 'education-icon', path: education },
      { id: 'healthcare-icon', path: healthcare },
      { id: 'dining-icon', path: dining },
      { id: 'amenities-icon', path: amenities },
      { id: 'transport-icon', path: transport },
      { id: 'wellness-icon', path: wellness },
      { id: 'front-of-bus-icon', path: frontOfBus },
    ]

    imsPaths.forEach(({ path, id }) => {
      loadImageIfNotLoaded(map, path, id)
    })

    map.addSource(source, {
      type: 'vector',
      tiles: [
        `${API_BASE_URL}/pois/tiles?lat=${geoCenter?.lat}&lon=${geoCenter?.lon}&x={x}&y={y}&z={z}`,
      ],
      minzoom: 0,
      maxzoom: 24,
    })

    // map.addLayer({
    //   id: fillStyle,
    //   type: 'symbol',
    //   source,
    //   minzoom: 13,
    //   'source-layer': 'hits',
    //   layout: {
    //     'icon-image': [
    //       'match',
    //       ['get', 'category'], // Use the result 'category' property
    //       'Shopping',
    //       'shopping-icon',
    //       'Grocery',
    //       'grocery-icon',
    //       'Culture and Entertainment',
    //       'culture-icon',
    //       'Wellness',
    //       'wellness-icon',
    //       'Transport',
    //       'front-of-bus-icon',
    //       'Dining and Drinking',
    //       'dining-icon',
    //       'Healthcare',
    //       'healthcare-icon',
    //       'Basic Amenities',
    //       'amenities-icon',
    //       'Education',
    //       'education-icon',
    //       'education-icon', // any other category
    //     ],
    //     'icon-size': 1.1,
    //     'icon-allow-overlap': true,
    //     'icon-padding': 20,
    //   },
    //   paint: {
    //     'icon-halo-color': [
    //       'match',
    //       ['get', 'category'], // Use the result 'category' property
    //       'Shopping',
    //       '#ffff3b',
    //       'Grocery',
    //       '#81b1d3',
    //       'Culture and Entertainment',
    //       '#d9d9d9',
    //       'Wellness',
    //       '#fb8072',
    //       'Transport',
    //       '#bebada',
    //       'Dining and Drinking',
    //       '#fdb462',
    //       'Healthcare',
    //       '#b3de69',
    //       'Education',
    //       '#fccde5',
    //       'Basic Amenities',
    //       '#8dd3c7',
    //       '#ffff3b', // any other category
    //     ],
    //     'icon-halo-width': 12,
    //     'icon-opacity': 1,
    //     'icon-color': '#fff',
    //   },
    // })

    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source,
      filter: ['has', 'point_count'],
      'source-layer': 'hits',
      paint: {
        // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#00abe6',
          100,
          '#f1f075',
          750,
          '#f28cb1',
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        // 'text-color': '#fff',
      },
    })

    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source,
      filter: ['has', 'point_count'],
      'source-layer': 'hits',
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 14,
      },
      paint: {
        'text-color': '#fff', // Change the text color to red
      },
    })
    map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source,
      'source-layer': 'hits',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#00ade6',
        'circle-radius': 6,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff',
      },
    })
  } catch (error) {
    console.error(error)
  }
}

const getPoiAroudLayers = (
  Pois: any[],
  yearRange: number[],
  startDate: Date | string,
  rent: boolean,
  purchase: boolean,
) => {
  const data = {
    type: 'FeatureCollection',
    features:
      Pois?.map((Poi: any[]) => {
        const years = Poi?.map((t) => Number(t.Poi_timestamp.substring(0, 4)))

        const PoiYears = years?.filter(
          (year) => year >= yearRange[0] && year <= yearRange[1],
        )

        const PoiType = Poi?.map((l) => l.type)

        if (PoiYears?.length <= 0) return null

        if (
          (!rent && PoiType?.includes('rent')) ||
          (!purchase && PoiType?.includes('purchase'))
        ) {
          return null
        }

        const index = years?.findIndex((year) => year === PoiYears[0]) || 0

        const properties = JSON.parse(JSON.stringify(Poi?.[index] || {}))

        const geometry = properties.land_geojson
          ? JSON.parse(properties.land_geojson)
          : []

        delete properties.land_geojson

        const date = properties?.Poi_timestamp
        const year = date ? new Date(date).getFullYear() : undefined

        properties.color = getYearColor(year as number, startDate)

        return { type: 'Feature', geometry, properties }
      }).filter(Boolean) || [],
  }

  return [
    {
      id: 'Poi',
      type: 'geojson',
      data,
      layers: [
        {
          id: 'Poi-layer',
          type: 'fill',
          paint: { 'fill-color': ['get', 'color'] },
          click: true,
          hover: true,
        },
      ],
    },
  ]
}

export { getPoiAroudLayers, getPoisTiles, removePoisTiles, displayPois }
