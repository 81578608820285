/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

import extraReducers from './extraReducers'
import { updateGeoJSONData } from './updateGeoJSONData'

const initialState = {
  alertsById: {},
  name: '',
  geoPolygon: { type: 'MultiPolygon', coordinates: [] },
  features: [],
  featureDeletedId: '',
}

const alert = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    clearGeoPolygon: (state, action) => {
      updateGeoJSONData(action.payload)(state, action)
    },
    updateGeoPolygon: (state, action) => {
      state.geoPolygon = action.payload || initialState.geoPolygon

      if (!action.payload) {
        state.data = {}
        state.features = []
      }
    },
    updateFeatures: (state, action) => {
      state.features = action.payload || []
    },
    updateAlertName: (state, action) => {
      state.name = action.payload || ''
    },
    setFeatureDeletedId: (state, { payload }) => {
      state.featureDeletedId = payload || ''
    },
  },
  extraReducers,
})

export const {
  updateGeoPolygon,
  updateFeatures,
  updateAlertName,
  clearGeoPolygon,
  setFeatureDeletedId,
} = alert.actions

export const alertsReducer = {
  alert: alert.reducer,
}
