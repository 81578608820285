import { useI18n, InfoBox } from '@popety_io/popety-io-lib'
import { LandOwners } from '../../../../LandDetails/Summary/LandOwners'

const LandSummaryOwners = ({ land }: any) => {
  const { t } = useI18n()
  const owners: any[] = land.owners || []

  const uniqueOwners = Array.from(
    new Set(
      owners?.filter(
        (owner, index, array) =>
          array.findIndex((o) => o?.name === owner?.name) === index,
      ) || [],
    ),
  ).flat()

  const lastModified = uniqueOwners?.length
    ? Math.max(...uniqueOwners.map((name) => name?.create_timestamp))
    : null

  const data = [
    { label: t('land.zone.number'), value: land.owner_nb || 'N/A' },
    {
      label: t('common.search.details.lastModif'),
      value:
        lastModified && !Number.isNaN(lastModified)
          ? new Date(lastModified).toLocaleString('fr-FR')?.substring(0, 10)
          : 'N/A',
    },
  ]

  const data2 = [
    {
      label: t('land.owners.names'),
      value: <LandOwners data={uniqueOwners} canAnalyse />,
    },
  ]

  return <InfoBox title={t('common.owners')} data={data} data2={data2} />
}

export default LandSummaryOwners
