import { ApiClient } from '../../../services'

const client = new ApiClient()

export type HistorySearchOpts = Record<string, any>

const getFaos = ({ ...filter }: HistorySearchOpts) =>
  client.post('/faos/search', filter)

const getSelectedFaos = ({ ...filter }: HistorySearchOpts) =>
  client.post('/faos/search', filter)

const countFaos = (filter: HistorySearchOpts) => {
  return client.post('/faos/count', filter)
}

const getFaosStats = ({ ...filter }: HistorySearchOpts) => {
  return client.post('/faos/stats', filter)
}

const getFaosTileDetails = (tile: string[], q = '') => {
  return client.get(`/faos/tiles/details?tile=${tile}&query=${q}`)
}

const faoHistoryService = {
  getFaos,
  getFaosTileDetails,
  getFaosStats,
  countFaos,
  getSelectedFaos,
}

export default faoHistoryService
