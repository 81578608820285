import Tooltip from '@mui/material/Tooltip'

import useProspect, { ProspectLandOpts } from './useProspect'

export type ProspectIconProps = ProspectLandOpts

const ProspectIcon = ({ landId, opacity }: ProspectIconProps) => {
  const { Icon, title } = useProspect({ landId, opacity })

  return (
    <Tooltip title={title} placement="top">
      {Icon}
    </Tooltip>
  )
}

export default ProspectIcon
