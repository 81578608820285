/* eslint-disable max-lines */
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  SwipeableTabs,
  useI18n,
  useRouter,
  Driver,
  fetchOnce,
} from '@popety_io/popety-io-lib'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import { Badge } from '@mui/material'

import { regionsByNameSelector } from '../../../components/LocationsInput'
import HistoryAroundFilters from '../components/HistoryAroundFilters'
import {
  landRegionSelector,
  landTransactionSetSelector,
  transactionsAroundCountSelector,
  transactionsAroundSelector,
  transactionReasonsStatsSelector,
  transactionOwnershipTypesStatsSelector,
  landTransactionsLoadingSelector,
} from '../redux'
import { buildingRegionSelector } from '../../BuildingDetails/redux'
import { userSelector } from '../../Login'
import { updateTutorials } from '../../Account'
import TransactionList from './TransactionList'
import TransactionStats from './TransactionStats'
import getDriverInstructions from './driverInstructions'

interface IProps {
  show?: boolean
}

const Transaction = ({ show = true }: IProps) => {
  const { query, updateQuery } = useRouter()

  const [displayDriver, setDisplayDriver] = useState(false)

  const transactions = useSelector(landTransactionSetSelector)
  const transactionsAround = useSelector(transactionsAroundSelector)
  const transactionsAroundCount = useSelector(transactionsAroundCountSelector)
  const transactionReasonStats = useSelector(transactionReasonsStatsSelector)
  const transactionOwnershipTypeStats = useSelector(
    transactionOwnershipTypesStatsSelector,
  )
  const loading = useSelector(landTransactionsLoadingSelector)

  const landRegion = useSelector(landRegionSelector)
  const buildingRegion = useSelector(buildingRegionSelector)
  const regionsByName = useSelector(regionsByNameSelector)
  const user = useSelector(userSelector)

  const region = landRegion || buildingRegion || ''

  const { t } = useI18n()
  const dispatch = useDispatch<any>()

  const tabs = ['transaction', 'stats']
    .map((value) => ({
      label: t(`land.history.tab.${value}`),
      key: value,
    }))
    .filter((value) => show || (!show && value.key !== 'stats'))

  const tabsTransaction = [
    {
      label: t('land.transaction.title'),
      count: transactions?.length,
    },
    { label: t('land.transactionAround'), count: transactionsAroundCount },
  ]
    .slice(show ? 0 : 1)
    .map((value) => ({
      label: (
        <Badge
          badgeContent={value.count}
          color="primary"
          sx={{
            '& .MuiBadge-badge': { top: -2 },
            paddingTop: 1,
          }}
          showZero
        >
          {value.label}
        </Badge>
      ),
      key: value.label,
    }))

  const first_transaction_date = regionsByName[region]?.first_transaction_date

  const [opened, setOpened] = useState<string>()

  const handleChange = (uid: string) => {
    setOpened(uid)
    updateQuery({
      transactionOpened: uid,
    })
  }

  useEffect(() => {
    if (query?.transactionOpened) {
      setOpened(query?.transactionOpened)
    }
  }, [query?.transactionOpened])

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedTransactionDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedTransactionDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  if (!show) {
    return (
      <Box>
        <Box
          position="sticky"
          top={0}
          pt={2}
          zIndex={10}
          bgcolor="background.paper"
        >
          <Box
            sx={{
              marginBottom: '20px',
            }}
          >
            <Typography variant="h2" fontSize="1.7rem" fontWeight={500}>
              {t('common.search.details.transaction')}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#969696',
                paddingBottom: '10px',
              }}
              fontSize="1rem"
            >
              {t('common.transactions.header_description')}
            </Typography>
          </Box>
          <HistoryAroundFilters />
        </Box>
        {loading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {Array.from({ length: 10 }).map((_, i) => (
              <Box
                key={i}
                sx={{
                  width: '100%',
                  height: '45px',
                  mt: 1.5,
                }}
              >
                <Skeleton
                  variant="rounded"
                  sx={{
                    fontSize: '1rem',
                    borderRadius: 2,
                    height: '45px',
                    width: '100%',
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <TransactionList
            transactions={transactionsAround}
            opened={opened}
            onChange={handleChange as any}
          />
        )}
      </Box>
    )
  }

  return (
    <Box px={1}>
      <SwipeableTabs
        tabs={tabsTransaction}
        value={transactions?.length && transactionsAroundCount ? 0 : 1}
      >
        {first_transaction_date && (
          <Box>
            <TransactionList
              transactions={transactions}
              opened={opened}
              onChange={handleChange as any}
            />
          </Box>
        )}
        <Box>
          <Box
            pt={2}
            zIndex={10}
            bgcolor="background.paper"
            sx={{
              position: {
                lg: 'sticky',
              },
              top: {
                lg: 0,
              },
            }}
          >
            <HistoryAroundFilters />
          </Box>
          {loading ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {Array.from({ length: 10 }).map((_, i) => (
                <Box
                  key={i}
                  sx={{
                    width: '100%',
                    height: '45px',
                    mt: 1.5,
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{
                      fontSize: '1rem',
                      borderRadius: 2,
                      height: '45px',
                      width: '100%',
                    }}
                  />
                </Box>
              ))}
            </Box>
          ) : (
            <SwipeableTabs
              tabs={tabs}
              tabKey="fao-around"
              label={t('common.new')}
              fixedTabs
            >
              <TransactionList
                transactions={transactionsAround}
                opened={opened}
                onChange={handleChange as any}
              />

              <TransactionStats
                transactionsStats={[
                  transactionReasonStats,
                  transactionOwnershipTypeStats,
                ].filter(Boolean)}
              />
            </SwipeableTabs>
          )}
        </Box>
      </SwipeableTabs>
      <Driver instructions={getDriverInstructions(t)} display={displayDriver} />
    </Box>
  )
}

export default Transaction
