import {
  sum,
  useI18n,
  toReadableNumber,
  InfoBox,
} from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'

import { buildingsSelector, landSelector } from '../redux'

const BuildingInfo = () => {
  const { t } = useI18n()
  const buildings = useSelector(buildingsSelector)
  const land: any = useSelector(landSelector)

  const maxFloor = Math.max(...(buildings?.map((b) => b.floor_nb || 0) || []))

  const maxHeight =
    Number(land?.max_height) ||
    Math.max(...(buildings?.map((b) => b.height || 0) || []))

  const groundArea = sum(
    buildings?.map((b) => b.areaOveride ?? (b.area || 0)) || [],
  )

  const floorArea = sum(
    buildings?.map((b) => b.floorAreaOveride ?? b.area * (b?.floor_nb || 1)) ||
      [],
  )

  const data = [
    { label: t('land.zone.nomber'), value: land.building_nb || 'N/A' },
    {
      label: t('common.buildingYear'),
      value: land.average_building_year || 'N/A',
    },
    {
      label: t('land.underExploited.floor'),
      value: Number.isInteger(maxFloor) ? maxFloor : 'N/A',
    },
    {
      label: t('land.underExploited.height'),
      value: Number.isInteger(maxHeight) ? `${maxHeight} m` : 'N/A',
    },
  ]

  const data2 = [
    {
      label: t('land.groundArea.total'),
      value:
        Number.isInteger(groundArea) && groundArea
          ? `${toReadableNumber(groundArea)} m2`
          : 'N/A',
    },
    {
      label: t('land.floorArea.total'),
      value:
        Number.isInteger(floorArea) && floorArea
          ? `${toReadableNumber(floorArea)} m2`
          : 'N/A',
    },
    { label: t('land.building.mass'), value: '' },
  ]

  return (
    <InfoBox
      title={t('land.building')}
      data={data}
      data2={data2}
      id="land-building-info"
    />
  )
}

export default BuildingInfo
