import { Fragment, memo } from 'react'

import { SummaryMapConfig } from '../Summary'
import { TransactionMapConfig } from '../../LandDetails/Transaction'
import { FaoMapConfig } from '../../LandDetails/Fao'
import { ListingMapConfig } from '../../LandDetails/Listing'
import { LivabilityMapConfig } from '../../LandDetails/Livability'
import { LandSummaryMapConfig } from '../LandSummary'
import { SunlightMapConfig } from '../../LandDetails/Sunlight'
import { EnergyMapConfig } from '../Energy'

type MapConfigType = Partial<typeof TransactionMapConfig> & {
  Controller?: React.ElementType<any>
  style?: string
}

const MapConfigByPage: Record<string, MapConfigType> = {
  summary: SummaryMapConfig,
  parcelle: LandSummaryMapConfig,
  energy: EnergyMapConfig,
  transaction: TransactionMapConfig,
  fao: FaoMapConfig,
  listing: ListingMapConfig,
  livability: LivabilityMapConfig,
  sunlight: SunlightMapConfig,
}

/**
 * Map controllers have the full control of the map
 */
const MapControllers = memo((props: any) => (
  <>
    {Object.values(MapConfigByPage).map((comp, i) => {
      const { Controller } = comp

      if (!Controller) return <Fragment key={i} />

      return <Controller {...props} key={i} />
    })}
  </>
))

export { MapConfigByPage, MapControllers }
