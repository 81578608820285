import { Fragment, memo } from 'react'

import { FaoMapConfig } from '../Fao/FaoAround'
import { GeoMapConfig } from '../Geo'
import { ListingMapConfig } from '../Listing'
import { LivabilityMapConfig } from '../Livability'
import { SummaryMapConfig } from '../Summary'
import { SunlightMapConfig } from '../Sunlight'
import { TransactionMapConfig } from '../Transaction'
import { UnderExploitedMapConfig } from '../UnderExploited'
import { RestrictionMapConfig } from '../Restriction'
import { DesignMapConfig } from '../Design'

type MapConfigType = Partial<typeof TransactionMapConfig> & {
  Controller?: React.ElementType<any>
  style?: string
}

const MapConfigByPage: Record<string, MapConfigType> = {
  summary: SummaryMapConfig,
  transaction: TransactionMapConfig,
  fao: FaoMapConfig,
  listing: ListingMapConfig,
  livability: LivabilityMapConfig,
  geo: GeoMapConfig,
  sunlight: SunlightMapConfig,
  lupa: UnderExploitedMapConfig,
  'financial-plan': SummaryMapConfig,
  ownership: SummaryMapConfig,
  building: SummaryMapConfig,
  restriction: RestrictionMapConfig,
  'design-2': DesignMapConfig,
}

/**
 * Map controllers have the full control of the map
 */
const MapControllers = memo((props: any) => (
  <>
    {Object.values(MapConfigByPage).map((comp, i) => {
      const { Controller } = comp

      if (!Controller) return <Fragment key={i} />

      return <Controller {...props} key={i} />
    })}
  </>
))

export { MapConfigByPage, MapControllers }
