import { formReducer } from './form'
import { landuseplanReducer } from './landuseplan'
import { previewReducer } from './preview'
import { legendReducer } from './legend'
import { basemapReducer } from './basemap'
import { thematicReducer } from './thematic'
import { favoritesReducer } from './favorites'
import { tagsReducer } from './tags'
import { prospectsReducer } from './prospects'
import { restrictionsReducer } from './restrictions'
import { overridedLandUsePlanReducer } from './overridedLandUsePlan'
import { tagsOnMapReducer } from './tagsOnMap'
import { reportsActionsReducer } from './reports'

export * from './form'
export * from './preview'
export * from './landuseplan'
export * from './basemap'
export * from './thematic'
export * from './favorites'
export * from './tags'
export * from './prospects'
export * from './restrictions'
export * from './overridedLandUsePlan'
export * from './tagsOnMap'
export * from './reports'

export const developerMapReducer = {
  ...formReducer,
  ...previewReducer,
  ...landuseplanReducer,
  ...legendReducer,
  ...basemapReducer,
  ...thematicReducer,
  ...favoritesReducer,
  ...tagsReducer,
  ...prospectsReducer,
  ...restrictionsReducer,
  ...overridedLandUsePlanReducer,
  ...tagsOnMapReducer,
  ...reportsActionsReducer,
}
