import { FC } from 'react'
import { ResetIcon } from '@popety_io/popety-io-lib'

import {
  HeatingValueFieldBoxRoot,
  HeatingValueFieldRoot,
  HeatingValueRoot,
} from './HeatingAndHotWater.style'

interface IProps {
  value: string | undefined
  valueEdited?: string
  valueDefault?: string
  onChangeValue: (v: string) => void
  onReset: () => void
  onRetrieve: () => void
  isEdit: boolean
}
const HeatingValueField: FC<IProps> = ({
  value = '',
  valueEdited,
  valueDefault,
  onChangeValue,
  onReset,
  onRetrieve,
  isEdit,
}) => {
  const onChange = (e: any) => {
    onChangeValue(e.target.value)
  }

  const isReset = valueDefault !== value && value === valueEdited

  if (!isEdit) {
    return (
      <HeatingValueRoot edited={!!value && value !== valueDefault}>
        {value || '-'}
      </HeatingValueRoot>
    )
  }

  return (
    <HeatingValueFieldBoxRoot retrieve={!isReset}>
      <HeatingValueFieldRoot value={value} onChange={onChange} />
      {valueEdited && (
        <ResetIcon
          sx={{ margin: 0, fontSize: 14 }}
          onClick={() => (isReset ? onReset() : onRetrieve())}
        />
      )}
    </HeatingValueFieldBoxRoot>
  )
}

export default HeatingValueField
