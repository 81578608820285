/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import {
  Bullet,
  formatPrice,
  useI18n,
  ResetIcon,
  useRouter,
  Bubbles,
} from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Skeleton } from '@mui/material'
import Grid from '@mui/material/Grid'
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close'
import Paper from '@mui/material/Paper'
import { useDispatch, useSelector } from 'react-redux'

import {
  dwellingSelectedSelector,
  estimateEditedSelector,
  estimateIdSelector,
  estimateSelector,
  loadEstimationSelector,
  saveEditedEstimate,
} from '../../../redux'
import { ValueField } from '../../../../../components/ValueField'
import MouseHoverPopover from '../../../../../components/MouseHoverPopover'

const optionsSettings = () => [
  {
    color: 'rgba(127, 188, 83, 0.7)',
    value: 3,
    fillCount: 3,
  },
  {
    color: 'rgb(233, 123, 14)',
    value: 2,
    fillCount: 2,
  },
  {
    color: 'rgba(177, 73, 69, 0.7)',
    value: 1,
    fillCount: 1,
  },
]

const EstimatedPrice = () => {
  const { t } = useI18n()
  const options = optionsSettings()
  const dispatch = useDispatch()
  const estimate: any = useSelector(estimateSelector)
  const estimateEdited: any = useSelector(estimateEditedSelector)
  const dwellingSelected = useSelector(dwellingSelectedSelector)
  const estimateId = useSelector(estimateIdSelector)
  const loadEstimation = useSelector(loadEstimationSelector)
  const [isEdit, setEdit] = useState(false)
  const [data, setData]: any = useState({})
  const { query } = useRouter()

  const defaultData = {
    lowRange: estimate?.valueRange?.lower,
    lowRangePerM2:
      estimate?.valueRange?.lower &&
      Math.round(estimate.valueRange.lower / (dwellingSelected?.area || 100)),
    upperRange: estimate?.valueRange?.upper,
    upperRangePerM2:
      estimate?.valueRange?.upper &&
      Math.round(estimate.valueRange.upper / (dwellingSelected?.area || 100)),
    reliability: estimate?.valueRange?.confidence || 'Medium',
  }

  useEffect(() => {
    setData({
      ...defaultData,
      price: estimateEdited?.price
        ? estimateEdited?.price
        : Math.round(
            ((Number(estimateEdited?.lowRange) ||
              estimate?.valueRange?.lower ||
              0) +
              (Number(estimateEdited?.upperRange) ||
                estimate?.valueRange?.upper ||
                0)) /
              2,
          ),
      lowRange: estimateEdited?.lowRange || estimate?.valueRange?.lower,
      upperRange: estimateEdited?.upperRange || estimate?.valueRange?.upper,
      reliability: estimate.confidence
        ? estimate.confidence.charAt(0).toUpperCase() +
          estimate.confidence.slice(1)
        : '',
    })
  }, [estimate, dwellingSelected, estimateEdited])

  const onChange = (name: string) => (e: any) => {
    setData((pre: any) => ({
      ...(pre || {}),
      [name]: e || '',
    }))
  }

  const onSave = () => {
    dispatch(
      saveEditedEstimate({
        id: estimateId,
        body: { ...estimateEdited, ...data },
      }) as any,
    )
    setEdit(false)
  }

  const onReset = () => {
    dispatch(
      saveEditedEstimate({
        id: estimateId,
        body: {
          ...estimateEdited,
          ...defaultData,
          price: Math.round(
            ((Number(estimateEdited?.lowRange) ||
              estimate?.valueRange?.lower ||
              0) +
              (Number(estimateEdited?.upperRange) ||
                estimate?.valueRange?.upper ||
                0)) /
              2,
          ),
        },
      }) as any,
    )
    setData(defaultData)
    setEdit(false)
  }

  return (
    <Box
      sx={{
        marginTop: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: '4px',
            fontSize: '1.1rem',
            textTransform: 'uppercase',
          }}
        >
          {t('estimate.summary.Estimation du prix')}
        </Typography>
        {!isEdit ? (
          <IconButton
            aria-label="alarm"
            sx={{
              color: '#969696',
              padding: '0',
            }}
            onClick={() => setEdit(true)}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <div>
            <IconButton
              aria-label="alarm"
              sx={{
                color: '#969696',
                padding: '0',
              }}
              onClick={onReset}
            >
              <ResetIcon />
            </IconButton>
            <IconButton
              aria-label="alarm"
              sx={{
                color: '#969696',
                padding: '0',
              }}
              onClick={onSave}
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              aria-label="alarm"
              sx={{
                color: '#969696',
                padding: '0',
              }}
              onClick={onSave}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: '5px',
              paddingTop: '5px',
            }}
          >
            {!loadEstimation && estimateId ? (
              <>
                {' '}
                <ValueField
                  value={data?.price}
                  valueDefault={data?.price}
                  onChangeValue={(v) => onChange('price')(v)}
                  isEdit={isEdit}
                  isFormatPrice
                  sx={{
                    display: 'flex',
                    fontSize: '1.5rem',
                    color: 'primary.main',
                    fontWeight: 600,
                  }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    color: '#969696',
                    paddingBottom: '10px',
                  }}
                  fontSize="1rem"
                >
                  {data?.price &&
                    formatPrice(
                      Math.round(
                        (data?.price || 2600000) /
                          (query.livingArea || dwellingSelected?.area || 100),
                      ),
                    )}
                  / m2
                </Typography>
              </>
            ) : (
              <Skeleton variant="text" width={100} />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: '5px',
              paddingTop: '5px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                paddingBottom: '5px',
              }}
            >
              <Typography fontWeight="500" fontSize="1rem">
                {t('estimate.summary.Fourchette basse')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {!loadEstimation && estimateId ? (
                  <>
                    <ValueField
                      value={data?.lowRange}
                      valueDefault={data?.lowRange}
                      onChangeValue={(v) => onChange('lowRange')(v)}
                      isEdit={isEdit}
                      isFormatPrice
                      sx={{
                        display: 'flex',
                        fontSize: '1rem',
                        color: 'primary.main',
                        fontWeight: 600,
                      }}
                    />
                    <Typography
                      sx={{
                        color: '#969696',
                        paddingBottom: '10px',
                      }}
                      fontSize="0.8rem"
                    >
                      {data?.lowRange
                        ? formatPrice(
                            Math.round(
                              (data?.lowRange || 1675100) /
                                (query.livingArea ||
                                  dwellingSelected?.area ||
                                  100),
                            ),
                          )
                        : 'CHF 0'}{' '}
                      /m2
                    </Typography>
                  </>
                ) : (
                  <Skeleton variant="text" width={100} />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                paddingBottom: '5px',
              }}
            >
              <Typography fontWeight="500" fontSize="1rem">
                {t('estimate.summary.Fourchette haute')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {!loadEstimation && estimateId ? (
                  <>
                    <ValueField
                      value={data?.upperRange}
                      valueDefault={data?.upperRange}
                      onChangeValue={(v) => onChange('upperRange')(v)}
                      isEdit={isEdit}
                      isFormatPrice
                      sx={{
                        display: 'flex',
                        fontSize: '1rem',
                        color: 'primary.main',
                        fontWeight: 600,
                      }}
                    />
                    <Typography
                      sx={{
                        color: '#969696',
                        paddingBottom: '10px',
                      }}
                      fontSize="0.8rem"
                    >
                      {data?.upperRange
                        ? formatPrice(
                            Math.round(
                              (data?.upperRange || 2150700) /
                                (query.livingArea ||
                                  dwellingSelected?.area ||
                                  100),
                            ),
                          )
                        : 'CHF 0'}{' '}
                      /m2
                    </Typography>
                  </>
                ) : (
                  <Skeleton variant="text" width={100} />
                )}
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '5px',
              paddingTop: '5px',
              height: '100%',
            }}
          >
            <MouseHoverPopover
              title={
                <Box>
                  <Typography>{t('common.Fiabilité')}</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    {!loadEstimation && estimateId ? (
                      <Bullet
                        isHover={false}
                        data={options}
                        value={
                          // eslint-disable-next-line no-nested-ternary
                          data?.reliability === 'Low'
                            ? 1
                            : data?.reliability === 'Medium'
                              ? 2
                              : 3
                        }
                        title="Label"
                      />
                    ) : (
                      <Skeleton variant="text" width={100} />
                    )}
                  </Box>
                </Box>
              }
            >
              <Paper
                sx={{
                  padding: 2,
                  width: 'fit-content',
                  maxWidth: '600px',
                  'div > span': {
                    marginLeft: '12px',
                    padding: '3px',
                  },
                }}
              >
                <Box
                  gap="2px"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box display="flex" gap="2px">
                    <Bubbles data={options} value={3} />
                    <Typography component="span" color="text.secondary">
                      {t(
                        "estimate.price.reliability.Bonne = données concluantes, peu d'incertitudes.",
                      )}
                    </Typography>
                  </Box>
                  <Box display="flex" gap="2px">
                    <Bubbles data={options} value={2} />
                    <Typography component="span" color="text.secondary">
                      {t(
                        'estimate.price.reliability.Moyenne = données solides, quelques incertitudes, fluctuations/écarts plus importants possibles dans la fourchette de prix.',
                      )}
                    </Typography>
                  </Box>
                  <Box display="flex" gap="2px">
                    <Bubbles data={options} value={1} />
                    <Typography component="span" color="text.secondary">
                      {t(
                        "estimate.price.reliability.Faible = données potentiellement incohérentes ou insuffisantes, proportion élevée d'hypothèses et d'incertitudes.",
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </MouseHoverPopover>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EstimatedPrice
