import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

export const ListingDetailsRoot = styled(Grid)`
  .MuiStepButton-root {
    color: #03254c;
  }

  .ControlsCard {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .MuiListItem-root {
    > span {
      width: 50%;
    }
  }

  .MuiDivider-root {
    opacity: 0.5;
  }

  .addedDate {
    color: #969696;
  }

  .addAddress {
    line-height: 1.4;
  }

  .addTitle {
    font-weight: 500;
    line-height: 1.4;
  }

  .price {
    font-size: 1.3rem;
  }

  .priceSideCost {
    font-size: 0.8rem;
  }

  .addFeatureTitle {
    font-weight: 500;
    padding-bottom: 8px;
  }
`
