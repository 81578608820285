import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

export const FaoDetailsRoot = styled(Grid)`
  .MuiStepButton-root {
    color: #03254c;
  }

  .ControlsCard {
    display: flex;
    flex-direction: column;
    box-shadow: none;
    gap: 8px;
  }

  .MuiListItem-root {
    > span {
      width: 50%;
    }
  }
  .MuiDivider-root {
    opacity: 0.5;
  }
  .faoTitle {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.4;
  }
  .faoAddress {
    line-height: 1.4;
  }
  .faoPublishDate {
    color: #ccc;
    line-height: 20px;
  }
  .faoTitleSection {
    font-weight: 500;
    line-height: 1.4;
  }
  .faoValueSection {
    line-height: 1.4;
  }
  .officialLink {
    color: #00ade6;
  }
`
