/* eslint-disable max-lines */
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  SwipeableTabs,
  useI18n,
  useRouter,
  Driver,
  fetchOnce,
} from '@popety_io/popety-io-lib'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import { Badge } from '@mui/material'

import HistoryAroundFilters from '../components/HistoryAroundFilters'
import {
  faosAroundCountSelector,
  faosAroundSelector,
  landFaosSelector,
  faoStatsSelector,
  faosPercentSelector,
  landFaosLoadingSelector,
} from '../redux'
import { userSelector } from '../../Login'
import { updateTutorials } from '../../Account'
import getDriverInstructions from './driverInstructions'
import FaoList from './FaoList'
import FaoStats from './FaoStats'

interface IProps {
  show?: boolean
}

const Fao = ({ show = true }: IProps) => {
  const { query, updateQuery } = useRouter()
  const dispatch = useDispatch<any>()

  const faos = useSelector(landFaosSelector)
  const faosAround = useSelector(faosAroundSelector)
  const faosAroundCount = useSelector(faosAroundCountSelector)
  const faosStatsData = useSelector(faoStatsSelector)
  const faosPercent = useSelector(faosPercentSelector)

  const loading = useSelector(landFaosLoadingSelector)
  const user = useSelector(userSelector)

  const [opened, setOpened] = useState<string>()
  const [displayDriver, setDisplayDriver] = useState(false)

  const { t } = useI18n()

  useEffect(() => {
    if (faos?.length) {
      setOpened(`fao${faos?.[0]?.id}`)
    }
  }, [faos])

  const tabs = ['fao', 'stats']
    .map((value) => ({
      label: t(`land.history.tab.${value}`),
      key: value,
    }))
    .filter((value) => show || (!show && value.key !== 'stats'))

  const tabFao = [
    {
      label: t('land.fao.title'),
      count: faos?.length,
    },
    { label: t('land.faoAround'), count: faosAroundCount },
  ]
    .slice(show ? 0 : 1)
    .map((value) => ({
      label: (
        <Badge
          badgeContent={value.count}
          color="primary"
          sx={{
            '& .MuiBadge-badge': { top: -2 },
            paddingTop: 1,
          }}
          showZero
        >
          {value.label}
        </Badge>
      ),
      key: value.label,
    }))

  const handleChange = (uid: string) => {
    setOpened(uid)
    updateQuery({
      faoOpened: uid,
    })
  }

  useEffect(() => {
    if (query?.faoOpened) {
      setOpened(query?.faoOpened)
    }
  }, [query?.faoOpened])

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedFaoDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedFaoDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  if (!show) {
    return (
      <Box
        pt={2}
        zIndex={10}
        bgcolor="background.paper"
        sx={{
          position: {
            lg: 'sticky',
          },
          top: {
            lg: 0,
          },
        }}
      >
        <Box
          sx={{
            marginBottom: '20px',
          }}
        >
          <Typography variant="h2" fontSize="1.7rem" fontWeight={500}>
            {t('common.building_permit')}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#969696',
              paddingBottom: '10px',
            }}
            fontSize="1rem"
          >
            {t('common.building_permit.header_description')}
          </Typography>
        </Box>
        <HistoryAroundFilters />
        {loading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {Array.from({ length: 10 }).map((_, i) => (
              <Box
                key={i}
                sx={{
                  width: '100%',
                  height: '45px',
                  mt: 1.5,
                }}
              >
                <Skeleton
                  variant="rounded"
                  sx={{
                    fontSize: '1rem',
                    borderRadius: 2,
                    height: '45px',
                    width: '100%',
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <FaoList
            faos={faosAround}
            opened={opened}
            onChange={handleChange as any}
          />
        )}
      </Box>
    )
  }

  return (
    <Box px={1}>
      <SwipeableTabs
        tabs={tabFao}
        value={faos?.length && faosAroundCount ? 0 : 1}
      >
        <FaoList faos={faos} opened={opened} onChange={handleChange as any} />
        <Box
          pt={2}
          zIndex={10}
          bgcolor="background.paper"
          sx={{
            position: {
              lg: 'sticky',
            },
            top: {
              lg: 0,
            },
          }}
        >
          <HistoryAroundFilters />
          {loading ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {Array.from({ length: 10 }).map((_, i) => (
                <Box
                  key={i}
                  sx={{
                    width: '100%',
                    height: '45px',
                    mt: 1.5,
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{
                      fontSize: '1rem',
                      borderRadius: 2,
                      height: '45px',
                      width: '100%',
                    }}
                  />
                </Box>
              ))}
            </Box>
          ) : (
            <SwipeableTabs
              tabs={tabs}
              tabKey="fao-around"
              label={t('common.new')}
              fixedTabs
            >
              <FaoList
                faos={faosAround}
                opened={opened}
                onChange={handleChange as any}
                show={show}
              />
              <FaoStats
                faosStatsData={faosStatsData}
                faosPercent={faosPercent}
              />
            </SwipeableTabs>
          )}
        </Box>
      </SwipeableTabs>
      {!loading && (
        <Driver
          instructions={getDriverInstructions(t)}
          display={displayDriver}
        />
      )}
    </Box>
  )
}

export default Fao
