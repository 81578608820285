/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */

export const getBuildingAreaInformations = (
  t: any,
  land: any,
  lup: any,
  edit?: boolean,
) => {
  const maxFloor = land.max_floor_nb
  const floorAuthorized =
    lup.floorNbOverride || lup.floor_nb || lup.floor_nb_estimate
  const isFloor = maxFloor || floorAuthorized

  const maxHeight = land?.max_height?.toFixed(2)
  const heightAuthorized =
    lup.heightOverride || lup.height || lup.height_estimate
  const isHeight = maxHeight || heightAuthorized

  const distanceLimit = land.distance_limit
  const distanceLimitAuthorized =
    lup.distanceLimitOverride ||
    lup.distance_limit ||
    lup.distance_limit_estimate
  const isDistanceLimit = distanceLimit || distanceLimitAuthorized

  const noiseSensibilityLevel = land.noise_sensibility_level
  const noiseSensibilityLevelAuthorized =
    lup.noiseSensibilityLevelOverride ||
    lup.noise_sensibility_level ||
    lup.noise_sensibility_level_estimate
  const isNoise = noiseSensibilityLevel || noiseSensibilityLevelAuthorized

  const values = [
    (isFloor || edit) && {
      id: 'floorNb',
      title: t('land.underExploited.floor'),
      current: maxFloor,
      authorized: floorAuthorized,
      defaultAuthorized: lup.floor_nb || lup.floor_nb_estimate || 0,
      className: lup.floorNbOverride
        ? 'greenColor'
        : lup.floor_nb
          ? ''
          : 'blueColor',
      difference:
        floorAuthorized && maxFloor && Math.round(floorAuthorized - maxFloor),
      authorizedColor: !lup.floor_nb ? 'blueColor' : '',
      differenceColor:
        Math.round(floorAuthorized - maxFloor) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
    },
    (isHeight || edit) && {
      id: 'height',
      title: t('land.underExploited.height'),
      current: maxHeight,
      authorized: heightAuthorized,
      defaultAuthorized: lup.height || lup.height_estimate || 0,
      className: lup.heightOverride
        ? 'greenColor'
        : lup.height
          ? ''
          : 'blueColor',
      difference: Number((heightAuthorized - maxHeight)?.toFixed(2)),
      authorizedColor: !lup.height ? 'blueColor' : '',
      differenceColor:
        Number((heightAuthorized - maxHeight)?.toFixed(2)) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
    },
    (isDistanceLimit || edit) && {
      id: 'distanceLimit',
      title: t('land.underExploited.distanceLimit'),
      current: distanceLimit,
      authorized: distanceLimitAuthorized,
      defaultAuthorized: lup.distance_limit || lup.distance_limit_estimate || 0,
      className: lup.distanceLimitOverride
        ? 'greenColor'
        : lup.distance_limit
          ? ''
          : 'blueColor',
      difference: Math.round(distanceLimitAuthorized - distanceLimit),
      authorizedColor: !lup.distance_limit ? 'blueColor' : '',
      differenceColor:
        Math.round(distanceLimitAuthorized - distanceLimit) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
    },
    (isNoise || edit) && {
      id: 'noiseSensibilityLevel',
      title: t('land.underExploited.noiseSensibilityLevel'),
      current: noiseSensibilityLevel,
      authorized: noiseSensibilityLevelAuthorized,
      defaultAuthorized: lup.noise_sensibility_level || 0,
      className: lup.noiseSensibilityLevelOverride
        ? 'greenColor'
        : lup.noise_sensibility_level
          ? ''
          : 'blueColor',
      difference: Math.round(
        noiseSensibilityLevelAuthorized - noiseSensibilityLevel,
      ),
      authorizedColor: !lup.noise_sensibility_level ? 'blueColor' : '',
      differenceColor:
        Math.round(noiseSensibilityLevelAuthorized - noiseSensibilityLevel) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
    },
  ].filter(Boolean)

  return values
}

export const getIndicesValues = (
  t: any,
  land: any,
  lup: any,
  landUsePlanStatus: any,
  buildings: any,
  edit = false,
) => {
  const isNotPLQ = lup.planType !== 'PLQ' && lup.planType !== 'PAD'
  const primaryIndice = lup.typeZone?.ios || land.primaryIndice || ''
  const secondaryIndice = lup.typeZone?.ius || land.secondaryIndice || ''

  const iosEdited = lup?.ios_overide?.value || lup?.iosOverride
  const ios = land.current_iosOverride || land.ios || land.ios_estimate || 0

  const iosAuthorized =
    iosEdited ||
    (isNotPLQ
      ? lup.ios !== null
        ? lup.ios
        : lup.ios_estimate
      : landUsePlanStatus === 'future'
        ? land.future_ios
        : land.ios)

  const ibusEdited = lup?.ibus_overide?.value || lup?.ibusOverride
  const ibus = land.current_ibusOverride || land.ibus || land.ibus_estimate || 0
  const ibusAuthorized =
    ibusEdited ||
    (isNotPLQ
      ? lup.ibus !== null
        ? lup.ibus
        : lup.ibus_estimate
      : landUsePlanStatus === 'future'
        ? land.future_ibus
        : land.ibus)

  const cosEdited = lup?.cos_overide?.value || lup?.cosOverride
  const cos = land.current_cosOverride || land.cos || land.cos_estimate || 0
  const cosAuthorized =
    cosEdited ||
    (isNotPLQ
      ? lup.cos !== null
        ? lup.cos
        : lup.cos_estimate
      : landUsePlanStatus === 'future'
        ? land.future_cos
        : land.cos)

  const cusEdited = lup?.cus_overide?.value || lup?.cusOverride
  const cus = land.current_cusOverride || land.cus || land.cus_estimate || 0
  const cusAuthorized =
    cusEdited ||
    (isNotPLQ
      ? lup.cus !== null
        ? lup.cus
        : lup.cus_estimate
      : landUsePlanStatus === 'future'
        ? land.future_cus
        : land.cus)

  const iusEdited = lup?.ius_overide?.value || lup?.iusOverride
  const ius = land.current_iusOverride || land.ius || land.ius_estimate || 0
  const iusAuthorized =
    iusEdited ||
    (isNotPLQ
      ? lup.ius !== null
        ? lup.ius
        : lup.ius_estimate
      : landUsePlanStatus === 'future'
        ? land.future_ius
        : land.ius)

  const std = parseInt((land.area * lup.cover_area_perc).toFixed(0), 10)

  const sbd = land.sbdOverride || land.sbd

  const imEdited = lup?.im_overide?.value || lup?.imOverride
  const im = land.current_imOverride || land.im || land.im_estimate || 0
  const imAuthorized =
    imEdited ||
    (isNotPLQ
      ? lup.im !== null
        ? lup.im
        : lup.im_estimate
      : landUsePlanStatus === 'future'
        ? land.future_im
        : land.im)

  const getVbrAuthorized = () => {
    if (typeof land.authorized_vbr !== 'undefined') {
      return land.authorized_vbr || 0
    }

    if (lup.im !== null || lup.im_estimate !== null || lup.imOverride) {
      if (isNotPLQ) {
        return Math.round(
          std *
            (Number(imEdited) ||
              (lup.im !== null ? lup.im || 0 : lup.im_estimate || 0)),
        )
      }
    }

    return 0
  }

  const vbr = land.vbrOverride || land.vbrOverride || land.vbr || 0
  const vbrAuthorized = getVbrAuthorized()

  const getSbdAuthorized = () => {
    if (typeof land.authorized_sbd !== 'undefined') {
      return land.authorized_sbd
    }

    if (lup.ios !== null || lup.ios_estimate !== null || lup.iosOverride) {
      if (isNotPLQ) {
        return Math.round(
          std *
            (Number(iosEdited) ||
              (lup.ios !== null ? lup.ios : lup.ios_estimate)),
        )
      }
    }

    return 0
  }
  const sbdAuthorized = getSbdAuthorized()

  const sb = land.sbOverride || land.sb

  const getSbAuthorized = () => {
    if (typeof land.authorized_sb !== 'undefined') {
      return land.authorized_sb
    }

    if (lup.cos !== null || lup.cos_estimate !== null || lup.cosOverride) {
      if (isNotPLQ) {
        return Math.round(
          std *
            (Number(cosEdited) ||
              (lup.cos !== null ? lup.cos : lup.cos_estimate)),
        )
      }
    }

    return 0
  }
  const sbAuthorized = getSbAuthorized()

  const sp = land.spOverride || land.sp
  const getSpAuthorized = () => {
    if (typeof land.authorized_sp !== 'undefined') {
      return land.authorized_sp
    }

    if (lup.ibus !== null || lup.ibus_estimate !== null || lup.ibusOverride) {
      if (isNotPLQ) {
        return Math.round(
          std *
            (Number(ibusEdited) ||
              (lup.ibus !== null ? lup.ibus : lup.ibus_estimate)),
        )
      }
    }

    return 0
  }
  const spAuthorized = getSpAuthorized()

  const sbpu = land.sbpuOverride || land.sbpu

  const getSbpuAuthorized = () => {
    if (typeof land.authorized_sbpu !== 'undefined') {
      return land.authorized_sbpu
    }

    if (lup.cus !== null || lup.cus_estimate !== null || lup.cusOverride) {
      if (isNotPLQ) {
        return Math.round(
          std *
            (Number(cusEdited) ||
              (lup.cus !== null ? lup.cus : lup.cus_estimate)),
        )
      }
    }

    return 0
  }

  const sbpuAuthorized = getSbpuAuthorized()

  const spd = land.spdOverride || land.spd
  const getSpdAuthorized = () => {
    if (typeof land.authorized_spd !== 'undefined') {
      return land.authorized_spd
    }

    if (lup.ius !== null || lup.ius_estimate !== null || lup.iusOverride) {
      if (isNotPLQ) {
        return Math.round(
          std *
            (Number(iusEdited) ||
              (lup.ius !== null ? lup.ius : lup.ius_estimate)),
        )
      }
    }

    return 0
  }

  const spdAuthorized = getSpdAuthorized()

  const values = {
    std: {
      id: 'std',
      subtitle: t('land.financialAnalysis.std'),
      currentSurface: std,
    },
    ios: {
      id: 'ios',
      title: t('land.underExploited.ios'),
      subtitle: t('land.underExploited.sbd'),
      descriptionTitle: t('land.underExploited.iosDescription'),
      descriptionSubTitle: t('land.underExploited.sbdDescription'),
      current: ios,
      currentOverride: !!land.current_iosOverride,
      authorized: iosAuthorized,
      defaultAuthorized: lup.ios || lup.ios_estimate,
      className: iosEdited ? 'greenColor' : lup.ios ? '' : 'blueColor',
      difference: Number((iosAuthorized - ios).toFixed(2)),
      authorizedSurface: sbdAuthorized,
      currentSurface: sbd,
      currentSurfaceOvrride: !!land.sbdOverride,
      differenceSurface: sbdAuthorized - sbd,
      differenceColorSurface:
        Number((sbdAuthorized - sbd)?.toFixed(2)) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
      differenceColor:
        Number((iosAuthorized - ios)?.toFixed(2)) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
      primaryIndice,
      secondaryIndice,
      indiceName: 'primaryIndice',
    },
    cos: {
      id: 'cos',
      title: t('land.underExploited.cos'),
      subtitle: t('land.underExploited.sb'),
      descriptionTitle: t('land.underExploited.cosDescription'),
      descriptionSubTitle: t('land.underExploited.sbDescription'),
      current: cos,
      currentOverride: !!land.current_cosOverride,
      authorized: cosAuthorized,
      defaultAuthorized: lup.cos || lup.cos_estimate,
      className: cosEdited ? 'greenColor' : lup.cos ? '' : 'blueColor',
      difference: Number((cosAuthorized - cos).toFixed(2)),
      differenceColor:
        Number((cosAuthorized - cos)?.toFixed(2)) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
      authorizedSurface: sbAuthorized,
      currentSurface: sb,
      currentSurfaceOvrride: !!land.sbOverride,
      differenceSurface: sbAuthorized - sb,
      differenceColorSurface:
        Number((sbAuthorized - sb)?.toFixed(2)) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
      primaryIndice,
      secondaryIndice,
      indiceName: 'primaryIndice',
      warning: land?.sb_override_by_distance_limit,
      warningText: t('common.sb_override_by_distance_limit'),
    },
    ius: {
      id: 'ius',
      title: t('land.underExploited.ius'),
      subtitle: t('land.underExploited.spd'),
      descriptionTitle: t('land.underExploited.iusDescription'),
      descriptionSubTitle: t('land.underExploited.spdDescription'),
      current: ius,
      currentOverride: !!land.current_iusOverride,
      authorized: iusAuthorized,
      defaultAuthorized: lup.ius || lup.ius_estimate,
      className: iusEdited ? 'greenColor' : lup.ius ? '' : 'blueColor',
      difference: Number((iusAuthorized - ius).toFixed(2)),
      differenceColor:
        Number((iusAuthorized - ius)?.toFixed(2)) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
      authorizedSurface: spdAuthorized,
      currentSurface: spd,
      currentSurfaceOvrride: !!land.spdOverride,
      differenceSurface: spdAuthorized - spd,
      differenceColorSurface:
        Number((spdAuthorized - spd)?.toFixed(2)) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
      primaryIndice,
      secondaryIndice,
      indiceName: 'secondaryIndice',
    },
    cus: {
      id: 'cus',
      title: t('land.underExploited.cus'),
      subtitle: t('land.underExploited.sbpu'),
      descriptionTitle: t('land.underExploited.cusDescription'),
      descriptionSubTitle: t('land.underExploited.sbpuDescription'),
      current: cus,
      currentOverride: !!land.current_cusOverride,
      className: cusEdited ? 'greenColor' : lup.cus ? '' : 'blueColor',
      authorized: cusAuthorized,
      defaultAuthorized: lup.cus || lup.cus_estimate,
      difference: Number((cusAuthorized - cus).toFixed(2)),
      differenceColor:
        Number((cusAuthorized - cus)?.toFixed(2)) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
      authorizedSurface: sbpuAuthorized,
      currentSurface: sbpu,
      currentSurfaceOvrride: !!land.sbpuOverride,
      differenceSurface: sbpuAuthorized - sbpu,
      differenceColorSurface:
        Number((sbpuAuthorized - sbpu)?.toFixed(2)) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
      primaryIndice,
      secondaryIndice,
      indiceName: 'secondaryIndice',
    },
    ibus: {
      id: 'ibus',
      title: t('land.underExploited.ibus'),
      subtitle: t('land.underExploited.sp'),
      descriptionTitle: t('land.underExploited.ibusDescription'),
      descriptionSubTitle: t('land.underExploited.spDescription'),
      current: ibus,
      currentOverride: !!land.current_ibusOverride,
      authorized: ibusAuthorized,
      defaultAuthorized: lup.ibus || lup.ibus_estimate,
      className: ibusEdited ? 'greenColor' : lup.ibus ? '' : 'blueColor',
      difference: Number((ibusAuthorized - ibus).toFixed(2)),
      differenceColor:
        Number((ibusAuthorized - ibus)?.toFixed(2)) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
      authorizedSurface: spAuthorized,
      currentSurface: sp,
      currentSurfaceOvrride: !!land.spOverride,
      differenceSurface: spAuthorized - sp,
      differenceColorSurface:
        Number((spAuthorized - sp)?.toFixed(2)) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
      primaryIndice,
      secondaryIndice,
      indiceName: 'secondaryIndice',
    },
    im: {
      id: 'im',
      title: t('land.underExploited.im'),
      subtitle: t('land.underExploited.vbr'),
      descriptionTitle: '',
      descriptionSubTitle: '',
      current: im,
      currentOverride: !!land.current_imOverride,
      authorized: imAuthorized || 0,
      defaultAuthorized: lup.im || lup.im_estimate,
      className: imEdited ? 'greenColor' : lup.im ? '' : 'blueColor',
      difference: Number(((imAuthorized || 0) - im).toFixed(2)),
      differenceColor:
        Number(((imAuthorized || 0) - im)?.toFixed(2)) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
      authorizedSurface: Number(vbrAuthorized?.toFixed(2)),
      currentSurface: Number(vbr?.toFixed(2)),
      currentSurfaceOvrride: !!land.vbrOverride,
      differenceSurface: Number((vbrAuthorized - vbr)?.toFixed(2)) || 0,
      differenceColorSurface:
        Number((vbrAuthorized - vbr)?.toFixed(2)) >= 0
          ? 'rgb(127, 188, 83)'
          : 'red',
      primaryIndice,
      secondaryIndice,
      indiceName: 'secondaryIndice',
    },
  } as any

  return edit
    ? Object.values(values)
    : [values.std, values[primaryIndice], values[secondaryIndice]].filter(
        Boolean,
      )
}
