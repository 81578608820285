import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { PaperProps } from '@mui/material/Paper'
import { OverlayLoader, useI18n } from '@popety_io/popety-io-lib'
import Typography from '@mui/material/Typography'

import NotePreview from '../LandNote/NotePreview'
import LandRadar from '../LandRadar'
import LandExternalLinks from '../LandExternalLinks'
import { landdevSelector } from '../../pages/Login'
import Addresses from './Addresses'
import DevelopingArea from './DevelopingArea'
import PreviewHeader from './PreviewHeader'
import History from './History'
import OpenDetailsButton from './OpenDetailsButton'
import LandDetails from './LandDetails'
import Listings from './Listings'
import Ownership from './Ownership'
import { Body, StyledDivider, LandPreviewRoot } from './LandPreview.style'

export type LandPreviewProps = PaperProps & {
  planDisplay?: string
  data: any
  onClose?: () => void
  grouping?: boolean
  onGrouping?: () => void
  open?: boolean
}

const LandPreview = ({
  planDisplay,
  data,
  onClose,
  children,
  grouping,
  onGrouping,
  open,
  ...other
}: LandPreviewProps) => {
  const landdev = useSelector(landdevSelector)
  const { t } = useI18n()

  return (
    <LandPreviewRoot
      {...other}
      sx={{ minHeight: '70vh', ...other.sx, zIndex: 12 }}
      className="LandPreview"
    >
      <OverlayLoader loading={!data} />

      <PreviewHeader
        feature={data}
        onClose={onClose}
        onGrouping={onGrouping}
        grouping={grouping}
        isLand
        open={open}
      />
      {!data && <Typography p={2}>{t('common.Not found')}</Typography>}
      {data && (
        <Box sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
          {landdev && (
            <>
              <LandRadar land={data} planDisplay={planDisplay} />
              <StyledDivider />
            </>
          )}

          <Body>
            <LandDetails feature={data} />
            <StyledDivider />
            <NotePreview
              landId={data.land_id || data.id}
              addressId={data?.address_id}
            />

            {data?.addresses?.length > 0 && <StyledDivider />}
            <Addresses feature={data} click />

            <StyledDivider />
            <DevelopingArea feature={data} planDisplay={planDisplay} />

            <StyledDivider />
            <History data={data} />

            <StyledDivider />
            <LandExternalLinks data={data} />

            <StyledDivider />
            <Ownership feature={data} />

            <Listings data={data} />
            {children}
          </Body>
        </Box>
      )}
      {data && <OpenDetailsButton feature={data} planDisplay={planDisplay} />}
    </LandPreviewRoot>
  )
}

export default LandPreview
