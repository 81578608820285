/* eslint-disable max-lines */
import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchOnce,
  getDate,
  useRouter,
  getRadiusGeoJSON,
  fromFilter,
  useI18n,
  format,
} from '@popety_io/popety-io-lib'
import subMonths from 'date-fns/subMonths'

import {
  getHeatConsumptionAround,
  getRoofBuildingPolygon,
  heatConsumptionAroundSelector,
} from '../BuildingDetails'

import {
  getTransactionAggs,
  getTransactionsAround,
  getListingAround,
  // getListingAggs,
  getFaosAround,
  getFaoAggs,
  countLandListings,
  getPoisAround,
  getPoiCategories,
  getBuildings,
  sunDateSelector,
  updateSunlightOptions,
  getTransactionsByIds,
  getFaosByIds,
  // estimatePotentialSolarDataSelector,
  // poisSelector,
  // poiCategorySelector,
  getMonthlyExposureAverage,
  getBuildingPotentialSolar,
  getIsochrone,
  getWalkingIsochrone,
  getBikingIsochrone,
  getDrivingIsochrone,
  getSelectedEstimateListingsByIds,
} from '../LandDetails'
import { getSunTimes } from '../LandDetails/Sunlight/SunExposure'
// import { EstimateOptions } from './estimate.d'
import {
  estimateLandsSelector,
  estimateSelector,
  getEstimateLandSummary,
  // savePublicEstimateSummary,
  getPublicEditedEstimate,
  estimateIdSelector,
  getDwellingDetails,
  estimateEditedSelector,
  getListingsByIds,
  getPublicHashEstimate,
  submitEstimation,
  getEstimateListingAggs,
  getEstimateListingBuyInsights,
  getListingsAggsPriceAnnoncements,
  getEnvironmentData,
  // dwellingSelectedSelector,
  getEstimateSummary,
  listingAggsPurchasePerSquareMeterSelector,
  estimateLoadingAggsSelector,
  saveEditedEstimate,
  listingAggsPurchaseSelector,
  announcementDataSelector,
  // estimateLandLoadingSelector,
} from './redux'

const getXaxis = (data: Record<string, any>) => {
  let result = data?.key?.replace(/\.0/g, '')

  result = result?.split('-')

  result = result?.map((item: any) => {
    if (!Number.isNaN(Number(item))) {
      const price = Math.floor(Number(item) / 1000)

      return `${price}K`
    }

    return item
  })
  result = result?.join('-')

  return `${result} CHF`
}

const getXaxisDate = (
  data: Record<string, any>,
  t: Translator,
  interval = 'Q',
) => {
  const intervalText = t('common.quarter', {
    count: +format(data.date, interval),
  })

  return `${intervalText} ${new Date(data.date).getFullYear()}`
}

const useGetEstimateData = () => {
  const dispatch: any = useDispatch()
  const { t } = useI18n()
  // const sunlight = useSelector(estimatePotentialSolarDataSelector)
  // const pois = useSelector(poisSelector)
  // const poiCategories = useSelector(poiCategorySelector)

  const estimateId = useSelector(estimateIdSelector)
  const estimate: any = useSelector(estimateSelector)
  const estimateEdited: any = useSelector(estimateEditedSelector)
  const aggsLoading = useSelector(estimateLoadingAggsSelector)
  const announcementData = useSelector(announcementDataSelector)
  const heatConsumptionAround = useSelector(heatConsumptionAroundSelector)

  // const dwellingSelected = useSelector(dwellingSelectedSelector)
  const lands = useSelector(estimateLandsSelector)
  // const estimateLandLoading = useSelector(estimateLandLoadingSelector)
  const listingsIds = estimate?.listings?.map(
    (listing: any) => listing.id || listing.listing_id,
  )
  const transactionsIds = estimate?.lands_transactions?.map(
    (transaction: any) => transaction.id || transaction.transaction_id,
  )
  const faosIds = estimate?.lands_building_permits?.map(
    (fao: any) => fao.id || fao.fao_id,
  )
  const aggsPurchasePerSquareMeter = useSelector(
    listingAggsPurchasePerSquareMeterSelector,
  )
  const aggsPurchase = useSelector(listingAggsPurchaseSelector)

  const regex = /Maison?/gi

  const isHouse = regex.test(estimate?.category || '')

  const defaultType = isHouse ? 'House' : 'Apartment'

  const sunDate = useSelector(sunDateSelector)

  const { query, updateQuery } = useRouter()

  const queryEstimateId = query.estimateId || estimateId

  const isHomePage = query?.tab === 'home'

  useEffect(() => {
    if (isHomePage) return

    const updatedQuery: { [key: string]: any } = {}

    if (query?.roomsNb) {
      const roomsNb = Number(query.roomsNb)

      updatedQuery.rooms = `${Math.round(roomsNb - 1)},${Math.round(roomsNb + 1)}`
    }

    if (!query.propertyCategory) {
      updatedQuery.propertyCategory = defaultType
    }

    updatedQuery.estimateId = estimateId

    updateQuery({ ...query, ...updatedQuery })
  }, [estimateId])

  // if (isHomePage) return
  const landId =
    estimate?.lands?.length > 0
      ? estimate?.lands[0]?.id || estimate?.lands?.[0]?.land_id
      : undefined

  const city = [query.city_history].filter(Boolean) || null
  const zone = [query.district_history].filter(Boolean) || null

  const radius = city?.length || zone?.length ? null : +query.radius || 500

  const center =
    estimate?.geo_center &&
    estimate?.geo_center?.lon &&
    estimate?.geo_center?.lat
      ? [estimate?.geo_center?.lon, estimate?.geo_center?.lat]
      : undefined

  const polygon =
    center && radius ? getRadiusGeoJSON(center, radius) : undefined

  useEffect(() => {
    if (estimateId && !isHomePage) {
      dispatch(getPublicEditedEstimate(estimateId))
      dispatch(getPublicHashEstimate(estimateId))
    }
  }, [estimateId])

  const annoncement = useMemo(
    () =>
      announcementData?.map((d: any) => {
        return { ...d, x: getXaxis(d) }
      }),

    [announcementData],
  )

  const heatConsumptionAroundData = useMemo(() => {
    return heatConsumptionAround?.buckets?.map((entry: any) => {
      if (entry?.from === 550) {
        return {
          ...entry,
          renovationIndice: 550,
          color: '#FFCB60',
        }
      }

      if (entry?.from === 650) {
        return {
          ...entry,
          renovationIndice: 650,
          color: '#FC8723',
        }
      }

      if (entry?.from === 800) {
        return {
          ...entry,
          renovationIndice: 800,
          color: '#E63900',
        }
      }

      if (
        estimate.heat_consumption_index > entry?.from &&
        estimate.heat_consumption_index < entry?.to
      ) {
        return {
          ...entry,
          currentHeatConsumption: estimate.heat_consumption_index,
        }
      }

      return entry
    })
  }, [heatConsumptionAround, estimate?.heat_consumption_index])

  useEffect(() => {
    if (
      estimateEdited?.comparablesIds &&
      estimateEdited?.comparablesIds.length > 0
    ) {
      dispatch(
        getSelectedEstimateListingsByIds({
          listingsIds: estimateEdited?.comparablesIds,
        }),
      )
    }
  }, [estimateEdited?.comparablesIds])

  useEffect(() => {
    if (estimateId && heatConsumptionAroundData) {
      dispatch(
        saveEditedEstimate({
          id: estimateId,
          body: {
            ...estimateEdited,
            heatConsumptionAroundData,
          },
        }) as any,
      )
    }
  }, [heatConsumptionAroundData])

  useEffect(() => {
    if (annoncement && estimateId) {
      dispatch(
        saveEditedEstimate({
          id: estimateId,
          body: { ...estimateEdited, annoncement },
        }) as any,
      )
    }
  }, [annoncement, estimateId])

  const aggsData = useMemo(
    () => [
      {
        data: aggsPurchasePerSquareMeter?.map((d: any) => ({
          ...d,
          x: getXaxisDate(d, t),
        })),
        title: t('common.Prix m2'),
      },
      {
        data: aggsPurchase?.map((d: any) => ({
          ...d,
          x: getXaxisDate(d, t),
        })),
        title: t('common.Prix médian'),
      },
    ],
    [aggsPurchase, aggsPurchasePerSquareMeter],
  )

  useEffect(() => {
    if (
      aggsPurchasePerSquareMeter &&
      aggsPurchase &&
      estimateId &&
      !aggsLoading &&
      !isHomePage
    ) {
      dispatch(
        saveEditedEstimate({
          id: estimateId,
          body: {
            ...estimateEdited,
            mutateAggsPurchase: aggsData,
            buy: aggsPurchase,
          },
        }) as any,
      )
    }
  }, [aggsData, estimateId, aggsPurchasePerSquareMeter, aggsPurchase])

  useEffect(() => {
    if (!query?.geoCenter || !estimateId || isHomePage) return

    const geoCenterSplit = query?.geoCenter?.split(',')

    const body = {
      dealType: 'sale',
      property: {
        location: {
          coordinates: {
            latitude: geoCenterSplit[1],
            longitude: geoCenterSplit[0],
          },
        },
        propertyType: {
          code: query?.propertyType,
        },
        buildingYear: Number(query.constructionYear),
        livingArea: Number(query.livingArea),
        landArea:
          query?.propertyType === 'house'
            ? query.landArea || lands?.[0]?.area
            : null,
        numberOfRooms: Number(query.roomsNb),
        numberOfBathrooms: Number(query.numberOfBathrooms),
        condition: query.condition,
      },
      countryCode: 'CH',
    }

    fetchOnce('submitEstimation', estimateId, () =>
      dispatch(submitEstimation({ id: estimateId, body }) as any),
    )
  }, [estimateId])

  useEffect(() => {
    if (!query.dwellingId || isHomePage) return

    dispatch(getDwellingDetails(query.dwellingId))
  }, [query.dwellingId, isHomePage])

  useEffect(() => {
    if (estimate.id && !isHomePage) {
      dispatch(getRoofBuildingPolygon(estimate.id) as any)
    }
  }, [estimate?.id])

  useEffect(() => {
    if (isHomePage) return
    const sunlightTimes = getSunTimes({
      sunDate,
      center: {
        lng: estimate?.geo_center?.lon,
        lat: estimate?.geo_center?.lat,
      },
    })

    const sunlightSummerTimes = getSunTimes({
      sunDate,
      center: {
        lng: estimate?.geo_center?.lon,
        lat: estimate?.geo_center?.lat,
      },
      month: 5,
      day: 21,
    })

    const sunlightWinterTimes = getSunTimes({
      sunDate,
      center: {
        lng: estimate?.geo_center?.lon,
        lat: estimate?.geo_center?.lat,
      },
      month: 11,
      day: 21,
    })

    dispatch(
      updateSunlightOptions({
        sunlightTimes,
        sunlightWinterTimes,
        sunlightSummerTimes,
      }),
    )
  }, [estimate])

  useEffect(() => {
    if (listingsIds && listingsIds?.length && !isHomePage) {
      fetchOnce('estimateListings', listingsIds, () => {
        dispatch(getListingsByIds({ listingsIds }))
      })
    }
  }, [JSON.stringify(listingsIds)])

  useEffect(() => {
    if (transactionsIds && transactionsIds?.length > 0 && !isHomePage) {
      fetchOnce('buildingTransactions', transactionsIds, () => {
        dispatch(getTransactionsByIds({ transactionsIds }))
      })
    }
  }, [JSON.stringify(transactionsIds)])

  useEffect(() => {
    if (faosIds && faosIds?.length > 0 && !isHomePage) {
      fetchOnce('buildingFaos', faosIds, () => {
        dispatch(getFaosByIds({ faosIds }))
      })
    }
  }, [JSON.stringify(faosIds)])

  const heatConsumptionFilters = {
    geoCenter: estimate?.geo_center,
    popety_classification: query.energyPopetyClassification,
    radius: query.radius,
    city_name: query.city_history,
    district_name: query.district_history,
  }

  useEffect(() => {
    if (!estimate.geo_center && !isHomePage) return

    fetchOnce('heatConsumption', heatConsumptionFilters, () =>
      dispatch(getHeatConsumptionAround(heatConsumptionFilters) as any),
    )
  }, [heatConsumptionFilters])

  useEffect(() => {
    if (!landId || isHomePage) return

    // SUMMARY
    fetchOnce('getEstimateLandSummary', { lands: estimate?.lands }, () => {
      dispatch(getEstimateLandSummary({ lands: estimate?.lands }))
    })

    // TRANSACTIONS
    // dispatch(getLandTransactions({ landId }))

    // FAO
    // dispatch(getLandFaos({ landId }))

    // LISTING
    fetchOnce('countLandListingsRent', { landId, dealType: 'rent' }, () => {
      dispatch(countLandListings({ landId, dealType: 'rent' }))
    })
    fetchOnce('countLandListingsBuy', { landId, dealType: 'buy' }, () => {
      dispatch(countLandListings({ landId, dealType: 'buy' }))
    })

    // LIVABILITY
    fetchOnce('getPoisAround', landId, () => {
      dispatch(getPoisAround({ landId }))
    })
    fetchOnce('getPoiCategories', landId, () => {
      dispatch(getPoiCategories({ landId }))
    })

    dispatch(
      getIsochrone({
        polygons: true,
        lon: estimate?.geo_center?.lon,
        lat: estimate?.geo_center?.lat,
        type: 'walking',
      }),
    )

    dispatch(
      getWalkingIsochrone({
        polygons: true,
        lon: estimate?.geo_center?.lon,
        lat: estimate?.geo_center?.lat,
        type: 'walking',
      }),
    )
    dispatch(
      getBikingIsochrone({
        polygons: true,
        lon: estimate?.geo_center?.lon,
        lat: estimate?.geo_center?.lat,
        type: 'cycling',
      }),
    )
    dispatch(
      getDrivingIsochrone({
        polygons: true,
        lon: estimate?.geo_center?.lon,
        lat: estimate?.geo_center?.lat,
        type: 'driving',
      }),
    )
  }, [landId, estimate])

  useEffect(() => {
    if (!lands || lands.length === 0 || isHomePage) return

    let buildings: any[] = []

    lands.forEach((land: any) => {
      const building = land.buildings || []

      buildings = [...buildings, ...building]
    })

    fetchOnce('environment_data', [lands], () => {
      // BUILDINGS
      dispatch(getBuildings({ landId, buildings: lands[0]?.buildings }))
      dispatch(getEnvironmentData({ geoPolygon: lands[0]?.geo_polygon }))
    })
  }, [lands])

  // HISTORY AROUND
  const date = query.month
    ? getDate(subMonths(new Date(), +query.month))
    : undefined

  useEffect(() => {
    if (estimate.id) {
      const areaComparables = undefined
      const priceComparables = undefined
      const roomsComparables = query.roomsNb
        ? `${Number(query.roomsNb) - 1},${Number(query.roomsNb) + 1}`
        : undefined
      const propertyCategoryComparables =
        query.propertyCategory || 'Apartment,House'

      const obj = {
        priceComparables,
        areaComparables,
        roomsComparables,
        propertyCategoryComparables,
        propertyCategoryMarket: propertyCategoryComparables,
        roomsMarket: roomsComparables,
        areaMarket: areaComparables,
        priceMarket: priceComparables,
        city_comparables: estimate.city_name,
        monthComparables: 24,
        monthMarket: 24,
      }

      updateQuery({ ...obj })
    }
  }, [estimate.id])

  const propertyCategory =
    estimateEdited?.type || query.propertyType || defaultType

  const dateComparables = query.monthComparables
    ? getDate(subMonths(new Date(), +query.monthComparables))
    : undefined

  const dateMarket = query.monthMarket
    ? getDate(subMonths(new Date(), +query.monthMarket))
    : undefined

  const cityComparables =
    [query.city_comparables || estimate.city_name].filter(Boolean) || null
  const zoneComparables = [query.district_comparables].filter(Boolean) || null

  const priceRange: any = fromFilter(query.priceComparables, true)

  const comparablesFilters = useMemo(
    () => ({
      page: 0,
      size: 1000,
      date: dateComparables
        ? { min: dateComparables, max: undefined }
        : undefined,
      dealType: ['purchase'],
      city: cityComparables,
      zone: zoneComparables,
      price: {
        min: priceRange?.min ? priceRange.min : 1,
        max: priceRange?.max || 100000000,
      },
      rooms: fromFilter(query.roomsComparables, true),
      area: fromFilter(query.areaComparables, true),
      propertyCategory: query?.propertyCategoryComparables
        ? fromFilter(query.propertyCategoryComparables)
        : ['Apartment', 'House'],
    }),
    [
      query.priceComparables,
      query.roomsComparables,
      query.areaComparables,
      query.propertyCategoryComparables,
      dateComparables,
      cityComparables,
      zoneComparables,
    ],
  )

  const historyFilters = {
    // landId,
    page: 0,
    size: 1000,
    radius,
    date: date ? { min: date, max: undefined } : undefined,
    center,
    polygon,
    classification: fromFilter(query.classification),
    dealType: fromFilter(query.dealType),
    reason: fromFilter(query.reason),
    ownershipType: fromFilter(query.ownershipType),
    area: fromFilter(query.area, true),
    price: fromFilter(query.price, true),
    rooms: fromFilter(query.rooms, true),
    histogram: true,
    city,
    zone,
    propertyCategory: propertyCategory ? [propertyCategory] : undefined,
  }

  const cityMarket =
    [query.city_market || estimate.city_name].filter(Boolean) || null

  const districtMarket = [query.district_market].filter(Boolean) || null

  const marketFilters = useMemo(
    () => ({
      page: 0,
      size: 1000,
      center,
      radius,
      date: dateMarket ? { min: dateMarket, max: undefined } : undefined,
      dealType: ['purchase'],
      city: cityMarket,
      zone: districtMarket,
      price: fromFilter(query.priceMarket, true),
      rooms: fromFilter(query.roomsMarket, true),
      area: fromFilter(query.areaMarket, true),
      propertyCategory: query.propertyCategoryMarket
        ? fromFilter(query.propertyCategoryMarket)
        : ['Apartment', 'House'],
    }),
    [
      query.priceMarket,
      query.roomsMarket,
      query.areaMarket,
      query.propertyCategoryMarket,
      cityMarket,
      districtMarket,
      dateMarket,
    ],
  )

  const transactionFilters = {
    page: 0,
    size: 1000,
    center,
    radius,
    date,
    ownershipType: fromFilter(query.ownershipType),
    reason: ['Achat'],
    city,
    zone,
    price: fromFilter(query.price, true),
  }

  useEffect(() => {
    if (estimateId) {
      updateQuery({ ownershipType: isHouse ? 'B-F' : 'PPE' })
    }
  }, [isHouse])

  const transactionDate = {
    min: date,
  }

  const faoDate = {
    min: date,
  }

  useEffect(() => {
    if (!queryEstimateId || isHomePage) return

    const obj = {
      estimateId: queryEstimateId,
      geoCenter: query.geoCenter,
    }

    dispatch(getEstimateSummary(obj) as any)
  }, [queryEstimateId])

  const isFaoTab = query?.tab === 'fao'
  const isListingsTab = query?.tab === 'listing'
  const isTransactionsTab = query?.tab === 'transaction'

  useEffect(() => {
    if (!estimate.id || isHomePage || isListingsTab || isFaoTab) return

    fetchOnce('land_histories_around_transaction', [transactionFilters], () => {
      // TRANSACTION
      dispatch(
        getTransactionsAround({
          ...transactionFilters,
          date: transactionDate,
        }),
      )
      dispatch(
        getTransactionAggs({ ...transactionFilters, date: transactionDate }),
      )
    })
  }, [estimate.id, transactionFilters])

  useEffect(() => {
    if (!estimate.id || isHomePage || isTransactionsTab || isFaoTab) return

    fetchOnce('land_histories_around_listing', [comparablesFilters], () => {
      // LISTING
      dispatch(
        getListingAround({
          ...comparablesFilters,
        }),
      )
    })
  }, [estimate.id, comparablesFilters])

  useEffect(() => {
    if (!estimate.id || isHomePage || isTransactionsTab || isListingsTab) return

    fetchOnce('land_histories_around_market', [marketFilters], () => {
      // MARKET
      dispatch(getEstimateListingAggs({ ...marketFilters }))
      dispatch(getEstimateListingBuyInsights({ ...marketFilters }))
      dispatch(getListingsAggsPriceAnnoncements({ ...marketFilters }))
    })
  }, [estimate.id, marketFilters])

  useEffect(() => {
    if (!estimate.id || isHomePage || isTransactionsTab || isListingsTab) return

    fetchOnce('land_histories_around', [historyFilters], () => {
      // FAO
      dispatch(getFaosAround({ ...historyFilters, date: faoDate }))
      dispatch(getFaoAggs({ ...historyFilters, date: faoDate }))
    })
  }, [estimate.id, historyFilters])

  useEffect(() => {
    if (!estimate.id || isHomePage) return

    fetchOnce('sunlight', [estimate.id], () => {
      dispatch(getMonthlyExposureAverage(estimate?.id))
      dispatch(getBuildingPotentialSolar(estimate?.id))
    })
  }, [estimate.id, isHomePage])
}

export default useGetEstimateData
