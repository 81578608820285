export const CECBLimitValue = {
  A: {
    min: 0,
    max: 50,
  },
  B: {
    min: 51,
    max: 90,
  },
  C: {
    min: 91,
    max: 150,
  },
  D: {
    min: 151,
    max: 230,
  },
  E: {
    min: 231,
    max: 330,
  },
  F: {
    min: 331,
    max: 450,
  },
  G: {
    min: 451,
    max: undefined,
  },
  'N/A': {
    min: undefined,
    max: undefined,
  },
}
