import { fromFilter, getDate } from '@popety_io/popety-io-lib'
import subMonths from 'date-fns/subMonths'

/**
 * Get fao filters from url query
 * @param {Object} query url query param
 */
const getFaoFilters = (
  query: Record<string, any>,
  geoCenter: { lat: number; lon: number },
  defaultDate: Date,
) => {
  const date = query.month
    ? getDate(subMonths(new Date(), +query.month))
    : defaultDate

  const city = fromFilter(query.city_history)
  const zone = fromFilter(query.district_history)

  let radius = null

  if (!city && !zone) {
    radius = +query.radius || 500
  }

  return {
    center: [geoCenter?.lon, geoCenter?.lat],
    radius,
    date: date ? { min: date, max: undefined } : undefined,
    classification: fromFilter(query.classification),
    city,
    zone,
  }
}

export { getFaoFilters }
