import { formatPrice } from '@popety_io/popety-io-lib'

import { CustomTooltipPriceRoot } from './ListingAnalyticCard.style'

const CustomTooltipPrice = ({ active, payload, label }: any) => {
  if (!active || !payload?.length) return null

  return (
    <CustomTooltipPriceRoot>
      <div>{label}</div>
      <div className="CustomTooltipPriceRoot">
        {formatPrice(Math.round(payload[1].value))}
      </div>
      <div style={{ fontSize: '0.6rem' }}>
        {formatPrice(Math.round(payload[0].value[0]))} -{' '}
        {formatPrice(Math.round(payload[0].value[1]))}
      </div>
    </CustomTooltipPriceRoot>
  )
}

export default CustomTooltipPrice
