/* eslint-disable max-lines */
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Map,
  useRouter,
  useMediaQuery,
  useAsync,
  useI18n,
} from '@popety_io/popety-io-lib'

import mapboxgl from 'mapbox-gl'
import listingHistoryService from '../../../../ListingHistory/redux/listingHistoryService'
import {
  getListingsTiles,
  removeListingTiles,
} from '../../../../LandDetails/redux/listing/listing.layers'
import { estimateSelector } from '../../../redux'
import EstimateListingTiles from '../../../../../components/EstimateListingTiles'
import { getListingFiltersComparables } from '../../../../LandDetails/Listing/ListingAround/utils'

const fillStyle = 'layer_fill_listing'

const EstimateListing = ({ map, loaded }: { map: Map; loaded?: boolean }) => {
  const { dispatch, data } = useAsync<any[]>()
  const { t } = useI18n()
  const router = useRouter()
  const estimate: any = useSelector(estimateSelector)
  const geoCenter = estimate?.geo_center

  const [feature, setFeature] = useState<any>()

  const [popup, setPopup] = useState<mapboxgl.Popup>()
  const touchScreen = useMediaQuery('(hover: none)')

  const isListingTab = router.query.tab === 'listing'

  const filters = getListingFiltersComparables(router.query, geoCenter)

  const query = filters && encodeURIComponent(JSON.stringify(filters))

  const { listingCenter, title } = router.query

  const handleClick = (e: any) => {
    // Use the first found feature.

    dispatch(() =>
      listingHistoryService.getListingsTileDetails(
        e.features.map(
          (f: { properties: { _key: string } }) => f.properties._key,
        ),
        query,
      ),
    )
  }

  const handleMouseMove = (e: any) => {
    map.getCanvas().style.cursor = 'pointer'
    if (popup && (!listingCenter || !title)) {
      const coordinates = e.features[0].geometry.coordinates.slice()
      const str =
        e.features[0].properties._count > 1
          ? `${t('listing.listing')}s`
          : t('listing.listing')
      const description = `${e.features[0].properties._count} ${str}`

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(map)
    }
  }

  const handleMouseLeave = () => {
    map.getCanvas().style.cursor = ''

    if (popup && (!listingCenter || !title)) {
      popup.remove()
    }
  }

  const addTransactionTiles = () => {
    try {
      getListingsTiles({
        map,
        query,
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    setPopup(
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    )
  }, [])

  useEffect(() => {
    if (router?.query?.tab !== 'listing' && map && loaded) {
      removeListingTiles({ map })
    }
  }, [router?.query?.tab])

  useEffect(() => {
    if (touchScreen) setPopup(undefined)
  }, [touchScreen])

  useEffect(() => {
    if (!map || !loaded || router?.query?.tab !== 'listing') return

    addTransactionTiles()

    map.on('style.load', addTransactionTiles)
    map.on('click', fillStyle, handleClick)
    map.on('mousemove', fillStyle, handleMouseMove)
    map.on('mouseleave', fillStyle, handleMouseLeave)

    return () => {
      map.off('style.load', addTransactionTiles)
      map.off('click', fillStyle, handleClick)
      map.off('mousemove', fillStyle, handleMouseMove)
      map.off('mouseleave', fillStyle, handleMouseLeave)
    }
  }, [isListingTab, loaded, query, listingCenter, title])

  useEffect(() => {
    if (data?.length) {
      setFeature(data[0])
    }
  }, [data])

  const handleClose = () => {
    setFeature(null)
  }

  useEffect(() => {
    if (map && loaded && listingCenter && popup && title) {
      const [lng, lat] = listingCenter.split(',')

      map.flyTo({ center: [lng, lat], zoom: 17 })
      popup.setLngLat([lng, lat]).setHTML(title).addTo(map)
    } else if (map && loaded && !listingCenter && popup && !title) {
      popup.remove()
    }
  }, [listingCenter, map, loaded, title, popup])

  if (!isListingTab || !map || !loaded || !feature) return null

  return (
    <EstimateListingTiles
      data={data}
      feature={feature}
      handleClose={handleClose}
    />
  )
}

export default EstimateListing
