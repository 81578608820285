/* eslint-disable no-nested-ternary */
import React, { ReactNode, useEffect, useRef } from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { BoxProps } from '@mui/material/Box'
import SwipeableViews from 'react-swipeable-views'
import { useRouter, Button, ProgressIndicator } from '@popety_io/popety-io-lib'

// import { Button } from '../../atoms/Button'
// import { ProgressIndicator } from '../ProgressIndicator'
// import { useRouter } from '../../hooks'
import { HistoryMapTileModalRoot } from './TilesMaps.style'

export type EstimateTransactionMapTileModalProps = BoxProps & {
  data: any[]
  Details: any
  loading?: boolean
  autoSwipe?: boolean
  nextText?: ReactNode
  previousText?: ReactNode
  notFound?: ReactNode
  openText?: ReactNode
  index: number
  handleChangeIndex: (newIndex: number) => void
  handlePrevious: () => void
  handleNext: () => void
}

/**
 * History map modal
 */
const EstimateTransactionMapTileModal = ({
  data = [],
  Details,
  loading,
  padding,
  autoSwipe = true,
  nextText = 'Next',
  previousText = 'Previous',
  notFound = 'No data',
  index,
  handleChangeIndex,
  handlePrevious,
  handleNext,
}: EstimateTransactionMapTileModalProps) => {
  const swiperRef = useRef<any>()
  const hasData = data?.length > 0
  const { updateQuery } = useRouter()

  const swiperAction = (actions: any) => {
    swiperRef.current = actions
  }

  useEffect(() => {
    setTimeout(() => swiperRef.current?.updateHeight(), 250)
  }, [data])

  const currentData = data?.[index]

  useEffect(() => {
    updateQuery({ id: currentData?.id, index: String(index || '0') })
  }, [index])

  return (
    <HistoryMapTileModalRoot minHeight={data?.length ? undefined : '200px'}>
      {loading && <ProgressIndicator circular />}
      {!data?.length && !loading && <Typography p={2}>{notFound}</Typography>}

      {data?.length > 1 && (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            flexGrow: 1,
            alignItems: 'flex-end',
            mt: '-10px',
            padding: 0,
            background: 'white',
          }}
        >
          <Button
            color="primary"
            onClick={handlePrevious}
            disabled={index === 0}
            sx={{ ml: '-8px' }}
          >
            {previousText}
          </Button>

          <Button disabled>{`${index + 1} / ${data.length}`}</Button>

          <Button
            color="primary"
            onClick={handleNext}
            disabled={index === data.length - 1}
            sx={{ mr: '-8px' }}
          >
            {nextText}
          </Button>
        </Stack>
      )}

      {hasData && (
        <SwipeableViews
          index={index}
          animateHeight
          onChangeIndex={handleChangeIndex}
          action={swiperAction as any}
        >
          {data.map((history, i) => (
            <Details
              key={history.id || i}
              data={history}
              showMap={false}
              showDescription
              autoSwipe={autoSwipe}
              padding={padding}
              sx={{
                width: '100%',
                pointerEvpents: 'all',
                position: 'relative',
              }}
            />
          ))}
        </SwipeableViews>
      )}
    </HistoryMapTileModalRoot>
  )
}

export default EstimateTransactionMapTileModal
