import { css, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const RightContainer = styled(Box)`
  top: 0;
  bottom: 0;
  right: 0;
`

export const EstimateRoot = styled(Box)`
  height: 100%;
  border: 1px solid #ebebeb;
  @media (max-width: 991px) {
    margin-bottom: 40px;
  }
`

export const LeftContainer = styled(Box)`
  z-index: 2;
  padding: 0 20px;

  ${({ theme }) => css`
    ${theme.breakpoints.up('lg')} {
      height: calc(100vh - 170px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  `}
`
