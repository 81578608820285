/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useState, ReactNode } from 'react'
import { AccordionProps } from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Grid from '@mui/material/Grid'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Modal, SourceLink, useRouter } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import { LandUsePlansOpts } from '../../UnderExploited/LandUsePlan'
import { HistoryAccordionRoot } from './HistoryAccordion.style'

export type HistoryAccordionProps = Omit<AccordionProps, 'children'> & {
  col1?: { title: React.ReactNode; md?: number; [k: string]: any }
  col2?: { title: React.ReactNode; md?: number; [k: string]: any }
  headerComponent?: ReactNode
  link?: string
  children?: ReactNode
  lup?: LandUsePlansOpts
  listing?: any
}

const HistoryAccordion = (props: HistoryAccordionProps) => {
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const {
    col1,
    col2,
    children,
    link,
    headerComponent,
    lup,
    listing,
    ...other
  } = props

  const { location, query } = useRouter()

  const isEstimate =
    location?.pathname?.includes('estimate') && query?.tab === 'listing'

  return (
    <HistoryAccordionRoot
      {...other}
      disableGutters
      elevation={0}
      sx={{
        '& .MuiAccordionSummary-content': {
          maxWidth: isEstimate ? '100%' : 'calc(100% - 70px)',
          margin: isEstimate ? '0px' : '12px 0',
        },
        '& .MuiAccordionSummary-root': {
          padding: isEstimate ? '0px' : '0px 10px',
        },
      }}
    >
      <AccordionSummary
        expandIcon={!isEstimate ? <ExpandMoreIcon /> : null}
        onClick={() => {
          if (isEstimate) setShareModalOpen(true)
        }}
      >
        {headerComponent || (
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs={12} md={10}>
              {col1?.title}
            </Grid>
            <Grid item xs={12} md="auto">
              {col2?.title}
            </Grid>
          </Grid>
        )}
      </AccordionSummary>
      {!isEstimate ? (
        <Box
          bgcolor="#f9f9f9"
          sx={{
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            pt: link ? 4 : 2,
          }}
        >
          <AccordionDetails data-cy="land-detail-accordionDetails">
            {children}
            {link && <SourceLink href={link}>Source</SourceLink>}
          </AccordionDetails>
        </Box>
      ) : (
        <Modal
          open={shareModalOpen}
          onClose={() => {
            setShareModalOpen(false)
          }}
          disableGutters
          showCloseIcon={false}
          responsive="sm"
          loading={false}
          fullWidth
          sx={{
            '.PreviewHeader': { borderBottom: '1px solid gainsboro' },
            '.OpenDetailsButton': { display: 'none' },
          }}
          maxWidth="lg"
        >
          <Box
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            }}
          >
            <AccordionDetails
              data-cy="land-detail-accordionDetails"
              sx={{
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              {children}
            </AccordionDetails>
          </Box>
        </Modal>
      )}
    </HistoryAccordionRoot>
  )
}

export default HistoryAccordion
