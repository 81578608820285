import { createSelector } from '@reduxjs/toolkit'

import { getPriceData } from '../../../../utils/listingInsightHelper'

const estimateListingAggsSelector = (state: {
  estimateMarket: { aggregations: any }
}) => state.estimateMarket.aggregations

const listingAggsPurchasePerSquareMeterSelector = createSelector(
  estimateListingAggsSelector,
  (aggs) =>
    aggs?.price_over_time_purchase_per_square_meter?.price_over_time &&
    getPriceData(
      aggs?.price_over_time_purchase_per_square_meter?.price_over_time
        ?.buckets || [],
    ),
)

const listingAggsPurchaseSelector = createSelector(
  estimateListingAggsSelector,
  (aggs) =>
    aggs?.price_over_time_purchase?.price_over_time?.buckets &&
    getPriceData(aggs?.price_over_time_purchase?.price_over_time?.buckets),
)

const estimateLoadingAggsSelector = (state: {
  estimateMarket: { loadingAggs: boolean }
}) => state.estimateMarket.loadingAggs

const estimateLoadingInsightsSelector = (state: {
  estimateMarket: { loadInsights: boolean }
}) => state.estimateMarket.loadInsights

const buyAggsSelector = (state: any) => state.estimateMarket.buyData

const estimateBuyDataSelector = createSelector(
  buyAggsSelector,
  (aggs) =>
    aggs && getPriceData(aggs?.price_over_time?.price_over_time?.buckets || []),
)

const estimateBuyMaxDateSelector = createSelector(
  buyAggsSelector,
  (aggs) =>
    aggs?.price_over_time.price_over_time.buckets.slice(-1)[0]?.key_as_string,
)

const estimateBuyDataLoadingSelector = (state: any) =>
  state.estimateMarket.loadBuyData

const announcementDataSelector = (state: any) =>
  state.estimateMarket.announcementData

const loadAnnouncementsSelector = (state: any) =>
  state.estimateMarket.loadAnnouncements

const estimateAnnouncementDataSelectorSelector = createSelector(
  announcementDataSelector,
  (aggs) =>
    aggs &&
    getPriceData(aggs?.price_over_time_purchase?.price_range?.buckets || []),
)

export {
  estimateListingAggsSelector,
  estimateLoadingAggsSelector,
  listingAggsPurchasePerSquareMeterSelector,
  listingAggsPurchaseSelector,
  estimateBuyDataSelector,
  estimateLoadingInsightsSelector,
  estimateBuyMaxDateSelector,
  estimateAnnouncementDataSelectorSelector,
  announcementDataSelector,
  loadAnnouncementsSelector,
  estimateBuyDataLoadingSelector,
  buyAggsSelector,
}
