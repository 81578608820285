import TransactionIcon from '@mui/icons-material/CurrencyExchange'
import { useSelector } from 'react-redux'
import { landTransactionCountSelector } from '../redux'
import CountChip from '../../../components/Tag/CountChip'

interface IProps {
  show?: boolean
}
const TransactionCountChip = ({ show }: IProps) => {
  const count = useSelector(landTransactionCountSelector)

  return <CountChip count={count} icon={<TransactionIcon />} show={show} />
}

export default TransactionCountChip
