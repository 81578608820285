import { useEffect } from 'react'
import { useRouter, useI18n } from '@popety_io/popety-io-lib'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import {
  TagIcon,
  BuildingTagIcon,
  PreviewBuildingTagChips,
  PreviewLandChip,
} from '../../Tag'

import AnalysisAndFavorites from '../AnalysisAndFavorites'
import BuildingFavoriteIcon from '../../BuildingFavoriteIcon/BuildingFavoriteIcon'
import {
  buildingTagSelector,
  landTagSelector,
  setGrouping,
} from '../../../pages/DeveloperMap/redux'
import {
  PreviewHeaderRoot,
  LandSpan,
  Land,
  ContainerLand,
} from './PreviewHeader.style'

type PreviewHeaderProps = {
  feature: any
  onClose?: () => void
  building?: boolean
  grouping?: boolean
  onGrouping?: () => void
  isLand?: boolean
  open?: boolean
}

const PreviewHeader = ({
  feature = {},
  onClose,
  building = !!feature?.egid,
  grouping,
  onGrouping,
}: PreviewHeaderProps) => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const { updateQuery } = useRouter()

  const buildingId = feature?.id

  const landId = feature?.land_id || feature?.id

  const buildingTags = useSelector(buildingTagSelector)
  const landTags = useSelector(landTagSelector)

  const code = feature.egid || feature.code_number
  const codeNums = (Array.isArray(code) ? code : [code]).filter(Boolean)

  useEffect(() => {
    updateQuery({ selectedLand: codeNums[codeNums.length - 1] })
  }, [codeNums.length])

  const handleClick = () => {
    updateQuery({ geoCenter: undefined })
    onClose?.()
    dispatch(setGrouping(false))
  }

  return (
    <PreviewHeaderRoot className="PreviewHeader">
      <ContainerLand>
        <Box>
          <LandSpan className="land">
            <Land>
              {`${t(feature.egid ? 'common.egid' : 'common.land')} `}
              <span>{!grouping && codeNums.join(', ')}</span>
            </Land>

            {!building && (
              <AnalysisAndFavorites
                feature={feature}
                grouping={grouping}
                onGrouping={onGrouping}
              />
            )}
            {building && (
              <BuildingFavoriteIcon buildingId={feature?.id} opacity={1} />
            )}
          </LandSpan>
        </Box>
        {grouping && <span>{codeNums.join(', ')}</span>}

        {building ? (
          <>
            <PreviewBuildingTagChips buildingId={buildingId} />
            <BuildingTagIcon
              buildingIds={buildingId}
              display={!buildingTags?.length}
            />
          </>
        ) : (
          <>
            <PreviewLandChip landId={landId} />
            <TagIcon landIds={landId} display={!landTags?.length} />
          </>
        )}
      </ContainerLand>
      <Box>
        <IconButton
          aria-label="close"
          onClick={handleClick}
          sx={{ mt: -1, mr: -0.5 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </PreviewHeaderRoot>
  )
}

export default PreviewHeader
