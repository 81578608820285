/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import {
  SwipeableTabs,
  useI18n,
  useRouter,
  Driver,
  fetchOnce,
} from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { Badge } from '@mui/material'

import {
  landListingsSelector,
  listingsAroundCountSelector,
  rentListingsAroundSelector,
  purchaseListingsAroundSelector,
  landListingsLoadingSelector,
} from '../redux'
import HistoryAroundFilters from '../components/HistoryAroundFilters'
import { userSelector } from '../../Login'
import { updateTutorials } from '../../Account'
import ListingList from './ListingList'
import ListingStats from './ListingStats'
import getDriverInstructions from './driverInstructions'

interface IProps {
  show?: boolean
}

const Listing = ({ show = true }: IProps) => {
  const { query, updateQuery } = useRouter()
  const { dealType } = query
  const dispatch = useDispatch<any>()
  const { t } = useI18n()
  const [opened, setOpened] = useState<string>()
  const [displayDriver, setDisplayDriver] = useState(false)

  const listings = useSelector(landListingsSelector)
  const rentListingsAround = useSelector(rentListingsAroundSelector)
  const purchaseListingsAround = useSelector(purchaseListingsAroundSelector)
  const listingsAroundCount = useSelector(listingsAroundCountSelector)
  const user = useSelector(userSelector)

  const loading = useSelector(landListingsLoadingSelector)

  const tabs = ['sale', 'rentalAd', 'stats'].map((value) => ({
    label: t(`land.history.tab.${value}`),
    key: value,
  }))

  const tabsListings = [
    {
      label: t('land.listing.title'),
      count: listings?.length,
    },
    { label: t('land.listing.listingAroundFound'), count: listingsAroundCount },
  ]
    .slice(show ? 0 : 1)
    .map((value) => ({
      label: (
        <Badge
          showZero
          badgeContent={value.count}
          color="primary"
          sx={{
            '& .MuiBadge-badge': { top: -2 },
            paddingTop: 1,
          }}
        >
          {value.label}
        </Badge>
      ),
      key: value.label,
    }))

  const handleChange = (uid: string) => {
    setOpened(uid)
    updateQuery({
      listingOpened: uid,
    })
  }

  useEffect(() => {
    if (listings?.length) {
      setOpened(`listing${listings?.[0]?.ad_id}`)
    }
  }, [listings])

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedListingsDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedListingsDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  return (
    <Box
      px={1}
      sx={{
        mb: 4,
      }}
    >
      <SwipeableTabs
        tabs={tabsListings}
        value={listings?.length && listingsAroundCount && show ? 0 : 1}
      >
        <Box>
          <ListingList
            listings={listings}
            opened={opened}
            onChange={handleChange as any}
          />
        </Box>
        <Box>
          <Box
            pt={2}
            zIndex={10}
            bgcolor="background.paper"
            sx={{
              position: {
                lg: 'sticky',
              },
              top: {
                lg: 0,
              },
            }}
          >
            <HistoryAroundFilters />
          </Box>
          {loading ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {Array.from({ length: 10 }).map((_, i) => (
                <Box
                  key={i}
                  sx={{
                    width: '100%',
                    height: '55px',
                    mt: 1.5,
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{
                      fontSize: '1rem',
                      borderRadius: 2,
                      height: '55px',
                      width: '100%',
                    }}
                  />
                </Box>
              ))}
            </Box>
          ) : (
            <SwipeableTabs
              tabs={tabs}
              tabKey="fao-around"
              label={t('common.new')}
              fixedTabs
              value={dealType === 'rent' ? 1 : 0}
            >
              <ListingList
                listings={purchaseListingsAround}
                opened={opened}
                onChange={handleChange as any}
              />
              <ListingList
                listings={rentListingsAround}
                opened={opened}
                onChange={handleChange as any}
              />
              <ListingStats />
            </SwipeableTabs>
          )}
        </Box>
      </SwipeableTabs>
      {!loading && (
        <Driver
          instructions={getDriverInstructions(t)}
          display={displayDriver}
        />
      )}
    </Box>
  )
}

export default Listing
