import * as turf from '@turf/turf'

const getPlotRadius = (
  geoCenter: { lon: number; lat: number },
  radius = 500,
) => {
  if (!geoCenter?.lon && !geoCenter?.lat) return []

  const numberRadius = Number(radius) / 1000
  const center = [Number(geoCenter?.lon), Number(geoCenter?.lat)]

  const options = { steps: 64, units: 'kilometers', properties: {} }
  const circle = turf.circle(center, numberRadius, options as any)

  return [
    {
      id: 'radius',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [circle] },
      layers: [
        {
          id: 'radiusLayer',
          type: 'line',
          paint: {
            'line-color': '#00ade6',
            'line-width': 3,
            'line-dasharray': [3, 1],
          },
        },
      ],
    },
  ]
}

const getPlotLocation = (geoPolygon: any) =>
  [
    geoPolygon?.type && {
      id: 'locationPolygon',
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [{ type: 'feature', properties: {}, geometry: geoPolygon }],
      },
      layers: [
        {
          id: 'locationPolygonLayer',
          type: 'line',
          paint: {
            'line-color': '#00ade6',
            'line-width': 3,
            'line-dasharray': [3, 1],
          },
        },
      ],
    },
  ].filter(Boolean)

const getBbox = (data: any, tab: string, geoCenter?: number[]) => {
  if (tab === 'transaction' || tab === 'fao' || tab === 'listing') {
    const geometry = data?.[1]?.data?.features?.[0]?.geometry

    if (!geometry) return

    const scaledPoly = turf.transformScale(geometry, 1.5)

    const bbox = turf.bbox(scaledPoly)

    return bbox
  }

  const radius = tab === 'livability' ? (15000 + 0.4) / 1000 : 0.5

  const options = { steps: 64, units: 'kilometers', properties: {} }
  const circle = turf.circle(geoCenter as any, radius, options as any)

  if (!circle) return

  const bbox = turf.bbox(circle?.geometry)

  return bbox
}

export { getPlotRadius, getBbox, getPlotLocation }
