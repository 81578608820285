import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import summaryService from './summaryService'

export type SummaryState = {
  loading: boolean
  building: any
  cityGeoCenter: any
  cityGeojson: any
}

const initialState: SummaryState = {
  loading: false,
  building: {},
  cityGeoCenter: {},
  cityGeojson: {},
}

export const getBuildingSummary = createAsyncThunk(
  'buildingSummary/getBuildingSummary',
  summaryService.getBuildingSummary,
)

const buildingSummary = createSlice({
  name: 'buildingSummary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBuildingSummary.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getBuildingSummary.fulfilled, (state, action) => {
      const { data } = action.payload || {}

      state.building = data
      state.loading = false
    })
    builder.addCase(getBuildingSummary.rejected, (state) => {
      state.loading = false
    })
  },
})

export const buildingSummaryReducer = {
  buildingSummary: buildingSummary.reducer,
}
