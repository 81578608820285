import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const DivContainer = styled(Box)`
  h3 {
    margin: 0;
  }

  .oneValue {
    margin-bottom: -5px;
    font-size: 0.8rem;
    display: flex;
  }

  .oneFutureValue {
    color: #00ade6;
    margin-bottom: 4px;
    font-size: 0.8rem;
    display: flex;
  }
`

export const SectionTitle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: 500;
`

export const InfoValue = styled(Box)`
  display: flex;
  justify-content: space-between;
  line-height: 16px;

  > span:first-of-type {
    color: ${({ theme }) => theme.palette.text.primary};
    min-width: 150px;
  }

  > span:last-child {
    text-align: right;
  }

  &.blue {
    color: #00ade6;
  }
`

export const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const InfoBody = styled(Box)``

export const ContainerOneValue = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;

  span {
    font-weight: 500;
    font-size: 0.8rem;
  }

  i {
    font-size: 0.8rem;
    color: #8d9ba3;
    font-weight: 500;
  }
`
