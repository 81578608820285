import { ChangeEvent } from 'react'
import TablePagination from '@mui/material/TablePagination'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

import { useI18n, useMediaQuery, useRouter } from '@popety_io/popety-io-lib'
import FaoAccordion from './FaoAccordion'

export type FaoListProps = {
  faos: Record<string, any>[]
  opened?: string
  onChange: (id?: string | null) => void
  show?: boolean
}

const FaoList = ({ opened, onChange, faos, show }: FaoListProps) => {
  const { t } = useI18n()

  const faoList = [...faos]?.sort((a, b) =>
    a?.inquiry_start_date > b?.inquiry_start_date ? -1 : 1,
  )

  const { query, updateQuery } = useRouter()
  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { page = 0, size = 25 } = query

  const pagedResult = faoList?.slice(+page * +size, (+page + 1) * +size)
  const hasPage = pagedResult.length !== faoList?.length

  const handleChangePage = (e: any, newPage: number) => {
    updateQuery({ page: newPage })
  }

  const handleChangePageSize = (event: ChangeEvent<HTMLInputElement>) => {
    updateQuery({ size: +event.target.value, page: 0 })
  }

  const handleChange =
    (fao: Record<string, any>) => (_e: any, isExpanded?: boolean) => {
      const id = fao.id ? fao.id : fao.fao_id

      onChange(isExpanded ? `fao${id}` : null)

      if (isExpanded && fao?.location) {
        const faoCenter = fao?.location
          ?.substring(6)
          ?.replace(/^\((.+)\)$/, '$1')
          ?.replace(' ', ',')

        updateQuery({ faoCenter, title: fao.work_description })
      } else {
        updateQuery({ faoCenter: undefined, title: undefined })
      }
    }

  if (!pagedResult.length)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="calc(100vh - 257px)"
      >
        <Typography variant="h5">{t('land.fao.faoNoConsultation')}</Typography>
      </Box>
    )

  const mb = onDesktop ? 0 : 5

  return (
    <Box mt={2} mb={hasPage ? mb : 3}>
      {pagedResult?.map((fao) => (
        <Box sx={{ mb: 1.5, mx: 0.2 }} key={fao.id ? fao.id : fao.fao_id}>
          <FaoAccordion
            fao={fao}
            expanded={opened === `fao${fao.id ? fao.id : fao.fao_id}`}
            onChange={handleChange(fao)}
            show={show}
          />
        </Box>
      ))}

      {hasPage && (
        <TablePagination
          component="div"
          align="left"
          count={faoList?.length}
          page={+page}
          onPageChange={handleChangePage}
          rowsPerPage={+size}
          onRowsPerPageChange={handleChangePageSize}
          labelRowsPerPage={t('common.rowsPerPage')}
        />
      )}
    </Box>
  )
}

export default FaoList
