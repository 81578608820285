import { ChangeEvent } from 'react'
import TablePagination from '@mui/material/TablePagination'
import Box from '@mui/material/Box'
import { useI18n, useRouter } from '@popety_io/popety-io-lib'
import { Typography } from '@mui/material'

import TransactionAccordion from './TransactionAccordion'

export type TransactionListProps = {
  transactions: Record<string, any>[]
  opened?: string
  onChange: (id?: string | null) => void
}

const TransactionList = ({
  transactions,
  opened,
  onChange,
}: TransactionListProps) => {
  const { t } = useI18n()

  const transactionList = [...transactions].sort((a, b) =>
    a.date > b.date ? -1 : 1,
  )

  const { query, updateQuery } = useRouter()
  const { page = 0, size = 25 } = query

  const pagedResult = transactionList.slice(+page * +size, (+page + 1) * +size)
  const hasPage = pagedResult.length !== transactionList.length

  const handleChangePage = (e: any, newPage: number) => {
    updateQuery({ page: newPage })
  }

  const handleChangePageSize = (event: ChangeEvent<HTMLInputElement>) => {
    updateQuery({ size: +event.target.value, page: 0 })
  }

  const handleChange =
    (transaction: any) => (_e: any, isExpanded?: boolean) => {
      onChange(isExpanded ? `transaction${transaction.id}` : null)
      if (isExpanded && transaction?.location) {
        const transactionCenter = transaction?.location
          ?.substring(11)
          ?.replace(/^\((.+)\)$/, '$1')
          ?.replace(' ', ',')

        updateQuery({
          transactionCenter,
          title: transaction?.reason,
        })
      } else {
        updateQuery({ transactionCenter: null, title: null })
      }
    }

  if (!pagedResult.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="calc(100vh - 257px)"
      >
        <Typography variant="h5">{t('land.fao.noTransactions')}</Typography>
      </Box>
    )
  }

  return (
    <Box mt={2} mb={hasPage ? 0 : 3}>
      {pagedResult.map((transaction) => (
        <Box sx={{ mb: 1.5, mx: 0.2 }} key={transaction.id}>
          <TransactionAccordion
            transaction={transaction as any}
            expanded={opened === `transaction${transaction.id}`}
            onChange={handleChange(transaction)}
          />
        </Box>
      ))}

      {hasPage && (
        <TablePagination
          component="div"
          align="left"
          count={transactionList.length}
          page={+page}
          onPageChange={handleChangePage}
          rowsPerPage={+size}
          onRowsPerPageChange={handleChangePageSize}
          labelRowsPerPage={t('common.rowsPerPage')}
        />
      )}
    </Box>
  )
}

export default TransactionList
