import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useI18n, InfoBox } from '@popety_io/popety-io-lib'
import { landSelector } from '../redux'
import { userSelector } from '../../Login'
import { LandOwners } from './LandOwners'

const OwnersInfo = () => {
  const { t } = useI18n()
  const land: any = useSelector(landSelector)
  const user = useSelector(userSelector)
  const owners: any[] = land.owners || []

  const canAnalyse = useMemo(
    () =>
      user.can_exceed_max_analysis ||
      Number(user.analysis_counter) < Number(user?.analysis_nb) ||
      (user?.listsAnalysis &&
        user?.listsAnalysis?.includes(land?.land_id?.toString())),
    [user],
  )

  const uniqueOwners = Array.from(
    new Set(
      owners?.filter(
        (owner, index, array) =>
          array.findIndex((o) => o?.name === owner?.name) === index,
      ) || [],
    ),
  ).flat()

  const lastModified = Math.max(
    ...uniqueOwners.map((name) => name?.create_timestamp),
  )

  const data = [
    { label: t('land.zone.number'), value: land.owner_nb || 'N/A' },
    {
      label: t('common.search.details.lastModif'),
      value: Number.isNaN(lastModified)
        ? new Date(lastModified).toLocaleString('fr-FR')?.substring(0, 10)
        : 'N/A',
    },
  ]

  const data2 = [
    {
      label: t('land.owners.names'),
      value: <LandOwners data={uniqueOwners} modal={canAnalyse} canAnalyse />,
    },
  ]

  return (
    <InfoBox
      title={t('common.owners')}
      data={data}
      data2={data2}
      id="land-owners-info"
    />
  )
}

export default OwnersInfo
