/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Caroussel, formatCurrency, useI18n } from '@popety_io/popety-io-lib'
import Grid from '@mui/material/Grid'
import HistoryAccordion, {
  HistoryAccordionProps,
} from '../components/HistoryAccordion'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import HistoryListItem from '../components/HistoryListItem'
import ListingAccordionHeader from './ListingAccordionHeader'

export type FaoAccordionProps = HistoryAccordionProps & {
  listing: { [k: string]: any }
}

const ListingAccordion = ({ listing, ...other }: FaoAccordionProps) => {
  const { t } = useI18n()

  const items = [
    { title: t('common.location'), value: listing.address, sx: { pt: 2 } },
    {
      title: t('land.listing.vendor'),
      value: listing.vendor_name || listing.vendor,
      sx: { pt: 2 },
    },
    {
      title: 'Type',
      value: t(`land.fao.${listing.deal_type}`),
      sx: { pt: 2 },
    },
    {
      title: t('land.title'),
      value: listing.title,
      sx: { pt: 2, fontWeight: 500 },
    },
    {
      title: t('land.fao.description'),
      value: listing.description,
      sx: { pt: 2 },
    },
    {
      title: 'Type / Catégorie',
      value: `${t(`land.property_type.${listing.property_type}`)} / ${t(
        `land.property_type.${listing.property_category}`,
      )}`,
      sx: { pt: 2 },
    },
    {
      title:
        listing.deal_type === 'rent' ? t('land.rent') : t('land.fao.price'),
      value:
        listing.deal_type === 'rent'
          ? `${formatCurrency(
              listing?.price_information?.NetRent || 0,
              'CHF',
            )} ${t('land.fao.NetRent')} + ${formatCurrency(
              listing?.price_information?.SideCost || 0,
              'CHF',
            )} ${t('land.fao.SideCost')}`
          : formatCurrency(listing?.price ?? 0, 'CHF'),
      sx: { pt: 2 },
    },
  ].filter((item) => item?.value)

  return (
    <HistoryAccordion
      {...other}
      link={listing.original_url}
      headerComponent={<ListingAccordionHeader listing={listing} />}
    >
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          {items.map((item, i) => (
            <HistoryListItem
              key={i}
              {...item}
              isFontWeight={item.title === t('land.title')}
              md={3}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          {!!listing.urls_image?.filter((url: string) => url)?.length && (
            <Caroussel
              images={listing.urls_image?.filter((url: string) => url)}
              defaultImage="/assets/no-image.jpeg"
            />
          )}
        </Grid>
      </Grid>
    </HistoryAccordion>
  )
}

export default ListingAccordion
