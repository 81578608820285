import { useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const ComparablesDescription = ({ listing }: { listing: any }) => {
  const { t } = useI18n()

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: '1.1rem',
          fontWeight: 500,
          textTransform: 'uppercase',
          mb: 1,
        }}
      >
        {t('land.financialAnalysis.description')}
      </Typography>
      <Box
        sx={{
          fontSize: '1rem',
          color: 'rgb(141, 155, 163)',
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: listing.description }} />
      </Box>
    </Box>
  )
}

export default ComparablesDescription
