import Grid from '@mui/material/Grid'
import { formatCurrency, useI18n, format } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import HistoryAccordion, {
  HistoryAccordionProps,
} from '../components/HistoryAccordion'
import HistoryListItem from '../components/HistoryListItem'

export type TransactionAccordionProps = HistoryAccordionProps & {
  transaction: {
    sellers_information_json: any[]
    buyers_information_json: any[]
    [k: string]: any
  }
}

const TransactionAccordion = (props: TransactionAccordionProps) => {
  const { transaction, ...other } = props
  const { t } = useI18n()

  const title = (
    <Box
      sx={{
        display: 'flex',
        gap: 4,
        width: '100%',
      }}
    >
      <Box
        sx={{
          minWidth: '150px',
          maxWidth: '205px',
        }}
      >
        <Typography
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          fontWeight="500"
        >
          {`${t('land.fao.camacNo')} ${transaction?.official_id}`}
        </Typography>
      </Box>

      <Typography className="ellipsis">{transaction.reason}</Typography>
    </Box>
  )

  const date = (
    <Typography variant="body2" color="info.main">
      {format(transaction.date, 'P')}
    </Typography>
  )

  const items = [
    { title: t('land.fao.camacNo'), value: transaction.official_id },
    { title: t('land.fao.ownershipType'), value: transaction.ownership_type },
    { title: t('land.fao.reason'), value: transaction.reason },
    {
      title: t('land.fao.price'),
      value: transaction.price
        ? formatCurrency(transaction.price, 'CHF ')
        : 'NA',
    },
    { title: t('land.fao.details'), value: transaction.details },
  ]

  const items2 = [
    {
      title: t('land.fao.seller'),
      value: transaction.sellers,
    },
    {
      title: t('land.fao.previousTransactionDate'),
      value: transaction.previous_transaction_date,
    },
    {
      title: t('land.fao.buyer'),
      value: transaction.buyers,
    },
    {
      title: t('land.fao.address'),
      value: transaction.address,
    },
    {
      title: t('land.fao.lands'),
      value: transaction.land_information,
    },
  ]

  return (
    <HistoryAccordion
      {...other}
      col1={{ title, md: 10 }}
      col2={{ title: date, md: 2 }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {items?.map((item, i) => (
            <HistoryListItem key={i} {...item} md={3} />
          ))}
        </Grid>
        <Grid item xs={12}>
          {items2?.map((item, i) => (
            <HistoryListItem {...item} key={i} md={3} />
          ))}
        </Grid>
      </Grid>
    </HistoryAccordion>
  )
}

export default TransactionAccordion
