/* eslint-disable max-len */
/* eslint-disable max-lines */
import {
  parseGeoJSON,
  getColorNoDwellings,
  mapBy,
  // groupBy,
  // i18n,
} from '@popety_io/popety-io-lib'
import * as turf from '@turf/turf'

const getEnergyBuildingColor = ({
  energyType,
  solarAverageExposure,
  powerSourceWaterHeater,
  powerSourceHeater,
}: {
  energyType: string | undefined
  solarAverageExposure: number | undefined
  powerSourceWaterHeater?: string | undefined
  powerSourceHeater?: string | undefined
}) => {
  if (energyType === 'power_source_heater') {
    switch (powerSourceHeater) {
      case 'Mazout':
        return '#000000'
      case 'Gaz':
        return '#1f78b4'
      case 'Indéterminé':
        return '#ccc'
      case 'Bois (générique)':
        return '#b15928'
      case 'Aucune':
        return '#b2df8a'
      case 'Electricité':
        return '#a6cee3'
      case 'Chaleur produite à distance (générique)':
        return '#e31a1c'
      case 'Air':
        return '#fdbf6f'
      case 'Sonde géothermique':
        return '#ff7f00'
      case 'Soleil (thermique)':
        return '#33a02c'
      default:
        return '#fff'
    }
  }
  if (energyType === 'power_source_water_heater') {
    switch (powerSourceWaterHeater) {
      case 'Mazout':
        return '#000000'
      case 'Gaz':
        return '#1f78b4'
      case 'Indéterminé':
        return '#ccc'
      case 'Bois (générique)':
        return '#b15928'
      case 'Aucune':
        return '#b2df8a'
      case 'Electricité':
        return '#a6cee3'
      case 'Chaleur produite à distance (générique)':
        return '#e31a1c'
      case 'Air':
        return '#fdbf6f'
      case 'Sonde géothermique':
        return '#ff7f00'
      case 'Soleil (thermique)':
        return '#33a02c'
      default:
        return '#fff'
    }
  }

  if (energyType === 'solar_average_exposure' && solarAverageExposure) {
    switch (true) {
      case solarAverageExposure < 800:
        return '#06C5FF'
      case solarAverageExposure >= 800 && solarAverageExposure < 1000:
        return '#FDFB01'
      case solarAverageExposure >= 1000 && solarAverageExposure < 1200:
        return '#FFA901'
      case solarAverageExposure >= 1200 && solarAverageExposure < 1400:
        return '#FD5501'
      case solarAverageExposure >= 1400:
        return '#A90100'
      default:
        return '#CCCCCC'
    }
  }

  return '#CCCCCC'
}

// const compareArrays = (arr1: any, arr2: any, idKey: any) => {
//   if (!arr2) return []

//   const idSet = new Set(arr2?.map((item: any) => item[idKey]))
//   const result = []

//   if (!arr1) return []

//   for (const item of arr1) {
//     if (!idSet.has(item[idKey])) {
//       result.push(item)
//     }
//   }

//   return result
// }

/**
 * getBuildingLayer
 * @param building
 * @param energyLayer
 * @param tab
 * @param customBuildingHeight
 * @param egid
 * @param roofPolygons
 * @param configuration
 * @param dimension
 * @returns
 */
const getBuildingLayer = (
  building: any,
  energyLayer: string | undefined,
  tab: string | undefined,
  customBuildingHeight: string,
  egid: string,
  roofPolygons?: any | undefined,
  configuration?: any,
  dimension = '3D',
  subTab?: string,
) => {
  const layers: any[] = []
  const floorsLayers: any[] = []
  const is3D = dimension === '3D' && energyLayer !== 'solar_average_exposure'
  const regex = /Maison individuelle?/gi

  const isHouse =
    regex.test(building?.category || '') &&
    building?.popety_classification === 'Residential'

  if (building?.geo_polygon) {
    const buildingHeight =
      parseInt(customBuildingHeight, 10) ||
      building?.height ||
      3 * (building?.floor_nb || 0)

    const floorHeight = building.floor_nb
      ? buildingHeight / building.floor_nb
      : 3

    let buildingColor = building?.popety_classification
      ? getColorNoDwellings(building?.popety_classification)
      : '#000000'

    if (energyLayer && tab === 'energy') {
      buildingColor = getEnergyBuildingColor({
        energyType: energyLayer,
        solarAverageExposure: building?.solar_average_exposure,
        powerSourceWaterHeater: building?.power_source_water_heater,
        powerSourceHeater: building?.power_source_heater,
      })
    }

    if (!is3D) {
      layers.push({
        id: `floor-${Math.random() * 1000}`,
        type: 'fill',
        paint: {
          'fill-color': buildingColor,
        },
      })
    } else if (is3D && tab === 'configuration') {
      const isMixed =
        (building?.popety_classification === 'Mixed_1' ||
          building?.popety_classification === 'Mixed_2') &&
        !energyLayer &&
        tab === 'configuration'

      // const dwellingsByfloor: any[] = []
      // const dwellingsByEdid: any[] = []

      const buildingByEgid = mapBy('egid', building?.buildings_administrative)

      const selectedBuilding = buildingByEgid[egid]
      const officialDwellings = selectedBuilding?.addresses
        ?.flatMap((address: any) =>
          address?.dwellings?.flatMap((dwelling: any) => dwelling),
        )
        ?.filter(Boolean)

      const dwellingsIds = officialDwellings?.map((d: any) => d?.id)

      // building?.buildings_administrative?.[0]?.addresses?.forEach((ad: any) => {
      //   ad?.dwellings?.forEach((dwelling: any) => {
      //     dwellingsByEdid[ad.edid] = [
      //       ...(dwellingsByEdid[ad.edid] || []),
      //       dwelling,
      //     ].filter(Boolean)
      //   })
      // })

      // building?.buildings_administrative?.[0]?.addresses?.[0]?.dwellings?.forEach(
      //   (dwelling: { floor: number }) => {
      //     dwellingsByfloor[dwelling?.floor] = [
      //       ...(dwellingsByfloor[dwelling?.floor] || []),
      //       dwelling,
      //     ].filter(Boolean)
      //   },
      // )

      const editedDwellings = configuration
        ?.filter(
          (config: any) =>
            config?.buildingAdminId === selectedBuilding?.id &&
            !dwellingsIds.includes(config?.id),
        )
        ?.filter(Boolean)

      const dwellings = [officialDwellings, editedDwellings]
        .flat()
        .filter(Boolean)

      const buildingArea = building.gross_floor

      for (let i = 0; i < (building.floor_nb || 1); i += 1) {
        const dwellingsByFloor = !isHouse
          ? dwellings.filter((d: { floor: number }) => d?.floor === i)
          : dwellings
        let floorColor = buildingColor
        let unitType: string | undefined | null = ''
        let edited: any = null
        let isFloorEdit = false

        let dwellingsArea = 0

        edited = configuration?.find(
          (config: any) =>
            (isHouse || config?.floor === i) &&
            config?.unitType &&
            selectedBuilding.id === config.buildingAdminId,
        )

        unitType = edited?.unitType

        const isResidential = dwellingsByFloor?.every((d) => {
          return (
            Number.isInteger(Number(d?.type?.edited)) ||
            Number.isInteger(Number(d?.type?.value)) ||
            Number.isInteger(Number(d?.room_nb))
          )
        })

        const isNotMixed = dwellingsByFloor?.every((d) => {
          return (
            (dwellingsByFloor?.[0]?.type?.edited ||
              dwellingsByFloor?.[0]?.type?.value) ===
            (d?.type?.edited || d?.type?.value)
          )
        })

        dwellingsByFloor?.forEach((d: any) => {
          const edit = configuration?.find(
            (config: any) => config?.id === d?.id,
          )
          const type = edit?.type?.edited || edit?.type?.value

          isFloorEdit = !!edit?.area?.value || !!edit?.area?.edited

          dwellingsArea +=
            Number(edit?.area?.edited) ||
            Number(edit?.area?.value) ||
            d?.area ||
            0

          if (isNotMixed && !isHouse && typeof type === 'string') {
            unitType = type
          }
          if (isResidential && !isHouse) {
            // unitType = ''
            isFloorEdit = true
            floorColor = 'rgb(249, 213, 24)'
          }
          if (!isNotMixed && !isResidential && !isHouse) {
            // unitType = ''
            floorColor = 'rgb(243, 168, 60)'
          }
        })

        // dwellingsByfloor[i]?.forEach((dwelling: any) => {
        //   edited = configuration?.find(
        //     (config: any) => config?.id === dwelling?.id,
        //   )
        // })

        // editedDwellings?.forEach((dwelling: any) => {
        //   if (dwelling?.floor === i) {
        //     isFloorEdit = !!dwelling?.area?.edited
        //     dwellingsArea += Number(dwelling?.area?.edited) || 0
        //   }
        // })

        const dwellingsAreaPercentage = (dwellingsArea / buildingArea) * 100

        if ((isMixed || (dwellingsArea && isFloorEdit)) && !isHouse) {
          unitType = undefined

          if (dwellingsAreaPercentage === 0) {
            floorColor = 'rgb(243, 115, 60)'
          } else if (dwellingsAreaPercentage < 50) {
            floorColor = 'rgb(243, 168, 60)'
          } else if (dwellingsAreaPercentage > 50) {
            floorColor = 'rgb(249, 213, 24)'
          }
        }

        if (isHouse) {
          unitType = edited?.unitType
        }

        floorColor = unitType ? getColorNoDwellings(unitType) : floorColor

        floorsLayers.push({
          id: `floor-${i}-${Math.random() * 1000}`,
          type: 'fill-extrusion',
          paint: {
            'fill-extrusion-color': floorColor,
            'fill-extrusion-height': floorHeight * (i + 1) - 0.2,
            'fill-extrusion-base': floorHeight * i,
            'fill-extrusion-vertical-gradient': false,
            'fill-extrusion-opacity': 0.8,
          },
        })
      }
    } else {
      layers.push({
        id: `building-${Math.random() * 1000}`,
        type: 'fill-extrusion',
        paint: {
          'fill-extrusion-color': buildingColor,
          'fill-extrusion-height': buildingHeight,
        },
      })
    }
    let roofLayer: any = {}

    if (energyLayer === 'solar_average_exposure' && roofPolygons?.length) {
      roofLayer = roofPolygons?.map((roof: any) => {
        return {
          id: `roof-${Math.random() * 1000}`,
          type: 'geojson',
          data: {
            geometry: parseGeoJSON(roof?.st_asgeojson),
            type: 'Feature',
            properties: roof?.average_exposure,
          },
          layers: [
            is3D
              ? {
                  id: `building-${Math.random() * 1000}`,
                  type: 'fill-extrusion',
                  paint: {
                    'fill-extrusion-color': getEnergyBuildingColor({
                      energyType: 'solar_average_exposure',
                      solarAverageExposure: roof?.average_exposure,
                    }),
                    'fill-extrusion-height': buildingHeight - 0.2,
                    'fill-extrusion-base': buildingHeight + 0.5,
                    // 'fill-extrusion-opacity': 1,
                  },
                }
              : {
                  id: `building-${Math.random() * 1000}`,
                  type: 'fill',
                  paint: {
                    'fill-color': getEnergyBuildingColor({
                      energyType: 'solar_average_exposure',
                      solarAverageExposure: roof?.average_exposure,
                    }),
                    // 'fill-outline-color': '#fffff',
                  },
                },
            {
              id: `outline-${Math.random() * 1000}`,
              type: 'line',
              paint: {
                'line-color': '#696969',
                'line-width': 3,
              },
            },
          ],
        }
      })
    }

    // KEEP THIS UNTIL WE GET THE OFFCIAL GEO_CENTER

    const entranceList = building?.buildings_administrative?.flatMap(
      (ba: any) => ba?.addresses?.map((b: any) => b),
    )

    const buildingsCenters = entranceList
      ?.filter((entrance: any) => entrance.geoCenter && tab === 'configuration')
      ?.map((entrance: any) => {
        return {
          geometry: entrance.geoCenter,
          type: 'Feature',
          properties: { ...entrance },
        }
      })

    const entranceNamesGeoJSON = !!entranceList?.length && {
      type: 'FeatureCollection',
      features: buildingsCenters || [],
    }

    const entranceLayer = {
      id: 'building-names',
      type: 'geojson',
      data: entranceNamesGeoJSON,
      layers: [
        {
          id: 'building-names-layer',
          type: 'symbol',
          layout: {
            'text-field': ['get', 'street_nb'],
            'text-size': 12,
          },
          paint: {
            'text-color': '#ffffff',
          },
        },
      ],
    }

    const entranceCircleLayer = {
      id: 'building-circle',
      type: 'geojson',
      data: entranceNamesGeoJSON,
      layers: [
        {
          id: 'building-circle-layer',
          type: 'circle',
          paint: {
            'circle-color': '#00ade6',
            'circle-radius': 12,
            'circle-stroke-color': '#ffffff',
          },
        },
      ],
    }

    let y = 1

    const newLayers =
      is3D && tab === 'configuration'
        ? floorsLayers?.map((floorLayer: any, i: number) => {
            y -= 0.00999999

            return {
              id: `floorLayer-${i}-${Math.random() * 1000}`,
              type: 'geojson',
              data: {
                geometry: turf.transformScale(
                  parseGeoJSON(building.geo_polygon),
                  y,
                ),
                type: 'Feature',
                properties: {},
              },
              layers: [floorLayer],
            }
          })
        : []

    const buildingLayer = {
      id: `buildings-${Math.random() * 1000}`,
      type: 'geojson',
      data: {
        geometry: parseGeoJSON(building.geo_polygon),
        type: 'Feature',
        properties: {},
      },
      layers: [...layers],
    }

    if (tab === 'sunlight' || subTab === 'sunlight') {
      buildingLayer.data.properties = {
        height: buildingHeight,
      }
    }

    let buildingL = [buildingLayer]

    if (
      energyLayer === 'solar_average_exposure' &&
      roofPolygons?.length &&
      roofPolygons[0]?.st_asgeojson
    ) {
      buildingL = [...roofLayer]
    }

    return [
      ...buildingL,
      ...newLayers,
      entranceCircleLayer,
      entranceLayer,
    ].filter(Boolean)
  }

  return []
}

export { getBuildingLayer, getEnergyBuildingColor }
