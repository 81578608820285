import { lazy, Suspense, useEffect, useMemo, useRef, useState } from 'react'
import {
  fetchOnce,
  fromFilter,
  OverlayLoader,
  useRouter,
  Driver,
  useI18n,
} from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'

import {
  getListings,
  listingLoadingSelector,
  getAggregationsListings,
  countListings,
  clearAnalyticData,
  getListingRentAndBuyInsights,
  getPropertyTypeThunk,
  propertyTypeInitStateSelector,
  getSelectedListings,
  getLocationsAggregations,
} from '../redux'
import { getListingFilters } from '../../../utils'
import { userSelector } from '../../Login'
import { updateTutorials } from '../../Account'
import getDriverInstructions from './driverInstructions'

const ListingsTable = lazy(() => import('../ListingTable'))
const ListingMap = lazy(() => import('../ListingMap'))
const ListingAnalytics = lazy(() => import('../ListingAnalytics'))

const content = {
  map: ListingMap,
  list: ListingsTable,
  analytic: ListingAnalytics,
} as Record<string, any>

const ListingHistory = () => {
  const { query, location } = useRouter()

  const [displayDriver, setDisplayDriver] = useState(false)

  const { t } = useI18n()

  const dispatch = useDispatch<any>()
  const loading = useSelector(listingLoadingSelector)
  const user = useSelector(userSelector)
  const prevOrderByRef: any = useRef(null)

  const filters = getListingFilters(query)

  const Content = useMemo(
    () => content[filters.view] || ListingsTable,
    [filters.view],
  )
  const propertyCategory: string[] = fromFilter(
    query.propertyCategory,
  ) as string[]

  const propertyTypeStates = useSelector(propertyTypeInitStateSelector)

  useEffect(() => {
    const {
      page: _,
      size: __,
      view: ___,
      orderBy: ____,
      ...countFilters
    } = filters

    fetchOnce('listing', filters, () => {
      clearAnalyticData()
      dispatch(getListings(filters))
      dispatch(getListingRentAndBuyInsights(filters))
    })
    fetchOnce('aggregationsLocationsListings', countFilters, () => {
      dispatch(getLocationsAggregations(filters))
    })

    fetchOnce('countListing', countFilters, () => {
      dispatch(countListings(filters))
    })
  }, [location.search])

  useEffect(() => {
    // Extract the value of "orderBy" from location.search
    const urlParams = new URLSearchParams(location.search)
    const currentOrderBy = urlParams.get('orderBy')

    // Compare with previous value
    if (currentOrderBy !== prevOrderByRef.current) {
      prevOrderByRef.current = currentOrderBy

      return // Do not execute the effect if "orderBy" has changed
    }

    prevOrderByRef.current = currentOrderBy

    const { page: _, size: __, view: ___, orderBy: ____ } = filters
    const aggregationsController = new AbortController()

    dispatch(
      getAggregationsListings({
        filters,
        signal: aggregationsController.signal,
      }),
    )

    return () => {
      aggregationsController.abort()
    }
  }, [location.search])

  useEffect(() => {
    if (query.selected) {
      dispatch(
        getSelectedListings({
          value: `ID:${query.selected.split('-').join(',')}`,
        }),
      )
    }
  }, [query?.selected])

  useEffect(() => {
    if (propertyCategory?.length) {
      propertyCategory.forEach((category: string) => {
        const categoryKey = category.replaceAll(' ', '_').toLowerCase()

        if (!propertyTypeStates[categoryKey]) {
          const {
            propertyCategory: _,
            propertyType: _propertyType,
            ...restFilters
          } = filters

          dispatch(
            getPropertyTypeThunk({ category, filter: restFilters }) as any,
          )
        }
      })
    }
  }, [dispatch, JSON.stringify(propertyCategory), propertyTypeStates])

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedListingsHistoryDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedListingsHistoryDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  return (
    <Suspense fallback={<OverlayLoader loading />}>
      <Content />
      <OverlayLoader loading={filters.view === 'map' ? false : loading} />
      <Driver instructions={getDriverInstructions(t)} display={displayDriver} />
    </Suspense>
  )
}

export default ListingHistory
