import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { LabelGrid } from '@popety_io/popety-io-lib'

import { plusSignNumber } from '../../../../utils'
import ValueGrid from './EditableTextUnderExploited'

const BuildingAreaInformations = ({ rows, open, handleEdit }: any) => {
  return (
    <TableRow>
      {open && <TableCell padding="none" />}
      <TableCell scope="row">
        {rows.map((row: { title: string }, i: number) => (
          <LabelGrid
            key={i}
            sx={{
              padding: 0,
              height: '30px',
              display: 'flex',
              alignItems: 'center',
            }}
            text={row.title}
          />
        ))}
      </TableCell>
      <TableCell scope="row" align="right" width="15%">
        {rows.map(
          (row: { current: string | number; className: string }, i: number) => (
            <span
              key={i}
              className={row.current ? row.className : ''}
              style={{
                height: '30px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {row.current || '-'}
            </span>
          ),
        )}
      </TableCell>
      <TableCell scope="row" align="right" width="15%">
        {rows.map(
          (row: {
            id: number | string
            authorizedColor: string
            authorized: string
            className: string
            indiceName: string
            defaultAuthorized: number | string
          }) => (
            <ValueGrid
              key={row.id}
              id={row.id}
              open={open}
              onChange={handleEdit}
              val={row.authorized || '-'}
              defaultValue={row.defaultAuthorized}
              keyType={row.id}
              className={row.authorized ? row.className : ''}
              isSelected
              indiceName={row.indiceName}
              type="number"
            />
          ),
        )}
      </TableCell>
      <TableCell scope="row" align="right" width="15%">
        {rows.map(
          (
            row: { difference: string | number; differenceColor: string },
            i: number,
          ) => (
            <span
              key={i}
              style={{
                color: row.difference ? row.differenceColor : '#000000',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {row.difference ? plusSignNumber(Number(row.difference)) : '-'}
            </span>
          ),
        )}
      </TableCell>
      <TableCell scope="row" />
    </TableRow>
  )
}

export default BuildingAreaInformations
