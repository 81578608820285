/* eslint-disable max-lines */
import { useDispatch, useSelector } from 'react-redux'
import { fetchOnce, useI18n, useRouter } from '@popety_io/popety-io-lib'

import { useEffect, useMemo } from 'react'

import {
  buildingSelector,
  getRoofBuildingPotentialSolar,
  energyService,
  userEditedDataSelector,
  userEditedLoadingSelector,
  getUserDetailedEditing,
  getHeatConsumptionAround,
  heatConsumptionAroundSelector,
  heatConsumptionAroundLoadingSelector,
} from '../redux'

import {
  potentialSolar,
  potentialSolarColor,
} from '../../LandDetails/Building/Building.utils'
import {
  ICECB,
  IHeatingAndHotWaterEditedValues,
} from '../redux/energy/energyType'
import {
  buildingPotentialSolarDataSelector,
  monthlyAverageExposureSelector,
} from '../../LandDetails'
import MonthlyAverageExposure from '../../../components/MonthlyAverageExposure'
import HeatingAndHotWaterSection from './HeatingAndHotWaterSection'
import EnergyHeatConsumptionAround from './EnergyHeatConsumptionAround'
import EnergyHeader from './EnergyHeader'
import CECBSection from './CECBSection'
import PotetialSolaireSection from './PotetialSolaireSection'

const Energy = () => {
  const { t } = useI18n()
  const dispatch = useDispatch<any>()

  const building = useSelector(buildingSelector)
  const userDetailed = useSelector(userEditedDataSelector)
  const isLoading = useSelector(userEditedLoadingSelector)
  const monthlyAverageExposure = useSelector(monthlyAverageExposureSelector)
  const buildingPotentialSolar = useSelector(buildingPotentialSolarDataSelector)
  const heatConsumptionAround = useSelector(heatConsumptionAroundSelector)
  const heatConsumptionAroundLoading = useSelector(
    heatConsumptionAroundLoadingSelector,
  )

  const { query, updateQuery } = useRouter()

  const solarAverage = potentialSolar(
    t,
    buildingPotentialSolar?.average_exposure,
  )
  const solarAverageColor = potentialSolarColor(
    buildingPotentialSolar?.average_exposure,
  )

  const isGeneva = building?.region_name === 'Genève'

  const updateHeatingAndHotWater = async (
    values: IHeatingAndHotWaterEditedValues,
  ) => {
    await energyService.updateHeatingAndHotWater(building.id, values)
    dispatch(getUserDetailedEditing([building.id]))
  }

  const updateCECB = async (values: ICECB) => {
    await energyService.updateCECB(building.id, values)
    dispatch(getUserDetailedEditing([building.id]))
  }

  useEffect(() => {
    if (building.id) {
      fetchOnce('getRoofBuildingPotentialSolar', building?.id, () => {
        dispatch(getRoofBuildingPotentialSolar(building.id) as any)
      })
      fetchOnce('getUserDetailedEditing', building?.id, () => {
        dispatch(getUserDetailedEditing(building.id) as any)
      })
    }
  }, [building?.id])

  useEffect(() => {
    if (!building.geo_center || query.tab !== 'energy') return

    dispatch(
      getHeatConsumptionAround({
        geoCenter: building.geo_center,
        popety_classification: query.energyPopetyClassification,
        radius: query.radius,
        city_name: query.city_history,
        district_name: query.district_history,
      }) as any,
    )
  }, [
    building,
    query.energyPopetyClassification,
    query.radius,
    query.tab,
    query.district_history,
    query.city_history,
  ])

  useEffect(() => {
    if (!query.energyLayer) {
      updateQuery({ energyLayer: 'power_source_heater' })
    }
  }, [])

  const heatConsumptionAroundData = useMemo(() => {
    return heatConsumptionAround?.buckets?.map((entry: any) => {
      if (entry?.from === 550) {
        return {
          ...entry,
          renovationIndice: 550,
          color: '#FFCB60',
        }
      }

      if (entry?.from === 650) {
        return {
          ...entry,
          renovationIndice: 650,
          color: '#FC8723',
        }
      }

      if (entry?.from === 800) {
        return {
          ...entry,
          renovationIndice: 800,
          color: '#E63900',
        }
      }

      if (
        building.heat_consumption_index > entry?.from &&
        building.heat_consumption_index < entry?.to
      ) {
        return {
          ...entry,
          currentHeatConsumption: building.heat_consumption_index,
        }
      }

      return entry
    })
  }, [heatConsumptionAround, building?.heat_consumption_index])

  return (
    <div
      style={{
        margin: '12px',
      }}
    >
      <EnergyHeader building={building} />
      <HeatingAndHotWaterSection
        building={{
          ...building,
          last_update_timestamp_heater:
            building?.last_update_timestamp_heater?.split('T')[0],
          last_update_timestamp_water_heater:
            building?.last_update_timestamp_water_heater?.split('T')[0],
          last_update_timestamp_additional_heater:
            building?.last_update_timestamp_additional_heater?.split('T')[0],
          last_update_timestamp_additional_water_heater:
            building?.last_update_timestamp_additional_water_heater?.split(
              'T',
            )[0],
        }}
        heatingAndHotWaterEdited={userDetailed?.heatingAndHotWater}
        onSaveChange={updateHeatingAndHotWater}
        isLoading={isLoading}
      />
      <CECBSection onSaveChange={updateCECB} defaultData={userDetailed?.cecb} />
      <PotetialSolaireSection
        building={buildingPotentialSolar}
        solarAverage={solarAverage}
        solarAverageColor={solarAverageColor}
      />
      {monthlyAverageExposure?.length ? (
        <MonthlyAverageExposure data={monthlyAverageExposure} />
      ) : null}

      {isGeneva && (
        <EnergyHeatConsumptionAround
          data={heatConsumptionAroundData}
          heatConsumptionIndex={building?.heat_consumption_index}
          loading={heatConsumptionAroundLoading}
        />
      )}
    </div>
  )
}

export default Energy
