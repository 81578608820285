import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Tag, useI18n, format, useRouter } from '@popety_io/popety-io-lib'

import HistoryAccordion, {
  HistoryAccordionProps,
} from '../components/HistoryAccordion'
import HistoryListItem from '../components/HistoryListItem'

export type FaoAccordionProps = HistoryAccordionProps & {
  fao: { [k: string]: any }
  show?: boolean
}

const FaoAccordion = (props: FaoAccordionProps) => {
  const { fao, show, ...other } = props
  const { t } = useI18n()

  const { query } = useRouter()

  const title = (
    <Box display="flex" gap={3}>
      <Box
        sx={{
          minWidth: '150px',
          maxWidth: '205px',
        }}
      >
        <Typography
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          fontWeight="500"
        >
          {`${t('land.fao.camacNo')} ${
            fao.fao_code_number || fao.camac_code_number
          }`}
        </Typography>
      </Box>

      <Typography className="ellipsis">{fao.work_description}</Typography>
    </Box>
  )

  const date = (
    <Typography variant="body2" color="info.main">
      {format(fao.inquiry_start_date, 'P')}
    </Typography>
  )

  const items = [
    fao.classification === 'development' && {
      title: (
        <Tag
          color="info"
          style={{ position: 'relative', top: '-16px', left: '40px' }}
        >
          {t('land.fao.developmentProject')}
        </Tag>
      ),
      value: ' ',
    },
    {
      title: t('land.fao.camacNo'),
      value: fao.fao_code_number || fao.camac_code_number,
    },
    {
      title: t('land.fao.type'),
      value: fao.type_name && `${fao.type_name} (${fao.type_code || ''})`,
    },
    { title: t('land.fao.lastStatus'), value: fao.result || fao.result_name },
    { title: t('land.fao.workType'), value: fao.work_type },
    { title: t('land.fao.description'), value: fao.work_description },
    { title: t('land.fao.dispensation'), value: fao.dispensation },
  ].filter(Boolean)

  const items2 = [
    {
      title: t('land.fao.authority'),
      value: `${fao.jurisdiction_code ? `(${fao.jurisdiction_code})` : ''} ${
        fao.jurisdiction_name || ''
      }`,
      show: true,
    },
    !query?.estimateId && {
      title: t('land.fao.owners'),
      value: fao?.owner || '',
      show,
    },
    !query?.estimateId && {
      title: t('land.fao.promisor'),
      value: fao?.promisor || '',
      show: true,
    },
    {
      title: t('land.fao.architect'),
      value: fao.architect || '',
      show: true,
    },
    {
      title: t('land.fao.applicant'),
      value: fao.applicant || '',
      show,
    },
  ]
    .filter((item: any) => item.show)
    .filter(Boolean)

  return (
    <HistoryAccordion
      {...other}
      link={fao.url_official}
      col1={{ title }}
      col2={{ title: date }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {items.map((item, i) => (
            <HistoryListItem key={i} {...(item as any)} md={3} />
          ))}
        </Grid>
        <Grid item xs={12}>
          {items2.map((item: any, i) => (
            <HistoryListItem {...item} key={i} md={3} />
          ))}
        </Grid>
      </Grid>
    </HistoryAccordion>
  )
}

export default FaoAccordion
