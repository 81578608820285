import { useEffect, useState } from 'react'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import TextField from '@mui/material/TextField'
import { useDispatch } from 'react-redux'
import { Modal, useMediaQuery, useI18n } from '@popety_io/popety-io-lib'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { addReminder, updateReminder } from '../../pages/Dashboard/redux'

interface Props {
  onClose: () => void
  open: boolean
  landId: string | string[]
  action?: { id?: string; alarm_date?: string }
}

const RememberDialog = ({
  onClose,
  open,
  landId,
  action = {},
}: Props): JSX.Element => {
  const { t } = useI18n()
  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const landIds = Array.isArray(landId) ? landId : [landId]

  const [date, setDate] = useState<Date | undefined>(
    action?.alarm_date ? new Date(action.alarm_date) : undefined,
  )

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()

  const handleChange = (newDate: Date | null) => {
    setDate(newDate || undefined)
  }

  const handleAddReminder = () => {
    if (action?.id) {
      landIds.forEach((id) => {
        dispatch(
          updateReminder({
            landId: id,
            dateAlarm: date,
            alarm_date: date?.toISOString(),
          }),
        )
      })
    } else {
      landIds.forEach((id) => {
        dispatch(addReminder({ landId: id, date }))
      })
    }

    onClose()
  }

  useEffect(() => {
    if (action?.alarm_date) {
      setDate(new Date(action.alarm_date))
    }
  }, [action])

  return (
    <Modal
      okText={t('common.notes.addReminder')}
      onOk={handleAddReminder}
      open={open}
      onClose={onClose}
      fullScreen={!onDesktop}
      title={t('common.info.reminderIcon')}
      disableGutters
      cancelText={t('common.cancel')}
    >
      <StaticDatePicker
        orientation={!onDesktop ? 'portrait' : 'landscape'}
        value={date}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} />}
        minDate={new Date()}
        displayStaticWrapperAs="desktop"
      />
    </Modal>
  )
}

export default RememberDialog
