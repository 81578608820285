import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BaseLayout, fetchOnce, useMediaQuery } from '@popety_io/popety-io-lib'

import { useLocations } from '../LocationsInput'
// import useNewAppSetup from '../../hooks/useNewAppSetup'
import { useAppSelector } from '../../redux/store'
import { handleSideBar } from '../../redux/appSlice'
// import useWelcomeModal from '../../hooks/useWelcomeModal'
import { getCurrentUserDetails, getSecurityStatus } from '../../pages/Account'
import { userSelector } from '../../pages/Login'
import TwoFaModal from '../../pages/Account/Security/TwoFaModal'
import AppMenu from './AppMenu'
import HeaderActions from './HeaderActions'
import HeaderLeft from './HeaderLeft'
import HeaderRight from './HeaderRight'
import SideBox from './SideBox'

const Layout = ({ children }: any) => {
  useLocations()
  // useNewAppSetup()
  // const welcome = useWelcomeModal()

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const openSideBar = useAppSelector((state: any) => state.app.openSideBar)
  const dispatch = useDispatch<any>()

  const authUser = useSelector(userSelector)

  const onSideMenuOpen = (open: boolean) => {
    dispatch(handleSideBar(open))
  }

  useEffect(() => {
    if (isDesktop) {
      dispatch(handleSideBar(true))
    }
  }, [dispatch, isDesktop, window.location.pathname])

  useEffect(() => {
    if (authUser) {
      fetchOnce('getCurrentUserDetails', authUser?.id, () => {
        dispatch(getCurrentUserDetails())
      })
    }
  }, [authUser?.id])

  useEffect(() => {
    if (authUser) {
      fetchOnce('getSecurityStatus', null, () => dispatch(getSecurityStatus()))
    }
  }, [])

  const isShowCloseSidePaneIcon = [
    'listing-history',
    'fao-history',
    'transaction-history',
    'developer-map',
  ].some((p) => window.location.pathname.includes(p))

  return (
    <BaseLayout
      SideBox={<SideBox />}
      HeaderActions={<HeaderActions />}
      HeaderLeft={<HeaderLeft />}
      HeaderRight={<HeaderRight />}
      Menu={AppMenu}
      onSideMenuOpen={onSideMenuOpen}
      openSidebar={openSideBar}
      hideSidebarCloseIcon={!isShowCloseSidePaneIcon}
    >
      {children}
      {/* {welcome.modal} */}
      <TwoFaModal />
    </BaseLayout>
  )
}

export default Layout
