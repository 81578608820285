/* eslint-disable max-lines */
import { MapSearchIcon } from '@popety_io/popety-io-lib'
import TransactionIcon from '@mui/icons-material/CurrencyExchange'
import FaoIcon from '@mui/icons-material/Construction'
import ListingIcon from '@mui/icons-material/LocalOffer'
import AddAlertIcon from '@mui/icons-material/AddAlert'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MapIcon from '@mui/icons-material/MapsHomeWork'
import AssessmentIcon from '@mui/icons-material/Assessment'

const ConstructionIcon = () => {
  return (
    <img src="/assets/plot-map.svg" width={22} height={22} alt="plot-icon" />
  )
}

const getMenus = (
  onDesktop: boolean,
  showAnalysis: boolean,
  isEstimate: boolean,
  t: Translator,
) => {
  return !onDesktop
    ? [
        {
          order: 2,
          icon: <MapSearchIcon />,
          href: '/developer-map',
          text: t('common.explore'),
          collapse: true,
          subMenus: [
            {
              href: '/developer-map/list',
              hide: true,
            },
            {
              icon: <ConstructionIcon />,
              href: '/developer-map',
              text: t('common.lands'),
              hide: false,
            },
            {
              icon: <MapIcon />,
              href: '/developer-map?type=building',
              text: t('common.buildings'),
              hide: false,
            },
            {
              icon: <TransactionIcon />,
              href: '/transaction-history',
              text: t('common.transactionHistory'),
              hide: false,
            },
            {
              icon: <FaoIcon />,
              href: '/fao-history',
              text: t('common.faoHistory'),
              hide: false,
            },
            {
              icon: <ListingIcon />,
              href: '/listing-history',
              text: t('common.listingHistory'),
              hide: false,
            },
          ],
          hide: false,
        },
        {
          order: 2,
          icon: <AssessmentIcon />,
          href: '/land-detail',
          text: t('common.landDetailAnalysis'),
          collapse: true,
          hide: !showAnalysis,
          subMenus: [
            {
              icon: <ConstructionIcon />,
              href: '/land-detail/plot',
              text: t('common.lands'),
              hide: false,
            },
            {
              icon: <MapIcon />,
              href: '/land-detail/building',
              text: t('common.buildings'),
              hide: false,
              beta: true,
            },
          ],
        },
        {
          order: 2,
          icon: <AssessmentIcon />,
          href: '/estimate?tab=home',
          text: t('common.estimate'),
          hide: !isEstimate,
        },
        {
          order: 2,
          subMenus: [
            {
              href: '/my-alerts',
              text: t('common.alerts'),
              icon: <AddAlertIcon />,
            },
          ],
        },
        {
          order: 2,
          text: t('common.dashboard'),
          icon: <DashboardIcon />,
          collapse: true,
          subMenus: [
            {
              icon: <ConstructionIcon />,
              href: '/dashboard',
              text: t('common.lands'),
              hide: false,
            },
            {
              icon: <MapIcon />,
              href: '/building-dashboard',
              text: t('common.buildings'),
              hide: false,
            },
          ],
          hide: false,
        },
      ]
    : [
        {
          order: 2,
          icon: <MapSearchIcon />,
          href: '/developer-map',
          text: t('common.explore'),
          subMenus: [
            {
              href: '/developer-map/list',
              hide: true,
            },
            {
              icon: <ConstructionIcon />,
              href: '/developer-map',
              text: t('common.lands'),
              hide: false,
            },
            {
              icon: <MapIcon />,
              href: '/developer-map?type=building',
              text: t('common.buildings'),
              hide: false,
            },
            {
              icon: <TransactionIcon />,
              href: '/transaction-history',
              text: t('common.transactionHistory'),
              hide: false,
            },
            {
              icon: <FaoIcon />,
              href: '/fao-history',
              text: t('common.faoHistory'),
              hide: false,
            },
            {
              icon: <ListingIcon />,
              href: '/listing-history',
              text: t('common.listingHistory'),
              hide: false,
            },
          ],
          hide: false,
        },
        {
          order: 2,
          icon: <AssessmentIcon />,
          href: '/land-detail',
          text: t('common.landDetailAnalysis'),
          hide: !showAnalysis,
          subMenus: [
            {
              icon: <ConstructionIcon />,
              href: '/land-detail/plot',
              text: t('common.lands'),
              hide: false,
            },
            {
              icon: <MapIcon />,
              href: '/land-detail/building',
              text: t('common.buildings'),
              beta: true,
              hide: false,
            },
          ],
        },
        {
          order: 2,
          icon: <AssessmentIcon />,
          href: '/estimate?tab=home',
          text: t('common.estimate'),
          hide: !isEstimate,
        },
        {
          order: 2,
          href: '/my-alerts',
          text: t('common.alerts'),
          icon: <AddAlertIcon />,
        },
        {
          order: 2,
          text: t('common.dashboard'),
          icon: <DashboardIcon />,
          subMenus: [
            {
              icon: <ConstructionIcon />,
              href: '/dashboard',
              text: t('common.lands'),
              hide: false,
            },
            {
              icon: <MapIcon />,
              href: '/building-dashboard',
              text: t('common.buildings'),
              hide: false,
            },
          ],
          hide: false,
        },
      ]
}

const clearAppDataAndCache = () => {
  // Clear app data
  localStorage.clear()
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie

    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
  }
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister()
    }
  })
  const { indexedDB } = window

  if (indexedDB) {
    const request = indexedDB.deleteDatabase('your-database-name')

    request.onsuccess = () => {}
    request.onerror = () => {}
  }
  sessionStorage.clear()

  // Clear cache and reload app
  caches.keys().then((cacheNames) => {
    cacheNames.forEach((cacheName) => {
      caches.delete(cacheName)
    })
  })
  window.location.reload()
}

export { getMenus, clearAppDataAndCache }
