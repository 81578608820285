/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import EventIcon from '@mui/icons-material/Event'
import MailIcon from '@mui/icons-material/Mail'
import { Box, Grid } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import { Link, useNavigate } from 'react-router-dom'
import { mapBy, Modal, Button, useI18n } from '@popety_io/popety-io-lib'

import {
  supportsSelector,
  getSupports,
  authIsLoadingSelector,
  supportDataSelector,
  accountManagerDataSelector,
  authIsLoggedInSelector,
  getPublicSupport,
} from '../../pages/Login'
import { getCompanyId } from '../../services'

interface SupportModalProps {
  open: boolean
  handleModal: (state: boolean) => void
}

const SupportModal = ({ open, handleModal }: SupportModalProps) => {
  const { t, currentLanguage: lang } = useI18n()

  const supportData = useSelector(supportDataSelector)
  const accountManagerData = useSelector(accountManagerDataSelector)
  const authIsLoading = useSelector(authIsLoadingSelector)
  const isLoggedIn = useSelector(authIsLoggedInSelector)
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()

  const supportsByName = useMemo(
    () => (supportData ? mapBy('name', supportData) : {}),
    [supportData],
  )

  const handleToggle = (v: boolean) => {
    handleModal(v)
  }

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(
        getSupports({
          companyId: getCompanyId(),
        }),
      )
    }
    if (!isLoggedIn) {
      dispatch(getPublicSupport())
    }
  }, [])

  const getValue = (name: string) => {
    if (!supportsByName[name]?.value) return ''

    return (
      supportsByName[name].value[lang] ||
      supportsByName[name].value.fr ||
      Object.values(supportsByName[name].value)[0]
    )
  }

  const learningButton = (
    <Button
      color="primary"
      variant="contained"
      onClick={() => {
        navigate('/e-learning')
        handleToggle(false)
      }}
    >
      {t('common.eLearning')}
    </Button>
  )

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleToggle(false)
        }}
        title={t('common.support')}
        showCloseIcon
        cancelText={t('common.close')}
        maxWidth={accountManagerData ? 'md' : 'sm'}
        fullWidth
        actions={isLoggedIn ? [learningButton] : []}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={accountManagerData ? 6 : 12}>
            <Box>
              {!authIsLoading && accountManagerData && (
                <>
                  <Typography variant="h6">
                    {t('common.accountManager')}
                  </Typography>
                  {accountManagerData.first_name && (
                    <Stack
                      direction="row"
                      spacing={2}
                      component="p"
                      color="inherit"
                      mb={2}
                      mt={2}
                    >
                      <ContactPhoneIcon />
                      <Typography>
                        {accountManagerData.first_name}{' '}
                        {accountManagerData?.last_name}
                      </Typography>
                    </Stack>
                  )}

                  {accountManagerData.phone && (
                    <Stack
                      direction="row"
                      spacing={2}
                      href={`tel:${accountManagerData.phone}`}
                      component="a"
                      color="inherit"
                      mb={2}
                      mt={2}
                    >
                      <ContactPhoneIcon />
                      <Typography>{accountManagerData.phone}</Typography>
                    </Stack>
                  )}

                  {accountManagerData.email && (
                    <Stack
                      direction="row"
                      spacing={2}
                      href={`mailto:${accountManagerData.email}`}
                      component="a"
                      color="inherit"
                      mb={2}
                    >
                      <MailIcon />
                      <Typography>{accountManagerData.email}</Typography>
                    </Stack>
                  )}
                </>
              )}
              {authIsLoading &&
                [1, 2, 3, 4, 5].map((i) => (
                  <Box
                    key={i}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      gap: '1rem',
                    }}
                  >
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '1.5rem', borderRadius: 2, width: '6%' }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '1.5rem', borderRadius: 2, width: '45%' }}
                    />
                  </Box>
                ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={accountManagerData ? 6 : 12}>
            <Box>
              {!authIsLoading && supportData && (
                <>
                  <Typography variant="h6">
                    {t('common.generalSupport')}
                  </Typography>
                  {supportsByName.phone?.value && (
                    <Stack
                      direction="row"
                      spacing={2}
                      color="inherit"
                      mb={2}
                      mt={2}
                    >
                      <LocalLibraryIcon />
                      <Typography>
                        <Link
                          to="/e-learning"
                          onClick={() => {
                            handleToggle(false)
                          }}
                        >
                          {t('common.eLearning')}
                        </Link>
                      </Typography>
                    </Stack>
                  )}
                  {supportsByName.phone?.value && (
                    <Stack
                      direction="row"
                      spacing={2}
                      href={`tel:${getValue('phone')}`}
                      component="a"
                      color="inherit"
                      mb={2}
                      mt={2}
                    >
                      <ContactPhoneIcon />
                      <Typography>{getValue('phone')}</Typography>
                    </Stack>
                  )}

                  {supportsByName.whatsapp?.value && (
                    <Stack
                      direction="row"
                      spacing={2}
                      href={`https://wa.me/${getValue('whatsapp')
                        ?.slice(1)
                        .replaceAll(' ', '')}`}
                      component="a"
                      color="inherit"
                      target="_blank"
                      rel="noopener noreferrer"
                      mb={2}
                    >
                      <WhatsAppIcon />
                      <Typography>{getValue('whatsapp')}</Typography>
                    </Stack>
                  )}

                  {supportsByName.email?.value && (
                    <Stack
                      direction="row"
                      spacing={2}
                      href={`mailto:${getValue('email')}`}
                      component="a"
                      color="inherit"
                      mb={2}
                    >
                      <MailIcon />
                      <Typography>{getValue('email')}</Typography>
                    </Stack>
                  )}

                  {supportsByName.whatsapp?.value && (
                    <Stack
                      direction="row"
                      spacing={2}
                      title={t('common.openingHours')}
                    >
                      <EventIcon />
                      <Typography>{getValue('openingHours')}</Typography>
                    </Stack>
                  )}
                </>
              )}
              {authIsLoading &&
                [1, 2, 3, 4, 5].map((i) => (
                  <Box
                    key={i}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      gap: '1rem',
                    }}
                  >
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '1.5rem', borderRadius: 2, width: '6%' }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '1.5rem', borderRadius: 2, width: '45%' }}
                    />
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default SupportModal
