import { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import {
  Modal,
  ProgressIndicator,
  useI18n,
  ModalProps,
  Driver,
  useRouter,
  fetchOnce,
} from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { userSelector } from '../../../Login'

import {
  getLandSummary,
  landLoadingSelector,
  setSearchHistory,
} from '../../redux'
import { LOCATION_TYPE } from '../../../../config'
import LocationsInput from '../../../../components/LocationsInput'
import { updateTutorials } from '../../../Account/redux'
import { LandSearchRoot, SearchForm } from './LandSearch.style'
import { getItem } from './LandSearch.util'
import getDriverInstructions from './driverInstructions'
import LandSearchHistory from './LandSearchHistory'

const LandSearch = ({ open, onClose, showCloseIcon }: ModalProps) => {
  const [value, setValue] = useState<any[]>([])
  const [displayDriver, setDisplayDriver] = useState(false)

  const dispatch: any = useDispatch()
  const loading = useSelector(landLoadingSelector)
  const user = useSelector(userSelector)
  const { query } = useRouter()
  const { landId } = query

  const canAnalyse = useMemo(() => {
    return (
      user.can_exceed_max_analysis ||
      Number(user.analysis_counter) < Number(user?.analysis_nb) ||
      (user?.listsAnalysis && user?.listsAnalysis?.includes(landId))
    )
  }, [user])

  const navigate = useNavigate()
  const { t } = useI18n()

  const handleSubmit = () => {
    if (!value?.length) return

    const geoCenter = value.map((i: any) => i.geo_center).filter(Boolean)

    dispatch(getLandSummary({ geoCenter, canAnalyse }))
      .then(unwrapResult)
      .then((result: any) => {
        const land = result?.data
        const landId = land?.land_id

        if (landId) {
          dispatch(
            setSearchHistory({
              ...getItem(land?.land_id, land),
              location: value[0].location_search,
            }),
          )
          navigate(`/land-detail/plot?landId=${landId}&landUsePlanStatus=now`)
        }
      })
  }

  const handleChange = (newLocations: any) => {
    setValue(newLocations.filter(Boolean).slice(-1))
  }

  useEffect(() => {
    if (value?.length && value.length > 0) {
      handleSubmit()
    }
  }, [value])

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedLandSearchDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedLandSearchDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  return (
    <Modal
      top={50}
      open={open}
      keepMounted
      naked
      blur
      disableGutters
      showCloseIcon={false}
      maxWidth="sm"
      onClose={onClose}
      sx={{ zIndex: '3 !important', '.MuiDialog-paper': { mt: '-15vh' } }}
    >
      {loading && <ProgressIndicator />}

      {!loading && (
        <LandSearchRoot sx={{ width: { xs: '300px', sm: '500px' } }}>
          <Paper className="Title">
            {t('land.search.locationSearch')}

            {showCloseIcon && (
              <IconButton aria-label="close" onClick={onClose} size="small">
                <CloseIcon />
              </IconButton>
            )}
          </Paper>

          <SearchForm component="form" onSubmit={() => {}}>
            <LocationsInput
              onChange={handleChange}
              value={value}
              types={[LOCATION_TYPE.ADDRESS, LOCATION_TYPE.LAND]}
              size={10}
              onKeyDown={handleSubmit}
            />
            <LandSearchHistory />
          </SearchForm>
        </LandSearchRoot>
      )}
      {!query.landId && (
        <Driver
          instructions={getDriverInstructions(t)}
          display={displayDriver}
        />
      )}
    </Modal>
  )
}

export default LandSearch
