import { useI18n } from '@popety_io/popety-io-lib'
import SelectorLegend from '../../../../../components/SelectorLegend/SelectorLegend'
import { pricingOptions } from '../../../../../utils/thematic/glossary/pricingOptions'

const SummaryMapContent = () => {
  const { t } = useI18n()
  const priceOptions = pricingOptions(t)
  // const { query } = useRouter()

  const options = [
    {
      value: 'estimate-purchase-apartment-v1',
      label: t('estimate.map.legend.market price'),
    },
  ]

  return (
    <div>
      <SelectorLegend
        options={options}
        legends={priceOptions?.[0]?.legend}
        value="estimate-purchase-apartment-v1"
        keyValue="summaryLayer"
        legendValue="displayNoiseLegend"
      />
    </div>
  )
}

export default SummaryMapContent
