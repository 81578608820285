import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

export const BuildingIconContainer = styled(Box)`
  > svg {
    width: 24px !important;
    height: 24px !important;
  }

  > svg.protectedIcon {
    color: #e27670;
  }

  > svg.WellIntegratedIcon {
    color: #fbbe64;
  }

  > svg.qualityAndDefaultIcon {
    color: #ffe768;
  }

  > svg.notInterestIcon {
    color: #8ed1a5;
  }

  > svg.unassessedIcon {
    color: #a5a5a5;
  }

  > svg.undergroundIcon {
    color: rgba(127, 145, 160);
  }
`

export const LogoRoot = styled('div')`
  display: flex;
  border: ${({ color }) => `2px solid ${color}`};
  flex-direction: column;
  padding: 9px;
  border-radius: 12px;
  line-height: 12px;
  font-size: 9px;
  text-align: center;

  span {
    display: flex;
    /* width: 60px; */
    max-width: 45px;
    justify-content: center;
  }

  img {
    max-width: 60px;
    max-height: 60px;
  }
`
export const BuildingInfo = styled('div')`
  width: 400px;

  > h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 12px;
  }

  li {
    padding-top: 4px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 4px;
    border-top: 1px solid gainsboro;

    > span,
    a {
      color: rgb(141, 155, 163);
      width: 50%;
    }

    > span:last-child,
    a:last-child {
      color: rgb(0, 173, 230);
    }
  }
`

export const TextFieldComponent = styled(TextField)`
  .MuiInput-input {
    font-size: 13px;
  }

  .MuiOutlinedInput-root {
    border-radius: 5px;
  }
`

export const IConBuilding = styled('div')<any>`
  width: 30px;
  height: 30px;
  background-image: ${(props) => {
    return `url(${props?.image})`
  }};

  background-size: 70px 70px;
  background-position: center center;
`
