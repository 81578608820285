import { lazy } from 'react'
import FinancialIcon from '@mui/icons-material/AttachMoney'
import SunIcon from '@mui/icons-material/Brightness4'
import LupaIcon from '@mui/icons-material/FeaturedVideoOutlined'
import BuildingIcon from '@mui/icons-material/Home'
import HomeIcon from '@mui/icons-material/InfoOutlined'
import LivabilityIcon from '@mui/icons-material/LocationOn'
import RestrictionIcon from '@mui/icons-material/NotInterested'
import GeoIcon from '@mui/icons-material/RoundedCorner'
import ArchitectureIcon from '@mui/icons-material/Architecture'
import { TransactionCountChip } from '../../Transaction'
import ListingCountChip from '../../Listing/ListingCountChip'
import FaoCountChip from '../../Fao/FaoCountChip'

const Restriction = lazy(() => import('../../Restriction'))
const LandDetailsSummary = lazy(() => import('../../Summary'))
const Listing = lazy(() => import('../../Listing'))
const Transaction = lazy(() => import('../../Transaction'))
const Fao = lazy(() => import('../../Fao'))
const Geo = lazy(() => import('../../Geo'))
const FinancialAnalysis = lazy(() => import('../../FinancialAnalysis'))
const LandUsePlans = lazy(() => import('../../UnderExploited'))
const Building = lazy(() => import('../../Building'))
const Livability = lazy(() => import('../../Livability'))
const Sunlight = lazy(() => import('../../Sunlight'))
const Design = lazy(() => import('../../Design/Design'))

const getLandDetailsMenuData = (
  t = (k: string) => k,
  firstTransactionDate?: string,
  firstFaoDate?: string,
  isInCompany?: boolean,
  canAnalyse?: boolean,
) => {
  const pages = [
    {
      icon: <HomeIcon />,
      key: 'summary',
      text: t('land.summary'),
      page: <LandDetailsSummary />,
      show: true,
    },
    {
      icon: <BuildingIcon />,
      key: 'building',
      text: t('land.building'),
      page: <Building />,
      show: canAnalyse,
    },
    {
      icon: <LupaIcon />,
      key: 'lupa',
      text: t('land.lupa'),
      page: <LandUsePlans />,
      show: canAnalyse,
    },
    {
      icon: <RestrictionIcon />,
      key: 'restriction',
      text: t('land.restrictions.title'),
      page: <Restriction />,
      show: canAnalyse,
    },
    {
      icon: <LivabilityIcon />,
      key: 'livability',
      text: t('land.livabilityScore.livabilityHeader'),
      page: <Livability />,
      show: canAnalyse,
    },
    {
      icon: <GeoIcon />,
      key: 'geo',
      text: t('land.geoScore.geoHeader'),
      page: <Geo />,
      show: canAnalyse,
    },
    {
      icon: <FaoCountChip show={!!firstFaoDate} />,
      key: 'fao',
      text: t('land.fao'),
      page: <Fao show />,
      show: canAnalyse && !!firstFaoDate,
    },
    {
      icon: <TransactionCountChip show={!!firstTransactionDate} />,
      key: 'transaction',
      text: t('land.transaction'),
      page: <Transaction show />,
      show: canAnalyse && !!firstTransactionDate,
    },
    {
      icon: <ListingCountChip />,
      key: 'listing',
      text: t('land.listing'),
      page: <Listing />,
      show: canAnalyse,
    },
    {
      icon: <SunIcon />,
      key: 'sunlight',
      text: t('land.history.sunlight'),
      page: <Sunlight />,
      show: canAnalyse,
    },
    // {
    //   icon: <OwnershipIcon />,
    //   key: 'ownership',
    //   text: t('land.ownership.ownershipHeader'),
    //   page: <Owners />,
    // },
    {
      icon: <FinancialIcon />,
      key: 'financial-plan',
      text: t('land.financialAnalysis.financialPlanHeader'),
      page: <FinancialAnalysis />,
      show: canAnalyse,
    },
  ].filter(Boolean)

  // Feature EPFL: Do not touch
  if (isInCompany /* POPETY company id */) {
    pages.push({
      icon: <ArchitectureIcon />,
      key: 'design-2',
      text: 'Design',
      page: <Design />,
      show: true,
    })

    // pages.push({
    //   icon: (
    //     <img
    //       src={DesignIcon}
    //       alt="React Logo"
    //       style={{
    //         width: '100px',
    //         marginTop: '10px',
    //         height: '55px',
    //       }}
    //     />
    //   ),
    //   key: 'design',
    //   text: 'Design',
    //   page: <DesignComponent />,
    //   show: true,
    // })
  }

  return pages
}

export { getLandDetailsMenuData }
