import ListingIcon from '@mui/icons-material/LocalOffer'
import { useSelector } from 'react-redux'
import { landListingsTotalSelector } from '../redux'
import CountChip from '../../../components/Tag/CountChip'

interface IProps {
  show?: boolean
}
const ListingCountChip = ({ show }: IProps) => {
  const count = useSelector(landListingsTotalSelector)

  return <CountChip count={count} icon={<ListingIcon />} show={show} />
}

export default ListingCountChip
