/* eslint-disable max-lines */
import { useState, useRef, useEffect } from 'react'
import { Modal, Button, useI18n } from '@popety_io/popety-io-lib'
import AccordionDetails from '@mui/material/AccordionDetails'
import SwipeableViews from 'react-swipeable-views'
import { Box, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import ListingAccordionBody from '../ListingAccordionBody'
import {
  estimateEditedSelector,
  estimateIdSelector,
} from '../../pages/Estimate/redux/summary/summarySelector'

import { saveEditedEstimate } from '../../pages/Estimate/redux/summary/summarySlice'

type EstimateListingTilesProps = {
  feature?: any
  handleClose: () => void
  data: any[]
}

const EstimateListingTiles = ({
  feature,
  handleClose,
  data,
}: EstimateListingTilesProps) => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const [index, setIndex] = useState(0)
  const swiperRef = useRef<any>()
  const estimateEdited: any = useSelector(estimateEditedSelector)
  const estimateId = useSelector(estimateIdSelector)
  const [comparablesIds, setComparablesIds] = useState<string[]>([])

  const onSave = (listing: any) => {
    setComparablesIds((pre) => pre && [...pre, listing.id])
    dispatch(
      saveEditedEstimate({
        id: estimateId,
        body: {
          ...estimateEdited,
          comparablesIds: comparablesIds?.length
            ? [...comparablesIds, listing.id]
            : [listing.id],
        },
      }) as any,
    )
  }

  const onRemove = (listing: any) => {
    setComparablesIds((pre) => pre.filter((id) => id !== listing.id))
    dispatch(
      saveEditedEstimate({
        id: estimateId,
        body: {
          ...estimateEdited,
          comparablesIds: comparablesIds.filter((id) => id !== listing.id),
        },
      }) as any,
    )
  }

  useEffect(() => {
    setComparablesIds(estimateEdited?.comparablesIds)
  }, [estimateEdited])

  const onClose = () => {
    setIndex(0)
    handleClose()
  }

  const swiperAction = (actions: any) => {
    swiperRef.current = actions
  }

  const handleChangeIndex = (newIndex: number) => {
    setIndex(newIndex)
  }

  const handlePrevious = () => {
    setIndex((i) => i - 1)
  }

  const handleNext = () => {
    setIndex((i) => i + 1)
  }

  return (
    <Modal
      open={!!feature}
      onClose={onClose}
      disableGutters
      showCloseIcon={false}
      responsive="sm"
      loading={false}
      fullWidth
      sx={{
        '.PreviewHeader': { borderBottom: '1px solid gainsboro' },
        '.OpenDetailsButton': { display: 'none' },
      }}
      maxWidth="lg"
    >
      <SwipeableViews
        index={index}
        animateHeight
        onChangeIndex={handleChangeIndex}
        action={swiperAction as any}
      >
        {data?.map((listing) => (
          <Box
            key={listing.id}
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            }}
          >
            <AccordionDetails
              data-cy="land-detail-accordionDetails"
              sx={{
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <ListingAccordionBody
                listing={listing}
                comparablesIds={comparablesIds}
                onSave={onSave}
                onRemove={onRemove}
              >
                {data?.length > 1 && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      flexGrow: 1,
                      alignItems: 'flex-end',
                      mt: '8px',
                      padding: 0,
                      background: 'white',
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={handlePrevious}
                      disabled={index === 0}
                      sx={{ ml: '-8px' }}
                    >
                      {t('common.previous')}
                    </Button>

                    <Button disabled>{`${index + 1} / ${data.length}`}</Button>

                    <Button
                      color="primary"
                      onClick={handleNext}
                      disabled={index === data.length - 1}
                      sx={{ mr: '-8px' }}
                    >
                      {t('common.next')}
                    </Button>
                  </Stack>
                )}
              </ListingAccordionBody>
            </AccordionDetails>
          </Box>
        ))}
      </SwipeableViews>
    </Modal>
  )
}

export default EstimateListingTiles
