/* eslint-disable import/no-named-default */
import { useState, useEffect } from 'react'
import { Box, Typography, Skeleton } from '@mui/material'
import { useI18n, useSize } from '@popety_io/popety-io-lib'
import { BarChart, YAxis, XAxis, Bar, Tooltip } from 'recharts'
import Switch from '@mui/material/Switch'
import { default as TooltipMui } from '@mui/material/Tooltip'

import { CustomTooltip } from './MonthlyAverageExposure.style'

const CustomTooltipExposure = ({ active, payload, label }: any) => {
  if (!active || !payload?.length) return null

  return (
    <CustomTooltip>
      <p style={{ fontSize: '0.9rem' }}>{label}</p>
      <p style={{ color: '#00ade6', fontSize: '0.8rem' }}>
        {payload[0].value} kWh/m2
      </p>
    </CustomTooltip>
  )
}

const MonthlyAverageExposure = ({
  data,
  type,
  toggleDisplay,
  defaultShow = true,
  loading,
}: {
  data: any
  type?: string
  toggleDisplay?: (name: string) => (checked: boolean) => void
  defaultShow?: boolean
  loading?: boolean
}) => {
  const { t } = useI18n()
  const { ref, width = 300 } = useSize()

  const [show, setShow] = useState(defaultShow)

  const dataTranslate = data.map((item: any) => ({
    ...item,
    key: t(`common.${item.key}`),
  }))

  const onToggle = (checked: boolean) => {
    toggleDisplay?.('MonthlyAverageExposureSection')(checked)
    setShow(checked)
  }

  useEffect(() => {
    setShow(defaultShow)
  }, [defaultShow])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBlock: '16px',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: '4px',
            fontSize: '1.1rem',
            textTransform: 'uppercase',
          }}
        >
          {t('common.Monthly average exposure in kWh')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {type === 'estimate' && (
            <TooltipMui title={t('common.disable_button_tooltip')}>
              <Switch
                checked={show}
                onChange={(e) => onToggle(e.target.checked)}
                size="small"
              />
            </TooltipMui>
          )}
        </Box>
      </Box>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
          }}
        >
          <Skeleton
            variant="rectangular"
            sx={{ height: '250px', borderRadius: 2, width: '100%' }}
          />
        </Box>
      ) : (
        <Box ref={ref} sx={{ width: '95%', flexGrow: 1 }}>
          {!data || data.length === 0 ? (
            <Box>
              {show ? (
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: 'center',
                    marginTop: '20px',
                  }}
                >
                  {t('common.no_data_available')}
                </Typography>
              ) : null}
            </Box>
          ) : (
            <BarChart
              width={width}
              height={300}
              data={dataTranslate}
              style={{
                display: show ? 'block' : 'none',
                marginRight: '20px',
              }}
            >
              <XAxis dataKey="key" />
              <YAxis />
              <Tooltip content={CustomTooltipExposure} />
              <Bar
                dataKey="average_exposure"
                barSize={40}
                fill="#2FACE6"
                opacity={1}
              />
            </BarChart>
          )}
        </Box>
      )}
    </Box>
  )
}

export default MonthlyAverageExposure
