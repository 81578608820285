import Person from '@mui/icons-material/Person'
import Avatar from '@mui/material/Avatar'
import { stringToColor } from '@popety_io/popety-io-lib'

export type NoteHeaderProps = {
  action: {
    note_id: string
    action: string
    first_name: string
    last_name: string
    photo?: string
  }
}

const EstimateNoteHeader = ({ action }: NoteHeaderProps) => {
  const name = `${action?.first_name} ${action?.last_name}`
  const userDefault = action?.first_name?.charAt(0)?.toUpperCase() || ''

  return (
    <Avatar
      sx={{ bgcolor: stringToColor(name), mt: 1 }}
      alt="author"
      src={action.photo}
    >
      {userDefault ? <span>{userDefault}</span> : <Person color="action" />}
    </Avatar>
  )
}

export default EstimateNoteHeader
