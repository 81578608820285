import { mapBy } from '@popety_io/popety-io-lib'

import * as actions from './actions'
import { updateGeoJSONData } from './updateGeoJSONData'

const updateAffectationZone = (state, action) => {
  const { data } = action.payload

  if (data) state.zoneAffectation = data
}

const extraReducers = (builder) => {
  builder
    .addCase(actions.getAlerts.pending, (state) => {
      state.loading = true
    })
    .addCase(actions.getAlerts.rejected, (state) => {
      state.loading = false
    })
    .addCase(actions.getAlerts.fulfilled, (state, action) => {
      const { data = [] } = action.payload || {}

      state.loading = false
      state.alertsById = mapBy('id', data)
    })
    .addCase(actions.getZonesByCity.fulfilled, updateAffectationZone)
    .addCase(actions.getZonesByDistrict.fulfilled, updateAffectationZone)
    .addCase(actions.getZonesByRegion.fulfilled, updateAffectationZone)
    .addCase(actions.getDistrictById.fulfilled, updateGeoJSONData('district'))
    .addCase(actions.getCityById.fulfilled, updateGeoJSONData('city'))
    .addCase(actions.getRegionById.fulfilled, updateGeoJSONData('region'))
    .addCase(
      actions.getDistrictGeojsonByZone.fulfilled,
      updateGeoJSONData('district'),
    )
    .addCase(
      actions.getRegionGeojsonByZone.fulfilled,
      updateGeoJSONData('region'),
    )
    .addCase(actions.getCityGeojsonByZone.fulfilled, updateGeoJSONData('city'))
    .addCase(actions.createAlert.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data) {
        const input = action.meta.arg
        const newAlert = {
          ...input,
          ...data,
          city: input.cityLocation,
          zone: input.zoneLocation,
          region: input.regionLocation,
          type: input.typeName,
        }

        state.alertsById[newAlert.id] = newAlert
      }
    })
    .addCase(actions.deleteAlert.fulfilled, (state, action) => {
      const id = action.meta.arg

      delete state.alertsById[id]
    })
    .addCase(actions.updateAlert.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data) {
        const input = action.meta.arg

        const newAlert = {
          ...input,
          ...data,
          city: input.cityLocation,
          zone: input.zoneLocation,
          region: input.regionLocation,
          type: input.typeName,
        }

        state.alertsById[newAlert.id] = newAlert
      }
    })
}

export default extraReducers
