/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import transactionService from './transactionHistoryService'

const currentYear = new Date().getFullYear()

const initialState = {
  transactions: [],
  selectedTransactions: [],
  priceData: {},
  comparePriceData: {},
  aggregations: {
    dates: {
      dates: {
        min_as_string: '2005-01-01',
        max_as_string: currentYear.toString(),
      },
    },
    areas: { lands: { areas: { min: 0, max: 9999999 } } },
    reasons: { reasons: { buckets: [] } },
    ownership_types: { ownership_types: { buckets: [] } },
    regions: { regions: { buckets: [] } },
    zones: { zones: { buckets: [] } },
    cities: { cities: { buckets: [] } },
    landUsePlans: { land_use_principal_type: { buckets: [] } },
  },
  total: 0,
  count: 0,
}

export const getTransactions = createAsyncThunk(
  'transactionHistory/getTransactions',
  transactionService.getTransactions,
)

export const getSelectedTransactions = createAsyncThunk(
  'transactionHistory/getSelectedTransactions',
  transactionService.getSelectedTransactions,
)

export const getTransactionStats = createAsyncThunk(
  'transactionHistory/getTransactionStats',
  transactionService.getTransactionStats,
)

export const getComparedTransactionStats = createAsyncThunk(
  'transactionHistory/getComparedTransactionStats',
  transactionService.getTransactionStats,
)

export const countTransactions = createAsyncThunk(
  'transactionHistory/countTransactions',
  transactionService.countTransactions,
)

const transactionHistory = createSlice({
  name: 'transactionHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransactions.pending, (state) => {
        state.loading = true
      })
      .addCase(getTransactions.rejected, (state) => {
        state.loading = false
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.loading = false

        if (data) {
          state.transactions = data.result

          state.aggregations = {
            ...initialState.aggregations,
            ...data.aggregations,
          }

          state.total = data.total || 0
          state.bbox = data.bbox
        }
      })
      .addCase(getSelectedTransactions.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          state.selectedTransactions = data.result || [
            { ...data.data, id: data.id },
          ]
        }
      })
      .addCase(countTransactions.fulfilled, (state, action) => {
        const { data = 0 } = action.payload || {}

        state.count = data
      })
      .addCase(getTransactionStats.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) state.priceData = data
      })
      .addCase(getComparedTransactionStats.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) state.comparePriceData = data
      })
  },
})

export const transactionReducer = {
  transactionHistory: transactionHistory.reducer,
}
