/**
 * Base REST Http Client wrapper arround the native fetch api
 *
 * @namespace Services
 * @see https://developer.mozilla.org/fr/docs/Web/API/Fetch_API/Using_Fetch
 *
 * @example <caption>Usage</caption>
 * const client = new HttpClient({ baseURL: 'https://localhost.com' })
 * client.get('/users')
 * client.post('/users', userInput)
 * client.delete('/users/1')
 */
class HttpClient {
  constructor(options = {}) {
    this._baseURL =
      typeof options === 'string' ? options : options.baseURL || ''

    this._headers = { 'Content-Type': 'application/json', ...options.headers }
    this._interceptors = {}
    this._interceptors.request = options?.interceptors?.request
    this._interceptors.response = options?.interceptors?.response
  }

  async _fetchJSON(endpoint, options = {}) {
    try {
      const interceptedOpts = await this._interceptors?.request?.()

      const mergedOpts = {
        ...options,
        ...interceptedOpts,
        headers: {
          ...this._headers,
          ...options?.headers,
          ...interceptedOpts?.headers,
        },
      }

      const isJSON = mergedOpts.headers['Content-Type'] === 'application/json'

      if (mergedOpts.body && mergedOpts.formData) {
        const formData = new FormData()

        Object.keys(mergedOpts.body).forEach((k) => {
          formData.append(k, mergedOpts.body[k])
        })

        mergedOpts.body = formData
        delete mergedOpts.headers['Content-Type']
      } else if (mergedOpts.body && isJSON) {
        mergedOpts.body = JSON.stringify(mergedOpts.body)
      }

      if (!mergedOpts.body) {
        delete mergedOpts.body
      }

      const res = await fetch(this._baseURL + (endpoint || ''), mergedOpts)

      if (this._interceptors?.response) {
        return this._interceptors.response(res)
      }

      if (!res.ok) throw new Error(res.statusText)

      if (isJSON && res.status !== 204) return await res.json()

      return res
    } catch (error) {
      console.error(error)
    }
  }

  get(endpoint, options = {}) {
    return this._fetchJSON(endpoint, { ...options, method: 'GET' })
  }

  post(endpoint, body, options = {}) {
    return this._fetchJSON(endpoint, { ...options, body, method: 'POST' })
  }

  put(endpoint, body, options = {}) {
    return this._fetchJSON(endpoint, { ...options, body, method: 'PUT' })
  }

  patch(endpoint, body, options = {}) {
    return this._fetchJSON(endpoint, { ...options, body, method: 'PATCH' })
  }

  delete(endpoint, body = {}, options = {}) {
    return this._fetchJSON(endpoint, { ...options, body, method: 'DELETE' })
  }
}

export default HttpClient
