/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import { useI18n, formatCurrency } from '@popety_io/popety-io-lib'
import { Box, Typography } from '@mui/material'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'

import MouseHoverPopover from '../../../components/MouseHoverPopover'

const PotetialSolaireSection = ({
  building,
  solarAverage,
  solarAverageColor,
  type,
  toggleDisplay,
  defaultShow = true,
}: {
  building: any
  solarAverage: any
  solarAverageColor: any
  type?: string
  toggleDisplay?: (name: string) => (checked: boolean) => void
  defaultShow?: boolean
}) => {
  const [show, setShow] = useState(defaultShow)
  const { t } = useI18n()

  useEffect(() => {
    setShow(defaultShow)
  }, [defaultShow])

  const onToggle = (checked: boolean) => {
    toggleDisplay?.('potentialSolaire')(checked)
    setShow(checked)
  }

  return (
    <Box paddingY={2} display="flex" flexDirection="column" gap={4}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: '4px',
            fontSize: '1.1rem',
            textTransform: 'uppercase',
          }}
        >
          {t('building.energy.Potential Solaire toiture')}
        </Typography>
        {type === 'estimate' && (
          <Tooltip title={t('common.disable_button_tooltip')}>
            <Switch
              checked={show}
              onChange={(e) => onToggle(e.target.checked)}
              size="small"
            />
          </Tooltip>
        )}
      </Box>
      {show && (
        <>
          <Grid container spacing={{ xs: 1, sm: 3 }} alignItems="flex-start">
            <Grid item container xs={12} sm={6} md={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography
                  color={solarAverageColor as string}
                  variant="h5"
                  fontSize="1.846rem"
                  fontWeight="500"
                >
                  {solarAverage || 'N/A'}
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <MouseHoverPopover
                    title={t('common.Aptitude Solaire')}
                    sx={{ display: 'flex', gap: 1 }}
                  >
                    <Typography
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 1,
                        gap: '2px',
                      }}
                    >
                      <span>{t('land.sunlight.aptitudeSolaire-desc1')}</span>
                      <span>{t('land.sunlight.aptitudeSolaire-desc2')}</span>
                      <span>
                        {t('land.sunlight.aptitudeSolaire-desc-weak')}
                      </span>
                      <span>{t('land.sunlight.aptitudeSolaire-desc-mid')}</span>
                      <span>
                        {t('land.sunlight.aptitudeSolaire-desc-good')}
                      </span>
                      <span>
                        {t('land.sunlight.aptitudeSolaire-desc-very-good')}
                      </span>
                      <span>
                        {t('land.sunlight.aptitudeSolaire-desc-excellent')}
                      </span>
                    </Typography>
                  </MouseHoverPopover>
                </Box>
              </Box>
            </Grid>
            <Grid item container xs={12} sm={6} md={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography variant="h5" fontWeight="500" fontSize="1.846rem">
                  {!building?.average_exposure
                    ? 'N/A'
                    : `${formatCurrency(
                        building?.average_exposure || 0,
                        '',
                      )} kWh/m2`}
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <MouseHoverPopover
                    title={t('land.sun.Exposition moyenne')}
                    sx={{ display: 'flex', gap: 1 }}
                  >
                    <Typography
                      sx={{
                        maxWidth: 300,
                        padding: 1,
                        gap: '2px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      <span>{t('land.sunlight.expositionMoyenne-desc')}</span>
                    </Typography>
                  </MouseHoverPopover>
                </Box>
              </Box>
            </Grid>
            <Grid item container xs={12} sm={6} md={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography variant="h5" fontWeight="500" fontSize="1.846rem">
                  {!building?.roof_area
                    ? 'N/A'
                    : `${formatCurrency(building?.roof_area || 0, '')} m2`}
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <MouseHoverPopover
                    title={t('land.sun.Surface toits')}
                    sx={{ display: 'flex', gap: 1 }}
                  >
                    <Typography
                      sx={{
                        maxWidth: 300,
                        padding: 1,
                        gap: '2px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      <span>{t('land.sunlight.Surface toits-desc')}</span>
                    </Typography>
                  </MouseHoverPopover>
                </Box>
              </Box>
            </Grid>
            <Grid item container xs={12} sm={6} md={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography variant="h5" fontWeight="500" fontSize="1.846rem">
                  {!building?.global_exposure
                    ? 'N/A'
                    : `${formatCurrency(
                        building?.global_exposure || 0,
                        '',
                      )} kWh`}
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <MouseHoverPopover
                    title={t('land.sun.Exposition globale')}
                    sx={{ display: 'flex', gap: 1 }}
                  >
                    <Typography
                      sx={{
                        maxWidth: 300,
                        padding: 1,
                        gap: '2px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      <span>{t('land.sun.Exposition globale-desc')}</span>
                    </Typography>
                  </MouseHoverPopover>
                </Box>
              </Box>
            </Grid>
            <Grid item container xs={12} sm={6} md={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography variant="h5" fontWeight="500" fontSize="1.846rem">
                  {!building?.electrical_efficiency
                    ? 'N/A'
                    : `${formatCurrency(
                        building?.electrical_efficiency || 0,
                        '',
                      )} kWh`}
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <MouseHoverPopover
                    title={`${t('land.sun.Rendement électrique')}`}
                    sx={{ display: 'flex', gap: 1, textAlign: 'center' }}
                  >
                    <Typography
                      sx={{
                        // maxWidth: 600,
                        padding: 1,
                        gap: '2px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      <span>
                        {t('land.sun.Rendement électrique-desc').replace(
                          /(;|:)/g,
                          '$1\n',
                        )}
                      </span>
                    </Typography>
                  </MouseHoverPopover>
                </Box>
              </Box>
            </Grid>
            <Grid item container xs={12} sm={6} md={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography variant="h5" fontWeight="500" fontSize="1.846rem">
                  {!building?.thermal_efficiency
                    ? 'N/A'
                    : `${formatCurrency(
                        building?.thermal_efficiency || 0,
                        '',
                      )} kWh`}
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <MouseHoverPopover
                    title={t('land.sun.Rendement thermique')}
                    sx={{ display: 'flex', gap: 1 }}
                  >
                    <Typography
                      sx={{
                        // maxWidth: 300,
                        padding: 1,
                        gap: '2px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      <span>
                        {t('land.sun.Rendement thermique-desc').replace(
                          /(;|:)/g,
                          '$1\n',
                        )}
                      </span>
                    </Typography>
                  </MouseHoverPopover>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  )
}

export default PotetialSolaireSection
