/* eslint-disable max-lines */
import Radio from '@mui/material/Radio'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { LabelGrid } from '@popety_io/popety-io-lib'

import Warning from '@mui/icons-material/Warning'
import { Tooltip } from '@mui/material'
import { plusSignNumber } from '../../../../utils'
import ValueGrid from './EditableTextUnderExploited'
import { TableCellRoot, TreeMapRoot } from './BuildingArea.style'

type BuildingAreaProps = {
  row: any
  open: boolean
  std: number
  selected: { primaryIndice: string; secondaryIndice: string }
  handleChangeRadio?: (e: any) => void
  handleEdit: (e: any) => void
}

const BuildingArea = ({
  open,
  row,
  std,
  selected,
  handleChangeRadio,
  handleEdit,
}: BuildingAreaProps) => {
  const isSelected =
    (selected.primaryIndice || row.primaryIndice) === row.id ||
    (selected.secondaryIndice || row.secondaryIndice) === row.id ||
    row.id === 'std'

  const opacity = isSelected ? 1 : 0.5

  const isStd = row.id === 'std'

  const unit = row?.id === 'im' ? 3 : 2

  return (
    <TableRow
      key={row.id}
      style={{ opacity }}
      sx={{
        '& td, & th': { border: 0 },
      }}
    >
      {open && (
        <TableCell padding="none" width="3%">
          {!isStd && (
            <Radio
              checked={isSelected}
              onChange={handleChangeRadio}
              value={row.id}
              key={row.id}
              name={row.indiceName}
              inputProps={{ 'aria-label': 'A' }}
              sx={{
                height: '30px',
              }}
            />
          )}
        </TableCell>
      )}
      <TableCellRoot component="th" scope="row" width="35%">
        <LabelGrid
          sx={{ padding: 0, height: '30px' }}
          text={row.title}
          description={row.descriptionTitle}
        />
        <div style={{ display: 'flex' }}>
          <LabelGrid
            sx={{ padding: 0 }}
            text={row.subtitle}
            description={row.descriptionSubTitle}
          />
          {row.warning && (
            <Tooltip title={row.warningText}>
              <Warning
                sx={{ color: 'red', cursor: 'pointer' }}
                fontSize="small"
              />
            </Tooltip>
          )}
        </div>
      </TableCellRoot>
      <TableCell align="left" width="15%">
        {!isStd ? (
          <span
            style={{
              color: row.currentOverride ? '#8ED1A5' : '#00ADE6',
              height: '30px',
            }}
          >
            {row.current}
          </span>
        ) : (
          <div style={{ height: 30 }} />
        )}
        <span
          style={{
            color: row.currentSurfaceOvrride ? '#8ED1A5' : '#00ADE6',
            height: '30px',
          }}
        >
          {row.currentSurface} m<sup>{unit}</sup>
        </span>
      </TableCell>
      {!isStd && (
        <>
          <TableCell align="left" width="15%">
            <ValueGrid
              id={row.id}
              open={open && isSelected}
              onChange={handleEdit}
              val={row.authorized}
              keyType={row.id}
              className={row.className}
              isSelected={isSelected}
              indiceName={row.indiceName}
              defaultValue={row.defaultAuthorized}
              type="number"
            />
            <span
              className={row.className}
              style={{
                height: '30px',
              }}
            >
              {row.authorizedSurface} m<sup>{unit}</sup>
            </span>
          </TableCell>
          <TableCell align="left" width="15%">
            <span style={{ color: row.differenceColor, height: '30px' }}>
              {plusSignNumber(Number(row.difference))}
            </span>
            <span style={{ color: row.differenceColorSurface, height: '30px' }}>
              {plusSignNumber(Number(row.differenceSurface))}m<sup>{unit}</sup>
            </span>
          </TableCell>
          <TableCell align="left" padding="none">
            {isSelected && (
              <TreeMapRoot
                width={123}
                height={61}
                built={row.currentSurface}
                unbuildable={std - row.authorizedSurface}
                available={row.differenceSurface}
                colors={{
                  built: '#00ADE6',
                  available: '#8ED1A5',
                  unbuildable: '#D8D8D8',
                }}
              />
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  )
}

export default BuildingArea
