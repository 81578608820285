import { getGeoPoint } from '@popety_io/popety-io-lib'

import { ApiClient } from '../../../../services'

const client = new ApiClient()

export type GetLandOpts = {
  landId?: string | number | string[] | number[]
  geoCenter?: string | string[]
  canAnalyse?: boolean
}

const getLandSummary = ({
  landId,
  geoCenter,
  canAnalyse = true,
}: GetLandOpts) => {
  if (geoCenter) {
    const centers = Array.isArray(geoCenter) ? geoCenter : [geoCenter]

    const geoPoints = centers
      .map(getGeoPoint)
      .filter(Boolean)
      .map((p) => [p?.lon, p?.lat].join(','))
      .join('_')

    const query = `GEO_CENTER:${geoPoints}`

    return client.get(`/land/${query}?canAnalyse=${canAnalyse}`)
  }

  return client.get(`/land/${landId}?canAnalyse=${canAnalyse}`)
}

const getCityGeojson = ({ city }: { city: string }) => {
  return client.get(`/cities/geojson/${city}`)
}

const getLandAdditionalData = ({ landId, ...body }: GetLandOpts) =>
  client.post(`/land/${landId}/details`, { ...body })

const getLandsOwner = (body: any) => {
  return client.post(`/owner/searchLand`, { ...body })
}

const summaryService = {
  getLandSummary,
  getLandAdditionalData,
  getLandsOwner,
  getCityGeojson,
}

export default summaryService
