import EnergyMapContent from './EnergyMapContent'
import EnergyMapController from './EnergyMapController'
import EnergyMapHover from './EnergyMapHover'

export { default } from './Energy'

export const EnergyMapConfig = {
  Content: EnergyMapContent,
  Controller: EnergyMapController,
  Hover: EnergyMapHover,
}
