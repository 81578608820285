import Box from '@mui/material/Box'
import { LeftMenu, useRouter, useI18n } from '@popety_io/popety-io-lib'

import useAutoCloseSidebar from '../../../../hooks/useAutoCloseSidebar'
import { getBuildingDetailsMenuData } from './BuildingDetailsMenu.data'

const BuildingDetailsMenu = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()
  const { onCloseSidebar } = useAutoCloseSidebar()

  const menus = getBuildingDetailsMenuData(t)

  const data = menus.map(({ page: _, ...menu }, i) => ({
    ...menu,
    active: !query.tab ? i === 0 : query.tab === menu.key,
    onClick: () => {
      updateQuery({
        tab: menu.key,
        fs: null,
        transactionCenter: null,
        title: null,
        faoCenter: null,
        transactionOpened: null,
        faoOpened: null,
        listingOpened: null,
        listingCenter: null,
      })
      onCloseSidebar()
    },
  }))

  return (
    <Box width={107} height="100%">
      <LeftMenu data={data as any} />
    </Box>
  )
}

export default BuildingDetailsMenu
