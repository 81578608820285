import { useState, useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import { Map, useRouter, useI18n } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import { estimateHistorySelector } from '../../redux'
import { addClusters } from './clusters.util'

import './EstimateHistory.style.css'

interface EstimateMapControllerProps {
  map: Map
  loaded?: boolean
}

const source = 'estimate-history'

const EstimateHistoryMapController = ({
  map,
  loaded,
}: EstimateMapControllerProps) => {
  const estimateHistory: any = useSelector(estimateHistorySelector)
  const { query } = useRouter()
  const [popups, setPopups] = useState<mapboxgl.Popup[]>([])
  const [newFeatures, setFeatures] = useState<any[]>([])
  const isHomePage = query?.tab === 'home'

  const { t } = useI18n()

  useEffect(() => {
    if (!map || !loaded || !isHomePage) return

    const newPopups: mapboxgl.Popup[] = []

    popups.forEach((popup) => popup.remove())
    newFeatures?.forEach((feature: any) => {
      const { properties } = feature

      const { price } = properties

      const coordinates = feature?.geometry?.coordinates?.slice()

      const popupContent = `
        <h4>${t('common.estimateion')}</h4>
        <p>${price}</p>
      `

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      })
        .setLngLat(coordinates)
        .setHTML(popupContent)
        .addTo(map)

      newPopups.push(popup)
    })

    setPopups(newPopups)

    return () => {
      // Remove all popups when the component unmounts
      newPopups.forEach((popup) => popup.remove())
    }
  }, [newFeatures])

  useEffect(() => {
    if (!isHomePage) {
      popups.forEach((popup) => popup.remove())
      setPopups([])
    }
  }, [isHomePage])

  useEffect(() => {
    if (!map || !loaded || isHomePage) return

    if (map.getSource(source)) {
      map.removeLayer('clusters')
      map.removeLayer('cluster-count')
      map.removeLayer('unclustered-point')
      map.removeSource(source)
    }
  }, [isHomePage, map, loaded])

  useEffect(() => {
    if (!map || !loaded || !isHomePage || !estimateHistory?.length) return

    addClusters(map, estimateHistory)

    map.on('load', () => addClusters(map, estimateHistory))
    map.on('mouseenter', 'clusters', () => {
      map.getCanvas().style.cursor = 'pointer'
    })
    map.on('mouseleave', 'clusters', () => {
      map.getCanvas().style.cursor = ''
    })
    map.on('mouseenter', 'unclustered-point', () => {
      map.getCanvas().style.cursor = 'pointer'
    })
    map.on('mouseleave', 'unclustered-point', () => {
      map.getCanvas().style.cursor = ''
    })
    map.on('click', 'clusters', (e: any) => {
      const features: any = map.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      })
      const clusterId = features[0]?.properties?.cluster_id

      const src = map.getSource(source) as any

      src.getClusterExpansionZoom(clusterId, (err: any, zoom: number) => {
        if (err) return

        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom,
        })
      })
    })
    map.on('moveend' as any, 'unclustered-point', (e: any) => {
      const { features } = e

      setFeatures(features)
    })

    return () => {
      map.off('load', () => addClusters(map, estimateHistory))
      map.off('mouseenter', 'clusters', () => {
        map.getCanvas().style.cursor = 'pointer'
      })
      map.off('mouseleave', 'clusters', () => {
        map.getCanvas().style.cursor = ''
      })
      map.off('mouseenter', 'unclustered-point', () => {
        map.getCanvas().style.cursor = 'pointer'
      })
      map.off('mouseleave', 'unclustered-point', () => {
        map.getCanvas().style.cursor = ''
      })
      map.off('moveend' as any, 'unclustered-point', (e: any) => {
        const { features } = e

        setFeatures(features)
      })
    }
  }, [isHomePage, loaded, estimateHistory])

  if (!isHomePage || !map || !loaded) return null

  return null
}

export default EstimateHistoryMapController
