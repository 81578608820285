import { createSelector } from '@reduxjs/toolkit'
import { addDays, isAfter, parseISO } from 'date-fns'

import { DEFAULT_PROFILE_IMG } from '../../../config'

const authSelector = (state) => state.auth

const authIsLoggedInSelector = (state) => state.auth.isLoggedIn

const authIsLoadingSelector = (state) => state.auth.isLoading

const authUserSelector = (state) => state.auth.user

const supportsSelector = (state) => state.auth.supports

const supportDataSelector = (state) => state.auth.supports?.support

const accountManagerDataSelector = (state) =>
  state.auth.supports?.accountManager

const userSelector = createSelector(authUserSelector, (user) => {
  if (!user?.id) return null

  const COMPARE_DAY = 90
  const timeCompare = user?.create_timestamp
    ? addDays(parseISO(user?.create_timestamp), COMPARE_DAY)
    : new Date()

  const isCreateTimeThanThreeMonthAgo = isAfter(timeCompare, new Date())

  return {
    ...user,
    name: `${user.first_name} ${user.last_name}`,
    companyLogo: user.company_logo || DEFAULT_PROFILE_IMG,
    isCreateTimeThanThreeMonthAgo,
  }
})

const loggedSelector = (state) => state.auth.logged

const appSelector = createSelector(userSelector, (user) => {
  try {
    if (!user) return 'brokerinfo'

    if (user.app) return user.app

    let app = 'brokerinfo'
    const userLandDev = user?.roles?.includes('User_landdev')

    if (userLandDev) {
      app = userLandDev ? 'landdev' : 'brokerinfo'
    }

    return app
  } catch (error) {
    console.error(error)

    return 'brokerinfo'
  }
})

const authRolesSelector = createSelector(authUserSelector, (user) => {
  return user?.roles || []
})

const isCompanyAdminSelector = createSelector(authRolesSelector, (roles) => {
  return roles.includes('Admin_company')
})

const landdevSelector = createSelector(appSelector, (app) => app === 'landdev')

const sharedSelector = createSelector(authUserSelector, (user) => {
  return user?.share_policy || {}
})

const isPopetyCompanySelector = createSelector(userSelector, (user) => {
  return !!user && user?.company_id === '1' /* POPETY company id */
})

const needVerifySelector = (state) => state.auth?.needVerify

const closedConfirmPhoneSelector = (state) => state.auth?.closedConfirmPhone

export {
  authSelector,
  authRolesSelector,
  userSelector,
  loggedSelector,
  appSelector,
  isCompanyAdminSelector,
  supportsSelector,
  landdevSelector,
  sharedSelector,
  isPopetyCompanySelector,
  authIsLoadingSelector,
  accountManagerDataSelector,
  supportDataSelector,
  needVerifySelector,
  closedConfirmPhoneSelector,
  authIsLoggedInSelector,
}
