/* eslint-disable max-lines */
import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SwipeableViews from 'react-swipeable-views'
import { Modal, useI18n, useRouter } from '@popety_io/popety-io-lib'
import { ModalProps } from '@popety_io/popety-io-lib/dist/components/Modal'

import { getMultipleLandLayersOultined } from '../../../LandDetails/redux/summary/land.layers'

import {
  estimateGeoCenterStrSelector,
  // submitEstimation,
  estimateLands,
  estimateSelector,
  clearEstimate,
  savePublicEstimateSummary,
} from '../../redux'
import LocationsSearch from './LocationsSearch'
import EstimateDwellings from './EstimateDwellings'
import EstimateForm from './EstimateForm'
import SelectBuilding from './SelectBuilding'

type EstimateSearchProps = {
  open: boolean
  onClose?: ModalProps['onClose']
  showCloseIcon?: boolean
  onCancelModal?: () => void
}

const EstimateSearch = ({
  open,
  onClose,
  showCloseIcon,
  onCancelModal,
}: EstimateSearchProps) => {
  const [index, setIndex] = useState<number>(0)
  const [value, setValue] = useState<any>()
  const dispatch = useDispatch()
  const { t } = useI18n()
  const geoCenter = useSelector(estimateGeoCenterStrSelector)
  const lands = useSelector(estimateLands)
  const { query, updateQuery } = useRouter()
  const estimate: any = useSelector(estimateSelector)
  const totalDwelling = useMemo(() => {
    const dwellings =
      estimate.buildings_administrative
        ?.flatMap((ba: any) => ba.addresses?.flatMap((a: any) => a.dwellings))
        .filter(Boolean) || []

    return dwellings?.length || 0
  }, [estimate])

  const landArea = useMemo(() => estimate?.lands?.[0]?.area, [estimate])
  const constructionYear = useMemo(
    () =>
      estimate?.buildings_administrative?.[0]?.building_year_max ||
      estimate?.buildings_administrative?.[0]?.building_year,
    [estimate],
  )

  const handleChangeIndex = (newIndex: number) => {
    setIndex(newIndex)
  }

  const handleOk = async () => {
    try {
      updateQuery({ ...query, geoCenter, tab: 'summary' })
      setIndex(0)
      setValue(undefined)
      onClose?.()

      dispatch(
        savePublicEstimateSummary({
          addressId: estimate?.addressId || query.addressId,
          lands: lands?.length ? lands : estimate?.lands,
          rooms: query.roomsNb || null,
          construction_date: query.constructionYear,
          area: query.livingArea,
          buildings: estimate?.buildings_administrative,
          type: query.propertyType || estimate?.type,
          address: estimate?.addresses?.[0],
          valueRange: estimate?.valueRange,
          geo_center: estimate?.geo_center,
          dwellingId: query.dwellingId,
          condition: query.condition,
          renovationYear: query?.renovationYear,
          estimate,
        }) as any,
      )
    } catch (error) {
      console.error(error)
    }
  }

  const onCancel = () => {
    if (index === 0) {
      onCancelModal?.()
    } else if (index === 2 && totalDwelling < 2) {
      setIndex(0)
    } else {
      setIndex(index - 1)
    }
  }

  const landsLayers: any = useMemo(() => {
    return getMultipleLandLayersOultined(lands)
  }, [lands])

  useEffect(() => {
    setIndex(0)
    setValue(undefined)

    dispatch(clearEstimate())
  }, [])

  useEffect(() => {
    if (Object.keys(query)?.length === 0) {
      setIndex(0)
      setValue(undefined)
    }
  }, [query])

  const regex = /Maison?/gi
  const isHouse = regex.test(estimate?.category || '')

  useEffect(() => {
    if (isHouse) {
      const dwelling =
        estimate?.buildings_administrative?.[0]?.addresses?.[0]?.dwellings?.[0]

      const buildingAdm = estimate?.buildings_administrative?.[0]

      setValue((prev: any) => ({
        ...prev,
        area: dwelling?.area,
        building_year: buildingAdm?.building_year_max,
        room_nb: dwelling?.room_nb,
      }))
    }
  }, [isHouse])

  return (
    <Modal
      top={50}
      open={open}
      keepMounted
      naked
      blur
      disableGutters
      showCloseIcon={false}
      maxWidth="sm"
      onClose={onCancelModal}
      sx={{
        zIndex: '3 !important',
        '.MuiDialog-paper': { mt: '-15vh' },
        '.MuiPaper-root': {
          width: '100%',
          maxHeight: 700,
          overflowY: 'auto',
          marginTop: 1,
        },
        '.MuiDialogActions-spacing': {
          justifyContent: 'space-between',
        },
      }}
      okText={index === 2 ? t('estimate.Créer estimation') : ''}
      onOk={handleOk}
      cancelText={index === 2 ? t('common.previous') : ''}
      onCancel={onCancel}
      okButtonProps={{ disabled: query.createEstimate === 'false' }}
    >
      <SwipeableViews animateHeight index={index}>
        {!query?.landId ? (
          <LocationsSearch
            showCloseIcon={showCloseIcon}
            onClose={onCancelModal}
            handleChangeIndex={handleChangeIndex}
            setValue={setValue}
          />
        ) : (
          <div style={{ minHeight: 700 }}>
            <SelectBuilding
              showCloseIcon={showCloseIcon}
              onClose={onCancelModal}
              handleChangeIndex={handleChangeIndex}
              setValue={setValue}
            />
          </div>
        )}

        <EstimateDwellings
          showCloseIcon={showCloseIcon}
          onClose={onCancelModal}
          handleChangeIndex={handleChangeIndex}
          setValue={setValue}
          geoCenter={geoCenter}
          landsLayers={landsLayers}
          value={value}
        />
        <EstimateForm
          value={value}
          landArea={landArea}
          geoCenter={geoCenter}
          landsLayers={landsLayers}
          constructionYear={constructionYear}
        />
      </SwipeableViews>
    </Modal>
  )
}

export default EstimateSearch
