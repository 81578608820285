import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProspectIcon from '@mui/icons-material/Assessment'
import UnProspectIcon from '@mui/icons-material/AssessmentOutlined'

import { useI18n, format } from '@popety_io/popety-io-lib'
import {
  prospectByLandIdSelector,
  addProspect,
  getProspectByLandIds,
  removeProspect,
} from '../../pages/Dashboard/redux'

export type ProspectLandOpts = {
  landId: string | string[]
  opacity?: number
}

const useProspect = ({ landId, opacity = 0.3 }: ProspectLandOpts) => {
  const prospects = useSelector(prospectByLandIdSelector)
  const dispatch = useDispatch<any>()

  const landIds = Array.isArray(landId) ? landId : [landId]

  const prospect = landIds?.map((id) => prospects[id]).filter(Boolean)
  const isProspect = !!prospect?.length

  const { t } = useI18n()

  const handleProspectToggle = (e: any) => {
    e.stopPropagation()

    if (!landId) return

    const toggleProspect = isProspect ? removeProspect : addProspect

    landIds.forEach((id) => {
      dispatch(toggleProspect(id))
    })
  }

  useEffect(() => {
    if (landId) {
      dispatch(getProspectByLandIds(landId))
    }
  }, [landId])

  const title = isProspect
    ? `${t('common.analysed', {
        name: prospect[0].name,
        date: format(prospect[0].date, 'P'),
      })}`
    : t('common.notAnalysed')

  const Icon = isProspect ? (
    <ProspectIcon color="primary" />
  ) : (
    <UnProspectIcon sx={{ opacity }} />
  )

  return { title, onClick: handleProspectToggle, Icon }
}

export default useProspect
