import React from 'react'
import Box from '@mui/material/Box'

import ImageCarousel from './ImageCarousel'
import EstimatedPrice from './EstimatedPrice'
import EstimationDetails from './EstimationDetails'
import EstimateNotes from './EstimateNotes'

const EstimateSummary = () => {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <ImageCarousel />
      <EstimatedPrice />
      <EstimationDetails />
      <EstimateNotes />
    </Box>
  )
}

export default EstimateSummary
