import { Box, Typography, styled } from '@mui/material'
import { FormField } from '@popety_io/popety-io-lib'

export const HeatingLabelRoot = styled(Typography)`
  font-weight: 500;
  color: #969696;
  font-size: 0.923rem;
`
export const HeatingValueRoot = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'edited',
})<{ edited?: boolean }>`
  /* font-weight: 500; */
  /* font-size: 0.923rem; */
  color: ${({ edited }) => (edited ? '#8ed1a5 !important' : '#041725')};
`
export const HistoryLinks = styled('a', {
  shouldForwardProp: (prop) => prop !== '$active',
})<{ $active?: boolean }>`
  cursor: ${({ $active }) => ($active ? 'pointer' : 'default')};
  color: ${({ $active }) => ($active ? '#00ade6' : 'black')};
`

export const HeatingValueFieldRoot = styled(FormField)`
  .MuiInputBase-root.MuiOutlinedInput-root {
    height: unset;
    padding-right: 0;
  }
  .MuiInputBase-root .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 2px 3px 1.5px 5px;
    font-size: 0.923rem;
  }
`

export const HeatingValueFieldBoxRoot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'retrieve',
})<{ retrieve?: boolean }>`
  display: flex;
  align-items: center;

  svg {
    transform: ${({ retrieve }) => (retrieve ? 'rotateY(180deg)' : 'unset')};
  }
`
