/* eslint-disable max-lines */
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import mapboxgl from 'mapbox-gl'
import {
  Map,
  useRouter,
  HistoryMapTileModal,
  useMediaQuery,
  useAsync,
  Modal,
  useI18n,
} from '@popety_io/popety-io-lib'
import transactionHistoryService from '../../../TransactionHistory/redux/transactionHistoryService'
import TransactionDetails from '../../../TransactionHistory/components/TransactionDetails'
import { regionsByNameSelector } from '../../../../components/LocationsInput'
import {
  getTransactionTiles,
  removeTransactionTiles,
} from '../../redux/transaction/transaction.layers'
import { estimateSelector } from '../../../Estimate/redux'
import { geoCenterSelector, landRegionSelector } from '../../redux'
import { buildingSelector } from '../../../BuildingDetails/redux'
import { getTransactionFilters } from './utils'

const fillStyle = 'layer_fill_transaction'

const FaoTiles = ({ map, loaded }: { map: Map; loaded?: boolean }) => {
  const { dispatch, data, loading } = useAsync<any[]>()
  const { t } = useI18n()
  const router = useRouter()
  const building = useSelector(buildingSelector)
  const estimate: any = useSelector(estimateSelector)
  const landGeoCenter = useSelector(geoCenterSelector)
  const geoCenter =
    building?.geo_center || landGeoCenter || estimate?.geo_center

  const [feature, setFeature] = useState<any>()
  const [popup, setPopup] = useState<mapboxgl.Popup>()
  const touchScreen = useMediaQuery('(hover: none)')
  const regionsByName = useSelector(regionsByNameSelector)
  const landRegion = useSelector(landRegionSelector)

  const isTransactionTab = router.query.tab === 'transaction'

  const defaultDate = regionsByName[landRegion]?.first_transaction_date

  const isEstimate = !!router?.query?.estimateId

  const filters = getTransactionFilters(
    router.query,
    geoCenter,
    defaultDate,
    isEstimate,
  )
  const query = filters && encodeURIComponent(JSON.stringify(filters))
  const { transactionCenter, title } = router.query

  const handleClick = (e: any) => {
    // Use the first found feature.

    dispatch(() =>
      transactionHistoryService.getTransactionsTileDetails(
        e.features.map(
          (f: { properties: { _key: string } }) => f.properties._key,
        ),
        query,
      ),
    )
  }

  const handleMouseMove = (e: any) => {
    map.getCanvas().style.cursor = 'pointer'
    if (popup && (!transactionCenter || !title)) {
      const coordinates = e.features[0].geometry.coordinates.slice()
      const str =
        e.features[0].properties._count > 1
          ? `${t('transaction.Transaction')}s`
          : t('transaction.Transaction')
      const description = `${e.features[0].properties._count} ${str}`

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(map)
    }
  }

  const handleMouseLeave = () => {
    map.getCanvas().style.cursor = ''

    if (popup && (!transactionCenter || !title)) {
      popup.remove()
    }
  }

  const addTransactionTiles = () => {
    try {
      getTransactionTiles({
        map,
        query,
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (touchScreen) setPopup(undefined)
  }, [touchScreen])

  useEffect(() => {
    if (router?.query?.tab !== 'transaction' && map && loaded) {
      removeTransactionTiles({ map })
    }
  }, [router?.query?.tab])

  useEffect(() => {
    setPopup(
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    )
  }, [])

  useEffect(() => {
    if (!map || !loaded || router?.query?.tab !== 'transaction') return

    addTransactionTiles()

    map.on('style.load', addTransactionTiles)
    map.on('click', fillStyle, handleClick)
    map.on('mousemove', fillStyle, handleMouseMove)
    map.on('mouseleave', fillStyle, handleMouseLeave)

    return () => {
      map.off('style.load', addTransactionTiles)
      map.off('click', fillStyle, handleClick)
      map.off('mousemove', fillStyle, handleMouseMove)
      map.off('mouseleave', fillStyle, handleMouseLeave)
    }
  }, [isTransactionTab, loaded, query, title, transactionCenter])

  useEffect(() => {
    if (data?.length) {
      setFeature(data[0])
    }
  }, [data])

  useEffect(() => {
    if (map && loaded && transactionCenter && popup && title) {
      const [lng, lat] = transactionCenter.split(',')

      map.flyTo({ center: [lng, lat], zoom: 17 })
      popup.setLngLat([lng, lat]).setHTML(title).addTo(map)
    } else if (map && loaded && !transactionCenter && popup && !title) {
      popup.remove()
    }
  }, [transactionCenter, map, loaded, popup, title])

  const handleClose = () => {
    setFeature(null)
  }

  if (!isTransactionTab || !map || !loaded) return null

  return (
    <>
      {!!feature && (
        <Modal
          open={!!feature}
          loading={loading}
          onClose={handleClose}
          title={data[0]?.reason || t('transaction.Transaction')}
        >
          <HistoryMapTileModal
            data={data}
            loading={loading}
            Details={TransactionDetails}
            previousText={t('common.previous')}
            nextText={t('common.next')}
            notFound={t('common.Not found')}
          />
        </Modal>
      )}
    </>
  )
}

export default FaoTiles
