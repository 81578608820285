import { Suspense, useMemo, useRef, useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import {
  FullscreenButton,
  ProgressIndicator,
  useRouter,
  useI18n,
  fetchOnce,
} from '@popety_io/popety-io-lib'
import { useSelector, useDispatch } from 'react-redux'

import {
  LandDetailsRoot,
  LeftContainer,
  RightContainer,
} from '../LandDetails/LandDetails.style'
import { buildingsByIdSelector } from '../LandDetails'
import { addAnalyzedBuilding } from '../BuildingDashboard'
import BuildingDetailsMap from './BuildingDetailsMap'
import useGetBuildingDetailsData from './BuildingDetails.data'
import { getBuildingDetailsMenuData } from './components/BuildingDetailsMenu'
import BuildingSearch from './components/BuildingSearch/BuildingSearch'
import { getBuildingSearchHistory, getBuildingSummary } from './redux'

const BuildingDetails = () => {
  const { query, updateQuery } = useRouter()
  const buildingIdStr = useSelector(buildingsByIdSelector)
  const [openSearch, setOpenSearch] = useState(!query.buidldingId)
  const { t } = useI18n()

  const leftContainerRef = useRef<HTMLDivElement>()
  const rightContainerRef = useRef<HTMLDivElement>()

  const dispatch: any = useDispatch()

  const pages = getBuildingDetailsMenuData(t)

  const buildingChanged = query.buildingId !== buildingIdStr

  useGetBuildingDetailsData()

  const content = useMemo(() => {
    return pages
      .filter((menu) => menu.show)
      .find((p: any, i) => (!query.tab ? i === 0 : p?.key === query.tab))?.page
  }, [query.tab])

  const fullscreen = query.fs === '1' || !content

  const handleClose = () => {
    setOpenSearch(false)
  }

  useEffect(() => {
    if (leftContainerRef.current) {
      const rect = leftContainerRef.current.getBoundingClientRect()

      if (rightContainerRef.current) {
        rightContainerRef.current.style.top = `${rect.top}px`
      }
    }
  }, [buildingIdStr])

  const handleToggle = () => {
    updateQuery({ fs: !fullscreen ? '1' : undefined })
  }

  useEffect(() => {
    fetchOnce('getBuildingSearchHistory', null, () => {
      dispatch(getBuildingSearchHistory())
    })
  }, [])

  useEffect(() => {
    updateQuery({ subTab: undefined })
  }, [])

  useEffect(() => {
    if (query.buildingId) {
      dispatch(
        addAnalyzedBuilding({
          buildingId: query.buildingId,
        }),
      )
    }
  }, [query.buildingId])

  useEffect(() => {
    if (query.buildingId && buildingChanged) {
      fetchOnce('getBuildingSummary', { buildingId: query.buildingId }, () => {
        dispatch(getBuildingSummary({ buildingId: query.buildingId }))
      })
    }
  }, [query.buildingId])

  useEffect(() => {
    setOpenSearch(!query.buildingId)
  }, [query.buildingId])

  return (
    <LandDetailsRoot bgcolor="background.paper">
      <BuildingSearch
        showCloseIcon={!!buildingIdStr}
        open={openSearch}
        onClose={handleClose}
      />

      <Box
        height="100%"
        display="flex"
        justifyContent={{ xs: 'flex-end', lg: 'flex-start' }}
        flexDirection={{ xs: 'column-reverse', lg: 'row' }}
        ref={leftContainerRef}
      >
        {content && (
          <LeftContainer
            bgcolor="background.paper"
            width={{ xs: '100%', lg: '58%' }}
            display={fullscreen ? 'none' : undefined}
            data-cy="land-detail-left-container"
          >
            <Suspense fallback={<ProgressIndicator circular />}>
              {content}
            </Suspense>
          </LeftContainer>
        )}

        <RightContainer
          height={{ xs: '100%', lg: '100%' }}
          width={{
            xs: '100%',
            lg: fullscreen ? 'calc(100% - 105px)' : '45.5%',
          }}
          ref={rightContainerRef}
          // position={{ xs: 'static', lg: 'fixed' }}
        >
          <Box position="relative" height="100%">
            {content && (
              <FullscreenButton
                fullScreen={fullscreen}
                onClick={handleToggle}
              />
            )}
            <BuildingDetailsMap fullscreen={fullscreen} />
          </Box>
        </RightContainer>
      </Box>
    </LandDetailsRoot>
  )
}

export default BuildingDetails
