/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
import { Fragment, ReactNode, useEffect, useState } from 'react'
import Divider from '@mui/material/Divider'
import { useDispatch, useSelector } from 'react-redux'
import Stack from '@mui/material/Stack'
import { Button, Modal, RichTextInput, useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material'

import {
  Note,
  NoteBox,
  InputContainer,
} from '../../../../../components/LandNote/LandNote.style'
import {
  removeEstimateNote,
  estimateActionHistoriesSelector,
  updateEstimateNote,
  estimateEditedSelector,
  saveEditedEstimate,
} from '../../../redux'
import EstimateNoteHeader from './EstimateNoteHeader'
import EstimateNoteBody from './EstimateNoteBody'
import { ButtonConfirm, InputRoot } from './EstimateNoteList.style'

type NoteListProps = {
  estimateId: any
  children?: ReactNode
  onMore?: (e?: any) => void
  defaultExtend?: boolean
  listNotesStyle?: SxProps
}

const EstimateNoteList = ({
  estimateId,
  children,
  onMore,
  defaultExtend = false,
  listNotesStyle,
}: NoteListProps) => {
  const { t } = useI18n()

  const actionHistories: any = useSelector(estimateActionHistoriesSelector)
  const estimateEdited: any = useSelector(estimateEditedSelector)

  const savedNotes = estimateEdited?.notes || []

  const dispatch = useDispatch<any>()

  const [openDelete, setOpenDelete] = useState<any>()
  const [action, setAction] = useState<any>()
  const [extended, setExtended] = useState<boolean>(defaultExtend)

  const estimateIds = (
    Array.isArray(estimateId) ? estimateId : [estimateId]
  ).map(Number)

  const actions = actionHistories.filter(
    (n: any) => estimateIds.includes(+n.estimate_id) && n.action === 'note',
  )

  const handleDelete = () => {
    dispatch(removeEstimateNote(openDelete.note_id))
    dispatch(
      saveEditedEstimate({
        id: estimateId,
        body: { ...estimateEdited, notes: [] },
      }),
    )
    setOpenDelete(undefined)
  }

  const handleOpen = (newNote: any) => {
    setAction(newNote)
  }

  const handleNoteUpdate = () => {
    if (action) dispatch(updateEstimateNote(action))
    setAction(undefined)
  }

  const handleClose = () => {
    setAction(undefined)
  }

  const handleNoteChange = (value: string) => {
    setAction({ ...action, content: value })
  }

  const preview = !!onMore
  const actionList: any = actions.slice(0, preview ? 5 : actions.length)

  useEffect(() => {
    setExtended(defaultExtend)
  }, [JSON.stringify(actionList), defaultExtend])

  return (
    <NoteBox>
      {actionList?.length > 0 && (
        <Fragment
          key={
            actionList[0].uid ||
            actionList[0].action + (actionList[0].note_id || actionList[0].id)
          }
        >
          <Note>
            <Stack direction="row" spacing={2}>
              {action?.action === 'note' ? (
                <InputRoot>
                  <RichTextInput
                    id="update-note"
                    placeholder={action?.content || ''}
                    value={action?.content || ''}
                    onChange={handleNoteChange}
                  />
                  <ButtonConfirm
                    variant="text"
                    onClick={handleNoteUpdate}
                    sx={{
                      color: '#00ade6',
                    }}
                  >
                    {t('common.update')}
                  </ButtonConfirm>
                </InputRoot>
              ) : (
                <>
                  <EstimateNoteHeader action={actionList[0]} />
                  <EstimateNoteBody
                    action={actionList[0]}
                    isNoteSaved={savedNotes.some(
                      (n: any) => n === actionList[0].note_id,
                    )}
                    estimateEdited={estimateEdited}
                    onDelete={setOpenDelete}
                    onOpen={handleOpen}
                  />
                </>
              )}
            </Stack>
          </Note>
        </Fragment>
      )}

      {children}

      <Modal
        onOk={handleDelete}
        open={!!openDelete}
        onClose={() => setOpenDelete(null)}
        okText={t('common.yes')}
        cancelText={t('common.no')}
        onCancel={() => setOpenDelete(null)}
        title={t('common.deleteNoteTitle')}
      >
        {t('common.deleteNotes')}
      </Modal>
    </NoteBox>
  )
}

export default EstimateNoteList
