import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { useI18n, format, Driver, fetchOnce } from '@popety_io/popety-io-lib'
import {
  // monthlyAverageExposureSelector,
  sunDateSelector,
  sunlightHoursSelector,
  sunlightSummerHoursSelector,
  sunlightSummerTimesSelector,
  sunlightTimesSelector,
  sunlightWinterHoursSelector,
  sunlightWinterTimesSelector,
} from '../redux'
// import MonthlyAverageExposure from '../../../components/MonthlyAverageExposure'
import { userSelector } from '../../Login'
import { updateTutorials } from '../../Account'
import SunlightTimeBox from './SunlightTimeBox'
// import PotentialSolar from './PotentialSolar'
import getDriverInstructions from './driverInstructions'

const Sunlight = () => {
  const { t } = useI18n()
  // const { query } = useRouter()
  // const monthlyAverageExposure = useSelector(monthlyAverageExposureSelector)
  const sunlightDate = useSelector(sunDateSelector)
  const sunlightTime = useSelector(sunlightTimesSelector)
  const sunlightHours = useSelector(sunlightHoursSelector)

  const sunlightWinterTimes = useSelector(sunlightWinterTimesSelector)
  const sunlightWinterHours = useSelector(sunlightWinterHoursSelector)

  const sunlightSummerTimes = useSelector(sunlightSummerTimesSelector)
  const sunlightSummerHours = useSelector(sunlightSummerHoursSelector)
  const user = useSelector(userSelector)

  const todayDate = new Date()
  const selectedDate = new Date(sunlightDate)

  const [isToday, setIsToday] = useState<boolean>()
  const [displayDriver, setDisplayDriver] = useState(false)
  const dispatch = useDispatch<any>()

  useEffect(() => {
    setIsToday(
      todayDate.getDate() === selectedDate.getDate() &&
        todayDate.getMonth() === selectedDate.getMonth() &&
        todayDate.getFullYear() === selectedDate.getFullYear(),
    )
  }, [sunlightDate])

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedSunlightDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedSunlightDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  return (
    <Box id="plot-sunlight">
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={5}
        pb={{ xs: 8, lg: 4 }}
      >
        <SunlightTimeBox
          sunlightTime={sunlightTime}
          sunlightHours={sunlightHours}
          title={`${
            isToday ? `${t('land.sun.today').toUpperCase()} - ` : ''
          }  ${format(sunlightDate, 'PPP')}`}
        />

        <SunlightTimeBox
          sunlightTime={sunlightWinterTimes}
          sunlightHours={sunlightWinterHours}
          title={`${t('land.sun.winter').toUpperCase()} - ${format(
            sunlightWinterTimes?.date,
            'dd MMMM',
          )}`}
        />

        <SunlightTimeBox
          sunlightTime={sunlightSummerTimes}
          sunlightHours={sunlightSummerHours}
          title={`${t('land.sun.summer').toUpperCase()} - ${format(
            sunlightSummerTimes?.date,
            'dd MMMM',
          )}`}
        />
      </Box>
      {/* {!query?.buildingId && <PotentialSolar />} */}
      {/* {monthlyAverageExposure?.length ? (
        <MonthlyAverageExposure data={monthlyAverageExposure} />
      ) : null} */}
      <Driver instructions={getDriverInstructions(t)} display={displayDriver} />
    </Box>
  )
}

export default Sunlight
