/* eslint-disable max-lines */
import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { ContentTitle, useI18n, InfoTooltip } from '@popety_io/popety-io-lib'
import { InfoOutlined } from '@mui/icons-material'
import {
  potentialSolar,
  potentialSolarColor,
} from '../../LandDetails/Building/Building.utils'
import { getIdcColor, getMinergieColor } from './utils'

const EnergyHeader = ({ building }: { building: any }) => {
  const { t } = useI18n()

  const solarAverage = potentialSolar(t, building?.solar_average_exposure)
  const solarAverageColor = potentialSolarColor(
    building?.solar_average_exposure,
  )
  const minergieColor = getMinergieColor(building?.minergie)
  const idcColor = getIdcColor(building?.heat_consumption_index)

  const energyHeaderData = useMemo(
    () => [
      {
        title: building.minergie ? (
          <Typography
            color={minergieColor || '#000000'}
            fontSize="1.846rem"
            fontWeight={500}
          >
            {building.minergie}
          </Typography>
        ) : (
          '-'
        ),
        subTitle: (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {t('building.energy.label')}
            <InfoTooltip
              infos={[
                {
                  text: (
                    <Typography
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 1,
                        gap: '2px',
                      }}
                    >
                      <span>{t('common.header_title.label')}</span>
                      <ul>
                        <li style={{ color: '#A62115' }}>
                          {t(
                            'common.minergie.Minergie (Indice Minergie thermique 38 kWh/m2a)',
                          )}
                        </li>{' '}
                        <li style={{ color: '#265DAA' }}>
                          {t(
                            'common.minergie.Minergie-P (Indice Minergie thermique 30 kWh/m2a)',
                          )}
                        </li>{' '}
                        <li style={{ color: '#FAD978' }}>
                          {t(
                            'common.minergie.Minergie-A (Indice Minergie thermique 0 kWh/m2a)',
                          )}
                        </li>
                      </ul>
                      <span>
                        {t(
                          'common.footer_title.Le complément ECO indique que les thématiques de la santé et de l’écologie sont prises en compte',
                        )}
                      </span>
                    </Typography>
                  ),
                },
              ]}
            >
              <InfoOutlined sx={{ fontSize: 12, marginLeft: 0.5 }} />
            </InfoTooltip>
          </Box>
        ),
      },
      {
        title: building.heat_consumption_index ? (
          <Typography
            color={idcColor || '#000000'}
            fontSize="1.846rem"
            fontWeight={500}
          >
            {building.heat_consumption_index}
          </Typography>
        ) : (
          '-'
        ),
        subTitle: (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {t('building.energy.IDC')}
            <InfoTooltip
              infos={[
                { text: t('common.description.idc').replace(/\./g, '.\n') },
              ]}
            >
              <InfoOutlined sx={{ fontSize: 12, marginLeft: 0.5 }} />
            </InfoTooltip>
          </Box>
        ),
      },
      {
        title: (
          <Typography
            color={solarAverageColor || '#000000'}
            fontSize="1.846rem"
            fontWeight={500}
          >
            {solarAverage}
          </Typography>
        ),
        subTitle: (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {t('building.energy.Solar Potential')}
            <InfoTooltip
              infos={[
                {
                  text: (
                    <Typography
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 1,
                        gap: '2px',
                      }}
                    >
                      <span>{t('land.sunlight.aptitudeSolaire-desc1')}</span>
                      <span>{t('land.sunlight.aptitudeSolaire-desc2')}</span>
                      <span>
                        {t('land.sunlight.aptitudeSolaire-desc-weak')}
                      </span>
                      <span>{t('land.sunlight.aptitudeSolaire-desc-mid')}</span>
                      <span>
                        {t('land.sunlight.aptitudeSolaire-desc-good')}
                      </span>
                      <span>
                        {t('land.sunlight.aptitudeSolaire-desc-very-good')}
                      </span>
                      <span>
                        {t('land.sunlight.aptitudeSolaire-desc-excellent')}
                      </span>
                    </Typography>
                  ),
                },
              ]}
            >
              <InfoOutlined
                sx={{ fill: '#969696', fontSize: 12, marginLeft: 0.5 }}
              />
            </InfoTooltip>
          </Box>
        ),
      },
    ],

    [t, building],
  )

  return (
    <Box
      pt={1}
      sx={{
        display: 'unset',
      }}
    >
      <Box
        position="sticky"
        top={0}
        pt={1}
        zIndex={10}
        bgcolor="background.paper"
      >
        <ContentTitle m={1} data={energyHeaderData} gap={3} />
      </Box>
    </Box>
  )
}

export default EnergyHeader
