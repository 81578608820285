import { Box } from '@mui/material'
import { PopetyMaps, getGeoPoint } from '@popety_io/popety-io-lib'
import { FC } from 'react'
import { MAPBOX_TOKEN, SATELLITE_MAPSTYLE } from '../../../../config'

interface IProps {
  geo_center?: string
  clean_geo_center?: { lon: number; lat: number }
  data: any
  zoom: number
  style?: string
}

const ListingDetailMap: FC<IProps> = ({
  geo_center,
  clean_geo_center,
  data,
  zoom,
  style = SATELLITE_MAPSTYLE,
  ...other
}) => (
  <Box width={{ xs: '100vw', md: 'auto' }} height={{ md: '100%', xs: '300px' }}>
    <PopetyMaps
      {...other}
      token={MAPBOX_TOKEN}
      style={style}
      center={
        clean_geo_center ||
        (getGeoPoint(geo_center) as {
          lon: number
          lat: number
        })
      }
      data={data}
      zoom={zoom}
    />
  </Box>
)

export default ListingDetailMap
