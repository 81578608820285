import { memo, useEffect, useState } from 'react'
import { ExternalLinks, useRouter, Select } from '@popety_io/popety-io-lib'
import { BoxProps } from '@mui/material'
import { useSelector } from 'react-redux'
import { regionsByNameSelector } from '../LocationsInput'
import { getExternalLinks } from '../../utils'

export type ExternalDataLinksProps = BoxProps & {
  data?: { lands?: any[]; buildings?: any[]; [k: string]: any }
  split?: boolean
  layout?: 'vertical' | 'horizontal'
}
const ExternalDataLinks = ({
  data,
  layout = 'horizontal',
  ...other
}: ExternalDataLinksProps) => {
  const { query, updateQuery } = useRouter()

  const { selectedLand } = query

  const regionsByName = useSelector(regionsByNameSelector)

  const groupedData = data?.egid ? data?.buildings : data?.lands

  const options = (groupedData || [data]).filter(Boolean)

  const [selectedData, setSelectedData] = useState(options[0])

  useEffect(() => {
    setSelectedData(options[options.length - 1])
  }, [JSON.stringify(options), options.length])

  useEffect(() => {
    if (selectedLand) {
      setSelectedData(
        options?.find(
          (el: { code_number: string }) =>
            el?.code_number === query?.selectedLand,
        ),
      )
    } else {
      updateQuery({ selectedLand: options[0]?.code_number })
    }
  }, [selectedLand])

  const handleChange = (newLand: any) => {
    updateQuery({ selectedLand: newLand })
  }

  if (!data) return null

  const region = regionsByName[selectedData?.region_name]
  const links = getExternalLinks(selectedData || options[0], region)

  const code = data.egid || data.code_number
  const codeNums = (Array.isArray(code) ? code : [code]).filter(Boolean)
  const key = data?.egid ? 'egid' : 'code_number'

  return (
    <>
      {codeNums?.length > 1 && (
        <Select
          sx={{ marginBottom: 1 }}
          id="demo-controlled-open-select"
          onChange={handleChange as any}
          options={codeNums}
          value={selectedLand}
          mapping={{ label: key, value: key }}
        />
      )}
      <ExternalLinks
        layout={layout}
        {...other}
        links={links}
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 86px)',
          justifyItems: 'center',
          alignItems: 'center',
        }}
      />
    </>
  )
}

export default memo(ExternalDataLinks)
