/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useI18n, useRouter, Select } from '@popety_io/popety-io-lib'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid'
import { RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { estimateSelector } from '../../redux'

import EstimateSearchMap from '../EstimateSearchMap'
import { EstimateSearchRoot, SearchForm } from './EstimateSearch.style'
import { validateEstimateForm } from './utils'

const EstimateForm = ({
  value,
  showCloseIcon,
  onClose,
  geoCenter,
  landsLayers,
  landArea,
  constructionYear,
}: any) => {
  const { t } = useI18n()
  const { updateQuery } = useRouter()
  const estimate: any = useSelector(estimateSelector)
  const regex = /Maison?/gi

  const isHouse = regex.test(estimate?.category || '')

  const address = value?.address?.split(',')
  const geoCenterSplit = geoCenter?.split(',')
  const [values, setValues] = useState({
    propertyType: '',
    livingArea: value?.area || '',
    constructionYear: constructionYear || value?.building_year || '',
    // renovationYear: value?.renovation_year || '',
    roomsNb: value?.room_nb || '',
    numberOfBathrooms: value?.bathroom_nb || '',
    landArea: landArea || '',
    condition: undefined,
  })

  const [errors, setErrors]: any = useState()

  useEffect(() => {
    if (!values) return

    const err = validateEstimateForm(values, values?.propertyType === 'house')

    if (Object.keys(err).length) {
      return setErrors({ ...err })
    }
  }, [values])

  const optionsBathrooms = Array.from(Array(20).keys()).map((value) => ({
    label: value === 0 ? 'None' : value,
    value: value === 0 ? '' : value,
  }))

  const optionsRooms = Array.from(Array(40).keys()).map((value) => ({
    label: value === 0 ? 'None' : (value + 1) / 2,
    value: value === 0 ? '' : (value + 1) / 2,
  }))

  const optionsCondition = [
    {
      label: t('common.renovation_needed'),
      value: 'renovation_needed',
    },
    { label: t('common.well_maintained'), value: 'well_maintained' },
    {
      label: t('common.new_or_recently_renovated'),
      value: 'new_or_recently_renovated',
    },
  ]

  useEffect(() => {
    if (!value) return

    setValues({
      ...values,
      livingArea: value?.area || '',
      constructionYear: constructionYear || value?.building_year || '',
      // renovationYear: value?.renovation_year || '',
      roomsNb: value?.room_nb || '',
    })

    updateQuery({
      livingArea: value?.area,
      constructionYear: constructionYear || value?.building_year,
      renovationYear: value?.renovation_year,
      roomsNb: value?.room_nb,
    })
  }, [value])

  useEffect(() => {
    if (!value) return

    setValues((pre) => ({
      ...pre,
      landArea: values?.propertyType === 'house' ? landArea : null,
    }))
    updateQuery({
      landArea: values?.propertyType === 'house' ? landArea : null,
    })
  }, [landArea, values?.propertyType])

  useEffect(() => {
    if (!estimate?.category) return

    if (isHouse) {
      setValues((pre) => ({ ...pre, propertyType: 'house' }))
      updateQuery({
        propertyType: 'house',
        propertyCategory: 'House',
      })
    } else {
      setValues((pre) => ({ ...pre, propertyType: 'apartment' }))
      updateQuery({
        propertyType: 'apartment',
        propertyCategory: 'Apartment',
      })
    }
  }, [estimate])

  const handleChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      updateQuery({ [name]: event.target.value })
      setValues({ ...values, [name]: event.target.value })
      setErrors()
    }

  const handleSelectChange = (name: string) => (event: any) => {
    updateQuery({ [name]: event.value })
    setValues({ ...values, [name]: event.value })
    setErrors()
  }

  useEffect(() => {
    updateQuery({ createEstimate: !errors })
  }, [errors])

  if (!geoCenter) return null

  return (
    <EstimateSearchRoot sx={{ width: { xs: '300px', sm: '500px' } }}>
      <Paper className="Title">
        <Typography
          component="h2"
          fontWeight="700"
          fontSize={16}
          textTransform="uppercase"
        >
          {t(`estimate.Entrez les détails du bien à estimer`)}
        </Typography>

        {showCloseIcon && (
          <IconButton aria-label="close" onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        )}
      </Paper>
      <Box
        sx={{
          padding: '16px 24px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Box
              sx={{
                height: '100px !important',
              }}
            >
              <EstimateSearchMap
                lat={geoCenterSplit?.[1]}
                lon={geoCenterSplit?.[0]}
                data={landsLayers}
                zoom={16}
              />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Typography fontWeight={600} fontSize={15}>
                {address?.[0]}
              </Typography>
              <Typography fontSize={13}>{address?.[1]}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            paddingTop: '16px',
            paddingBottom: '8px',
          }}
        >
          {value?.administrative_nb && (
            <Typography fontWeight={600} fontSize={15}>
              {value?.floor}
              {value?.floor === 1 ? t('estimate.er') : ''}
              {value?.floor === 2 ? t('estimate.nd') : ''}
              {value?.floor === 3 ? t('estimate.rd') : ''}
              {value?.floor > 3 ? t('estimate.th') : ''} {t('building.Etage')}{' '}
              {value?.administrative_nb}
            </Typography>
          )}
        </Box>
        <SearchForm component="form" height="auto">
          <RadioGroup
            aria-label="property-type"
            name="property-type"
            value={values?.propertyType}
            onChange={(e: any) => handleChange('propertyType')(e)}
            sx={{ display: 'flex', flexDirection: 'row' }}
          >
            <FormControlLabel
              value="apartment"
              control={<Radio />}
              label={t('common.Apartment')}
            />
            <FormControlLabel
              value="house"
              control={<Radio />}
              label={t('common.House')}
            />
          </RadioGroup>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label={t('land.financialAnalysis.livingArea')}
                type="number"
                value={values?.livingArea}
                error={!!errors?.livingArea}
                helperText={errors?.livingArea}
                onChange={(e: any) => handleChange('livingArea')(e)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                label={t('common.landRadarInfo.buildingYear')}
                type="number"
                value={values?.constructionYear}
                error={!!errors?.constructionYear}
                helperText={errors?.constructionYear}
                onChange={(e: any) => handleChange('constructionYear')(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                required
                value={
                  values?.numberOfBathrooms === undefined
                    ? ''
                    : values?.numberOfBathrooms.toString()
                }
                onChange={(e: any) =>
                  handleSelectChange('numberOfBathrooms')(e)
                }
                size="small"
                placeholder={t('common.numberOfBathrooms')}
                label={t('common.numberOfBathrooms')}
                options={optionsBathrooms}
                error={!!errors?.numberOfBathrooms}
                helperText={errors?.numberOfBathrooms}
                fullWidth
                name="numberOfBathrooms"
              />
              {/* <TextField
                label={t('common.numberOfBathrooms')}
                type="number"
                value={values?.numberOfBathrooms}
                onChange={(e: any) => handleChange('numberOfBathrooms')(e)}
              /> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                required
                value={
                  values?.roomsNb === undefined
                    ? ''
                    : values?.roomsNb.toString()
                }
                onChange={(e: any) => handleSelectChange('roomsNb')(e)}
                size="small"
                placeholder={t('land.history.rooms_nb')}
                label={t('land.history.rooms_nb')}
                options={optionsRooms}
                error={!!errors?.roomsNb}
                helperText={errors?.roomsNb}
                fullWidth
                name="roomsNb"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                required
                value={values?.condition === undefined ? '' : values?.condition}
                onChange={(e: any) => handleSelectChange('condition')(e)}
                size="small"
                placeholder={t('common.condition')}
                label={t('common.condition')}
                options={optionsCondition}
                error={!!errors?.condition}
                helperText={errors?.condition}
                name="condition"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={values?.propertyType !== 'house'}
                label={t('common.search.details.Land area')}
                required={values?.propertyType === 'house'}
                type="number"
                value={Number(values?.landArea)}
                onChange={(e: any) => handleChange('landArea')(e)}
                error={!!errors?.landArea}
                helperText={errors?.landArea}
              />
            </Grid>
          </Grid>
        </SearchForm>
      </Box>
    </EstimateSearchRoot>
  )
}

export default EstimateForm
