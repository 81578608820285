/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import authService from './authService'
import twoFactorAuthService from './twoFactorAuthService'

const initialState = {
  user: JSON.parse(localStorage.getItem('user')),
  supports: [
    {
      id: '1',
      name: 'phone',
      create_timestamp: '2022-09-02T12:24:16.645Z',
      update_timestamp: '2023-02-07T15:16:10.026Z',
      value: {
        fr: '+41 78 205 87 89',
        en: '+41 78 205 87 89',
        de: '+41 78 242 75 33',
        it: '+41 78 205 87 89',
      },
    },
    {
      id: '2',
      name: 'whatsapp',
      create_timestamp: '2022-09-02T12:24:16.646Z',
      update_timestamp: '2023-02-07T15:16:10.085Z',
      value: {
        en: '+41 78 205 87 89',
        fr: '+41 78 205 87 89',
        de: '+41 78 205 87 89',
        it: '+41 78 205 87 89',
      },
    },
    {
      id: '3',
      name: 'email',
      create_timestamp: '2022-09-02T12:24:16.651Z',
      update_timestamp: '2023-02-07T15:16:10.168Z',
      value: {
        en: 'support@popety.com',
        fr: 'support@popety.com',
        de: 'support@popety.com',
        it: 'support@popety.com',
      },
    },
    {
      id: '4',
      name: 'openingHours',
      create_timestamp: '2022-09-02T12:24:16.626Z',
      update_timestamp: '2023-02-07T15:16:10.258Z',
      value: {
        fr: 'Lun-Ven 9h-18h',
        en: 'Mon-Fri 9h-18h',
        de: 'Mo-Fr 9.00-18.00 Uhr',
        it: 'Lun-Ven 9am-6pm',
      },
    },
  ],
  isLoading: false,
  isLoggedIn: !!localStorage.getItem('jwt'),
  needVerify: false,
  closedConfirmPhone: localStorage.getItem('closedConfirmPhone') === 'true',
}

export const login = createAsyncThunk('auth/login', authService.login)

export const getPublicSupport = createAsyncThunk(
  'auth/getPublicSupport',
  authService.getPublicSupport,
)

export const getSupports = createAsyncThunk(
  'auth/getSupports',
  authService.getSupports,
)

export const verify2faCode = createAsyncThunk(
  'auth/verify2fa',
  twoFactorAuthService.verify2faCode,
)

export const resend2FaToSMS = createAsyncThunk(
  'auth/resend2FaToSMS',
  twoFactorAuthService.resend2FaToSMS,
)

export const confirmPhone = createAsyncThunk(
  'auth/confirmPhone',
  twoFactorAuthService.confirmPhone,
)

const handleLoginFulfilled = (state, action) => {
  const { data, errors } = action.payload || {}

  state.needVerify = false
  if (data) {
    state.user = data.user
    const userLandDev = data.user?.roles.includes('User_landdev')

    if (userLandDev) {
      state.user = {
        ...state.user,
        app: userLandDev ? 'landdev' : 'brokerinfo',
      }
    }

    state.logged = true
  }

  if (errors) {
    state.user = null
    state.logged = false

    state.user = { ...state.user, app: 'brokerinfo' }
  }
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeApp: (state, action) => {
      state.user = { ...state.user, app: action.payload }
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    forgetAuthUser: (state) => {
      state.user = null
      state.isLoggedIn = false

      localStorage.removeItem('jwt')
      localStorage.removeItem('user')
    },
    updateLocalUser: (state, action) => {
      if (state.user) {
        state.user = { ...state.user, ...action.payload }
        localStorage.setItem('user', JSON.stringify(state.user))
      }
    },
    logout: () => {
      localStorage.removeItem('user-details')
      localStorage.removeItem('enabled2Fa')
      localStorage.removeItem('closedConfirmPhone')
      // TODO: From here we can take action only at this "auth" state
      // But, as we have taken care of this particular "logout" action
      // in rootReducer, we can use it to CLEAR the complete Redux Store's state
    },

    setCloseConfirmPhone: (state, { payload }) => {
      state.closedConfirmPhone = payload
      if (payload) {
        localStorage.setItem('closedConfirmPhone', 'true')
      } else {
        localStorage.removeItem('closedConfirmPhone')
      }
    },
  },
  extraReducers: (builder) => {
    // Handle async actions here, not in reducers
    builder
      .addCase(login.fulfilled, (state, action) => {
        const { data, errors } = action.payload || {}

        if (data) {
          if (data.needVerify) {
            state.needVerify = true
          } else {
            state.user = data.user
            const userLandDev = data.user?.roles.includes('User_landdev')

            if (userLandDev) {
              state.user = {
                ...state.user,
                app: userLandDev ? 'landdev' : 'brokerinfo',
              }
            }

            state.logged = true
          }

          if (data.confirmedPhone) {
            localStorage.setItem('closedConfirmPhone', 'true')
          }
        }

        if (errors) {
          state.user = null
          state.logged = false

          state.user = { ...state.user, app: 'brokerinfo' }
        }
      })
      .addCase(verify2faCode.fulfilled, (state, action) =>
        handleLoginFulfilled(state, action),
      )
      .addCase(confirmPhone.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data && data?.user?.phone) {
          state.user = { ...state.user, phone: data?.user?.phone }
        }
      })
      .addCase(getSupports.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getSupports.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.isLoading = false
        if (data) state.supports = data
      })
      .addCase(getSupports.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getPublicSupport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPublicSupport.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.isLoading = false
        if (data) state.supports = data
      })
      .addCase(getPublicSupport.rejected, (state) => {
        state.isLoading = false
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  forgetAuthUser,
  changeApp,
  updateLocalUser,
  logout,
  setCloseConfirmPhone,
} = auth.actions

export const authReducer = {
  auth: auth.reducer,
}
