/* eslint-disable max-lines */
import {
  fetchOnce,
  fromFilter,
  useRouter,
  getRadiusGeoJSON,
  getDate,
} from '@popety_io/popety-io-lib'
import subMonths from 'date-fns/subMonths'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { regionsByNameSelector } from '../../components/LocationsInput'
import {
  landSelector,
  getLandAdditionalData,
  // getLandFaos,
  // getLandListings,
  // getLandTransactions,
  groupingIdSelector,
  getFinancialAnalysis,
  getFinancialDefault,
  getFaosAround,
  getTransactionsAround,
  getListingAround,
  getPoisAround,
  getPoiCategories,
  getBuildings,
  getLandUsePlansDetails,
  getLandUsePlanDocuments,
  getFutureLandUsePlanDocuments,
  getDifferenceLandUsePlanDocuments,
  getAllAssignementsOverideById,
  buildingsSelector,
  isBuildingEditedSelector,
  handleEditedBuildings,
  countLandListings,
  getFaoAggs,
  getTransactionAggs,
  getListingAggs,
  landRegionSelector,
  getPotentialSolar,
  getAllGroupingAssignementsOverideById,
  getFaosByIds,
  getTransactionsByIds,
  getListingsByIds,
  getWalkingIsochrone,
  getBikingIsochrone,
  getDrivingIsochrone,
} from './redux'
import { getAltimetry } from './redux/geo'

const getLandDistanceLimit = (land: any) => {
  try {
    const isBuildable = land.land_use_plans?.find(
      (lup: any) => lup.building_area,
    )

    if (!isBuildable) return undefined

    let distanceLimit

    if (land.lands) {
      // Grouping distanceLimit
      const maxLandUsePlan = land.land_use_plans.reduce(
        (prev: any, current: any) =>
          prev.distance_limit > current.distance_limit ? prev : current,
      )

      distanceLimit = maxLandUsePlan?.distance_limit || 3
    } else {
      // single land distanceLimit
      distanceLimit =
        land.land_use_plans?.[0]?.distanceLimit_overide?.value ||
        land.land_use_plans?.[0]?.distance_limit ||
        land.land_use_plans?.[0]?.distance_limit_estimate
    }

    return distanceLimit
  } catch (error) {
    console.error(error)
  }
}

const useGetLandDetailsData = () => {
  const land: any = useSelector(landSelector)
  const groupingId = useSelector(groupingIdSelector)
  const dispatch: any = useDispatch()
  const { query } = useRouter()
  const landUsePlanStatus = query.landUsePlanStatus || 'now'
  const buildings = useSelector(buildingsSelector)
  const isBuildingEdited = useSelector(isBuildingEditedSelector)
  const landRegion = useSelector(landRegionSelector)
  const regionsByName = useSelector(regionsByNameSelector)

  const regionId = land?.region_id
  const landId = land?.land_id
  const landuseplanIds = land?.land_use_plans
    ?.map((l: any) => l.id)
    ?.filter(Boolean)
  const faosIds =
    land?.building_permits?.map((f: any) => f.id)?.filter(Boolean) || []
  const transactionsIds =
    land?.transactions?.map((f: any) => f.id)?.filter(Boolean) || []
  const listingsIds =
    land?.listings?.map((f: any) => f.id)?.filter(Boolean) || []

  useEffect(() => {
    if (listingsIds?.length) {
      fetchOnce('landListings', listingsIds, () => {
        dispatch(getListingsByIds({ listingsIds }))
      })
    }
  }, [listingsIds])

  useEffect(() => {
    if (transactionsIds?.length) {
      fetchOnce('landTransactions', transactionsIds, () => {
        dispatch(getTransactionsByIds({ transactionsIds }))
      })
    }
  }, [transactionsIds])

  useEffect(() => {
    if (faosIds?.length) {
      fetchOnce('landFaos', faosIds, () => {
        dispatch(getFaosByIds({ faosIds }))
      })
    }
  }, [faosIds])

  useEffect(() => {
    if (!landId) return

    const distanceLimit = getLandDistanceLimit(land)

    const input = {
      landId,
      landuseplanIds,
      regionId,
      distanceLimit,
    }

    dispatch(getLandAdditionalData(input))

    // LISTING

    dispatch(countLandListings({ landId, dealType: 'rent' }))
    dispatch(countLandListings({ landId, dealType: 'buy' }))

    // LIVABILITY
    fetchOnce('getPoisAround', landId, () => {
      dispatch(getPoisAround({ landId }))
    })
    fetchOnce('getPoiCategories', landId, () => {
      dispatch(getPoiCategories({ landId }))
    })

    // SunLight
    dispatch(getPotentialSolar(landId))

    // GEO

    fetchOnce(
      'getAltimetry',
      {
        landId: land.land_id,
        precision: Math.max(1, Math.round(land.area / 1000)),
        region: land.region_name,
      },
      () => {
        dispatch(
          getAltimetry({
            landId: land.land_id,
            precision: Math.max(1, Math.round(land.area / 1000)),
            region: land.region_name,
          }),
        )
      },
    )

    // BUILDINGS
    if (land.buildings) {
      dispatch(getBuildings({ landId, buildings: land.buildings }))
    }

    // FINANCIAL
    dispatch(getFinancialAnalysis({ groupingId, landId }))

    // UNDER EXPLOITED
    if (land.land_use_plans) {
      dispatch(
        getLandUsePlansDetails({ landUsePlans: land.land_use_plans }),
      ).then(() => {
        if (!groupingId) {
          dispatch(
            getAllAssignementsOverideById({
              landUsePlanStatus,
              landUsePlans: land.land_use_plans,
              landId,
            }),
          )
        } else {
          dispatch(
            getAllGroupingAssignementsOverideById({
              landUsePlanStatus,
              landUsePlans: land.land_use_plans,
              groupingId,
            }),
          )
        }
      })
    }
    // if (land?.groupingId) {
    //   dispatch(getUserGroupingOverideValuesById())
    // } else {

    // }

    // Isochrone
    dispatch(
      getWalkingIsochrone({
        polygons: true,
        lon: land?.geo_center?.lon,
        lat: land?.geo_center?.lat,
        type: 'walking',
      }),
    )
    dispatch(
      getBikingIsochrone({
        polygons: true,
        lon: land?.geo_center?.lon,
        lat: land?.geo_center?.lat,
        type: 'cycling',
      }),
    )
    dispatch(
      getDrivingIsochrone({
        polygons: true,
        lon: land?.geo_center?.lon,
        lat: land?.geo_center?.lat,
        type: 'driving',
      }),
    )
  }, [landId])

  useEffect(() => {
    // UNDER EXPLOITED
    if (land.land_use_plans && landUsePlanStatus === 'now') {
      dispatch(getLandUsePlanDocuments(land.land_use_plans))
    } else if (land.future_land_use_plans && landUsePlanStatus === 'future') {
      dispatch(getFutureLandUsePlanDocuments(land.future_land_use_plans))
    } else if (
      land.land_use_plans &&
      land.future_land_use_plans &&
      landUsePlanStatus === 'difference'
    ) {
      dispatch(
        getDifferenceLandUsePlanDocuments({
          landUsePlans: land.land_use_plans,
          futureLandUsePlans: land.future_land_use_plans,
        }),
      )
    }
  }, [landId, landUsePlanStatus])

  useEffect(() => {
    if (isBuildingEdited?.length) {
      dispatch(handleEditedBuildings({ buildings } as any))
    }
  }, [buildings])

  useEffect(() => {
    fetchOnce('getFinancialDefault', null, () =>
      dispatch(getFinancialDefault()),
    )
  }, [])

  // HISTORY AROUND
  const date = query.month
    ? getDate(subMonths(new Date(), +query.month))
    : undefined

  // const { page, size = 25 } = query

  const center =
    land?.geo_center && land?.geo_center?.lon && land?.geo_center?.lat
      ? [land?.geo_center?.lon, land?.geo_center?.lat]
      : undefined

  const city = [query.city_history].filter(Boolean) || null
  const zone = [query.district_history].filter(Boolean) || null

  const radius = city?.length || zone?.length ? null : +query.radius || 500

  const polygon =
    center && radius ? getRadiusGeoJSON(center, radius) : undefined

  const historyFilters = {
    landId,
    page: 0,
    size: 1000,
    radius,
    date: date ? { min: date, max: undefined } : undefined,
    center,
    polygon,
    classification: fromFilter(query.classification),
    dealType: fromFilter(query.dealType),
    reason: fromFilter(query.reason),
    ownershipType: fromFilter(query.ownershipType),
    area: fromFilter(query.area, true),
    price: fromFilter(query.price, true),
    rooms: fromFilter(query.rooms, true),
    histogram: true,
    city,
    zone,
  }

  const transactionDate = {
    min: date || regionsByName[landRegion]?.first_transaction_date,
  }

  const faoDate = {
    min: date || regionsByName[landRegion]?.first_fao_date,
  }

  useEffect(() => {
    if (!landId) return

    fetchOnce('land_histories_around', historyFilters, () => {
      // FAO
      dispatch(getFaosAround({ ...historyFilters, date: faoDate }))
      dispatch(getFaoAggs({ ...historyFilters, date: faoDate }))

      // TRANSACTION
      dispatch(
        getTransactionsAround({ ...historyFilters, date: transactionDate }),
      )
      dispatch(getTransactionAggs({ ...historyFilters, date: transactionDate }))

      // LISTING
      dispatch(getListingAround(historyFilters))
      dispatch(getListingAggs(historyFilters))
    })
  }, [landId, historyFilters])
}

export default useGetLandDetailsData
