import { useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'

const ComparablesFooter = ({
  listing,
  comparablesIds,
  onSave,
  onRemove,
}: {
  listing: any
  comparablesIds: string[] | undefined
  onSave?: (listing: any) => void
  onRemove?: (listing: any) => void
}) => {
  const { t } = useI18n()

  const isListing =
    listing.original_url === '' ||
    (!!listing.original_url && !listing.ad_status)

  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: '#ffffff',
        paddingTop: 1,
        paddingBottom: 1,
        mt: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" gap={1}>
          <Button
            variant="contained"
            onClick={() => {
              window.open(listing.original_url, '_blank')
            }}
            disabled={isListing}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              backgroundColor: '#ffffff',
              color: '#00ade6',
              '&:hover': { backgroundColor: '#fff' },
            }}
          >
            <OpenInNewIcon />
            {t('common.view_comparable')}
          </Button>
        </Box>
        {comparablesIds?.find((id) => Number(id) === Number(listing?.id)) ? (
          <Button
            startIcon={<RemoveIcon />}
            sx={{
              backgroundColor: '#FF0000',
              color: 'white',
              borderRadius: '2px',
              padding: '8px',
              '&:hover': { backgroundColor: '#CC0000' },
            }}
            onClick={() => onRemove?.(listing)}
          >
            <Typography>{t('common.remove_from_comparables')}</Typography>
          </Button>
        ) : (
          <Button
            startIcon={<AddIcon />}
            sx={{
              backgroundColor: '#00ade6',
              color: 'white',
              borderRadius: '2px',
              padding: '8px',
              '&:hover': { backgroundColor: '#0dbef8' },
            }}
            onClick={() => onSave?.(listing)}
          >
            <Typography>{t('common.add_to_comparables')}</Typography>
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default ComparablesFooter
