/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Modal, useI18n } from '@popety_io/popety-io-lib'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TagCardAdd } from '../../pages/Account/components/TagActions'
import {
  removeBuildingTag,
  updateBuildingTag,
  addBuildingTag,
  listBuildingsTagsSelector,
} from '../../pages/BuildingDashboard/redux/buildingTag'
import { getCurrentUserId } from '../../utils/user'
import {
  fetchBuildingTags,
  addPreviewBuildingTag,
  updatePreviewBuildingTag,
  resetPreviewBuildingTag,
  fetchByBuildingId,
} from '../../pages/DeveloperMap/redux'
import { isCompanyAdminSelector } from '../../pages/Login'
import { userPreferencesSelector } from '../../pages/Account'
import useBuildingTag from './useBuildingTag'
import BuildingTagChooser from './BuildingTagChooser'

type TagModalProps = {
  buildingIds: string | string[]
  anchorEl: any
  tag?: any
  view?: number
  onClose: () => void
}

const BuildingTagModal = ({
  anchorEl,
  onClose,
  buildingIds,
  ...other
}: TagModalProps) => {
  const { t } = useI18n()
  const [addTagModalState, setAddTagModalState] = useState(false)

  const dispatch = useDispatch<any>()
  const userId = getCurrentUserId()

  const {
    activeBuildingTagIds,
    activeBuildingTags,
    myActiveBuildingTagIds,
    sanitizedBuildingIds,
    isTagOwner,
  } = useBuildingTag({ buildingIds })

  const isCompanyAdmin = useSelector(isCompanyAdminSelector)
  const { companyTagsCanBeEdited } = useSelector(userPreferencesSelector)

  const canAddTags = companyTagsCanBeEdited || isCompanyAdmin

  const cannotReplace = !activeBuildingTags?.length
    ? false
    : !isCompanyAdmin && !myActiveBuildingTagIds?.length

  const handleAddTagModalState = (state: boolean) => {
    setAddTagModalState(state)
  }

  const handleOnAdd = () => {
    handleAddTagModalState(true)
  }

  const handleTagToggle = (newVal: { tag_id: string }) => () => {
    sanitizedBuildingIds.forEach((buildingId) => {
      const findActiveTag = activeBuildingTags.find(
        (activeBuilding) =>
          Number(activeBuilding.building_id) === Number(buildingId),
      )

      if (
        activeBuildingTagIds?.some((id) => String(id) === String(newVal.tag_id))
      ) {
        if (
          isTagOwner(newVal) ||
          myActiveBuildingTagIds.some(
            (id) => String(id) === String(newVal.tag_id),
          ) ||
          isCompanyAdmin
        ) {
          dispatch(
            removeBuildingTag({
              buildingId,
              tagBuildingId: activeBuildingTags[0].id,
            }),
          )
            .then(() => {
              dispatch(resetPreviewBuildingTag())
            })
            .then(() => {
              dispatch(fetchBuildingTags())
            })
        }
      } else {
        if (cannotReplace) return

        if (findActiveTag?.id) {
          dispatch(
            updateBuildingTag({ tag: newVal, tagBuildingId: findActiveTag.id }),
          )
            .then(() => {
              dispatch(updatePreviewBuildingTag({ ...newVal, user_id: userId }))
            })
            .then(() => {
              dispatch(fetchBuildingTags())
            })
          onClose()
          handleAddTagModalState(false)
        } else {
          dispatch(
            addBuildingTag({
              ...newVal,
              user_id: userId,
              building_id: buildingId,
            }),
          )
            .then(() => {
              dispatch(addPreviewBuildingTag({ ...newVal, user_id: userId }))
            })
            .then(() => {
              dispatch(fetchBuildingTags())
            })
          onClose()
          handleAddTagModalState(false)
        }
      }
    })
  }

  const canToggle = (_tag: Record<string, any>) => {
    return !(
      (activeBuildingTagIds.some((id) => String(id) === String(_tag.tag_id)) &&
        !(
          isCompanyAdmin ||
          isTagOwner(_tag) ||
          activeBuildingTagIds.some((id) => String(id) === String(_tag.tag_id))
        )) ||
      cannotReplace
    )
  }

  const handleAddTag = (newVal: {
    tag_id: string
    newPrimary?: boolean
    newSecondary?: boolean
    secondary?: boolean
    primary?: boolean
  }) => {
    sanitizedBuildingIds.forEach((buildingId) => {
      dispatch(
        addBuildingTag({
          ...newVal,
          user_id: userId,
          building_id: buildingId,
          primary: !!newVal?.newPrimary,
        }),
      )
        .then(() => {
          dispatch(fetchByBuildingId(buildingId) as any)
        })
        .then(() => {
          dispatch(fetchBuildingTags())
        })
    })
    handleAddTagModalState(false)
  }

  const handleRemoveTag = (newVal: any) => {
    sanitizedBuildingIds.forEach((buildingId) => {
      const findActiveTag = activeBuildingTags.find(
        (activeBuilding) =>
          Number(activeBuilding.building_id) === Number(buildingId),
      )

      dispatch(
        removeBuildingTag({
          tagBuildingId: newVal.tagBuildingId,
          buildingId: findActiveTag.building_id,
        }),
      )
        .then(() => {
          dispatch(fetchByBuildingId(buildingId) as any)
        })
        .then(() => {
          dispatch(fetchBuildingTags())
        })
    })
    handleAddTagModalState(false)
  }

  return (
    <Modal
      {...other}
      maxWidth="xs"
      disableGutters
      open={!!anchorEl}
      onClose={onClose}
      title={t('common.chooseLabel')}
      okText={canAddTags && `+ ${t('common.addLabel')}`}
      onOk={handleOnAdd}
    >
      <BuildingTagChooser
        buildingIds={buildingIds}
        handleTagToggle={handleTagToggle}
        canToggle={canToggle}
        handleAddTag={handleAddTag}
        handleRemoveTag={handleRemoveTag}
      />
      <TagCardAdd
        open={addTagModalState}
        handleAddTagModalState={handleAddTagModalState}
        handleTagToggle={handleTagToggle}
      />
    </Modal>
  )
}

export default BuildingTagModal
