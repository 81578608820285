import { Fragment } from 'react'
import Card from '@mui/material/Card'
import Grid, { GridProps } from '@mui/material/Grid'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FilterChips, useI18n, format } from '@popety_io/popety-io-lib'

import { FaoDetailsRoot } from './FaoDetails.style'

export type FaoDetailsProps = GridProps & {
  data: Record<string, any>
}

const FaoDetails = ({ data: fao, sx, children }: FaoDetailsProps) => {
  const { t } = useI18n()

  const items = [
    { title: t('fao.owners'), value: fao.owner },
    { title: t('fao.promisor'), value: fao.promisor },
    {
      title: t('fao.architect'),
      value: fao.architect,
    },
    { title: t('fao.lastStatus'), value: fao.result_name },
    {
      title: t('fao.dispensation'),
      value: fao.dispensation !== ' ' && fao.dispensation,
    },
  ].filter((i) => i.value)

  const chips = [
    { label: fao.type_name, value: fao.type_name },
    { label: fao.result, value: fao.result && fao.region === 'Genève' },
    {
      label: `${fao.jurisdiction_code ? `(${fao.jurisdiction_code})` : ''} ${
        fao.jurisdiction_name || ''
      }`,
      value: fao.jurisdiction_code,
    },
  ].filter((i) => i.value)

  return (
    <FaoDetailsRoot container sx={sx}>
      <Grid xs={12} md={12} item>
        <Card className="ControlsCard">
          {(fao.address || fao.municipality) && (
            <Box className="faoAddress" textTransform="uppercase">
              {fao.address || fao.municipality}
            </Box>
          )}

          {!!chips?.length && (
            <Box>
              <FilterChips items={chips} size="small" />
            </Box>
          )}

          <Box className="faoPublishDate">
            <p>{`${t('fao.publishedOn')} ${format(
              fao.inquiry_start_date,
              'PPP',
            )}`}</p>
          </Box>

          <Box className="faoTitle">{fao.work_type}</Box>

          <Box>
            <Typography
              dangerouslySetInnerHTML={{ __html: fao.work_description }}
              fontSize="13px"
              sx={{
                height: undefined,
                marginBottom: 1,
              }}
            />
          </Box>

          <List sx={{ width: '100%' }}>
            {items.map((a, i) => (
              <Fragment key={i}>
                <Box className="faoTitleSection">{a.title}</Box>
                <Box className="faoValueSection">{a.value}</Box>
              </Fragment>
            ))}
          </List>

          <Box>
            <a
              className="officialLink"
              target="_blank"
              rel="noopener noreferrer"
              href={
                fao.url_official ||
                `https://www.actis.vd.ch/rest/exp/idqry/9008/param/${fao.camac_code_number}`
              }
            >
              {t('fao.officialLink')}
            </a>
          </Box>
        </Card>
        {children}
      </Grid>
    </FaoDetailsRoot>
  )
}

export default FaoDetails
