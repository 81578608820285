import { parseGeoJSON } from '@popety_io/popety-io-lib'
import turfUnion from '@turf/union'

const getAlertGeoPoint = (geoData: Record<string, any>) => {
  try {
    const { district, region, city } = geoData || {}

    const geoCentersData: any[] = [
      district?.map((d: Record<string, any>) => d.geojson_center),
      region?.map((d: Record<string, any>) => d.geojson_center),
      city?.map((d: Record<string, any>) => d.geojson_center),
    ]
      .filter(Boolean)
      .flat()
      .map(JSON.parse as any)

    const geoCenter = geoCentersData[0]?.coordinates
      ? [geoCentersData[0].coordinates[0], geoCentersData[0].coordinates[1]]
      : null

    const geoPoint = geoCenter
      ? { lat: geoCenter[1], lon: geoCenter[0] }
      : undefined

    return geoPoint
  } catch (error) {
    console.error(error)

    return null
  }
}

const getAlertGeoPolygon = (geoData: Record<string, any>) => {
  try {
    const { districtZone, regionZone, cityZone, district, region, city } =
      geoData || {}

    const data = [
      districtZone?.map((d: Record<string, any>) => d.geojson_polygon),
      regionZone?.map((d: Record<string, any>) => d.geojson_polygon),
      cityZone?.map((d: Record<string, any>) => d.geojson_polygon),
      district?.map((d: Record<string, any>) => d.geojson_polygon),
      region?.map((d: Record<string, any>) => d.geojson_polygon),
      city?.map((d: Record<string, any>) => d.geojson_polygon),
    ]
      .filter(Boolean)
      .flat()
      .map((v) => parseGeoJSON(v.geojson_polygon || v))
      .filter(Boolean)
    let geoPolygon = {
      type: data[0]?.type,
      coordinates: data[0]?.coordinates || [],
    }

    if (data.length > 1) {
      const union: any = data.reduce((pre, cur) => {
        return turfUnion(pre, cur)?.geometry
      }, data[0])

      geoPolygon = union
    }

    return geoPolygon
  } catch (error) {
    console.error(error)

    return { type: 'MultiPolygon', coordinates: [] }
  }
}

export const updateGeoJSONData =
  (name: string) =>
  (state: Record<string, any>, action: Record<string, any>) => {
    const { data = [] } = action.payload || {}

    state.data = { ...state.data, [name]: data }

    state.geoPoint = getAlertGeoPoint(state.data)
    state.geoPolygon = getAlertGeoPolygon(state.data)
  }
