/* eslint-disable max-lines */
import { useState, useEffect } from 'react'
import SellOutlinedIcon from '@mui/icons-material/SellOutlined'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'
import { Tooltip } from '@mui/material'

import { useSelector } from 'react-redux'

import { useI18n } from '@popety_io/popety-io-lib'

import { listBuildingsTagsSelector } from '../../pages/BuildingDashboard/redux'

import { TagChooserRoot } from './Tag.style'
import useBuildingTag from './useBuildingTag'

type TagChooserProps = {
  buildingIds: string | string[]
  handleTagToggle: (newVal: { tag_id: string }) => () => void
  canToggle: (_tag: Record<string, any>) => boolean
  handleAddTag: (newVal: {
    tag_id: string
    newPrimary?: boolean
    newSecondary?: boolean
  }) => void
  handleRemoveTag: (newVal: {
    tag_id: string
    newPrimary?: boolean
    newSecondary?: boolean
    tagBuildingId?: string
  }) => void
}

const BuildingTagChooser = ({
  buildingIds,
  handleAddTag,
  handleRemoveTag,
}: TagChooserProps) => {
  const { t } = useI18n()
  const uniqueTags = useSelector(listBuildingsTagsSelector)

  const { activeBuildingTags, tagsbyId } = useBuildingTag({
    buildingIds,
  })

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [filteredTags, setFilteredTags] = useState<any[]>(uniqueTags)

  const height = Math.min(200, 16 + 32 * uniqueTags.length)

  const handleSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchQuery(e.target.value)
  }

  useEffect(() => {
    setFilteredTags(
      (uniqueTags?.length ? uniqueTags : [])?.map((item: any) => {
        const primaryBuildingTag = activeBuildingTags?.find(
          (a) => Number(a?.tag_id) === Number(item?.id) && a?.primary,
        )

        const secondaryBuildingTag = activeBuildingTags?.find(
          (a: any) => Number(a?.tag_id) === Number(item?.id) && !a?.primary,
        )

        return {
          ...item,
          secondary: !!secondaryBuildingTag?.active,
          tagBuildingIdSecondary: secondaryBuildingTag?.id,
          primary: primaryBuildingTag?.primary,
          tagBuildingIdPrimary: primaryBuildingTag?.id,
        }
      }),
    )
    setSearchQuery('')
  }, [uniqueTags, tagsbyId])

  useEffect(() => {
    if (!searchQuery) {
      setFilteredTags(
        (uniqueTags?.length ? uniqueTags : [])?.map((item: any) => {
          const primaryBuildingTag = activeBuildingTags?.find(
            (a) => Number(a?.tag_id) === Number(item?.id) && a?.primary,
          )

          const secondaryBuildingTag = activeBuildingTags?.find(
            (a: any) => Number(a?.tag_id) === Number(item?.id) && !a?.primary,
          )

          return {
            ...item,
            secondary: !!secondaryBuildingTag?.active,
            tagBuildingIdSecondary: secondaryBuildingTag?.id,
            primary: primaryBuildingTag?.primary,
            tagBuildingIdPrimary: primaryBuildingTag?.id,
          }
        }),
      )
    } else {
      setFilteredTags(
        uniqueTags
          .filter((tag: { value: string }) =>
            tag.value.toLowerCase().includes(searchQuery.toLowerCase()),
          )
          ?.map((item: any) => {
            const primaryBuildingTag = activeBuildingTags?.find(
              (a) => Number(a?.tag_id) === Number(item?.id) && a?.primary,
            )

            const secondaryBuildingTag = activeBuildingTags?.find(
              (a: any) => Number(a?.tag_id) === Number(item?.id) && !a?.primary,
            )

            return {
              ...item,
              secondary: !!secondaryBuildingTag?.active,
              tagBuildingIdSecondary: secondaryBuildingTag?.id,
              primary: primaryBuildingTag?.primary,
              tagBuildingIdPrimary: primaryBuildingTag?.id,
            }
          }),
      )
    }
  }, [searchQuery])

  return (
    <TagChooserRoot>
      <Box sx={{ px: 2 }}>
        <TextField
          placeholder={t('common.enterTagName')}
          name="value"
          margin="normal"
          value={searchQuery}
          onChange={handleSearchChange}
          size="small"
          fullWidth
        />
      </Box>
      <Box height={`${height}px`} flexGrow={1} sx={{ overflowY: 'auto' }}>
        <Stack spacing={1} p={2}>
          {filteredTags.map((_tag) => (
            <Stack
              key={_tag.id}
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
              color={_tag?.color || '#00ade6'}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="ellipsis"
                sx={{
                  background: _tag?.color || '#00ade6',
                  color: '#fff',
                  p: '6px 8px',
                  fontWeight: 400,
                  lineHeight: '12px',
                  width: 'fit-content',
                  borderRadius: '4px',
                }}
              >
                {_tag.value}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <Box
                  onClick={() => {
                    if (_tag.primary) {
                      handleRemoveTag({
                        ..._tag,
                        tagBuildingId: _tag?.tagBuildingIdPrimary,
                      })
                    } else {
                      handleAddTag({
                        ..._tag,
                        newPrimary: true,
                      })
                    }
                  }}
                >
                  <Tooltip
                    title={
                      _tag?.active && !_tag.primary
                        ? t('common.tag_building_modal.star')
                        : ''
                    }
                  >
                    <WhereToVoteIcon
                      className="checkIcon"
                      sx={{
                        color:
                          _tag?.active && _tag.primary
                            ? '#00ade6'
                            : '#00000028',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </Box>
                <Box
                  onClick={() => {
                    if (_tag.secondary) {
                      handleRemoveTag({
                        ..._tag,
                        tagBuildingId: _tag?.tagBuildingIdSecondary,
                      })
                    } else {
                      if (_tag.primary) {
                        handleRemoveTag({
                          ..._tag,
                          tagBuildingId: _tag?.tagBuildingIdPrimary,
                        })
                      }
                      handleAddTag({
                        ..._tag,
                        newPrimary: false,
                      })
                    }
                  }}
                >
                  <Tooltip
                    title={
                      _tag?.active && !_tag?.secondary
                        ? t('common.tag_building_modal.check')
                        : ''
                    }
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <SellOutlinedIcon
                      className="checkIcon"
                      sx={{
                        color:
                          _tag?.active && _tag?.secondary
                            ? '#00ade6'
                            : '#00000028',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Stack>
          ))}
        </Stack>
      </Box>
    </TagChooserRoot>
  )
}

export default BuildingTagChooser
