/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Switch from '@mui/material/Switch'
import SmsIcon from '@mui/icons-material/Sms'
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Radio, Typography } from '@mui/material'
import {
  Snackbar,
  useI18n,
  GoogleAuthenticatorIcon,
  MicrosoftAuthenticatorIcon,
} from '@popety_io/popety-io-lib'

import { updateSecurityStatus, verifySecurityStatus } from '../redux'
import { setCloseConfirmPhone, userSelector } from '../../Login'
import {
  userDetailsSelector,
  securityStatusSelector,
  securityStatusLoadingSelector,
} from '../redux/accountSelector'

import { clearTwoFa } from '../redux/accountSlice'

import VerifyModal from './VerifyModal'

const DOWNLOAD_LINK = {
  googleAuthenticator: {
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1',
    appStore: 'https://apps.apple.com/fr/app/google-authenticator/id388497605',
  },
  microsoftAuthenticator: {
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=fr',
    appStore:
      'https://apps.apple.com/us/app/microsoft-authenticator/id983156458',
  },
}

const Security = () => {
  const [enable, setEnable] = useState(false)
  const [sms, setSms] = useState(false)
  const [authenticator, setAuthenticator] = useState(false)
  const [openVerify, setOpenVerify] = useState(false)
  const [qrValue, setQrValue] = useState('')
  const [updateType, setUpdateType] = useState<'sms' | 'authenticator'>()
  const [errorMess, setErrorMess] = useState('')
  const user = useSelector(userSelector)
  const userDetails = useSelector(userDetailsSelector)
  const securityStatus = useSelector(securityStatusSelector)
  const loading = useSelector(securityStatusLoadingSelector)

  const isTwoFaRequired = userDetails?.two_fa_required

  const dispatch = useDispatch<any>()

  const { t } = useI18n()

  useEffect(() => {
    setOpenVerify(securityStatus?.needVerify)
    setQrValue(securityStatus?.otpAuthUrl)
  }, [securityStatus])

  useEffect(() => {
    if (securityStatus?.enable_sms) {
      setUpdateType('sms')
      setSms(securityStatus?.enable_sms)
    } else {
      setSms(false)
    }

    if (securityStatus?.enable_authentification) {
      setUpdateType('authenticator')
      setAuthenticator(securityStatus?.enable_authentification)
    } else {
      setAuthenticator(false)
    }
  }, [securityStatus?.enable_sms, securityStatus?.enable_authentification])

  useEffect(() => {
    setEnable(isTwoFaRequired)
  }, [isTwoFaRequired])

  useEffect(() => {
    if (isTwoFaRequired) setEnable(true)
  }, [isTwoFaRequired])

  const updateStatus = (type: 'sms' | 'authenticator') => {
    if (
      type === 'sms' &&
      (!user?.phone || user?.phone === 'null' || user?.phone === '0')
    ) {
      dispatch(setCloseConfirmPhone(false))

      return
    }
    setOpenVerify(false)
    setQrValue('')
    setUpdateType(type)
    dispatch(updateSecurityStatus({ type }))
  }

  const onClose = () => {
    setOpenVerify(false)
    setQrValue('')
    setErrorMess('')
  }

  const onValidate = (code: string, type: 'sms' | 'authenticator') => {
    dispatch(
      verifySecurityStatus({
        type: type || 'sms',
        code,
      }),
    )
  }

  useEffect(() => {
    if (securityStatus?.otp_valid) {
      onClose()
      dispatch(clearTwoFa())
    }
  }, [securityStatus])

  const changeEnable = () => {
    if (!enable) {
      setEnable(true)

      return
    }

    if (sms || authenticator) {
      dispatch(updateSecurityStatus({ type: updateType || 'sms' }))
      setEnable(false)
    } else {
      setEnable(false)
    }
  }

  const handleClick = (link: string) => {
    window.open(link)
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          margin: 2,
          marginBottom: 0,
        }}
      >
        {isTwoFaRequired && (
          <Typography variant="h6">{t('common.enable_2fa')}</Typography>
        )}
        {!isTwoFaRequired && (
          <Typography variant="h6">{t('common.disable_2fa')}</Typography>
        )}
        {false && (
          <Switch
            edge="end"
            onChange={changeEnable}
            checked={enable}
            inputProps={{
              'aria-labelledby': 'switch-list-label-bluetooth',
            }}
            disabled
          />
        )}
      </Box>

      {enable && (
        <>
          <List
            sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}
            subheader={
              <ListSubheader component="h1">
                {t('common.2fa_methods')}
              </ListSubheader>
            }
          >
            <ListItem>
              <ListItemIcon>
                <PhonelinkLockIcon />
              </ListItemIcon>
              <ListItemText
                id="switch-list-label-bluetooth"
                primary={t('common.2fa_auth_app_title')}
                secondary={t('common.2fa_auth_app_description')}
              />

              <Radio
                onChange={() => updateStatus('authenticator')}
                checked={authenticator}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SmsIcon />
              </ListItemIcon>
              <ListItemText
                id="switch-list-label-wifi"
                primary={t('common.2fa_sms_title')}
                secondary={t('common.2fa_methods')}
              />

              <Radio onChange={() => updateStatus('sms')} checked={sms} />
            </ListItem>
          </List>

          <List
            sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}
            subheader={
              <ListSubheader component="h1">
                {t('common.Download App')}
              </ListSubheader>
            }
          >
            <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <ListItemText
                id="switch-list-label-bluetooth"
                primary={t('common.Google Authenticator')}
              />
              <Box sx={{ display: 'flex' }}>
                <ListItemIcon
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                >
                  <GoogleAuthenticatorIcon
                    onClick={() =>
                      handleClick(DOWNLOAD_LINK.googleAuthenticator.googlePlay)
                    }
                  />
                </ListItemIcon>
                <ListItemIcon
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                >
                  <MicrosoftAuthenticatorIcon
                    onClick={() =>
                      handleClick(DOWNLOAD_LINK.googleAuthenticator.appStore)
                    }
                  />
                </ListItemIcon>
              </Box>
            </ListItem>
            <ListItem>
              <ListItemText
                id="switch-list-label-bluetooth"
                primary={t('common.Microsoft Authenticator')}
              />
              <Box sx={{ display: 'flex' }}>
                <ListItemIcon
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                >
                  <GoogleAuthenticatorIcon
                    onClick={() =>
                      handleClick(
                        DOWNLOAD_LINK.microsoftAuthenticator.googlePlay,
                      )
                    }
                  />
                </ListItemIcon>
                <ListItemIcon
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                >
                  <MicrosoftAuthenticatorIcon
                    onClick={() =>
                      handleClick(DOWNLOAD_LINK.microsoftAuthenticator.appStore)
                    }
                  />
                </ListItemIcon>
              </Box>
            </ListItem>
          </List>
        </>
      )}
      <VerifyModal
        open={openVerify}
        qr={qrValue}
        onSubmit={onValidate}
        loading={loading}
        onClose={onClose}
        errorMess={errorMess}
      />
      <Snackbar open={loading} severity="info">
        {t('common.loading')}
      </Snackbar>
    </div>
  )
}

export default Security
