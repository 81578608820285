/* eslint-disable max-lines */
import { FC, useEffect, useMemo, useState } from 'react'
import { CECBProps, useI18n, CECB } from '@popety_io/popety-io-lib'
import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'

import { ICECB } from '../redux/energy/energyType'
import { CECBLimitValue } from '../utils/cecb'

interface IProps {
  onSaveChange: (values: ICECB) => void
  defaultData: ICECB
  type?: string
  defaultShow?: boolean
  toggleDisplay?: (name: string) => (checked: boolean) => void
}

const CECBSection: FC<IProps> = ({
  onSaveChange,
  defaultData,
  type,
  toggleDisplay,
  defaultShow = true,
}) => {
  const [edit, setEdit] = useState(false)
  const [show, setShow] = useState(defaultShow)
  const { t } = useI18n()

  useEffect(() => {
    setShow(defaultShow)
  }, [defaultShow])

  const [data, setData] = useState<Omit<CECBProps, 'onChange' | 'isEdit'>>({
    envelopeValue: 0,
    energyValue: 0,
    directCO2Value: 0,
    directCO2Type: 'N/A',
    energyType: 'N/A',
    envelopeType: 'N/A',
  })

  const mapDefaultValue = () => {
    setData({
      envelopeValue: defaultData?.performance_heating_value,
      energyValue: defaultData?.performance_energy_value,
      directCO2Value: defaultData?.direct_CO2_emission_value,
      directCO2Type: defaultData?.direct_CO2_emission_type || 'N/A',
      energyType: defaultData?.performance_energy_type || 'N/A',
      envelopeType: defaultData?.performance_heating_type || 'N/A',
    })
  }

  const handleEdit = () => {
    if (edit) {
      if (defaultData) {
        mapDefaultValue()
      }
    }
    setEdit(!edit)
  }

  useEffect(() => {
    if (defaultData) {
      mapDefaultValue()
    }
  }, [JSON.stringify(defaultData)])

  const onToggle = (checked: boolean) => {
    toggleDisplay?.('CECBSection')(checked)
    setShow(checked)
  }

  useEffect(() => {
    toggleDisplay?.('CECBSection')(defaultShow)
  }, [])

  const onChange = (
    property: string,
    type: CECBProps['directCO2Type'],
    value?: number,
  ) => {
    console.log(property, type, value, 'lollo')
    const min = (type && CECBLimitValue?.[type]?.min) || 0
    const max = (type && CECBLimitValue?.[type]?.max) || 0

    const isValidateMin =
      (!!value && value >= min) ||
      (min === 0 && value === 0) ||
      value === undefined
    const isValidateMax =
      (!!value && value <= max) ||
      (min === 0 && value === 0) ||
      value === undefined ||
      type === 'G'

    const isTypeA = type === 'A'

    const isValidate =
      (isValidateMax && !isTypeA) || (isValidateMin && isValidateMax)

    switch (property) {
      case 'envelope':
        if (isValidate) {
          setData((pre) => ({
            ...pre,
            envelopeType: type,
            envelopeValue: Number(value),
          }))
        } else if (type === 'N/A') {
          setData((pre) => ({
            ...pre,
            envelopeType: type,
            envelopeValue: Number(value),
          }))
        }
        break
      case 'energy':
        if (isValidate) {
          setData((pre) => ({
            ...pre,
            energyType: type,
            energyValue: Number(value),
          }))
        } else if (type === 'N/A') {
          setData((pre) => ({
            ...pre,
            energyType: type,
            energyValue: Number(value),
          }))
        }
        break
      case 'directCO2':
        if (isValidate) {
          setData((pre) => ({
            ...pre,
            directCO2Type: type,
            directCO2Value: Number(value),
          }))
        } else if (type === 'N/A') {
          setData((pre) => ({
            ...pre,
            directCO2Type: type,
            directCO2Value: Number(value),
          }))
        }
        break
      default:
        break
    }
  }

  const isValidValues = useMemo(() => {
    const isCO2Valid =
      data.directCO2Type === 'N/A' ||
      (Number(data.directCO2Value) >=
        Number(CECBLimitValue[data.directCO2Type || 'N/A']?.min) &&
        (Number(data.directCO2Value) <=
          Number(CECBLimitValue[data.directCO2Type || 'N/A']?.max) ||
          data.directCO2Type === 'G'))

    const isHeatingValid =
      data.envelopeType === 'N/A' ||
      (Number(data.envelopeValue) >=
        Number(CECBLimitValue[data.envelopeType || 'N/A']?.min) &&
        (Number(data.envelopeValue) <=
          Number(CECBLimitValue[data.envelopeType || 'N/A']?.max) ||
          data.envelopeType === 'G'))

    const isEnergyValid =
      data.energyType === 'N/A' ||
      (Number(data.energyValue) >=
        Number(CECBLimitValue[data.energyType || 'N/A']?.min) &&
        (Number(data.energyValue) <=
          Number(CECBLimitValue[data.energyType || 'N/A']?.max) ||
          data.energyType === 'G'))

    return isCO2Valid && isHeatingValid && isEnergyValid
  }, [data])

  const onSave = () => {
    if (isValidValues) {
      onSaveChange({
        performance_heating_type: data.envelopeType || 'N/A',
        performance_heating_value: Number(data.envelopeValue),
        performance_energy_type: data.energyType || 'N/A',
        performance_energy_value: Number(data.energyValue),
        direct_CO2_emission_type: data.directCO2Type || 'N/A',
        direct_CO2_emission_value: Number(data.directCO2Value),
      })
      handleEdit()
    }
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBlock: '16px',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: '4px',
            fontSize: '1.1rem',
            textTransform: 'uppercase',
          }}
        >
          CECB
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {!edit ? (
            <EditIcon
              sx={{ fill: '#969696', cursor: 'pointer' }}
              onClick={handleEdit}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <SaveIcon
                sx={{
                  fill: '#969696',
                  cursor: isValidValues ? 'pointer' : 'not-allowed',
                  marginRight: 2,
                  opacity: isValidValues ? 1 : 0.5,
                }}
                onClick={onSave}
              />
              <CloseIcon
                sx={{ fill: '#969696', cursor: 'pointer' }}
                onClick={handleEdit}
              />
            </Box>
          )}
          {type === 'estimate' && (
            <Tooltip title={t('common.disable_button_tooltip')}>
              <Switch
                checked={show}
                onChange={(e) => onToggle(e.target.checked)}
                size="small"
              />
            </Tooltip>
          )}
        </Box>
      </Box>
      {show && (
        <CECB
          directCO2Type={data.directCO2Type || 'N/A'}
          directCO2Value={data.directCO2Value}
          energyType={data.energyType}
          energyValue={data.energyValue}
          envelopeType={data.envelopeType}
          envelopeValue={data.envelopeValue}
          directCO2Unit="m3"
          isEdit={edit}
          onChange={onChange as any}
          t={t}
        />
      )}
    </div>
  )
}

export default CECBSection
