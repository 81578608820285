import { useMemo } from 'react'
import { useRouter } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import {
  buildingSelector,
  configurationSelector,
  landsSelector,
  roofPolygonSelector,
} from '../redux'
import {
  transactionGeoPolygonSelector,
  poisIsochroneLayersSelector,
  buildingsByIdSelector,
} from '../../LandDetails'

import {
  getPlotRadius,
  getPlotLocation,
} from '../../LandDetails/LandDetailsMap/utils'
import { getMultipleLandLayersOultined } from '../../LandDetails/redux/summary/land.layers'
import { getBuildingLayers } from '../../LandDetails/redux/building/building.layers'
import { getBuildingLayer } from './BuildingDetailsMap.utils'

const useBuildingDetailsMapLayers = () => {
  const { query } = useRouter()
  const location = useLocation()

  const { radius, dimension = '3D', egid } = query
  const isBuildingDetails = location.pathname === '/land-detail/building'

  const building = useSelector(buildingSelector)

  const geoPolygon = useSelector(transactionGeoPolygonSelector)
  const livabilityIsochrone = useSelector(poisIsochroneLayersSelector)

  const radiusLayer = useMemo(() => {
    if (!building?.geo_center) {
      return null
    }
    if (isBuildingDetails && !radius) {
      return null
    }

    return getPlotRadius(building.geo_center, radius)
  }, [isBuildingDetails, building?.geo_center, radius])

  const locationLayer = getPlotLocation(geoPolygon)
  const lands = useSelector(landsSelector)

  const roofPolygon = useSelector(roofPolygonSelector)
  const configuration = useSelector(configurationSelector)
  const buildingsById = useSelector(buildingsByIdSelector)

  const buildingsLayers = useMemo(() => {
    if (buildingsById) {
      return getBuildingLayers(
        Object.values(buildingsById).filter(
          (buildingItem: any) => buildingItem.id !== building.id,
        ),
        dimension,
      )
    }
  }, [buildingsById])

  const landsLayers: any = useMemo(() => {
    return getMultipleLandLayersOultined(lands)
  }, [lands])

  const buildingLayer = useMemo(() => {
    return getBuildingLayer(
      building,
      query?.energyLayer,
      query?.tab,
      query?.buildingHeight,
      egid,
      roofPolygon,
      configuration,
      dimension,
    )
  }, [
    building,
    query?.energyLayer,
    query?.tab,
    query?.buildingHeight,
    configuration,
    dimension,
    egid,
  ])

  const history = [
    buildingLayer,
    query.city_history && locationLayer,
    query.district_history && locationLayer,
    radiusLayer,
    ...landsLayers,
  ].filter(Boolean)

  const sourcesByPage: Record<string, any[][]> = {
    summary: [buildingLayer],
    parcelle: [...landsLayers, buildingsLayers, buildingLayer],
    transaction: history,
    fao: history,
    listing: history,
    livability: [livabilityIsochrone, buildingLayer],
    sunlight: [buildingLayer],
  }

  const layers = sourcesByPage[query.tab] || sourcesByPage.summary

  const data = layers?.flat()?.filter(Boolean)

  return { data }
}

export { useBuildingDetailsMapLayers }
