/* eslint-disable max-lines */
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchOnce,
  getDate,
  useRouter,
  getRadiusGeoJSON,
  fromFilter,
} from '@popety_io/popety-io-lib'
import subMonths from 'date-fns/subMonths'

import {
  getTransactionAggs,
  // getLandTransactions,
  getTransactionsAround,
  getListingAround,
  getListingAggs,
  getFaosAround,
  getFaoAggs,
  // getLandFaos,
  getLandListings,
  countLandListings,
  getBuildingPotentialSolar,
  getPoisAround,
  getPoiCategories,
  getBuildings,
  sunDateSelector,
  updateSunlightOptions,
  getTransactionsByIds,
  getFaosByIds,
  getIsochrone,
  getMonthlyExposureAverage,
} from '../LandDetails'
import { getSunTimes } from '../LandDetails/Sunlight/SunExposure'
import {
  landsSelector,
  buildingSelector,
  getLandSummary,
  getRoofBuildingPolygon,
  getConfigurationEdited,
} from './redux'

const useGetBuildingDetailsData = () => {
  const dispatch: any = useDispatch()

  const building = useSelector(buildingSelector)
  const lands = useSelector(landsSelector)
  const listingsIds = building?.listings?.map(
    (listing: any) => listing.id || listing.listing_id,
  )
  const transactionsIds = building?.lands_transactions?.map(
    (transaction: any) => transaction.id || transaction.transaction_id,
  )
  const faosIds = building?.lands_building_permits?.map(
    (fao: any) => fao.id || fao.fao_id,
  )

  const sunDate = useSelector(sunDateSelector)

  const { query } = useRouter()

  const landId =
    building?.lands?.length > 0
      ? building?.lands[0]?.id || building?.lands?.[0]?.land_id
      : undefined

  const city = [query.city_history].filter(Boolean) || null
  const zone = [query.district_history].filter(Boolean) || null

  const radius = city?.length || zone?.length ? null : +query.radius || 500

  const center =
    building?.geo_center &&
    building?.geo_center?.lon &&
    building?.geo_center?.lat
      ? [building?.geo_center?.lon, building?.geo_center?.lat]
      : undefined

  const polygon =
    center && radius ? getRadiusGeoJSON(center, radius) : undefined

  useEffect(() => {
    const sunlightTimes = getSunTimes({
      sunDate,
      center: {
        lng: building?.geo_center?.lon,
        lat: building?.geo_center?.lat,
      },
    })

    const sunlightSummerTimes = getSunTimes({
      sunDate,
      center: {
        lng: building?.geo_center?.lon,
        lat: building?.geo_center?.lat,
      },
      month: 5,
      day: 21,
    })

    const sunlightWinterTimes = getSunTimes({
      sunDate,
      center: {
        lng: building?.geo_center?.lon,
        lat: building?.geo_center?.lat,
      },
      month: 11,
      day: 21,
    })

    dispatch(
      updateSunlightOptions({
        sunlightTimes,
        sunlightWinterTimes,
        sunlightSummerTimes,
      }),
    )
  }, [building])

  useEffect(() => {
    if (listingsIds && listingsIds?.length) {
      const value = `ID:${listingsIds?.join(',')}`

      fetchOnce('buildingListings', listingsIds, () => {
        dispatch(getLandListings({ id: listingsIds, value }))
      })
    }
  }, [JSON.stringify(listingsIds)])

  useEffect(() => {
    if (transactionsIds && transactionsIds?.length > 0) {
      fetchOnce('buildingTransactions', transactionsIds, () => {
        dispatch(getTransactionsByIds({ transactionsIds }))
      })
    }
  }, [JSON.stringify(transactionsIds)])

  useEffect(() => {
    if (faosIds && faosIds?.length > 0) {
      fetchOnce('buildingFaos', faosIds, () => {
        dispatch(getFaosByIds({ faosIds }))
      })
    }
  }, [JSON.stringify(faosIds)])

  useEffect(() => {
    if (!landId) return

    // SUMMARY
    dispatch(getLandSummary({ lands: building?.lands }))

    // TRANSACTIONS
    // dispatch(getLandTransactions({ landId }))

    // FAO
    // dispatch(getLandFaos({ landId }))

    // LISTING
    fetchOnce('countLandListingsRent', { landId, dealType: 'rent' }, () => {
      dispatch(countLandListings({ landId, dealType: 'rent' }))
    })
    fetchOnce('countLandListingsBuy', { landId, dealType: 'buy' }, () => {
      dispatch(countLandListings({ landId, dealType: 'buy' }))
    })

    // LIVABILITY
    fetchOnce('getPoisAround', landId, () => {
      dispatch(getPoisAround({ landId }))
    })
    fetchOnce('getPoiCategories', landId, () => {
      dispatch(getPoiCategories({ landId }))
    })

    // Isochrone
    const input = {
      polygons: true,
      lon: building?.geo_center?.lon,
      lat: building?.geo_center?.lat,
      type: 'walking',
    }

    dispatch(getConfigurationEdited({ buildingId: building?.id }) as any)

    dispatch(getIsochrone(input))
  }, [landId])

  useEffect(() => {
    if (building.id) {
      fetchOnce('getRoofBuildingPolygon', building.id, () => {
        dispatch(getRoofBuildingPolygon(building.id) as any)
      })
    }
  }, [building?.id])

  useEffect(() => {
    if (!lands || lands.length === 0) return

    let buildings: any[] = []

    lands.forEach((land: any) => {
      const building = land.buildings || []

      buildings = [...buildings, ...building]
    })

    // BUILDINGS
    fetchOnce('getBuildings', { landId, buildings: lands[0]?.buildings }, () =>
      dispatch(getBuildings({ landId, buildings: lands[0]?.buildings })),
    )
  }, [lands])

  // HISTORY AROUND
  const date = query.month
    ? getDate(subMonths(new Date(), +query.month))
    : undefined

  const historyFilters = {
    landId,
    page: 0,
    size: 1000,
    radius,
    date: date ? { min: date, max: undefined } : undefined,
    center,
    polygon,
    classification: fromFilter(query.classification),
    dealType: fromFilter(query.dealType),
    reason: fromFilter(query.reason),
    ownershipType: fromFilter(query.ownershipType),
    area: fromFilter(query.area, true),
    price: fromFilter(query.price, true),
    rooms: fromFilter(query.rooms, true),
    histogram: true,
    city,
    zone,
  }

  const transactionDate = {
    min: date,
  }

  const faoDate = {
    min: date,
  }

  useEffect(() => {
    if (!landId) return

    fetchOnce('land_histories_around', historyFilters, () => {
      // FAO
      dispatch(getFaosAround({ ...historyFilters, date: faoDate }))
      dispatch(getFaoAggs({ ...historyFilters, date: faoDate }))

      // TRANSACTION
      dispatch(
        getTransactionsAround({ ...historyFilters, date: transactionDate }),
      )
      dispatch(getTransactionAggs({ ...historyFilters, date: transactionDate }))

      // LISTING
      dispatch(getListingAround(historyFilters))
      dispatch(getListingAggs(historyFilters))
    })
  }, [landId, historyFilters])

  useEffect(() => {
    if (!landId) return
    // SunLight
    fetchOnce('getBuildingPotentialSolar', building?.id, () => {
      dispatch(getBuildingPotentialSolar(building?.id))
    })
    // LISTING
    fetchOnce('getMonthlyExposureAverage', building?.id, () => {
      dispatch(getMonthlyExposureAverage(building?.id))
    })
  }, [landId])
}

export default useGetBuildingDetailsData
