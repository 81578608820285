import { memo } from 'react'
import Box from '@mui/material/Box'
import {
  Area,
  XAxis,
  Tooltip,
  Line,
  ComposedChart,
  CartesianGrid,
} from 'recharts'

import CustomizedAxisTick from './CustomizedAxisTick'
import CustomTooltipPrice from './CustomTooltipPrice'
import { ChartContainer } from './ListingAnalyticCard.style'

export type ListingAnalyticCardProps = {
  priceData: any[]
}

const ListingChart = ({ priceData }: ListingAnalyticCardProps) => {
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        width: '48%',
        padding: '0px 0px 0px 0px',
        fontWeight: 400,
        fontFamily: 'Roboto',
        color: 'rgb(4, 26, 37)',
      }}
    >
      <ChartContainer>
        <ComposedChart width={150} height={180} data={priceData}>
          <CartesianGrid vertical={false} stroke="rgb(240, 240, 240)" />
          <XAxis
            style={{ fontSize: '0.7rem' }}
            dataKey="x"
            axisLine={false}
            tickLine={false}
            height={60}
            tick={<CustomizedAxisTick />}
            interval="preserveStartEnd"
          />

          <Area
            type="monotone"
            dataKey="priceM2Range"
            stroke="none"
            fillOpacity={0.3}
            fill="#00ade6"
            yAxisId="left"
          />
          <Line
            type="monotone"
            dataKey="priceM2"
            stroke="#00ade6"
            dot={false}
            yAxisId="left"
          />
          <Tooltip content={<CustomTooltipPrice />} />
        </ComposedChart>
      </ChartContainer>
    </Box>
  )
}

export default memo(ListingChart)
