/* eslint-disable max-lines */
import { useState } from 'react'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import Skeleton from '@mui/material/Skeleton'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { Button, useI18n } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'

import {
  buildingSearchHistorySelector,
  buildingSearchHistoryIsLoadingSelector,
} from '../../redux'

export const FilterChipsRoot = styled(Box)`
  display: flex;
  gap: 4px;

  > .MuiChip-root {
    color: ${({ theme }) => theme.palette.primary.main};
    background: #f4f4f4;
    max-width: 200px;
    font-size: 1rem;
    height: 24px;

    svg {
      font-size: 1.5rem;
    }
  }
`

const BuildingSearchHistory = () => {
  const history: any[] | [] = useSelector(buildingSearchHistorySelector)
  const [extended, setExtended] = useState(false)
  const [selectedLand, setSelectedLand] = useState('')

  const isLoading = useSelector(buildingSearchHistoryIsLoadingSelector)

  const { t } = useI18n()
  const navigate = useNavigate()

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedLand(event.target.value)
    navigate(
      `/land-detail/building?buildingId=${event.target.value}&landUsePlanStatus=now`,
    )
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        sx={{ flexWrap: 'wrap', gap: 0.8 }}
      >
        {isLoading && !history?.length && (
          <Box
            display="flex"
            flexDirection="row"
            sx={{ flexWrap: 'wrap', gap: 0.8 }}
          >
            {[1, 2, 3, 4, 5, 6, 8, 9].map((i) => (
              <Skeleton
                key={i}
                width={85}
                height={30}
                animation="wave"
                sx={{
                  borderRadius: 5,
                }}
              />
            ))}
          </Box>
        )}
        {Array.isArray(history) &&
          history?.slice(0, 5)?.map((item, i) => (
            <Chip
              key={i}
              label={item.location}
              title={item.location}
              size="small"
              onClick={() => {
                navigate(
                  `/land-detail/building?buildingId=${item.building_id}&landUsePlanStatus=now`,
                )
              }}
            />
          ))}
      </Box>
      {extended && Array.isArray(history) && history.length > 5 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            justifyContent: 'center',
          }}
        >
          <FormControl fullWidth>
            <InputLabel
              shrink={false}
              id="searchHistory"
              style={{
                marginLeft: 10,
                top: '50%',
                transform: 'translate(0,-50%',
              }}
            >
              {t('common.searchHistory')}
            </InputLabel>

            <Select
              labelId="searchHistory"
              sx={{
                width: '100%',
                '& .MuiSelect-select': {
                  py: '8px',
                },
              }}
              value={selectedLand}
              onChange={handleChange}
              label={t('common.searchHistory')}
            >
              {Array.isArray(history) &&
                history?.slice(5)?.map((item, i) => (
                  <MenuItem key={i} value={item.building_id}>
                    {item.location}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {Array.isArray(history) && history.length > 5 && (
        <Box display="flex" justifyContent="flex-end" mr={-0.5}>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              setExtended(!extended)
            }}
          >
            {extended ? t('common.seeLess') : t('common.seeMore')}
          </Button>
        </Box>
      )}
    </>
  )
}

export default BuildingSearchHistory
