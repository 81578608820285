/* eslint-disable max-lines */
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import mapboxgl from 'mapbox-gl'
import {
  Map,
  useRouter,
  useMediaQuery,
  useAsync,
  Modal,
  useI18n,
} from '@popety_io/popety-io-lib'
import transactionHistoryService from '../../../../TransactionHistory/redux/transactionHistoryService'
import TransactionDetails from '../../../../TransactionHistory/components/TransactionDetails'
import { regionsByNameSelector } from '../../../../../components/LocationsInput'
import {
  getTransactionTiles,
  removeTransactionTiles,
} from '../../../../LandDetails/redux/transaction/transaction.layers'
import {
  estimateEditedSelector,
  estimateIdSelector,
  estimateSelector,
  saveEditedEstimate,
} from '../../../redux'
import { getTransactionFilters } from '../../../../LandDetails/Transaction/TransactionAround/utils'
import EstimateTransactionMapTileModal from './EstimateTransactionMapTileModal'

const fillStyle = 'layer_fill_transaction'

const EstimateTransactionsTiles = ({
  map,
  loaded,
}: {
  map: Map
  loaded?: boolean
}) => {
  const { dispatch, data, loading } = useAsync<any[]>()
  const d = useDispatch()
  const { t } = useI18n()
  const router = useRouter()
  const estimate: any = useSelector(estimateSelector)
  const estimateEdited: any = useSelector(estimateEditedSelector)
  const estimateId = useSelector(estimateIdSelector)
  const geoCenter = estimate?.geo_center

  const [index, setIndex] = useState(0)
  const [feature, setFeature] = useState<any>()
  const [popup, setPopup] = useState<mapboxgl.Popup>()
  const [transactionsIds, setTransactionsIds] = useState<string[]>([])
  const touchScreen = useMediaQuery('(hover: none)')
  const regionsByName = useSelector(regionsByNameSelector)

  const isTransactionTab = router.query.tab === 'transaction'

  const defaultDate =
    regionsByName[estimate.region_name]?.first_transaction_date

  const isEstimate = !!router?.query?.estimateId

  const filters = getTransactionFilters(
    router.query,
    geoCenter,
    defaultDate,
    isEstimate,
  )
  const query = filters && encodeURIComponent(JSON.stringify(filters))
  const { transactionCenter, title } = router.query

  useEffect(() => {
    setTransactionsIds(estimateEdited?.transactionsIds)
  }, [estimateEdited])

  const handleClick = (e: any) => {
    // Use the first found feature.

    dispatch(() =>
      transactionHistoryService.getTransactionsTileDetails(
        e.features.map(
          (f: { properties: { _key: string } }) => f.properties._key,
        ),
        query,
      ),
    )
  }

  const onSave = (transaction: any) => {
    setTransactionsIds((pre) => pre && [...pre, transaction._id])
    d(
      saveEditedEstimate({
        id: estimateId,
        body: {
          ...estimateEdited,
          transactionsIds: transactionsIds?.length
            ? [...transactionsIds, transaction._id]
            : [transaction._id],
        },
      }) as any,
    )
  }

  const onRemove = (transaction: any) => {
    setTransactionsIds((pre) => pre.filter((id) => id !== transaction._id))
    d(
      saveEditedEstimate({
        id: estimateId,
        body: {
          ...estimateEdited,
          transactionsIds: transactionsIds.filter(
            (id) => id !== transaction._id,
          ),
        },
      }) as any,
    )
  }

  const handleMouseMove = (e: any) => {
    map.getCanvas().style.cursor = 'pointer'
    if (popup && (!transactionCenter || !title)) {
      const coordinates = e.features[0].geometry.coordinates.slice()
      const str =
        e.features[0].properties._count > 1
          ? `${t('transaction.Transaction')}s`
          : t('transaction.Transaction')
      const description = `${e.features[0].properties._count} ${str}`

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(map)
    }
  }

  const handleMouseLeave = () => {
    map.getCanvas().style.cursor = ''

    if (popup && (!transactionCenter || !title)) {
      popup.remove()
    }
  }

  const addTransactionTiles = () => {
    try {
      getTransactionTiles({
        map,
        query,
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (touchScreen) setPopup(undefined)
  }, [touchScreen])

  useEffect(() => {
    if (router?.query?.tab !== 'transaction' && map && loaded) {
      removeTransactionTiles({ map })
    }
  }, [router?.query?.tab])

  useEffect(() => {
    setPopup(
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    )
  }, [])

  useEffect(() => {
    if (!map || !loaded || router?.query?.tab !== 'transaction') return

    addTransactionTiles()

    map.on('style.load', addTransactionTiles)
    map.on('click', fillStyle, handleClick)
    map.on('mousemove', fillStyle, handleMouseMove)
    map.on('mouseleave', fillStyle, handleMouseLeave)

    return () => {
      map.off('style.load', addTransactionTiles)
      map.off('click', fillStyle, handleClick)
      map.off('mousemove', fillStyle, handleMouseMove)
      map.off('mouseleave', fillStyle, handleMouseLeave)
    }
  }, [isTransactionTab, loaded, query, title, transactionCenter])

  useEffect(() => {
    if (data?.length) {
      setFeature(data[0])
    }
  }, [data])

  useEffect(() => {
    if (map && loaded && transactionCenter && popup && title) {
      const [lng, lat] = transactionCenter.split(',')

      map.flyTo({ center: [lng, lat], zoom: 17 })
      popup.setLngLat([lng, lat]).setHTML(title).addTo(map)
    } else if (map && loaded && !transactionCenter && popup && !title) {
      popup.remove()
    }
  }, [transactionCenter, map, loaded, popup, title])

  const handleClose = () => {
    setIndex(0)
    setFeature(null)
  }

  const handleChangeIndex = (newIndex: number) => {
    setIndex(newIndex)
  }

  const handlePrevious = () => {
    setIndex((i) => i - 1)
  }

  const handleNext = () => {
    setIndex((i) => i + 1)
  }

  if (!isTransactionTab || !map || !loaded || !data?.length) return null

  const isSaved = transactionsIds?.includes(data[index]?._id)

  const handleOk = () => {
    if (isSaved) {
      onRemove(data[index])
    } else {
      onSave(data[index])
    }
  }

  return (
    <>
      {!!feature && (
        <Modal
          open={!!feature}
          loading={loading}
          onClose={handleClose}
          title={data[index]?.reason || t('transaction.Transaction')}
          okText={
            isSaved
              ? `- ${t('common.remove_transactions')}`
              : `+ ${t('common.add_transaction')}`
          }
          okButtonProps={{ color: isSaved ? 'error' : 'primary' }}
          onOk={handleOk}
        >
          <EstimateTransactionMapTileModal
            data={data}
            loading={loading}
            Details={TransactionDetails}
            previousText={t('common.previous')}
            nextText={t('common.next')}
            notFound={t('common.Not found')}
            index={index}
            handleChangeIndex={handleChangeIndex}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        </Modal>
      )}
    </>
  )
}

export default EstimateTransactionsTiles
