import ComparablesHeader from '../../pages/Estimate/components/EstimateComparables/ComparablesHeader'
import ComparablesImages from '../../pages/Estimate/components/EstimateComparables/ComparablesImages'
import ComparablesMainInformation from '../../pages/Estimate/components/EstimateComparables/ComparablesMainInformation'
import ComparablesDetails from '../../pages/Estimate/components/EstimateComparables/ComparablesDetails'
import ComparablesFeatures from '../../pages/Estimate/components/EstimateComparables/ComparablesFeatures'
import ComparablesDescription from '../../pages/Estimate/components/EstimateComparables/ComparablesDescription'
import ComparablesFooter from '../../pages/Estimate/components/EstimateComparables/ComparablesFooter'

const ListingAccordionBody = ({
  listing,
  comparablesIds,
  onSave,
  onRemove,
  children,
}: {
  listing: any
  comparablesIds: any
  onSave: any
  onRemove: any
  children?: any
}) => (
  <>
    <ComparablesHeader listing={listing} />
    {children}
    <ComparablesImages listing={listing} />
    <ComparablesMainInformation listing={listing} />
    <ComparablesDetails listing={listing} />
    <ComparablesFeatures listing={listing} />
    <ComparablesDescription listing={listing} />
    <ComparablesFooter
      listing={listing}
      comparablesIds={comparablesIds}
      onSave={onSave}
      onRemove={onRemove}
    />
  </>
)

export default ListingAccordionBody
