import { MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FavoriteIcon from '@mui/icons-material/Star'
import UnfavoriteIcon from '@mui/icons-material/StarBorder'
import { useI18n, fetchOnce } from '@popety_io/popety-io-lib'

import {
  addFavorite,
  // getFavoriteByLandIds,
  removeFavorite,
} from '../../pages/Dashboard/redux'
import {
  favoritesSelector,
  userFavoriteLandsSelector,
  addFavoriteLandId,
  removeFavoriteLandId,
  fetchFavorites,
  getUserFavoriteLands,
} from '../../pages/DeveloperMap/redux'
import { isCompanyAdminSelector, sharedSelector } from '../../pages/Login'
import useClearUpEffect from '../../hooks/useClearUpEffect'

export type FavoriteLandOpts = {
  landId: string | string[]
  opacity?: number
}

const useFavoriteLand = ({ landId, opacity = 0.3 }: FavoriteLandOpts) => {
  const dispatch = useDispatch<any>()

  const myFavorites = useSelector(favoritesSelector)
  const userFavoriteLands = useSelector(userFavoriteLandsSelector)
  const shared = useSelector(sharedSelector)
  const isCompanyAdmin = useSelector(isCompanyAdminSelector)

  let landIds: any = Array.isArray(landId) ? landId : [landId]

  landIds = landIds.map((id: any) => +id)

  landIds = landIds.filter(
    (value: any, index: any, array: any) => array.indexOf(value) === index,
  )

  const isFavorite = landIds?.every((id: any) => myFavorites.includes(+id))

  const isFavoriteOwner = landIds?.every((id: any) =>
    userFavoriteLands.includes(+id),
  )

  const canDelete = isFavoriteOwner || isCompanyAdmin

  const { t } = useI18n()

  const handleFavoriteToggle = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
  ) => {
    e.stopPropagation()

    if (!landId) return

    landIds.forEach((id: any) => {
      if (myFavorites.includes(+id)) {
        if (shared?.sharedFavorite && canDelete) {
          dispatch(removeFavorite(id))
          dispatch(removeFavoriteLandId({ landId: id }))
        } else if (!shared?.sharedFavorite) {
          dispatch(removeFavorite(id))
          dispatch(removeFavoriteLandId({ landId: id }))
        }
      } else {
        dispatch(addFavorite(id))
        dispatch(addFavoriteLandId({ landId: id }))
      }
    })
  }

  useClearUpEffect(() => {
    fetchOnce('favorites', null, () => dispatch(fetchFavorites() as any))
    if (shared?.sharedFavorite) {
      fetchOnce('userFavorites', null, () =>
        dispatch(getUserFavoriteLands() as any),
      )
    }
  }, [])

  const title = isFavorite
    ? t('common.favoriteIcon')
    : t('common.unfavoriteIcon')

  const Icon = isFavorite ? (
    <FavoriteIcon
      color={shared?.sharedFavorite && !canDelete ? 'disabled' : 'primary'}
    />
  ) : (
    <UnfavoriteIcon
      sx={{
        opacity,
      }}
    />
  )

  return { title, onClick: handleFavoriteToggle, Icon }
}

export default useFavoriteLand
