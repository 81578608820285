import { i18n } from '@popety_io/popety-io-lib'

const getScoreConfidence = (landIndice?: string) => {
  if (
    landIndice === 'ibus' ||
    landIndice === 'ius' ||
    landIndice === 'cos' ||
    landIndice === 'cus' ||
    landIndice === 'ios' ||
    landIndice === 'im'
  ) {
    return 3
  }

  if (
    landIndice === 'ibus_estimate' ||
    landIndice === 'ius_estimate' ||
    landIndice === 'cos_estimate' ||
    landIndice === 'cus_estimate' ||
    landIndice === 'ios_estimate' ||
    landIndice === 'im_estimate'
  ) {
    return 2
  }

  if (
    landIndice === 'ibus_overide' ||
    landIndice === 'ius_overide' ||
    landIndice === 'cos_overide' ||
    landIndice === 'cus_overide' ||
    landIndice === 'ios_overide' ||
    landIndice === 'im_overide'
  ) {
    return 0
  }

  return 1
}

const getScoreConfidenceData = (landIndice?: string) => ({
  value: getScoreConfidence(landIndice),
  data: [
    {
      color: 'rgba(127, 188, 83, 0.7)',
      value: 3,
      text: i18n.t('common.score.bullet.green'),
      fillCount: 3,
    },
    {
      color: 'rgb(251, 190, 100)',
      value: 2,
      text: i18n.t('common.score.bullet.orange'),
      fillCount: 2,
    },
    {
      color: 'rgba(177, 73, 69, 0.7)',
      value: 1,
      text: i18n.t('common.score.bullet.red'),
      fillCount: 1,
    },
  ],
})

const getLandUsedIndice = (
  land: any,
  bigestLandUsePlan: any = land?.land_use_plans?.[0],
) => {
  try {
    const utilisation =
      bigestLandUsePlan?.typeZone?.ius ||
      bigestLandUsePlan?.typeZone?.utilisation
    const occupation =
      bigestLandUsePlan?.typeZone?.ios ||
      bigestLandUsePlan?.typeZone?.occupation

    let override = false
    let indiceUsed = ''

    const utilisationUsed =
      bigestLandUsePlan?.[`${utilisation}_overide`]?.value ||
      bigestLandUsePlan?.[`${utilisation}Override`]
    const occupationUsed =
      bigestLandUsePlan?.[`${occupation}_overide`]?.value ||
      bigestLandUsePlan?.[`${occupation}Override`]

    if (occupationUsed) {
      indiceUsed = `${occupation}_overide`
      override = true
    }

    if (utilisationUsed) {
      indiceUsed = `${utilisation}_overide`
      override = true
    }

    return override ? indiceUsed : land?.indice_used
  } catch (error) {
    console.error(error)

    return land?.indice_used
  }
}

export { getScoreConfidence, getScoreConfidenceData, getLandUsedIndice }
