import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useI18n, useRouter, useMediaQuery } from '@popety_io/popety-io-lib'
import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconWIthLegends from '../../../components/IconWIthLegends'

import { updateSunlightOptions, sunlightHoursSelector } from '../redux'
import SunMapControls from './SunMapControls'

const ColorBox = ({ children, flex = 1 }: BoxProps) => (
  <Box flex={flex} border="1px solid white" textAlign="center">
    {children}
  </Box>
)

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const SunlightExposureLegend = () => {
  const sunlightHours = useSelector(sunlightHoursSelector)

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const { updateQuery, query } = useRouter()

  const dispatch = useDispatch()
  const { t } = useI18n()

  const [value, setValue] = useState(0)

  const handleTabChange = (event: any) => {
    const newValue = event?.id

    if (newValue === 0) {
      setValue(newValue)
      updateQuery({ sunLightTab: 'sunlight' })
      dispatch(updateSunlightOptions({ showSunExposure: false }))
    } else if (newValue === 1) {
      setValue(newValue)
      updateQuery({ sunLightTab: 'sunExposer' })
      dispatch(updateSunlightOptions({ showSunExposure: true }))
    } else if (newValue === 2) {
      dispatch(updateSunlightOptions({ showSunExposure: false }))
      setValue(newValue)
      setTimeout(() => {
        updateQuery({ sunLightTab: 'sunExposerGraph' })
      }, 500)
    }
  }

  useEffect(() => {
    if (!isDesktop) {
      updateQuery({ mapLg: undefined })
    }
    updateQuery({ displaySunLegend: true })
  }, [query.mapLg, isDesktop])

  useEffect(() => {
    updateQuery({ mapLg: 'sun-hours' })
  }, [])

  useEffect(() => {
    return () => {
      updateQuery({ displaySunLegend: undefined })
      updateQuery({ mapLg: undefined })
    }
  }, [])

  useEffect(() => {
    handleTabChange({ id: 1 })
  }, [])

  const hours = Math.floor(sunlightHours || 0)
  const partial = sunlightHours - hours

  const backgroundImage =
    'linear-gradient(to right, rgb(0 0 255/ 0.5), rgb(0 255 0 / 0.5), rgb(255 0 0 / 0.5))'

  const options = [
    {
      id: 0,
      value: 'sunlight',
      label: t('common.daily_shadow'),
    },
    {
      id: 1,
      value: 'sunExposer',
      label: t('common.daily_sunlight'),
    },
    {
      id: 2,
      value: 'sunExposerGraph',
      label: t('common.annual_sunlight'),
    },
  ]

  // displaySunLegend

  return (
    <IconWIthLegends
      options={options}
      onChange={handleTabChange}
      value={query.sunLightTab || 'sunlight'}
      id="sun-hours"
      displayValue="displaySunLegend"
      keyValue="sunLightTab"
    >
      <Box sx={{ width: '100%' }}>
        <CustomTabPanel value={value} index={0}>
          <SunMapControls />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box
            display="flex"
            height="20px"
            minWidth={300}
            sx={{ backgroundImage }}
            className="print-legend"
          >
            {Array.from(Array(hours).keys()).map((h) => (
              <ColorBox key={h}>{h + 1}</ColorBox>
            ))}
            <ColorBox flex={partial} />
          </Box>
          <SunMapControls />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <span id="tooltip" />
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              flexWrap: 'wrap',
              zIndex: 2000,
            }}
          >
            <div id="output" />
            <button id="download" type="button">
              {t('land.sun.download')}
            </button>
          </div>
          <canvas className="loading" id="canvas" width="0" height="0" />
        </CustomTabPanel>
      </Box>
    </IconWIthLegends>
  )
}

export default SunlightExposureLegend
