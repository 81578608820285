import * as turf from '@turf/turf'

export const getEstimateBbox = (
  featureColletion: any,
  isLivabilityTab: boolean,
  geoCenter?: { lat: number; lon: number },
) => {
  if (!geoCenter) return
  const padding = 0.04

  if (isLivabilityTab) {
    const envelope = featureColletion ? turf.envelope(featureColletion) : null
    const paddedBounds = envelope?.bbox?.map((element: any, index: number) => {
      return index < 2 ? element - padding : element + padding
    })

    return paddedBounds
  }

  const options = { steps: 64, units: 'kilometers', properties: {} }
  const circle = turf.circle(
    [geoCenter.lon, geoCenter.lat] as any,
    2.4,
    options as any,
  )

  const bbox = turf.bbox(circle?.geometry)

  return bbox
}
