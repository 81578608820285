import { Fragment } from 'react'
// TODO: move all mui5 to lib
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid, { GridProps } from '@mui/material/Grid'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'

import {
  Filters,
  formatCurrency,
  useI18n,
  format,
  TransactionDetailsRoot,
} from '@popety_io/popety-io-lib'

export type TransactionDetailsProps = GridProps & {
  data: Record<string, any>
  sx?: any
}

const TransactionDetails = ({
  data: transaction,
  sx,
  children,
}: TransactionDetailsProps) => {
  const { t } = useI18n()

  const items = [
    { title: t('transaction.buyer'), value: transaction.buyers },
    { title: t('transaction.seller'), value: transaction.sellers },
    {
      title: t('transaction.previousTransactionDate'),
      value: transaction.previous_transaction_date,
    },
  ].filter((i) => i.value)

  const chips = [
    { label: transaction.ownership_type, value: transaction.ownership_type },
    {
      label: `N° ${transaction?.official_id}`,
      value: transaction?.official_id,
    },
    {
      label: transaction.land_information,
      value: transaction.land_information,
    },
  ].filter((i) => i.value)

  return (
    <TransactionDetailsRoot container sx={sx}>
      <Grid xs={12} md={12} item>
        <Card className="ControlsCard">
          {(transaction.address || transaction.city) && (
            <Box className="transactionAddress" textTransform="uppercase">
              {String(transaction.address || transaction.city)
                .replaceAll(';', '\n')
                .replace(/,\s*$/, '')}
            </Box>
          )}

          {!!chips?.length && (
            <Box>
              <Filters
                items={chips}
                size="small"
                closeText={t('common.close')}
              />
            </Box>
          )}

          {transaction.price && (
            <Box className="price">
              {formatCurrency(transaction.price, 'CHF ')}
            </Box>
          )}

          <Box className="transactionDate">{`${t(
            'transaction.transactionDate',
          )} ${format(transaction.date, 'PPP')}`}</Box>

          <Box className="transactionTitle">{transaction.reason}</Box>

          <Box pb={1}>
            <Typography
              dangerouslySetInnerHTML={{ __html: transaction.details }}
              fontSize="13px"
              sx={{ height: undefined, marginBottom: 1 }}
            />
          </Box>

          <List sx={{ width: '100%' }}>
            {items.map((a, i) => (
              <Fragment key={i}>
                <Box pb={1} className="transactionTitleSection">
                  {a.title}
                </Box>
                <Box className="transactionValueSection">{a.value}</Box>
              </Fragment>
            ))}
          </List>
        </Card>

        {children}
      </Grid>
    </TransactionDetailsRoot>
  )
}

export default TransactionDetails
