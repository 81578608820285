import Box from '@mui/material/Box'
import {
  useRouter,
  PopetyMaps,
  useI18n,
  PrintIcon,
} from '@popety_io/popety-io-lib'
import { memo, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  MAPBOX_TOKEN,
  STYLE_PREFIX,
  DEFAULT_MAPSTYLE,
} from '../../../../config'
import { transformTileRequest } from '../../../../utils'
import { estimateGeoCenterSelector, estimateHistorySelector } from '../../redux'
import { driveIsochroneLayersSelector } from '../../../LandDetails'
import ControlMapStyleSwitch from '../../../LandDetails/Summary/ControlMapStyleSwitch'
import { buildOptions } from '../../../DeveloperMap/components/Basemap/basemap.utils'
import { mapStyleSelector, changeMapStyle } from '../../../DeveloperMap/redux'
import GeolocMapControls from '../../../../components/MapControl/GeolocMapControls'
import { getHistoryBounds } from '../EstimateHistory/utils'
import { useEstimateMapLayers } from './EstimateMap.layers'
import { MapConfigByPage, MapControllers } from './EstimateMap.config'
import { getEstimateBbox } from './bbox.utils'

export type EstimateMapProps = {
  fullscreen?: boolean
}

const EstimateMap = ({ fullscreen }: EstimateMapProps) => {
  const geoCenter = useSelector(estimateGeoCenterSelector)
  const estimateHistory = useSelector(estimateHistorySelector)
  const drivingIsochroneLayers = useSelector(driveIsochroneLayersSelector)
  const [zoom, setZoom] = useState(18)
  const dispatch = useDispatch()

  const { query, updateQuery } = useRouter()

  const isHomePage = query?.tab === 'home'
  const isLivabilityTab = query?.subTab === 'livability'

  const { t } = useI18n()

  const MapConfig = useMemo(
    () => MapConfigByPage[query.subTab || query.tab],
    [query.subTab, query.tab],
  )

  useEffect(() => {
    if (query.zoom) {
      setZoom(Number(query.zoom))
    }
  }, [query.zoom])

  useEffect(() => {
    updateQuery({ zoom })
  }, [])

  const { data } = useEstimateMapLayers()
  const mapStyle: any = useSelector(mapStyleSelector)

  const minHeight = fullscreen
    ? 'calc(100vh - 160px)'
    : { xs: '300px', md: '40vh' }

  const bbox = useMemo(() => {
    return isHomePage && estimateHistory?.length
      ? getHistoryBounds(estimateHistory)
      : getEstimateBbox(drivingIsochroneLayers, isLivabilityTab, geoCenter)
  }, [estimateHistory, drivingIsochroneLayers, isHomePage])

  const mapProps: any = {
    zoom: bbox ? 7 : zoom,
    bearing: 0,
    bearingSnap: 0,
    dragPan: true,
    dragRotate: true,
    maxZoom: 20,
    minZoom: 7,
    preserveDrawingBuffer: true,
    transformRequest: transformTileRequest as any,
    bbox,
  }

  const options = buildOptions(t, mapStyle)

  const mapConfigStyle = useMemo(() => {
    const style = mapStyle.startsWith(STYLE_PREFIX)
      ? mapStyle.substring(STYLE_PREFIX.length)
      : mapStyle

    return `${STYLE_PREFIX}${style}`
  }, [mapStyle])

  useEffect(() => {
    dispatch(changeMapStyle(MapConfig?.style || DEFAULT_MAPSTYLE))
  }, [MapConfig?.style, query.tab, query.subTab])

  return (
    <Box height="100%" minHeight={minHeight}>
      <PopetyMaps
        dimension="3D"
        token={MAPBOX_TOKEN}
        data={data}
        center={geoCenter}
        isDragPan
        style={mapConfigStyle}
        Hover={MapConfig?.Hover}
        ClickComponent={MapConfig?.Content}
        {...mapProps}
        bbox={bbox}
        sx={{
          minHeight,
          maxHeight: fullscreen
            ? 'calc(100% - 106px)'
            : { xs: 'calc(100% - 106px)', lg: '100%' },
          overflow: 'hidden',
        }}
        zoom={zoom}
      >
        <GeolocMapControls center={geoCenter} zoom={zoom} />
        <MapControllers />
        <PrintIcon name={t('common.myAnalysis.fileName.details')} />
        <ControlMapStyleSwitch options={options.slice(0, 2)} />
      </PopetyMaps>
    </Box>
  )
}

export default memo(EstimateMap)
