/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import { SyntheticEvent } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  FilterChips,
  formatPrice,
  useI18n,
  toReadableNumber,
  format,
  StatusContainer,
  TextOverflow,
} from '@popety_io/popety-io-lib'
import Grid from '@mui/material/Grid'
import { HistoryAccordionProps } from '../components/HistoryAccordion'

export type FaoAccordionProps = HistoryAccordionProps & {
  listing: { [k: string]: any }
}

const ListingAccordionHeader = ({ listing }: FaoAccordionProps) => {
  const { t } = useI18n()

  const chips = [
    {
      label: listing.property_type,
      value: listing.property_type,
      key: 'property_type',
    },
    {
      label: `${t('listing.rooms', { count: listing.rooms_nb })}`,
      value: listing.rooms_nb,
      key: 'rooms_nb',
    },
    {
      label: `${toReadableNumber(listing?.area || 0)} m2`,
      value: listing.area,
      key: 'area',
    },
    {
      label: listing.YearOfConstruction,
      value: listing.YearOfConstruction,
      key: 'YearOfConstruction',
    },
    { label: listing.Floor, value: listing.Floor, key: 'Floor' },
    {
      label: `${toReadableNumber(listing?.UsefulArea || 0)} m2`,
      value: listing.UsefulArea,
      key: 'UsefulArea',
    },
  ].filter(
    (i) =>
      typeof i.value === 'string' ||
      (typeof i.value === 'number' && i.value > 0),
  )

  const calculateDate = (date2: any) => {
    try {
      const newdate1: any = new Date(Date.now())
      const newdate2: any = new Date(date2)

      // Calculate the time difference in milliseconds
      const differenceInMilliseconds = Math.abs(newdate2 - newdate1)

      // Convert the time difference to days
      const differenceInDays = Math.floor(
        differenceInMilliseconds / (24 * 60 * 60 * 1000),
      )

      return differenceInDays
    } catch (e) {
      return 0
    }
  }

  const listingDuration =
    listing?.listing_duration === 0 ? '0' : listing?.listing_duration

  return (
    <Grid
      item
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 12, sm: 12, md: 12 }}
      sx={{
        width: '100%',
      }}
    >
      <Grid item xs={12} sm={3} md={2}>
        <Box>
          <img
            src={
              listing.urls_image?.filter((url: string) => url)?.[0] ||
              '/assets/no-image.jpeg'
            }
            width={80}
            height={54}
            alt="urls_image"
            onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
              const target = e.target as HTMLImageElement

              target.onerror = null
              target.src = '/assets/no-image.jpeg'
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <Box display="flex" flexDirection="column" justifyContent="left">
          <Typography fontWeight={500}>
            {t(`listing.property_type.${listing.property_type}`)}
          </Typography>
          {listing?.price ? (
            <Typography fontSize="0.923rem" fontWeight={500} color="#00ADE6">
              {formatPrice(listing.price, 0)}
            </Typography>
          ) : null}
          {listing.price_information?.SideCost ? (
            <Typography fontSize="0.715rem" color="#969696">
              {formatPrice(listing.price_information.SideCost, 0)}/m2
            </Typography>
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={5}>
        <Box display="flex" flexDirection="column" justifyContent="left">
          <TextOverflow text={listing.title} />
          {!!chips?.length && (
            <FilterChips
              sx={{
                '> .MuiChip-root': {
                  fontSize: '0.9rem',
                },
                flexWrap: 'wrap',
              }}
              items={chips}
              size="small"
              getChipKey={(item: any) => item.key}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <Typography
              color="rgb(141, 155, 163)"
              sx={{ display: 'flex', whiteSpace: 'nowrap' }}
            >
              {t('common.publishedOn')}
              {' : '}
            </Typography>
            <span style={{ color: '#000000', marginLeft: 2 }}>
              {format(listing?.listing_timestamp, 'P')}
            </span>
          </Box>
          <Box display="flex">
            <StatusContainer>
              {listing?.ad_status ? (
                <span className="status active">
                  {t('land.listing.status.active')}
                </span>
              ) : (
                <span className="status expired">
                  {t('land.listing.status.expired')}
                </span>
              )}
            </StatusContainer>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <Typography component="i" color="rgb(141, 155, 163)">
              {t('land.listing.online')}
            </Typography>
            <Typography component="i" color="rgb(141, 155, 163)">
              <span style={{ color: '#000000' }}>{' : '}</span>
            </Typography>
            <Typography component="i" color="rgb(141, 155, 163)">
              <span style={{ color: '#000000' }}>
                {listing?.ad_status && listing.listing_timestamp
                  ? `${t('listing.daysAgo', {
                      days: calculateDate(listing.listing_timestamp),
                    })}`
                  : !listing?.ad_status && listingDuration
                    ? `${t('listing.daysAgo', {
                        days: listingDuration,
                      })}`
                    : 'N/A'}
              </span>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ListingAccordionHeader
