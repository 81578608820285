/* eslint-disable max-lines */
import { useState, memo, useMemo, useEffect } from 'react'
import {
  Menu,
  LanguageMenu,
  useAsync,
  useMediaQuery,
  useI18n,
  Driver,
  fetchOnce,
} from '@popety_io/popety-io-lib'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import Person from '@mui/icons-material/Person'
import Typography from '@mui/material/Typography'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import LogoutIcon from '@mui/icons-material/Logout'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import CachedIcon from '@mui/icons-material/Cached'

import { getColorLimit } from '../../pages/Account/UserPlan/UserPlan.helpers'
import {
  getUserPreferences,
  userIsLoadingSelector,
  getCurrentUserDetails,
  updateTutorials,
} from '../../pages/Account/redux'
import {
  userSelector,
  authRolesSelector,
  changeApp,
  appSelector,
  logout,
  forgetAuthUser,
  isPopetyCompanySelector,
} from '../../pages/Login'
import SupportModal from '../SupportModal'
import { APP_VERSION, LIB_VERSION } from '../../config'
import { API_BASE_URL } from '../../services'
import authService from '../../pages/Login/redux/authService'
import useClearUpEffect from '../../hooks/useClearUpEffect'
import { getMenus, clearAppDataAndCache } from './appMenu.utils'
import getDriverInstructions from './driverInstructions'

// TODO: move to App or Navigation

const AppMenu = () => {
  const [supportModalOpen, setSupportModalOpen] = useState(false)
  const [displayDriver, setDisplayDriver] = useState(false)
  const user = useSelector(userSelector)
  const userIsLoading = useSelector(userIsLoadingSelector)
  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const navigate = useNavigate()

  const dispatch = useDispatch<any>()
  const app = useSelector(appSelector)
  const roles = useSelector(authRolesSelector)
  const isPopetyCompany = useSelector(isPopetyCompanySelector)

  const async = useAsync()

  const showAnalysis = app === 'landdev' || app === null

  const { currentLanguage, setCurrentLanguage, t } = useI18n()

  const prod = API_BASE_URL?.includes('prod')

  const version = [APP_VERSION, !prod && LIB_VERSION, !prod && async.data]
    .filter(Boolean)
    .join(' | ')

  const handleLangageChange = (language: string) => {
    setCurrentLanguage(language)
    // TODO: Remove if unused
    // dispatch(updateLanguage(language))
  }

  const handleLogin = () => {
    navigate(
      { pathname: '/login', search: `?from=${window.location.href}` },
      { replace: true },
    )
  }

  const handleAccountClick = () => {
    navigate('/account')
  }

  const handleLogout = () => {
    dispatch(logout())
    dispatch(forgetAuthUser())
    sessionStorage.clear()
    handleLogin()
  }

  const handleChangeBrokerInfo = () => {
    if (app === 'landdev') {
      dispatch(changeApp('brokerinfo'))
    } else {
      dispatch(changeApp('landdev'))
    }
  }

  useClearUpEffect(() => {
    if (!roles?.includes('User_landdev') && app === 'landdev') {
      dispatch(changeApp('brokerinfo'))
    }

    async.dispatch(() => authService.getApiVersion())
  }, [])

  const handleDrawerOpen = () => {
    dispatch(getCurrentUserDetails())
  }

  const handleSupportModal = (state: boolean) => {
    setSupportModalOpen(state)
  }

  useClearUpEffect(() => {
    if (user) {
      fetchOnce('getUserPreferences', null, () => {
        dispatch(getUserPreferences())
      })
    }
  }, [user])

  useEffect(() => {
    const hasUserDetails = localStorage.getItem('user-details-authorization')

    if (user && !hasUserDetails) {
      fetchOnce('getCurrentUserDetails', user?.id, () =>
        dispatch(getCurrentUserDetails()),
      )
      localStorage.setItem('user-details-authorization', 'true')
    }
  }, [])

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedMenuDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedMenuDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  const isEstimate = user?.roles.includes('User_estimate')

  const menusData = getMenus(onDesktop, showAnalysis, isEstimate, t)

  const isAnalysis =
    user?.plan_name === 'analyse' || user?.plan_name === 'analyse_estimate'

  const menus = {
    title: user ? `${user.first_name} ${user.last_name}` : '',
    subTitle: user?.email || '',
    photo: user?.photo,
    homeUrl: '/',
    data: !user ? [] : menusData,
    footerMenu: [
      {
        text: (
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ fontSize: '1rem' }}
          >
            {version}
          </Typography>
        ),
      },
    ],
    rightMenus: [
      {
        order: 1,
        component: (
          <Box id="langauge-button">
            <LanguageMenu
              lang={currentLanguage as any}
              onChange={handleLangageChange}
            />
          </Box>
        ),
      },
      {
        order: 2,
        text: 'account',
        subMenus: [
          {
            icon: <Person />,
            href: '/account',
            text: t('common.myAccount'),
            hide: false,
          },
          {
            icon: <PeopleAltIcon />,
            href: '/company-dashboard',
            text: t('common.myCompanyDashboard'),
            hide: !user?.roles?.includes('Admin_company'),
          },
        ],
      },
      {
        order: 2,
        subMenus: [
          {
            icon: <LocalLibraryIcon />,
            href: '/e-learning',
            text: t('common.eLearning'),
            hide: false,
          },
        ],
      },
      ...(isPopetyCompany
        ? [
            {
              order: 2,
              text: app === 'landdev' ? 'BrokerInfo' : 'LandDev',
              icon: <PermIdentityIcon />,
              onClick: handleChangeBrokerInfo,
            },
          ]
        : []),
      {
        order: 2,
        subMenus: [
          {
            order: 2,
            onClick: handleLogout,
            text: t('common.signOut'),
            icon: <LogoutIcon />,
          },
        ],
      },
      {
        order: 2,
        subMenus: [
          {
            order: 2,
            onClick: clearAppDataAndCache,
            text: t('common.updateApp'),
            icon: (
              <Box id="update-app-button">
                <CachedIcon />
              </Box>
            ),
          },
        ],
      },
    ] as any,
  }

  const userPlan: any = useMemo(() => {
    return userIsLoading ? (
      <Box sx={{ display: 'flex', columnGap: 1, flexDirection: 'column' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem', borderRadius: 2, width: '45%' }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem', borderRadius: 2, width: '45%' }}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem', borderRadius: 2, width: '45%' }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem', borderRadius: 2, width: '45%' }}
          />
        </Box>
      </Box>
    ) : (
      <Box
        sx={{ display: 'flex', columnGap: 1, flexDirection: 'column' }}
        id="user-plan"
      >
        {user?.plan_name && (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: 2,
              fontSize: '1.17rem',
            }}
          >
            <span style={{ color: 'rgb(141, 155, 163)' }}>
              {t('common.subscription')}:
            </span>
            <span>{t(`common.${user?.plan_name}`)}</span>
          </Box>
        )}
        {isEstimate && (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: 2,
              fontSize: '1.17rem',
            }}
          >
            <span
              style={{
                color: 'rgb(141, 155, 163)',
              }}
            >
              Estimate:
            </span>
            <span>
              <span
                style={{
                  color: getColorLimit(
                    user?.estimationsAnalysisCounter || 0,
                    user?.estimations_nb || 0,
                  ),
                }}
              >
                {user?.estimationsAnalysisCounter || 0}
              </span>{' '}
              <span>/ {user?.estimations_nb}</span>
            </span>
          </Box>
        )}
        {isAnalysis && (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: 2,
              fontSize: '1.17rem',
            }}
          >
            <span
              style={{
                color: 'rgb(141, 155, 163)',
              }}
            >
              {t('common.landDetailAnalysis')}:
            </span>
            <span>
              <span
                style={{
                  color: getColorLimit(
                    user?.totalAnalysisCounter || 0,
                    user?.analysis_nb || 0,
                  ),
                }}
              >
                {user?.totalAnalysisCounter || 0}
              </span>{' '}
              <span>/ {user?.analysis_nb}</span>
            </span>
          </Box>
        )}
        {isAnalysis && (
          <Box
            sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}
            ml={2}
          >
            <span style={{ color: 'rgb(141, 155, 163)' }}>
              {t('common.lands')}:
            </span>
            <span>
              <span
                style={{
                  color: getColorLimit(
                    user?.analysis_counter || 0,
                    user?.analysis_nb || 0,
                  ),
                }}
              >
                {user?.analysis_counter || 0}
              </span>{' '}
              <span>/ {user?.analysis_nb}</span>
            </span>
          </Box>
        )}
        {isAnalysis && (
          <Box
            sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}
            ml={2}
          >
            <span style={{ color: 'rgb(141, 155, 163)' }}>
              {t('common.buildings')}:
            </span>
            <span>
              <span
                style={{
                  color: getColorLimit(
                    user?.buildingAnalysisCounter || 0,
                    user?.analysis_nb || 0,
                  ),
                }}
              >
                {user?.buildingAnalysisCounter || 0}
              </span>{' '}
              <span>/ {user?.analysis_nb}</span>
            </span>
          </Box>
        )}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 2,
            fontSize: '1.17rem',
          }}
        >
          <span style={{ color: 'rgb(141, 155, 163)' }}>
            {t('common.alerts')}:
          </span>
          <span>
            <span
              style={{
                color: getColorLimit(
                  user?.alerts_nb || 0,
                  user?.alerts_limit || 0,
                ),
              }}
            >
              {user?.alerts_nb || 0}
            </span>{' '}
            <span>/ {user?.alerts_limit}</span>
          </span>
        </Box>
        <Driver
          instructions={getDriverInstructions(t)}
          display={displayDriver}
        />
      </Box>
    )
  }, [
    user?.plan_name,
    user?.analysis_counter,
    user?.analysis_nb_limit,
    userIsLoading,
  ])

  const appMenu = useMemo(() => {
    return (
      <>
        <Menu
          {...menus}
          onAvatarClick={handleAccountClick}
          onLogin={user ? undefined : handleLogin}
          onDrawerOpen={handleDrawerOpen}
          sx={{ '&.MenuList': { flexGrow: 1, justifyContent: 'flex-end' } }}
          userPlanSection={userPlan}
          onHelp={() => {
            handleSupportModal(true)
          }}
        />
        {supportModalOpen && (
          <SupportModal
            open={supportModalOpen}
            handleModal={handleSupportModal}
          />
        )}
      </>
    )
  }, [userPlan, user, supportModalOpen, menusData])

  return appMenu
}

export default memo(AppMenu)
