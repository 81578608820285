import Grid from '@mui/material/Grid'
import { useSelector } from 'react-redux'

import { useI18n, useRouter } from '@popety_io/popety-io-lib'
import FormAutocomplete from '../../../../components/FormAutocomplete'
import { transactionAggsSelector } from '../../redux'

const getOpts = (r: any) => ({
  ...r,
  count: r.doc_count,
  value: r.key,
  label: r.key,
})

const TransactionAroundFilters = () => {
  const { t } = useI18n()
  const aggregations = useSelector(transactionAggsSelector)

  const { query } = useRouter()

  const reasons = aggregations.reasons?.reasons?.buckets.map(getOpts)

  const ownershipTypes =
    aggregations.ownership_types?.ownership_types?.buckets.map(getOpts)

  return (
    <>
      {!query.estimateId && (
        <Grid item xs={12} sm={4} md={3}>
          <FormAutocomplete
            options={reasons}
            name="reason"
            placeholder={t('land.transaction.reason')}
            autoSelect={false}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={4} md={3}>
        <FormAutocomplete
          options={ownershipTypes}
          name="ownershipType"
          placeholder={t('land.transaction.ownershipType')}
          autoSelect={false}
        />
      </Grid>
    </>
  )
}

export default TransactionAroundFilters
