import { useState, useEffect } from 'react'
import { Modal, useI18n } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'

import { userDetailsSelector, securityStatusSelector } from '..'
import Security from './Security'

const TwoFaModal = () => {
  const [openModal, setOpenModal] = useState(false)
  const userDetails = useSelector(userDetailsSelector)
  const isTwoFaRequired = userDetails?.two_fa_required
  const securityStatus = useSelector(securityStatusSelector)

  const { t } = useI18n()

  useEffect(() => {
    if (
      isTwoFaRequired &&
      !securityStatus?.enable_authentification &&
      !securityStatus?.enable_sms &&
      securityStatus?.enable_authentification !== undefined &&
      securityStatus?.enable_sms !== undefined
    ) {
      setOpenModal(true)
    } else {
      setOpenModal(false)
    }
  }, [
    isTwoFaRequired,
    securityStatus?.enable_authentification,
    securityStatus?.enable_sms,
  ])

  return (
    <Modal open={openModal} title={t('common.enable_2fa')} loading={false}>
      <Security />
    </Modal>
  )
}

export default TwoFaModal
