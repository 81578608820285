import { lazy } from 'react'
import CropLandscapeIcon from '@mui/icons-material/CropLandscape'
import ApartmentIcon from '@mui/icons-material/Apartment'
import HomeIcon from '@mui/icons-material/InfoOutlined'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import PlaceIcon from '@mui/icons-material/Place'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import FaoCountChip from '../../../LandDetails/Fao/FaoCountChip'
import { TransactionCountChip } from '../../../LandDetails/Transaction'
import ListingCountChip from '../../../LandDetails/Listing/ListingCountChip'

const BuildingDetailsSummary = lazy(() => import('../../Summary'))
const Configuration = lazy(
  () => import('../../Configuration/ConfigurationBuilding'),
)
const Listing = lazy(() => import('../../../LandDetails/Listing'))
const Transaction = lazy(() => import('../../../LandDetails/Transaction'))
const Fao = lazy(() => import('../../../LandDetails/Fao'))
const Livability = lazy(() => import('../../../LandDetails/Livability'))
const Sunlight = lazy(() => import('../../../LandDetails/Sunlight'))
const LandSummary = lazy(() => import('../../LandSummary'))
const Energy = lazy(() => import('../../Energy'))

const getBuildingDetailsMenuData = (t = (k: string) => k) => {
  const pages = [
    {
      icon: <HomeIcon />,
      key: 'summary',
      text: t('building.summary'),
      page: <BuildingDetailsSummary />,
      show: true,
    },
    {
      icon: <ApartmentIcon />,
      key: 'configuration',
      text: t('building.configuration'),
      page: <Configuration />,
      show: true,
    },
    {
      icon: <CropLandscapeIcon />,
      key: 'parcelle',
      text: t('building.info.parcelle'),
      page: <LandSummary />,
      show: true,
    },
    {
      icon: <ElectricBoltIcon />,
      key: 'energy',
      text: t('building.livability.type.energy'),
      page: <Energy />,
      show: true,
    },
    // {
    //   icon: <CrisisAlertIcon />,
    //   key: 'environnement',
    //   text: t('building.environment'),
    //   page: <Configuration />,
    //   show: false,
    // },
    {
      icon: <PlaceIcon />,
      key: 'livability',
      text: t('common.amenities'),
      page: <Livability />,
      show: true,
    },
    {
      icon: <FaoCountChip show />,
      key: 'fao',
      text: t('land.fao'),
      page: <Fao show />,
      show: true,
    },
    {
      icon: <TransactionCountChip show />,
      key: 'transaction',
      text: t('land.transaction'),
      page: <Transaction show />,
      show: true,
    },
    {
      icon: <ListingCountChip />,
      key: 'listing',
      text: t('land.listing'),
      page: <Listing />,
      show: true,
    },
    {
      icon: <Brightness4Icon />,
      key: 'sunlight',
      text: t('building.sunlight'),
      page: <Sunlight />,
      show: true,
    },
  ].filter(Boolean)

  return pages
}

export { getBuildingDetailsMenuData }
