import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { useI18n } from '@popety_io/popety-io-lib'
import {
  DivContainer,
  ContainerOneValue,
  InfoValue,
  SectionTitle,
} from './DevelopingArea.style'

export type DevelopingAreaProps = {
  planDisplay?: string
  hover?: boolean
  feature: {
    land_use_plans?: any[]
    future_land_use_plans: any[]
    [k: string]: any
  }
}

export const StyledSpan = styled(Box)`
  font-size: 1.1rem;
  font-weight: 500;
`

const DevelopingArea = ({
  feature,
  planDisplay = 'now',
  hover,
}: DevelopingAreaProps) => {
  const { t } = useI18n()

  const sortedLandUsePlans =
    feature?.land_use_plans &&
    feature?.land_use_plans
      ?.map((lup) => lup)
      ?.sort((a, b) => b.cover_area_perc - a.cover_area_perc)

  const sortedFutureLandUsePlans =
    feature?.future_land_use_plans &&
    feature?.future_land_use_plans
      ?.map((lup) => lup)
      ?.sort((a, b) => b.cover_area_perc - a.cover_area_perc)

  return (
    <>
      {!hover && (
        <SectionTitle>
          <StyledSpan> {t('common.search.details.affectation')}</StyledSpan>
          {Number.isInteger(feature?.developmentScoreOveride) && (
            <Tooltip title={t('common.search.details.edited')}>
              <img alt="lang" src="/assets/edit.png" height={20} />
            </Tooltip>
          )}
        </SectionTitle>
      )}

      <DivContainer>
        <div>
          {planDisplay !== 'future' && !hover && (
            <>
              {sortedLandUsePlans?.map((lup: any, index: number) => (
                <InfoValue key={index}>
                  <span>
                    {lup.plan_type} - {lup.name}
                  </span>

                  <span>
                    {Math.round(lup.cover_area_perc * 100) === 0
                      ? '< 1'
                      : Math.round(lup.cover_area_perc * 100)}
                    %
                  </span>
                </InfoValue>
              ))}

              {!sortedLandUsePlans?.length &&
                (feature?.main_lupa_name || feature?.lupa_name) && (
                  <InfoValue>
                    <span>{feature?.main_lupa_name || feature?.lupa_name}</span>
                  </InfoValue>
                )}
            </>
          )}

          {planDisplay !== 'future' && hover && (
            <>
              {feature?.main_lupa_name || feature?.lupa_name ? (
                <InfoValue style={{ marginTop: '8px' }}>
                  <div className="oneValue">
                    <span>
                      {feature?.main_lupa_name
                        ? `${feature?.main_lupa_type} - ${feature?.main_lupa_name}`
                        : feature?.lupa_name}
                    </span>
                  </div>
                  <i>
                    {feature?.lupa_number > 1 &&
                      `+ ${feature.lupa_number - 1} ${t(
                        'common.search.details.otherLandUsePlans',
                      )}`}
                  </i>
                </InfoValue>
              ) : (
                <span>{t('common.search.details.noLandUsePlans')}</span>
              )}
            </>
          )}
        </div>

        <div>
          {planDisplay !== 'now' &&
            !hover &&
            sortedFutureLandUsePlans?.map((lup: any, index: number) => (
              <InfoValue key={index} className="blue">
                <span>
                  {lup.plan_type} - {lup.name}
                </span>
                <span>{Math.round(lup.cover_area_perc * 100)}%</span>
              </InfoValue>
            ))}

          {planDisplay !== 'now' && hover && (
            <>
              {feature?.future_main_lupa_name ? (
                <ContainerOneValue>
                  <div className="oneFutureValue">
                    <span>
                      {feature?.future_main_lupa_type} -{' '}
                      {feature?.future_main_lupa_name}
                    </span>
                  </div>
                  <i>
                    {feature?.future_lupa_number > 1 &&
                      `+ ${feature.future_lupa_number - 1} ${t(
                        'common.search.details.otherLandUsePlans',
                      )}`}
                  </i>
                </ContainerOneValue>
              ) : (
                <span>{t('common.search.details.noLandUsePlans')}</span>
              )}
            </>
          )}
        </div>
      </DivContainer>
    </>
  )
}

export default DevelopingArea
