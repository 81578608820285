const useEstimateHistory = ({ element }: any) => {
  const expirationDate = element?.expiration_date
  const hash = element?.hash

  const title = element?.value_edited?.title || 'Estimation'

  const value =
    element?.value_edited || element?.value || element?.default_value

  const geo_center =
    element?.value?.geoCenter || element?.default_value?.geo_center

  const area =
    element?.value_edited?.area ||
    element?.value?.area ||
    element?.default_value?.area

  const price =
    value?.price ||
    ((element?.price?.valueRange?.lower || 0) +
      (element?.price?.valueRange?.upper || 0)) /
      2

  const priceM2 = price / (area || 0)

  const dwellingId =
    element?.dwelling_id || element?.value?.dwellingSelected?.id

  const type =
    element?.value_edited?.type ||
    element?.default_value?.type ||
    element?.value?.type

  const rooms =
    element?.value_edited?.rooms ||
    element?.value?.rooms ||
    element?.default_value?.rooms

  const constructionYear =
    element?.value_edited?.construction_date ||
    element?.value?.construction_date ||
    element?.default_value?.construction_date

  const condition =
    element?.value_edited?.condition || element?.default_value?.condition

  const renovationYear =
    element?.value_edited?.renovationYear ||
    element?.default_value?.renovationYear

  return {
    title,
    value,
    geo_center,
    area,
    price,
    priceM2,
    dwellingId,
    type,
    rooms,
    constructionYear,
    condition,
    renovationYear,
    expirationDate,
    hash,
  }
}

export default useEstimateHistory
