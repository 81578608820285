import { fromFilter, parseGeoJSON } from '@popety_io/popety-io-lib'

/**
 * Get transaction filters from url query
 */
const getTransactionFilters = (query: Record<string, any>) => {
  let { value } = query
  const { orderBy = 'date:desc' } = query

  if (query.landId) {
    value = `LAND_ID:${query.landId}`
  } else if (query.id) {
    value = `ID:${query.id}`
  }

  return {
    value,
    date: fromFilter(query.date, true),
    keyword: fromFilter(query.keyword),
    area: fromFilter(query.area, true),
    price: fromFilter(query.price, true),
    ownershipType: fromFilter(query.ownershipType),
    reason: fromFilter(query.reason),
    region: fromFilter(query.region),
    zone: fromFilter(query.zone),
    city: fromFilter(query.city),
    landUsePlan: fromFilter(query.landUsePlan),
    polygon: parseGeoJSON(query.polygon),
    size: +query.size || 25,
    page: +query.page || 0,
    ownershipTypeSize: +query.ownershipTypeSize || 1000,
    reasonSize: +query.reasonSize || 1000,
    view: query.view,
    orderBy,
  }
}

export { getTransactionFilters }
