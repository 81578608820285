import chroma from 'chroma-js'

const scoreColors = ['ffbfbf', 'ffc3a5', 'FFE768', '8ED1A5', '00441b']

export const getScoreColore = (score = 0, value = 100) =>
  chroma
    .scale(scoreColors)(score / value)
    .hex()

export const calculateUnderExploitedScore = (
  std: number,
  current: number,
  value: number,
) => {
  if (std === 0) return 0
  if (value === 0) return 0

  return Number(
    Math.round(Math.max(0, (1 - Number(current) / Number(value)) * 100)),
  )
}

export const calculateDevelopmentScore = (
  under_exploited_score: number,
  geo_score: number,
  legacy_score: number,
  livability_score: number,
) => {
  return Math.min(
    Math.max(
      Number(
        Math.round(
          (under_exploited_score -
            0.35 * (100 - geo_score) +
            0.5 * ((100 - under_exploited_score) / 100.0) * legacy_score) *
            0.9 +
            livability_score / 10.0,
        ),
      ),
      0,
    ),
    100,
  )
}

export const calculateGeoScore = (
  area: any,
  maxLength: any,
  altitudeMin: any,
  altitudeMax: any,
) => {
  if (area === 0) {
    return 0
  }

  return Number(
    Math.round(
      Math.max(
        0,
        Math.min(1, (area / (maxLength * maxLength)) * 2) * 100 -
          (altitudeMax - altitudeMin),
      ),
    ),
  )
}

const calculateConditionScore = (x: number) =>
  Number(
    Math.round(
      Math.min(
        Math.max(
          -0.00000004 * x ** 4 +
            0.00004 * x ** 3 -
            0.012 * x ** 2 +
            1.7672 * x -
            6.5567,
          0,
        ),
        100,
      ),
    ),
  )

export const calculateLegacyScore = (
  highestProtectedRank: number,
  averageConditionYear: number,
  area: number,
  ios: number,
  currentIos: number,
) => {
  if (highestProtectedRank > 2) {
    return 1
  }

  if (averageConditionYear) {
    return calculateConditionScore(
      new Date().getFullYear() - averageConditionYear,
    )
  }

  return calculateUnderExploitedScore(area, ios, currentIos)
}
