import { createSelector } from '@reduxjs/toolkit'

const actionHistorySelector = (state) => state.actionHistory.actionHistory

const activityBuildingSelector = (state) => state.actionHistory.activityHistory

const countActivityBuildingSelectorSelector = (state) =>
  state.actionHistory.countsHistoryTotal || 0

const actionHistoriesSelector = createSelector(actionHistorySelector, (h) =>
  Object.values(h).sort(
    (a, b) =>
      new Date(b.create_timestamp).getTime() -
      new Date(a.create_timestamp).getTime(),
  ),
)

const notesSelector = createSelector(actionHistoriesSelector, (h) =>
  h.filter((actionHistory) => actionHistory.action === 'note'),
)

const remindersSelector = createSelector(actionHistoriesSelector, (h) =>
  h.filter((actionHistory) => actionHistory.action === 'reminder'),
)

const countBuildingCategoriesSelector = (state) =>
  state.actionHistory.countsHistory

export {
  notesSelector,
  actionHistoriesSelector,
  remindersSelector,
  activityBuildingSelector,
  countActivityBuildingSelectorSelector,
  countBuildingCategoriesSelector,
}
