import AddAlertIcon from '@mui/icons-material/AddAlert'
import Box from '@mui/material/Box'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import Tooltip from '@mui/material/Tooltip'
import AddReminderIcon from '@mui/icons-material/AddAlarm'
import IconButton from '@mui/material/IconButton'
import { useSelector } from 'react-redux'
import { useState } from 'react'

import Hidden from '@mui/material/Hidden'
import { useI18n } from '@popety_io/popety-io-lib'
import FavoriteLandIcon from '../../FavoriteLandIcon'
// import { TagIcon } from '../../Tag'
import ProspectIcon from '../../ProspectIcon'
import { landdevSelector } from '../../../pages/Login'
import RememberDialog from '../../LandNote/RememberDialog'
import { getRadiusQuery } from './AnalysisAndFavorites.util'
import { StyledTooltip } from './AnalysisAndFavorites.style'

export type AnalysisAndFavoritesProps = {
  feature: Record<string, any>
  grouping?: boolean
  onGrouping?: () => void
}

const AnalysisAndFavorites = ({
  feature,
  grouping,
  onGrouping,
}: AnalysisAndFavoritesProps) => {
  const { t } = useI18n()
  const landId = feature?.land_id || feature?.id

  const landdev = useSelector(landdevSelector)
  const [openRemember, setOpenRemember] = useState(false)

  const search = getRadiusQuery({ feature })

  const handleAlertCreate = () => {
    window.open(`/my-alerts${search}`, '_blank')
  }

  const handleReminderClick = () => {
    setOpenRemember(true)
  }

  const closeReminder = () => {
    setOpenRemember(false)
  }

  return (
    <Box display="flex" alignItems="center" gap="8px">
      {!grouping && <FavoriteLandIcon landId={landId} opacity={1} />}
      {!grouping && <ProspectIcon landId={landId} opacity={1} />}
      {landdev && onGrouping && (
        <>
          <Hidden smDown>
            <Tooltip
              title={t('common.regrouping')}
              placement="top"
              sx={{ cursor: 'pointer' }}
              onClick={onGrouping}
            >
              <AddCircleOutlineOutlinedIcon
                fontSize="small"
                color={grouping ? 'primary' : undefined}
              />
            </Tooltip>
          </Hidden>
          <Hidden smUp>
            <IconButton disabled>
              <AddCircleOutlineOutlinedIcon fontSize="small" />
            </IconButton>
          </Hidden>
        </>
      )}
      {!grouping && (
        <>
          <StyledTooltip
            title={t('common.newAlert')}
            placement="top"
            onClick={handleAlertCreate}
          >
            <AddAlertIcon fontSize="small" />
          </StyledTooltip>
        </>
      )}
      <StyledTooltip
        title={t('common.info.reminderIcon')}
        placement="top"
        onClick={handleReminderClick}
      >
        <AddReminderIcon fontSize="small" />
      </StyledTooltip>
      <RememberDialog
        open={openRemember}
        onClose={closeReminder}
        landId={landId}
      />
    </Box>
  )
}

export default AnalysisAndFavorites
