import { ApiClient } from '../../../../services'

const client = new ApiClient()

type GetListingOpts = {
  value?: string
  id?: string | number | string[] | number[]
  landId?: string | number | string[] | number[]
  size?: number
  [k: string]: any
}

const getLandListings = ({ value, id, landId, size = 100 }: GetListingOpts) =>
  client.post('/listing/search', { value, id, landId, size })

const getListingAround = async ({ ...filters }: GetListingOpts) => {
  return client.post(`/land/listings`, filters)
}

const countListings = (filter: Record<string, any>) =>
  client.post('/listing/count', filter)

const countLandListings = ({ landId, dealType = 'rent' }: GetListingOpts) =>
  client.post('/listing/count', { landId, dealType: [dealType] })

const getListingAggs = (filter: Record<string, any>) =>
  client.post('/listing/aggregations', { ...filter, size: 1000 })

const getApartmentListingAggs = (filter: Record<string, any>) => {
  return client.post('/listing/aggregations', {
    ...filter,
    size: 1000,
    propertyType: ['Apartment'],
  })
}

const getHouseListingsAggs = (filter: Record<string, any>) => {
  return client.post('/listing/aggregations', {
    ...filter,
    size: 1000,
    propertyType: ['House'],
  })
}

const getListingsByIds = ({ listingsIds }: any) => {
  return client.get(`/listing/${listingsIds}`)
}

const getSelectedEstimateListingsByIds = ({ listingsIds }: any) => {
  return client.get(`/listing/${listingsIds}`)
}

const listingService = {
  getLandListings,
  getListingAround,
  countListings,
  countLandListings,
  getListingAggs,
  getApartmentListingAggs,
  getHouseListingsAggs,
  getListingsByIds,
  getSelectedEstimateListingsByIds,
}

export default listingService
