import { Box } from '@mui/material'
import QRCode from 'react-qr-code'
import { Alert, Modal, useI18n } from '@popety_io/popety-io-lib'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'

interface IProps {
  open: boolean
  qr: string
  onSubmit: (code: string, type: 'sms' | 'authenticator') => void
  loading: boolean
  onClose: () => void
  errorMess?: string
}
const VerifyModal = ({
  open,
  qr,
  onSubmit,
  loading,
  onClose,
  errorMess,
}: IProps) => {
  const [values, setValues] = useState({ code: '' })
  const [errors, setErrors] = useState({ code: '' })

  const { t } = useI18n()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value.replace(/\D/, '') })
    setErrors({ ...errors, [e.target.name]: null })
  }

  useEffect(() => {
    if (!open) {
      setValues({ code: '' })
      setErrors({ code: '' })
    }
  }, [open])

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('common.enable_2fa')}
      onOk={() => onSubmit(values.code, qr ? 'authenticator' : 'sms')}
      loading={loading}
      okText={t('common.submit')}
      cancelText={t('common.close')}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: 4,
          }}
        >
          {!!qr && <QRCode value={qr} />}
        </Box>
        <TextField
          fullWidth
          id="code"
          label={t('common.code')}
          margin="dense"
          type="text"
          name="code"
          value={values.code}
          onChange={handleChange}
          error={!!errors.code}
          helperText={errors.code}
          variant="standard"
        />
        <Alert open={!!errorMess} error={!!errorMess}>
          {errorMess}
        </Alert>
      </Box>
    </Modal>
  )
}

export default VerifyModal
