/* eslint-disable no-param-reassign */
import { mapBy } from '@popety_io/popety-io-lib'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import actionHistoryService from './actionHistoryService'

const initialState = {
  actionHistory: {},
  activityHistory: [],
}

export const getActionHistory = createAsyncThunk(
  'actionHistory/getActionHistory',
  actionHistoryService.getActionHistory,
)

export const addNote = createAsyncThunk(
  'actionHistory/addNote',
  actionHistoryService.addNote,
)

export const updateNote = createAsyncThunk(
  'actionHistory/updateNote',
  actionHistoryService.updateNote,
)

export const removeNote = createAsyncThunk(
  'actionHistory/removeNote',
  actionHistoryService.removeNote,
)

export const addReminder = createAsyncThunk(
  'actionHistory/addReminder',
  actionHistoryService.addReminder,
)

export const updateReminder = createAsyncThunk(
  'actionHistory/updateReminder',
  actionHistoryService.updateReminder,
)

export const removeReminder = createAsyncThunk(
  'actionHistory/removeReminder',
  actionHistoryService.removeReminder,
)

export const getActivityHistoryBuilding = createAsyncThunk(
  'actionHistory/getActivityHistoryBuilding',
  actionHistoryService.getActivityHistoryBuilding,
)

const actionHistory = createSlice({
  name: 'actionHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActionHistory.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.actionHistory = {
          ...state.actionHistory,
          ...mapBy(
            'uid',
            data.map((r) => ({
              ...r,
              uid: `${r.action}_${r.note_id || r.id}`,
            })),
          ),
        }
      })
      .addCase(addNote.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        if (data) {
          const note = data[0]

          if (note?.action) {
            state.actionHistory[
              `${note.action || 'note'}_${note.note_id || note.id}`
            ] = note
          }
        }
      })
      .addCase(removeNote.fulfilled, (state, action) => {
        const { noteId } = action.payload || {}

        if (noteId) {
          delete state.actionHistory[`note_${noteId}`]
        }
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        const value = action.payload || {}

        if (value) {
          state.actionHistory[`note_${value.note_id}`] = {
            ...state.actionHistory[`note_${value.note_id}`],
            ...value,
          }
        }
      })
      .addCase(addReminder.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const input = action.meta.arg

        if (!data.land_id) {
          data.land_id = input.landId
        }

        if (data) {
          state.actionHistory[`${data.action || 'reminder'}_${data.id}`] = data
        }
      })
      .addCase(removeReminder.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const input = action.meta.arg

        if (data) {
          delete state.actionHistory[`reminder_${input.id}`]
        }
      })
      .addCase(updateReminder.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const input = action.meta.arg

        if (data) {
          state.actionHistory[`reminder_${input.id}`] = {
            ...state.actionHistory[`reminder_${input.id}`],
            ...input,
          }
        }
      })
      .addCase(getActivityHistoryBuilding.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.activityHistory = data.results
        state.countsHistory = data.counts
        state.countsHistoryTotal = data.total
      })
  },
})

export const actionHistoryReducer = {
  actionHistory: actionHistory.reducer,
}
