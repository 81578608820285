/* eslint-disable max-lines */
import { formatPrice, useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const comparablesMainInformation = ({ listing }: { listing: any }) => {
  const { t } = useI18n()

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: '5px',
              paddingTop: '5px',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                paddingBottom: '4px',
              }}
              fontSize="1.2rem"
            >
              {t('land.fao.price')}
            </Typography>
            <Typography
              color="primary"
              variant="h4"
              fontWeight="bold"
              fontSize="1.5rem"
            >
              {listing.price ? formatPrice(listing.price) : '-'}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: '#969696',
                paddingBottom: '10px',
              }}
              fontSize="1rem"
            >
              {listing?.price &&
                listing?.area > 0 &&
                `${formatPrice(Math.round(listing.price / listing.area))} / m2`}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: '5px',
              paddingTop: '5px',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                paddingBottom: '4px',
              }}
              fontSize="1.2rem"
            >
              {t('common.type')}
            </Typography>
            <Typography
              color="primary"
              variant="h4"
              fontWeight="bold"
              fontSize="1.3rem"
            >
              {`${t(`land.property_type.${listing.property_type}`)} / ${t(
                `land.property_type.${listing.property_category}`,
              )}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: '5px',
              paddingTop: '5px',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                paddingBottom: '4px',
              }}
              fontSize="1.2rem"
            >
              {t('common.rooms')}
            </Typography>
            <Typography
              color="primary"
              variant="h4"
              fontWeight="bold"
              fontSize="1.5rem"
            >
              {listing?.rooms_nb > 0 ? listing?.rooms_nb : '-'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: '5px',
              paddingTop: '5px',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                paddingBottom: '4px',
              }}
              fontSize="1.2rem"
            >
              {t('financial.Surface habitable')}
            </Typography>
            <Typography
              color="primary"
              variant="h4"
              fontWeight="bold"
              fontSize="1.5rem"
            >
              {listing.area > 0 ? `${listing.area} m2` : '-'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default comparablesMainInformation
